import axios from 'axios'
import config from '../utils/configuration'

const {target, appVersion} = config
// Action Creators
export const UPDATE_QUERY = 'UPDATE_QUERY'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const FETCH_RESULTS_NONE = 'FETCH_RESULTS_NONE'
export const FETCH_RESULTS_START = 'FETCH_RESULTS_START'
export const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS'
export const FETCH_RESULTS_ERROR = 'FETCH_RESULTS_ERROR'
export const FETCH_ARTICLE_START = 'FETCH_ARTICLE_START'
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_ERROR = 'FETCH_ARTICLE_ERROR'
export const VIEW_EMAIL_FORM = 'VIEW_EMAIL_FORM'
export const CAPTURE_EMAIL = 'CAPTURE_EMAIL'
export const EMAIL_ARTICLE_SUCCESS = 'EMAIL_ARTICLE_SUCCESS'
export const EMAIL_ARTICLE_ERROR = 'EMAIL_ARTICLE_ERROR'
export const RESEND_ARTICLE = 'RESEND_ARTICLE'
export const GET_COMMENTS_START = 'GET_COMMENTS_START'
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS'
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR'
export const CAPTURE_COMMENT = 'CAPTURE_COMMENT'
export const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS'
export const HIDE_COMMENTS = 'HIDE_COMMENTS'
export const FETCH_PROFILE_START = 'FETCH_PROFILE_START'
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const LATEST_ARTICLES_SUCCESS = 'LATEST_ARTICLES_SUCCESS'
export const VIEW_LIKES_START = 'VIEW_LIKES_START'
export const VIEW_LIKES_SUCCESS = 'VIEW_LIKES_SUCCESS'
export const VIEW_LIKES_ERROR = 'VIEW_LIKES_ERROR'
export const FETCH_GUIDE_START = 'FETCH_GUIDE_START'
export const FETCH_GUIDE_SUCCESS = 'FETCH_GUIDE_SUCCESS'
export const FETCH_GUIDE_ERROR = 'FETCH_GUIDE_ERROR'
export const CLEAR_GUIDE = 'CLEAR_GUIDE'
export const FETCH_TOPICS_START = 'FETCH_TOPICS_START'
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS'
export const FETCH_TOPICS_ERROR = 'FETCH_TOPICS_ERROR'
export const FETCH_LIBRARY_START = 'FETCH_LIBRARY_START'
export const FETCH_LIBRARY_SUCCESS = 'FETCH_LIBRARY_SUCCESS'
export const FETCH_LIBRARY_ERROR = 'FETCH_LIBRARY_ERROR'
export const FETCH_BLOG_START = 'FETCH_BLOG_START'
export const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS'
export const FETCH_BLOG_ERROR = 'FETCH_BLOG_ERROR'

// ***************************** Article search

export const updateQuery = (key, value) => {
  return {
    type: UPDATE_QUERY,
    key,
    value,
  }
}

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  }
}

const fetchResultsStart = () => {
  return {
    type: FETCH_RESULTS_START,
  }
}

const fetchResultsSuccess = (data, flag) => {
  return {
    type: FETCH_RESULTS_SUCCESS,
    payload: data,
    flag,
  }
}

const fetchResultsNone = () => {
  return {
    type: FETCH_RESULTS_NONE,
  }
}

const fetchResultsError = () => {
  return {
    type: FETCH_RESULTS_ERROR,
  }
}

export const newSearch = (
  querySubmitted,
  typeSubmitted,
  topicSubmitted,
  themeSubmitted,
  sourceTypeSubmitted,
  skipSubmitted,
  userID,
  versionSubmitted,
  articleID
) => {
  let query = querySubmitted || '-'
  let type = typeSubmitted || '*'
  let topic = topicSubmitted || '*'
  const theme = themeSubmitted || '*'
  let sourceType = sourceTypeSubmitted || '*'
  const skip = skipSubmitted || 0
  const version = versionSubmitted || 's'

  if (query.includes('/')) query = query.replace('/', '%2F')
  if (type.includes('/')) type = type.replace('/', '%2F')
  if (topic.includes('/')) topic = topic.replace('/', '%2F')
  if (sourceType.includes('/')) sourceType = sourceType.replace('/', '%2F')

  return async (dispatch) => {
    !skipSubmitted && dispatch(fetchResultsStart())
    try {
      const response = await axios.get(
        `${target}/search/${query}/${type}/${topic}/${theme}/${sourceType}/${skip}/${userID}/${version}/${appVersion}/${articleID}`
      )
      const flag = skipSubmitted ? 'add' : 'replace'
      if (response.status === 200) {
        dispatch(fetchResultsSuccess(response.data, flag))
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        dispatch(fetchResultsNone())
      } else {
        dispatch(fetchResultsError())
      }
    }
  }
}

// ***************************** View article

const fetchArticleStart = () => ({type: FETCH_ARTICLE_START})

const fetchArticleSuccess = (article) => ({
  type: FETCH_ARTICLE_SUCCESS,
  article,
})

const fetchArticleError = () => ({type: FETCH_ARTICLE_ERROR})

export const getArticle = (query, userID) => {
  return async (dispatch) => {
    dispatch(fetchArticleStart())
    try {
      const response = await axios.get(`${target}/getArticle/${query}/${userID}`)
      if (response.status === 200) dispatch(fetchArticleSuccess(response.data))
    } catch (e) {
      console.log('Get Article Error', e)
      dispatch(fetchArticleError())
    }
  }
}

// ***************************** Email article

export const viewEmailForm = (articleID) => {
  return {
    type: VIEW_EMAIL_FORM,
    articleID,
  }
}

export const captureEmail = (email) => {
  return {
    type: CAPTURE_EMAIL,
    email,
  }
}

const emailArticleSuccess = () => ({type: EMAIL_ARTICLE_SUCCESS})

export const resendArticle = () => ({type: RESEND_ARTICLE})

const emailArticleError = () => {
  return {
    type: EMAIL_ARTICLE_ERROR,
  }
}

export const emailArticle = (articleID, userID, email) => {
  return async (dispatch) => {
    try {
      const sendEmail = await axios.get(
        `${config.target}/emailArticle/${articleID}/${userID}/${email}`
      )
      if (sendEmail.status === 200) dispatch(emailArticleSuccess())
    } catch (e) {
      console.log('Email Article Error', e)
      dispatch(emailArticleError())
    }
  }
}

// ***************************** Comment

export const getCommentsStart = (ID) => ({
  type: GET_COMMENTS_START,
  ID,
})

export const getCommentsSuccess = (comments) => {
  return {
    type: GET_COMMENTS_SUCCESS,
    comments,
  }
}

export const getCommentsError = () => {
  return {
    type: GET_COMMENTS_ERROR,
  }
}

export const captureComment = (comment) => {
  return {
    type: CAPTURE_COMMENT,
    comment,
  }
}

const updateCommentsSuccess = (ID, comments) => {
  return {
    type: UPDATE_COMMENTS_SUCCESS,
    ID,
    comments,
  }
}

export const hideComments = () => {
  return {
    type: HIDE_COMMENTS,
  }
}

export const viewComments = (articleID) => {
  return async (dispatch) => {
    dispatch(getCommentsStart(articleID))
    try {
      const comments = await axios.get(`${config.target}/getComments/${articleID}`)
      if (comments.status === 200) dispatch(getCommentsSuccess(comments.data))
    } catch (e) {
      console.log('Email Article Error', e)
      dispatch(emailArticleError())
    }
  }
}

export const submitComment = (label, articleID, userID, comment) => {
  return async (dispatch) => {
    try {
      const submitComment = await axios.post(`${config.target}/comment`, {
        label,
        articleID,
        userID,
        comment,
      })
      if (submitComment.status === 200)
        dispatch(updateCommentsSuccess(articleID, submitComment.data))
    } catch (e) {
      console.log('Email Article Error', e)
      // dispatch(emailArticleError())
    }
  }
}

// ***************************** View Likes

const viewLikesStart = (ID) => {
  return {
    type: VIEW_LIKES_START,
    ID,
  }
}

const viewLikesSuccess = (ID, data) => {
  return {
    type: VIEW_LIKES_SUCCESS,
    ID,
    data,
  }
}

const viewLikesError = (ID, data) => {
  return {
    type: VIEW_LIKES_SUCCESS,
    ID,
    data,
  }
}

export const viewLikes = (articleID) => {
  return async (dispatch) => {
    try {
      dispatch(viewLikesStart())
      const likes = await axios.get(`${config.target}/getLikes/${articleID}`)
      if (likes.status === 200) dispatch(viewLikesSuccess(articleID, likes.data))
    } catch (e) {
      console.log('Email Article Error', e)
      dispatch(viewLikesError())
    }
  }
}

// ***************************** View User Profile

const fetchProfileStart = () => ({type: FETCH_PROFILE_START})

const fetchProfileError = () => ({type: FETCH_PROFILE_ERROR})

const fetchProfileSuccess = (result) => ({
  type: FETCH_PROFILE_SUCCESS,
  result,
})

export const viewProfile = (user) => {
  return async (dispatch) => {
    dispatch(fetchProfileStart())
    try {
      const response = await axios.get(`${target}/viewProfile/${user}`)
      if (response.status === 200) dispatch(fetchProfileSuccess(response.data))
    } catch (e) {
      console.log('Fetch Profile Error', e)
      dispatch(fetchProfileError())
    }
  }
}

// ***************************** Latest articles

export const latestArticlesSuccess = (results) => {
  return {
    type: LATEST_ARTICLES_SUCCESS,
    results,
  }
}

export const getLatestArticles = (theme, articleID, search) => {
  if (!articleID) articleID = '*'
  if (search) search = search.split('=')[1]
  // if (!search) search = '*'
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${target}/latestArticles/${theme}/${articleID}/${search}`
      )
      if (response.status === 200) dispatch(latestArticlesSuccess(response.data))
    } catch (e) {
      console.log('Get Latest Articles Error', e)
    }
  }
}

// ***************************** Flag content

export const flagArticle = (articleID) => {
  return async (dispatch) => {
    try {
      const submitComment = await axios.get(
        `${config.target}/flagArticle/${articleID}`
      )
    } catch (e) {
      console.log('Flag Article Error', e)
      // dispatch(emailArticleError())
    }
  }
}

// ***************************** Get Topics

const fetchTopicsStart = () => ({type: FETCH_TOPICS_START})

const fetchTopicsError = () => ({type: FETCH_TOPICS_ERROR})

const fetchTopicsSuccess = (topics) => ({
  type: FETCH_TOPICS_SUCCESS,
  topics,
})

export const getTopicList = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchTopicsStart())
      const List = await axios.get(`${config.target}/topicList/guides`)
      dispatch(fetchTopicsSuccess(List.data))
    } catch (e) {
      console.log('Flag Article Error', e)
      dispatch(fetchTopicsError())
    }
  }
}

// ***************************** View Guide

const fetchGuideStart = () => ({type: FETCH_GUIDE_START})

const fetchGuideError = () => ({type: FETCH_GUIDE_ERROR})

const fetchGuideSuccess = (guide) => ({
  type: FETCH_GUIDE_SUCCESS,
  guide,
})

export const clearGuide = () => ({type: CLEAR_GUIDE})

export const viewGuide = (guide) => {
  return async (dispatch) => {
    try {
      dispatch(fetchGuideStart())
      const Guide = await axios.get(`${config.target}/viewGuide/${guide}`)
      dispatch(fetchGuideSuccess(Guide.data))
    } catch (e) {
      console.log('Flag Article Error', e)
      dispatch(fetchGuideError())
    }
  }
}

// ***************************** Get Library

const fetchLibraryStart = () => ({type: FETCH_LIBRARY_START})

const fetchLibraryError = () => ({type: FETCH_LIBRARY_ERROR})

const fetchLibrarySuccess = (topics) => ({
  type: FETCH_LIBRARY_SUCCESS,
  topics,
})

export const getLibrary = (userID) => {
  return async (dispatch) => {
    try {
      dispatch(fetchLibraryStart())
      const List = await axios.post(`${config.target}/library`, {userID})
      dispatch(fetchLibrarySuccess(List.data))
    } catch (e) {
      console.log('Get Library Error', e)
      dispatch(fetchLibraryError())
    }
  }
}

export const suggestUpdate = (data) => {
  return async (dispatch) => {
    try {
      axios.post(`${config.target}/suggestUpdate`, {data})
    } catch (e) {
      console.log('Suggest Update Error', e)
    }
  }
}

// ***************************** Get Library

const fetchBlogStart = () => ({type: FETCH_BLOG_START})

const fetchBlogError = () => ({type: FETCH_BLOG_ERROR})

const fetchBlogSuccess = (posts) => ({
  type: FETCH_BLOG_SUCCESS,
  posts,
})

export const getBlog = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchBlogStart())
      const List = await axios.get(`${config.target}/blog`)
      dispatch(fetchBlogSuccess(List.data))
    } catch (e) {
      console.log('Get Blog Error', e)
      dispatch(fetchBlogError())
    }
  }
}

// ***************************** RecordView

export const recordView = (label, title, user) => {
  return async (dispatch) => {
  try {
    axios.post(`${config.target}/recordView`, {label, title, user})
  } catch (e) {
    console.log('Record View Error', e)
  }
}
}

// ***************************** Download Guide

export const downloadGuide = (guide, token) => {
  return async (dispatch) => {
    try {
      // dispatch(fetchBlogStart())
      const Guide = await axios.post(
        `${config.target}/downloadGuide`,
        {
          guide,
          token,
        },
        {responseType: 'arraybuffer'}
      )
      const blob = new Blob([Guide.data], {type: 'application/pdf'})
      const url = window.URL.createObjectURL(blob)
      window.open(url)
      // dispatch(fetchBlogSuccess(List.data))
    } catch (e) {
      console.log('Download Guide Error', e)
      // dispatch(fetchBlogError())
    }
  }
}

// ***************************** Download Sample

export const downloadSample = (guide) => {
  return async (dispatch) => {
    try {
      // dispatch(fetchBlogStart())
      const Guide = await axios.post(
        `${config.target}/downloadSample`,
        {
          guide,
        },
        {responseType: 'arraybuffer'}
      )
      const blob = new Blob([Guide.data], {type: 'application/pdf'})
      const url = window.URL.createObjectURL(blob)
      window.open(url)
      // dispatch(fetchBlogSuccess(List.data))
    } catch (e) {
      console.log('Download Sample Error', e)
      // dispatch(fetchBlogError())
    }
  }
}
