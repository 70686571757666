import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Flex} from 'rebass'
import parse, {domToReact} from 'html-react-parser'
import HeadingNew from '../FormElements/HeadingNew'
import Text from '../FormElements/Text'
import Icons from '../../images/icons/icons'
import user from '../../images/identity/icon-user.svg'
import ArticleComments from '../ArticleComments'
import ArticleSocialShare from '../ArticleSocialShare'
import Modal from '../FormElements/Modal'
import ArticleEmail from '../ArticleEmail'
import Formatter from '../../utils/formatter'
import HomeArticle from './HomeArticle'
import {
  ArticleContainer,
  // TopicsContainer,
  ArticleInteractionContainer,
  Diagram,
  // Tag,
  NumberContainer,
  SummaryContainer,
  ActionIconContainer,
  ArticleSeparator,
  FullContainer,
  ArticleTitle,
} from './styles'

const validator = require('email-validator')

class Article extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    user: PropTypes.object,
    articleEngagement: PropTypes.func,
    email: PropTypes.bool,
    viewEmailForm: PropTypes.func,
    emailArticle: PropTypes.func,
    captureEmail: PropTypes.func,
    resendArticle: PropTypes.func,
    emailTarget: PropTypes.string,
    results: PropTypes.bool,
    preview: PropTypes.bool,
    articleEmailed: PropTypes.bool,
    articleEmailError: PropTypes.bool,
    showComments: PropTypes.bool,
    viewComments: PropTypes.func,
    hideComments: PropTypes.func,
    submitComment: PropTypes.func,
    captureComment: PropTypes.func,
    comment: PropTypes.string,
    comments: PropTypes.object,
    flagArticle: PropTypes.func,
    userName: PropTypes.string,
    viewLikes: PropTypes.func,
    likes: PropTypes.object,
    home: PropTypes.bool,
    showSocialShare: PropTypes.bool,
    singleArticle: PropTypes.bool,
    view: PropTypes.string,
    viewArticle: PropTypes.func.isRequired,
    viewProfile: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      // value: 0,
      // details: false,
      // background: false,
      // applications: false,
      // examples: false,
      // other: false,
      options: ['Summary'],
      // active: ['Summary'],
      lightbox: false,
    }
  }

  // componentWillMount() {
  //   const {details, background, applications, examples, other} = this.props.data
  //   const addition = ['Summary']
  //   if (details && this.clearText(details)) addition.push('Details')
  //   if (applications && this.clearText(applications)) addition.push('Application')
  //   if (examples && this.clearText(examples)) addition.push('Examples')
  //   if (background && this.clearText(background)) addition.push('Background')
  //   if (other && this.clearText(other)) addition.push('Other')
  //   this.setState({options: addition})
  // }

  isEllipsisActive(e) {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth
  }

  // resizeCheck() {
  //   console.log('%%%%%%%%%', this.span);
  //   // e && this.setState({ overflowActive: this.isEllipsisActive(e) });
  // }

  componentDidMount() {
    !this.props.home &&
      this.setState({overflowActive: this.isEllipsisActive(this.span)})
    // window.addEventListener('resize', this.resizeCheck);
  }

  componentDidUpdate(prevProps) {
  if (!this.props.home && this.props.expandedArticle !== prevProps.expandedArticle)
    this.setState({overflowActive: this.isEllipsisActive(this.span)})
}

  onChange = (e) => {
    e.preventDefault()
    const end = Number(e.target.value || e.target.id) + 1
    const newActive = this.state.options.slice(0, end)
    this.setState({active: newActive})
  }

  clearText = (text) => {
    return text.split(/<body\b[^>]*>/)[1].split('</body')[0]
  }

  handleFiedlOnBlur = () => {
    const emailTest = validator.validate(this.props.emailTarget)
    this.setState({error: emailTest ? null : 'Enter valid email'})
  }

  // emailArticle = () => {
  //   const emailTest = validator.validate(this.props.emailTarget)
  //   this.setState({error: emailTest ? null : 'Enter valid email'})
  //   if (!this.state.error) {
  //     this.props.emailArticle(
  //       this.props.data._id,
  //       this.props.user._id,
  //       this.props.emailTarget
  //     )
  //   }
  // }

  // submitComment = () => {
  //   this.setState({error: this.props.comment ? null : 'Enter a comment'})
  //   if (!this.state.error) {
  //     this.props.submitComment(
  //       this.props.data._id,
  //       this.props.user._id,
  //       this.props.comment
  //     )
  //   }
  // }

  showLikes = () => {
    this.setState({showLikes: true})
    this.props.viewLikes(this.props.data._id)
  }

  close = () => {
    this.setState({showLikes: false})
  }

  render() {
    let {
      created,
      published,
      title,
      sourceTitle,
      // sourceType,
      sourceURL,
      sourceLogo,
      // author,
      viaTitle,
      viaURL,
      _id,
      summary,
      digest,
      intro,
      overview,
      details,
      background,
      applications,
      examples,
      topics,
      type,
      other,
      authorFirstName,
      authorLastName,
      authorImage,
      authorID,
      image,
      theme,
      premium,
      restrictedAccess,
      relatedTitle,
      relatedURL,
    } = this.props.data
    if (sourceURL && sourceURL.substring(0, 4).toLowerCase() !== 'http')
      sourceURL = `http://${sourceURL}`
    if (relatedURL && relatedURL.substring(0, 4).toLowerCase() !== 'http')
      relatedURL = `http://${relatedURL}`
    if (viaURL && viaURL.substring(0, 4).toLowerCase() !== 'http')
      viaURL = `http://${viaURL}`
    summary = summary && summary.includes('<!DOCTYPE html>') ? this.clearText(summary) : null
    details = details ? this.clearText(details) : null
    background = background ? this.clearText(background) : null
    applications = applications ? this.clearText(applications) : null
    examples = examples ? this.clearText(examples) : null
    other = other ? this.clearText(other) : null
    const dateFormatted = published
      ? Formatter.dateFormatter(published)
      : created
      ? Formatter.dateFormatter(created)
      : 'Now'
    const liked =
      this.props.data.likes && !this.props.preview
        ? this.props.data.likes.map((a) => a.user).includes(this.props.user._id)
        : false
    const saved =
      this.props.user && !this.props.preview
        ? this.props.user.savedArticles.results.map((a) => a._id).includes(_id)
        : false


    const FormattedTitle = title
      .replace(/ /g, '-')
      .replace(/\//g, '')
      .replace(/%/g, '')

    const viewArticle = () => {
      this.props.viewArticle(_id, FormattedTitle)
    }

    const expandArticle = () => {
      this.props.expandArticle(_id)
    }

    // const viewProfile = () => {
    //   this.props.viewProfile(authorID, authorFirstName, authorLastName)
    // }

    if (this.props.home) {
      return (
        <HomeArticle
          viewArticle={viewArticle}
          title={title}
          digest={parse(summary || '')}
          sourceLogo={sourceLogo}
          sourceURL={sourceURL}
          theme={theme}
          topics={topics}
          type={type}
          landing={this.props.landing}
        />
      )
    }

    return (
      <ArticleContainer singleArticle={this.props.singleArticle}>
        {title && (
          <Flex alignItems='center' style={{marginBottom: '10px'}}>
            {premium && (
              <img
                src={Icons.Premium}
                height='15px'
                alt='Premium'
                style={{marginRight: '3px'}}
              />
            )}
            {this.props.singleArticle && (
              <HeadingNew
                size='h1'
                palette='medium'
                as='h1'
              >
                {title}
              </HeadingNew>
            )}
            {!this.props.singleArticle && (
              summary ?
              <ArticleTitle
                onClick={() => expandArticle() }
                style={{cursor: 'pointer'}}
              >
                {title}
              </ArticleTitle>
              :
                  <ArticleTitle
                  style={{cursor: 'pointer'}}
                >
                  <a 
                    href={sourceURL} 
                    target='_blank'
                    style={{
                      textDecoration: 'none',
                      color: '#0a1f44'
                    }}  
                  >
                  {title}
                  </a>
                </ArticleTitle>
            )}
          </Flex>
        )}
        <Flex alignItems='center' flexWrap='wrap'>
          {sourceLogo && (
            <img
              alt='source'
              src={sourceLogo}
              height='20px'
              style={{marginRight: '7px'}}
            />
          )}
          <Flex flexWrap='wrap'>
            <Text>
              {sourceURL ? (
                <a
                  href={sourceURL}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    textDecoration: 'none',
                    fontSize: '10px',
                    color: '#de7033',
                  }}
                >
                  {sourceURL.split('://')[1].split('/')[0].replace('www.', '')}
                </a>
              ) : (
                <span style={{fontSize: '10px', color: '#de7033'}}>
                  {sourceTitle}
                </span>
              )}
            </Text>
          </Flex>
          {dateFormatted && (
            <span style={{color: '#A9A3A3', fontSize: '10px', marginLeft: '25px'}}>
              {dateFormatted}
            </span>
          )}
          {this.props.user &&
            this.props.user.token &&
            !this.props.preview &&
            !restrictedAccess && (
              <ArticleInteractionContainer>
                <ActionIconContainer>
                  <img
                    src={liked ? Icons.likeSelected : Icons.like}
                    title='Like'
                    alt='like'
                    style={{cursor: 'pointer'}}
                    height='15px'
                    onClick={() =>
                      this.props.articleEngagement(_id, this.props.user._id, 'like')
                    }
                  />
                  <NumberContainer onClick={() => this.showLikes()}>
                    {this.props.data.likeCount > 0
                      ? `${this.props.data.likeCount}`
                      : null}
                  </NumberContainer>
                </ActionIconContainer>
                <ActionIconContainer
                  onClick={() =>
                    this.props.showComments
                      ? this.props.hideComments()
                      : this.props.viewComments(_id)
                  }
                >
                  <img
                    src={
                      this.props.showComments ? Icons.commentSelected : Icons.comment
                    }
                    title='Comments'
                    alt='Comments'
                    style={{cursor: 'pointer'}}
                    height='15px'
                  />
                  <NumberContainer>
                    {this.props.data.comments &&
                      this.props.data.comments.length > 0 &&
                      this.props.data.comments.length}
                  </NumberContainer>
                </ActionIconContainer>

                <ActionIconContainer
                  onClick={() =>
                    this.props.articleEngagement(_id, this.props.user._id, 'save')
                  }
                >
                  <img
                    src={saved ? Icons.saveSelected : Icons.save}
                    title='Save'
                    alt='Save'
                    style={{cursor: 'pointer'}}
                    height='15px'
                  />
                </ActionIconContainer>
              </ArticleInteractionContainer>
            )}
        </Flex>

        {this.props.expandedArticle === _id || this.props.singleArticle ? (
          <FullContainer ref={(ref) => (this.span = ref)}>
            {parse(summary || '')}
          </FullContainer>
        ) : (
          <SummaryContainer ref={(ref) => (this.span = ref)}>
            {parse(summary || '')}
          </SummaryContainer>
        )}

        {image && (
          <Flex
            mb='20px'
            justifyContent='center'
            onClick={() => this.setState({lightbox: true})}
          >
            <Diagram src={image} />
          </Flex>
        )}
        {this.state.lightbox && (
          <Modal closeModal={() => this.setState({lightbox: false})} article>
            <Flex width={1} justifyContent='center' alignItems='center'>
              <img src={image} style={{maxHeight: '90vh', maxWidth: '100%'}} />
            </Flex>
          </Modal>
        )}

        {this.props.showComments && (
          <ArticleComments
            comments={this.props.comments}
            articleID={_id}
            userID={this.props.user._id}
            comment={this.props.comment}
            captureComment={this.props.captureComment}
            submitComment={this.props.submitComment}
          />
        )}
        {this.props.email && (
          <ArticleEmail
            articleEmailed={this.props.articleEmailed}
            articleEmailError={this.props.articleEmailError}
            captureEmail={this.props.captureEmail}
            emailTarget={this.props.emailTarget}
            emailArticle={this.props.emailArticle}
            resendArticle={this.props.resendArticle}
            articleID={_id}
            userID={this.props.user._id}
          />
        )}
        {this.state.showSocialShare && (
          <ArticleSocialShare
            title={title}
            url={`https://www.sivv.io/${_id}/${FormattedTitle}`}
          />
        )}
        {this.state.showLikes && (
          <Modal closeModal={this.close}>
            <Flex p={3} flexDirection='column'>
              <HeadingNew size='h2' palette='darkColor' as='h2' spacing='0 0 8px'>
                Likes
              </HeadingNew>
              {this.props.likes.isLoading && 'Loading'}
              {this.props.likes.results.length > 0 &&
                this.props.likes.results.map((r, idx) => {
                  return (
                    <Flex width={1} justifyContent='space-between'>
                      <span>
                        {r.authorFirstName} {r.authorLastName}
                      </span>
                      <span>{Formatter.dateFormatter(r.time)}</span>
                    </Flex>
                  )
                })}
            </Flex>
          </Modal>
        )}
        {this.state.overflowActive && this.props.expandedArticle !== _id && (
          <div
            style={{
              marginRight: '5px',
              marginTop: '5px',
              marginLeft: 'auto',
              fontSize: '10px',
              color: '#de7033',
              cursor: 'pointer',
            }}
            onClick={() => expandArticle()}
          >
            + Expand
          </div>
        )}
        {!this.props.singleArticle && <ArticleSeparator />}
      </ArticleContainer>
    )
  }
}

export default Article
