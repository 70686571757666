// import fetch from 'cross-fetch'
import axios from 'axios'
import config from '../utils/configuration'

const {target} = config

export const PAYMENT_START = 'PAYMENT_START'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const RESET_PAYMENT_FORM = 'RESET_PAYMENT_FORM'
export const CANCELLATION_CONFIRMED = 'CANCELLATION_CONFIRMED'

const paymentStart = (label) => {
  return {
    type: PAYMENT_START,
    label,
  }
}

const paymentSuccess = (details, label) => {
  return {
    type: PAYMENT_SUCCESS,
    details,
    label,
  }
}

const paymentError = (label) => {
  return {
    type: PAYMENT_ERROR,
    label,
  }
}

const cancellationConfirmed = () => {
  return {
    type: CANCELLATION_CONFIRMED,
  }
}

export const cancelSubscription = (user) => {
  return async (dispatch) => {
    // dispatch(paymentStart('cancellation'))
    try {
      const subscription = await axios.post(`${target}/cancelSubscription`, {user})
      if (subscription.status === 200) {
        dispatch(cancellationConfirmed())
      }
      if (subscription.status === 500) dispatch(paymentError('cancellation'))
    } catch (e) {
      console.log('Cancel Subscription Error', e)
      dispatch(paymentError('cancellation'))
    }
  }
}
