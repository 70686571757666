import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true})
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Unfortunately we cannot display this data at this time
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
