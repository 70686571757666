import {combineReducers} from 'redux'
import signIn from './signIn'
import forgottenPassword from './forgottenPassword'
import resetPassword from './resetPassword'
import personalInfo from './personalInfo'
import preferences from './preferences'
import email from './email'
import password from './password'
import deleteAccount from './deleteAccount'

export default combineReducers({
  signIn,
  forgottenPassword,
  resetPassword,
  personalInfo,
  preferences,
  email,
  password,
  deleteAccount,
})
