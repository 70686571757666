import actionTypes from '../../actions/forms/forgottenPassword/actionTypes'

const initialState = {
  error: null,
  isLoading: false,
  isEmailSent: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_ERROR:
      return {
        ...state,
        error: null,
      }

    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actionTypes.TOGGLE_IS_EMAIL_SENT:
      return {
        ...state,
        isEmailSent: true,
      }

    case actionTypes.RESET:
      return {
        initialState,
      }

    default:
      return state
  }
}
