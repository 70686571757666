import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import iconEyeShow from '../../../images/icons/eye-show.svg'
import iconEyeHide from '../../../images/icons/eye-hide.svg'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const Label = styled.label`
  display: block;
  position: absolute;
  left: 9px;
  top: 16px;
  background: white;
  transition: all 0.1s ease-in;
  @media screen and (min-width: 640px) {
    left: 17px;
  }

  ${(props) =>
    props.value &&
    css`
      font-size: 11px;
      top: 4px;
      color: #657081;
    `}
`

const StyledInput = styled.input`
  padding: 26px 8px 11px;
  background: white;
  border-radius: ${(props) => (props.landingSignUp ? '4px 0px 0px 4px' : '4px')};
  border: ${(props) => (props.error ? '1px solid #D0021B' : 'solid 1px #DADEE6')};
  /* height: ${(props) => (props.small ? '25px' : '32px')}; */
  box-sizing: border-box;
  width: 100%;
  outline: none;
  transition: all .1s ease-in;
  color: #282828;
  font-family: OpenSans, sans-serif;
  // removes top shadow on iOS
  background-clip: padding-box;

  /* Change Autocomplete styles in Chrome*/
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus
  :-webkit-autofill,
  :-webkit-autofill:hover
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: 1px solid #d8d8d8;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder,
  :-moz-placeholder {
    display: none;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
  }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (min-width: 640px) {
  padding: 26px 16px 11px;
}

&--error {
  border-color: #D0021B;
}

&--hasText {
  & + label {
    font-size: 11px;
    top: 4px;
    color: #657081;
  }

  & ~ .passwordToggle {
    top: 6px;
    img {
      width: 16px;
    }
  }
}
&:focus {
  & ~ label {
    font-size: 11px;
    top: 4px;
    color: #657081;
  }
  & ~ .error  {
    display: none;
  }

  & ~ .passwordToggle {
    top: 6px;
    img {
      width: 16px;
    }
  }
}

&:disabled {
  opacity: .7;
}
}
`

const Error = styled.div`
  font-size: 11px;
  color: #d0021b;
  position: absolute;
  right: 8px;
  bottom: 4px;
`

const PasswordToggle = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  img {
    width: 20px;
  }
`

const Input = ({
  label,
  value,
  error,
  onChange,
  inputProps,
  styleOptions,
  hasText,
  narrow,
  limit,
  landingSignUp,
  onKeyDown
}) => {
  const [isPasswordVisible, togglePasswordVisibility] = useState(false)

  return (
    <Wrapper narrow={narrow}>
      {inputProps.type !== 'password' && (
        <StyledInput
          // onFocus={this.onFocusField}
          onChange={onChange}
          value={value}
          maxLength={limit}
          style={{height: narrow ? '42px' : '48px'}}
          {...inputProps}
          error={error}
          landingSignUp={landingSignUp}
          onKeyDown={onKeyDown}

        />
      )}

      {inputProps.type === 'password' && (
        <StyledInput
          // onFocus={this.onFocusField}
          onChange={onChange}
          value={value}
          style={{height: narrow ? '40px' : '48px'}}
          {...inputProps}
          type={isPasswordVisible ? 'text' : 'password'}
          error={error}
        />
      )}

      {label && (
        <Label htmlFor={inputProps.id} value={value}>
          {label}
        </Label>
      )}
      {inputProps.type === 'password' && (
        <PasswordToggle onClick={() => togglePasswordVisibility(!isPasswordVisible)}>
          {isPasswordVisible ? (
            <img src={iconEyeShow} alt='Show password' />
          ) : (
            <img src={iconEyeHide} alt='Hide password' />
          )}
        </PasswordToggle>
      )}
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  inputProps: PropTypes.object.isRequired,
  styleOptions: PropTypes.object,
  hasText: PropTypes.bool,
  narrow: PropTypes.bool,
  limit: PropTypes.number,
  landingSignUp: PropTypes.bool,
}

export default Input
