import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import axios from 'axios'
import FroalaEditor from 'react-froala-wysiwyg'
import Button from '../../components/FormElements/Button'
import Input from '../../components/FormElements/Input'
import Select from '../../components/FormElements/Select'
import TextBox from '../../components/FormElements/TextBox'
import config from '../../utils/configuration'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/plugins.pkgd.min.css'
import 'froala-editor/css/froala_editor.min.css'

const AdminCompose = ({token}) => {
  const [display, toggleDisplay] = useState('guide')
  const [guide, updateGuide] = useState({
    title: '',
    description: '',
    text: '',
    references: '',
    contents: '',
    category: '',
    wordCount: 0,
    status: 'draft',
  })
  const [topics, updateTopics] = useState([])
  const [feedback, showFeedback] = useState(null)
  const [bookList, updateBookList] = useState([])
  const [book, updateBook] = useState({
    title: '',
    author: '',
    keyIdea: '',
    summary: '',
    link: '',
    image: '',
    rating: '',
    tags: '',
  })
  const [blogList, updateBlogList] = useState([])
  const [blog, updateBlog] = useState({
    title: '',
    text: '',
  })
  const [logo, updateLogo] = useState({
    name: '',
    image: '',
  })

  useEffect(() => {
    getTopicList('guides')
    getTopicList('books')
    getTopicList('blogs')
  }, [])

  const Config = {
    fullPage: true,
    heightMin: 100,
    width: '100%',
    quickInsertTags: null,
    pastePlain: true,
    attribution: false,
  }

  const Config1 = {
    fullPage: true,
    heightMin: 100,
    width: '100%',
    quickInsertTags: null,
    pastePlain: true,
    attribution: false,
  }

  const Config2 = {
    fullPage: true,
    heightMin: 100,
    width: '100%',
    quickInsertTags: null,
    pastePlain: true,
    attribution: false,
  }

  const Config3 = {
    fullPage: true,
    heightMin: 100,
    width: '100%',
    quickInsertTags: null,
    pastePlain: true,
    attribution: false,
  }

  const Config4 = {
    fullPage: true,
    heightMin: 100,
    width: '100%',
    quickInsertTags: null,
    pastePlain: true,
    attribution: false,
  }

  // let axiosConfig = {
  //   headers: {
  //     authorization: token
  //   }
  // }

  const getTopicList = async (label) => {
    const result = await axios.get(`${config.target}/topicList/${label}`)
    const topics =
      result.data.length > 0
        ? result.data.map((r) => ({label: r.title, value: r.title})).sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
        })
        : []
    if (label === 'guides') updateTopics(topics)
    if (label === 'books') updateBookList(topics)
    if (label === 'blogs') updateBlogList(topics)
  }

  const saveGuide = async (editStatus) => {
    const status = editStatus === 'draft' ? 'draft' : 'publish'
    const result = await axios.post(`${config.target}/saveGuide`, {guide, status})
    if (result.status === 200) showFeedback('Article Saved')
  }

  const editGuide = async (name, value) => {
    const Guide = await axios.get(`${config.target}/viewGuide/${value}`)
    updateGuide(Guide.data)
  }

  const deleteGuide = async () => {
    const Guide = await axios.get(`${config.target}/deleteGuide/${guide.title}`)
    if (Guide.status === 200) showFeedback('Guide Deleted')
  }

  const editBook = async (name, value) => {
    const newValue = value.replace(/\?/g, '%3F')
    const Book = await axios.get(`${config.target}/viewBook/${newValue}`)
    updateBook(Book.data)
  }

  const editBlog = async (name, value) => {
    const Blog = await axios.get(`${config.target}/viewBlog/${value}`)
    updateBlog(Blog.data)
  }

  const saveBook = async () => {
    const bookAttributes = [
      'author',
      'image',
      'keyIdea',
      'link',
      'summary',
      'title',
      'tags'
    ]
    if (bookAttributes.every((v) => book[v] && book[v].length > 0)) {
      const result = await axios.post(`${config.target}/saveBook`, {book})
      if (result.status === 200) showFeedback('Book Saved')
    }
  }

  const saveBlog = async () => {
    const result = await axios.post(`${config.target}/saveBlog`, {blog})
    if (result.status === 200) showFeedback('Blog Saved')
  }

  const saveLogo = async () => {
    await axios.post(`${config.target}/saveLogo`, {blog})
  }

  const ContentTopics = [
    {label: 'Innovation', value: 'Innovation'},
    {label: 'People', value: 'People'},
    {label: 'Life Skills', value: 'Life Skills'},
    {label: 'Sales & Marketing', value: 'Sales & Marketing'},
    {label: 'Investing & Start-ups', value: 'Investing & Start-ups'},
  ]

  const Status = [
    {label: 'Published', value: 'Published'},
    {label: 'Draft', value: 'Draft'},
  ]

  const Display = [
    {label: 'Guide', value: 'guide'},
    {label: 'Book', value: 'book'},
    {label: 'Blog', value: 'blog'},
  ]

  return (
    <Flex width={1} justifyContent='center' flexWrap='wrap'>
      <Box p={4} width={[1, 1, 4 / 5]}>
        <Select
          label='Display'
          options={Display}
          placeholder='Select'
          name='Display'
          defaultValue={display}
          onChange={(name, value) => toggleDisplay(value)}
          narrow
        />
      </Box>
      {display === 'guide' && (
        <>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Select
              label='Edit - Select Topic'
              options={topics}
              placeholder='Select'
              name='type'
              defaultValue='Select'
              onChange={(name, value) => editGuide(name, value)}
              narrow
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Title'
              onChange={(e) => updateGuide({...guide, title: e.target.value})}
              value={guide.title}
              inputProps={{
                type: 'text',
                id: 'title',
                autoComplete: 'title',
                // onBlur: () => handleFiedlOnBlur(emailValidator)
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <TextBox
              label='Description'
              onChange={(e) => updateGuide({...guide, description: e.target.value})}
              value={guide.description}
              inputProps={{
                type: 'text',
                id: 'description',
                autoComplete: 'description',
                // onBlur: () => handleFiedlOnBlur(emailValidator)
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            Guide text
            <FroalaEditor
              // tag='textarea'
              config={Config}
              onModelChange={(e) => updateGuide({...guide, text: e})}
              model={guide.text}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            Contents
            <FroalaEditor
              // tag='textarea'
              config={Config2}
              onModelChange={(e) => updateGuide({...guide, contents: e})}
              model={guide.contents}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            References
            <FroalaEditor
              // tag='textarea'
              config={Config1}
              onModelChange={(e) => updateGuide({...guide, references: e})}
              model={guide.references}
            />
          </Box>
          <Box p={4} width={[1, 1, 1 / 3]}>
            <Select
              label='Category'
              options={ContentTopics}
              placeholder='Select'
              name='type'
              defaultValue={guide.category}
              onChange={(name, value) => updateGuide({...guide, category: value})}
              narrow
            />
          </Box>

          <Box p={4} width={[1, 1, 1 / 3]}>
            <Select
              label='Status'
              options={Status}
              placeholder='Select'
              name='status'
              defaultValue={guide.status}
              onChange={(name, value) => updateGuide({...guide, status: value})}
              narrow
            />
          </Box>
          <Box p={4} width={[1, 1, 1 / 3]}>
            <Input
              label='Word Count'
              onChange={(e) => updateGuide({...guide, wordCount: e.target.value})}
              value={guide.wordCount}
              inputProps={{
                type: 'number',
                id: 'wordCount',
                autoComplete: 'title',
                // onBlur: () => handleFiedlOnBlur(emailValidator)
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box px={2} width={[1, 1 / 2, 1 / 3]} p='20px'>
            <Button
              onClick={(e) => saveGuide('publish')}
              palette='primary'
              fullWidth
            >
              Publish
            </Button>
          </Box>
          <Box px={2} width={[1, 1 / 2, 1 / 3]} p='20px'>
            <Button onClick={(e) => deleteGuide()} palette='primary' fullWidth>
              Delete
            </Button>
          </Box>
        </>
      )}

      {display === 'book' && (
        <>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Select
              label='Edit - Select Topic'
              options={bookList}
              placeholder='Select'
              name='type'
              defaultValue='Select'
              onChange={(name, value) => editBook(name, value)}
              narrow
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Title'
              onChange={(e) => updateBook({...book, title: e.target.value})}
              value={book.title}
              inputProps={{
                type: 'text',
                id: 'title',
                autoComplete: 'title',
                // onBlur: () => handleFiedlOnBlur(emailValidator)
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Author'
              onChange={(e) => updateBook({...book, author: e.target.value})}
              value={book.author}
              inputProps={{
                type: 'text',
                id: 'author',
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Rating'
              onChange={(e) => updateBook({...book, rating: e.target.value})}
              value={book.rating}
              inputProps={{
                type: 'number',
                id: 'rating',
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <TextBox
              label='Key Idea'
              onChange={(e) => updateBook({...book, keyIdea: e.target.value})}
              value={book.keyIdea}
              inputProps={{
                type: 'text',
                id: 'keyIdea',
                autoComplete: 'keyIdea',
                // onBlur: () => handleFiedlOnBlur(emailValidator)
              }}
              // error={this.state.error.title}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Link'
              onChange={(e) => updateBook({...book, link: e.target.value})}
              value={book.link}
              inputProps={{
                type: 'text',
                id: 'link',
              }}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Image'
              onChange={(e) => updateBook({...book, image: e.target.value})}
              value={book.image}
              inputProps={{
                type: 'text',
                id: 'image',
              }}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Tags'
              onChange={(e) => updateBook({...book, tags: e.target.value})}
              value={book.tags}
              inputProps={{
                type: 'text',
                id: 'image',
              }}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <FroalaEditor
              config={Config3}
              onModelChange={(e) => updateBook({...book, summary: e})}
              model={book.summary}
            />
          </Box>

          <Box px={2} width={[1, 1 / 2, 1 / 3]} p='20px'>
            <Button onClick={(e) => saveBook()} palette='primary' fullWidth>
              Publish
            </Button>
          </Box>
        </>
      )}

      {display === 'blog' && (
        <>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Select
              label='Edit - Select Blog'
              options={blogList}
              placeholder='Select'
              name='type'
              defaultValue='Select'
              onChange={(name, value) => editBlog(name, value)}
              narrow
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Title'
              onChange={(e) => updateBlog({...blog, title: e.target.value})}
              value={blog.title}
              inputProps={{
                type: 'text',
                id: 'title',
              }}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <FroalaEditor
              config={Config4}
              onModelChange={(e) => updateBlog({...blog, text: e})}
              model={blog.text}
            />
          </Box>
          <Box px={2} width={[1, 1 / 2, 1 / 3]} p='20px'>
            <Button onClick={(e) => saveBlog()} palette='primary' fullWidth>
              Publish
            </Button>
          </Box>
        </>
      )}
      {display === 'logo' && (
        <>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Name'
              onChange={(e) => updateLogo({...logo, name: e.target.value})}
              value={logo.name}
              inputProps={{
                type: 'text',
                id: 'title',
              }}
            />
          </Box>
          <Box p={4} width={[1, 1, 4 / 5]}>
            <Input
              label='Image'
              onChange={(e) => updateLogo({...logo, image: e.target.value})}
              value={logo.image}
              inputProps={{
                type: 'text',
                id: 'title',
              }}
            />
          </Box>
          <Box px={2} width={[1, 1 / 2, 1 / 3]} p='20px'>
            <Button onClick={(e) => saveLogo()} palette='primary' fullWidth>
              Add
            </Button>
          </Box>
        </>
      )}
      <Box px={2} width={1} p='20px'>
        {feedback}
      </Box>
    </Flex>
  )
}

AdminCompose.propTypes = {
  token: PropTypes.string.isRequired,
}

export default AdminCompose
