import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Prompt} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {
  addArticleType,
  addCategory,
  clearDraft,
  removeCategory,
  saveArticle,
  saveDraft,
} from '../../actions/articleActions'
import ComposeInputsShort from '../../components/ComposeInputsShort/ComposeInputs'

class ComposeArticle extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    draft: PropTypes.object.isRequired,
    edit: PropTypes.bool,
    sourceType: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    type: PropTypes.array.isRequired,
    theme: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    error: PropTypes.bool,
    feedback: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {blocking: false}
    !this.props.edit && this.props.actions.clearDraft()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  captureInput = (e, id) => {
    e.target
      ? this.props.actions.saveDraft(id, e.target.value)
      : this.props.actions.saveDraft(id, e)
    this.setState({blocking: true})
  }

  submitArticle = (status) => {
    this.props.actions.saveArticle(this.props.draft, status, this.props.user._id)
    this.setState({blocking: false})
  }

  render() {
    return (
      <>
        <Prompt
          when={this.state.blocking}
          message='Unsaved changes - are you sure you want to go to leave this page?'
        />
        <ComposeInputsShort
          addCategory={this.props.actions.addCategory}
          addArticleType={this.props.actions.addArticleType}
          captureInput={this.captureInput}
          draft={this.props.draft}
          edit={this.props.edit}
          removeCategory={this.props.actions.removeCategory}
          sourceType={this.props.sourceType}
          submit={this.submitArticle}
          topics={this.props.topics}
          type={this.props.type}
          theme={this.props.theme}
          error={this.props.error}
          feedback={this.props.feedback}
          user={this.props.user}
          userName={`${this.props.user.firstName} ${this.props.user.lastName}`}
          userID={this.props.user._id}
        />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        addCategory,
        addArticleType,
        clearDraft,
        removeCategory,
        saveArticle,
        saveDraft,
      },
      dispatch
    ),
  }
}

const mapStateToProps = (state) => {
  const {article} = state
  return {
    topics: article.topics,
    type: article.type,
    sourceType: article.sourceType,
    draft: article.draft,
    error: article.error,
    feedback: article.feedback,
    uploading: article.uploading,
    uploadError: article.uploadError,
    theme: article.theme,
    user: state.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComposeArticle)
