import {combineReducers} from 'redux'
import search from './searchReducers'
import forms from './forms'
import article from './articleReducers'
import user from './user'
import payment from './paymentReducers'
import tracker from './trackerReducers'

export default combineReducers({
  search,
  user,
  forms,
  article,
  payment,
  tracker
})
