import keyMirror from '../../../utils/keyMirror'

export default keyMirror(
  {
    RESET: null,
    RESET_ERROR: null,
    SET_ERROR: null,
    TOGGLE_IS_EMAIL_SENT: null,
  },
  'FORM_FORGOTTEN_PASSWORD'
)
