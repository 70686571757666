import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Box, Flex} from 'rebass'
import {updateEmail, resetError} from '../../../actions/forms/email'
import {
  newEmailValidator,
  newEmailRepeatValidator,
  passwordValidator,
} from '../../../utils/validators'
import Input from '../../../components/FormElements/Input'
import Button from '../../../components/FormElements/Button'
import Text from '../../../components/FormElements/Text'
import HeadingNew from '../../../components/FormElements/HeadingNew'
import {Separator} from '../../../components/FormElements/utils'
import {LinkRouter} from '../../../components/FormElements/Link'

const ChangeEmailForm = ({
  token,
  _id,
  email,
  updateEmail,
  error,
  isEmailUpdated,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    emailRepeat: '',
    password: '',
  })
  const [formError, setFormError] = useState({})
  const [showFeedback, toggleShowFeedback] = useState(false)

  useEffect(() => {
    toggleFeedback(isEmailUpdated)
  }, [isEmailUpdated])

  const toggleFeedback = (isUpdated) => {
    if (isUpdated) {
      toggleShowFeedback(true)
      setTimeout(function () {
        toggleShowFeedback(false)
        setFormData({
          email: '',
          emailRepeat: '',
          password: '',
        })
      }, 4500)
    }
  }

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const handleFiedlOnBlur = (validation) => {

    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    // ------------------
    const emailError = newEmailValidator(formData)
    const emailRepeatError = newEmailRepeatValidator(formData)
    const passwordError = passwordValidator(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(
      existingErrors,
      emailError,
      emailRepeatError,
      passwordError
    )
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady &&
      updateEmail({
        _id,
        email: formData.email,
        password: formData.password,
      })
  }

  return (
    <section>
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <HeadingNew size='h3' palette='darkColor' as='h2' spacing='0 0 8px'>
          Current email address
        </HeadingNew>
        <Text as='p' palette='light'>
          {email}
        </Text>
        <Separator />
        <HeadingNew size='h3' palette='darkColor' as='h2' spacing='0 0 8px'>
          Change your email address
        </HeadingNew>
        <Text as='p' palette='light'>
          Please complete the following input fields to change your email
        </Text>
        {/* ==================== ERROR */}
        {error && error.form && (
          <Flex
            flexWrap='wrap'
            flexDirection='row'
            alignItems={['normal', 'flex-end']}
            mx={-2}
            mb={2}
          >
            <Box p={2} pb={0} width={[1, 1, 1 / 3]}>
              <Text errorForm>{error.form}</Text>
            </Box>
          </Flex>
        )}

        {/* ==================== FIELDS */}
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          my={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Enter new email *'
              onChange={(e) => handleInputChange(e)}
              value={formData.email}
              inputProps={{
                type: 'email',
                id: 'email',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(newEmailValidator),
              }}
              error={formError && formError.email}
            />
          </Box>
        </Flex>
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          my={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Retype new email *'
              onChange={(e) => handleInputChange(e)}
              value={formData.emailRepeat}
              inputProps={{
                type: 'email',
                id: 'emailRepeat',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(newEmailRepeatValidator),
              }}
              error={formError && formError.emailRepeat}
            />
          </Box>
        </Flex>
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          mb={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Password *'
              onChange={(e) => handleInputChange(e)}
              value={formData.password}
              inputProps={{
                type: 'password',
                id: 'password',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(passwordValidator),
              }}
              error={formError && formError.password}
            />
          </Box>
        </Flex>
        <Text as='p'>
          If you have forgotten your password{' '}
          <LinkRouter to='/forgottenPassword'>click here</LinkRouter>
        </Text>
        <Separator />
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
        >
          <Box px={2} pb='24px' width={[1 / 2, 1 / 2, 1 / 4]}>
            <Button
              onClick={(e) => handleSubmitForm(e)}
              palette='primary'
              fullWidth
              feedback='Email updated'
              showFeedback={showFeedback}
            >
              Change Email
            </Button>
          </Box>
        </Flex>
      </form>
    </section>
  )
}

ChangeEmailForm.propTypes = {
  token: PropTypes.string,
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  error: PropTypes.object,
  isEmailUpdated: PropTypes.bool.isRequired,
  updateEmail: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {token, _id, email} = state.user
  const {error, isEmailUpdated} = state.forms.email
  return {token, _id, email, error, isEmailUpdated}
}

export default connect(mapStateToProps, {updateEmail, resetError})(ChangeEmailForm)
