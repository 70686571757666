import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import styled from 'styled-components'
import axios from 'axios'
import Button from '../../components/FormElements/Button'
import config from '../../utils/configuration'
import Input from '../../components/FormElements/Input'

const ResponsiveTable = styled.table`
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
  text-align: left;

  thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    @media screen and (min-width: 40em) {
      clip: auto;
      height: auto;
      width: auto;
      margin: 0;
      position: relative;
    }
  }

  tr {
    border: 2px solid #ddd;
    display: block;
    margin-bottom: .625em;
    border-radius: 4px;

    @media screen and (min-width: 40em) {
      border: none;
      display: table-row;
    }
  }

  .row2 {
    border: none;
    border-bottom: 2px solid #ddd;
    border-radius: 0px;
    margin-top: -.625em;
  }

  th {
    font-size: 12px;
    padding: 10px;
    font-weight: normal;
    color: #9BB9C7;
    vertical-align: top;
  }

  td {
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 11px;
    text-align: right;
    position: relative;
    padding: 10px 10px 10px 10px;

    &:before {
      position: absolute;
      left: 10px;
      top: 10px;
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 9px;
    }

    &:last-child {
      border-bottom: 0;
    }

    @media screen and (min-width: 40em) {
      display: table-cell;
      font-size: 11px;
      text-align: left;
      padding: 10px;
      border-top: 1px solid #DFE8F0;
      border-bottom: 0;

      &:before {
        content: none;
        display: none;
      }

      &:last-child {
        // border-bottom: 1px solid #DFE8F0;
      }
    }
  }
  @media screen and (min-width: 40em) {
    tbody {
      tr{
        background-color: white;

        td {
          &:first-child {
            border-left: 1px solid #DFE8F0;
          }

          &:last-child {
            border-right: 1px solid #DFE8F0;
          }
        }

        &:first-child {
          td {
            &:first-child {
              border-top-left-radius: 4px;
            }

            &:last-child {
              border-top-right-radius: 4px;
            }
          }
        }

        &:last-child {
          td {
            border-bottom: 1px solid #DFE8F0;
            &:first-child {
              border-bottom-left-radius: 4px;
              border-left: 1px solid #DFE8F0;
            }

            &:last-child {
              border-bottom-right-radius: 4px;
              border-right: 1px solid #DFE8F0;
            }
          }
        }
      }
    }
  }
}`

const Admin = ({token}) => {
  const [reportData, setReportData] = useState([])
  const [email, confirmEmail] = useState(false)
  const [alert, updateAlert] = useState('')

  // let axiosConfig = {
  //   headers: {
  //     authorization: token
  //   }
  // }

  const generateReport = async () => {
    const report = await axios.get(`${config.target}/generateActivityReport`)
    setReportData(report.data)
  }

  const generateRecapEmail = async () => {
    const email = await axios.get(`${config.target}/generateRecapEmail/real`)
    if (email.status === 200) confirmEmail(true)
  }

  const generateTestEmail = async () => {
    const email = await axios.get(`${config.target}/generateRecapEmail/test`)
    if (email.status === 200) confirmEmail(true)
  }

  const sendTestAlert = async () => {
    const email = await axios.get(`${config.target}/sendAlert/test/${alert}`)
    if (email.status === 200) confirmEmail(true)
  }

  const sendAlert = async () => {
    const email = await axios.get(`${config.target}/sendAlert/real/${alert}`)
    if (email.status === 200) confirmEmail(true)
  }

  return (
    <Flex width={1} flexDirection='column'>
      Admin
      <Flex width={1} flexDirection='column' alignItems='center'>
        <ResponsiveTable>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Users</th>
              <th scope='col'>Posts</th>
              <th scope='col'>Visits</th>
              <th scope='col'>Logins</th>
              <th scope='col'>Searches</th>
              <th scope='col'>Likes</th>
              <th scope='col'>Comments</th>
              <th scope='col'>Saves</th>
              <th scope='col'>Article Views</th>
              <th scope='col'>Shares</th>
              <th scope='col'>Emails</th>
              <th scope='col'>Library</th>
            </tr>
          </thead>
          <tbody>
            {reportData.reverse().map((day, index) => {
              return (
                <tr className='bodyRow' key={index} id={day.id}>
                  <td data-label='Date'>{day.date.split('T')[0]}</td>
                  <td data-label='Users'>{day.users}</td>
                  <td data-label='Posts'>{day.posts}</td>
                  <td data-label='Visits'>{day.latestArticlesCount}</td>
                  <td data-label='Logins'>{day.loginCount}</td>
                  <td data-label='Searches'>{day.searchCount}</td>
                  <td data-label='Likes'>{day.likeCount}</td>
                  <td data-label='Comments'>{day.commentCount}</td>
                  <td data-label='Saves'>{day.articleSaveCount}</td>
                  <td data-label='Article views'>{day.articleViewCount}</td>
                  <td data-label='Shares'>{day.shareCount}</td>
                  <td data-label='Emails'>{day.emailCount}</td>
                  <td data-label='Library'>{day.libraryCount}</td>
                </tr>
              )
            })}
          </tbody>
        </ResponsiveTable>
        <Box px={2} width={[1, 1 / 2, 1 / 2]} p='20px'>
          <Button onClick={(e) => generateReport()} palette='primary' fullWidth>
            Generate Report
          </Button>
        </Box>
        <Box px={2} width={[1, 1 / 2, 1 / 2]} p='20px'>
          <Button
            onClick={(e) => generateTestEmail()}
            palette='primary'
            fullWidth
            showFeedback={email}
          >
            Generate Test Email
          </Button>
        </Box>
        <Box px={2} width={[1, 1 / 2, 1 / 2]} p='20px'>
          <Button
            onClick={(e) => generateRecapEmail()}
            palette='primary'
            fullWidth
            showFeedback={email}
          >
            Generate Email
          </Button>
        </Box>
      </Flex>
      <Flex justifyContent='space-between'>
        <Box px={2} width={1 / 3}>
          <Input
            label='articleID'
            onChange={(e) => updateAlert(e.target.value)}
            value={alert}
            inputProps={{
              type: 'text',
              id: 'alertID',
            }}
          />
        </Box>
        <Box px={2} width={1 / 3}>
          <Button
            onClick={(e) => sendTestAlert()}
            palette='primary'
            fullWidth
            showFeedback={email}
          >
            Test Alert
          </Button>
        </Box>
        <Box px={2} width={1 / 3}>
          <Button
            onClick={(e) => sendAlert()}
            palette='primary'
            fullWidth
            showFeedback={email}
          >
            Alert
          </Button>
        </Box>
      </Flex>
      <Box px={2} width={1} p='20px'>
        {email && (
          <div
            style={{
              backgroundColor: 'green',
              color: 'white',
              textAlign: 'center',
              padding: '5px',
            }}
          >
            Email sent
          </div>
        )}
      </Box>
    </Flex>
  )
}

Admin.propTypes = {
  token: PropTypes.string.isRequired,
}

export default Admin
