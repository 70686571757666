import React, {useState} from 'react'
import PropTypes from 'prop-types'

const ToggleContent = ({toggle, content}) => {
  const [isShown, setIsShown] = useState(false)
  const hide = () => setIsShown(false)
  const show = () => setIsShown(true)

  return (
    <>
      {toggle(show)}
      {isShown && content(hide)}
    </>
  )
}

ToggleContent.propTypes = {
  toggle: PropTypes.any,
  content: PropTypes.node,
}

export default ToggleContent
