import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-bottom: 5px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  width: ${(props) => (props.fullWidth ? '100%' : '50')};
  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    font-size: 9px;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: ${(props) => (props.error ? '1px solid #D0021B' : 'none')};
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #5e7d8c;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

const Label = styled.span`
  display: inline-block;
  margin-right: 10px;
  color: ${(props) => (props.textWhite ? '#fff' : '#0a1f44')};
  font-family: OpenSans, sans-serif;
  font-weight: 300;
`

const Checkbox = ({label, name, inputProps, fullWidth, error, textWhite}) => {
  return (
    <Container fullWidth={fullWidth} error={error}>
      {label && <Label textWhite={textWhite}>{label}</Label>}
      <input type='checkbox' name={name} {...inputProps} />
      <span className='checkmark' />
    </Container>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputProps: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  textWhite: PropTypes.bool,
  error: PropTypes.bool,
}

export default Checkbox
