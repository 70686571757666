import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Flex, Box} from 'rebass'
import Button from '../FormElements/Button'
import TextBox from '../FormElements/TextBox'

const ArticleComments = ({
  comments,
  articleID,
  userID,
  comment,
  captureComment,
  submitComment,
}) => {
  const [error, setError] = useState(null)

  const submit = () => {
    setError(comment ? null : 'Please write something')
    if (!error) submitComment('new', articleID, userID, comment)
  }

  return (
    <Flex flexDirection='column' width={1}>
      {comments.results.map((c) => {
        return (
          <Flex flexDirection='column' p={2} width={1}>
            <Box width={1}>
              {c.authorFirstName} {c.authorLastName} {' '}
              {c.time.toString().split('T')[0]}
            </Box>
            <Box p={2} width={1}>
              {c.comment}
            </Box>
            {c.userID === userID && (
              <div
                style={{
                  marginRight: '0px',
                  marginLeft: 'auto',
                  fontSize: '8px',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => submitComment('delete', articleID, userID, c.time)}
              >
                Delete
              </div>
            )}
          </Flex>
        )
      })}
      <Box p={2} width={1}>
        <TextBox
          label='Comment'
          onChange={(e) => captureComment(e.target.value)}
          value={comment}
          inputProps={{
            type: 'text',
            id: 'comment',
            autoComplete: 'false',
            // onBlur: () => this.handleFiedlOnBlur()
          }}
          error={error}
        />
      </Box>
      <Box pl={2} pt={2} width={[1, 1 / 5]}>
        <Button onClick={() => submit()} fullWidth>
          Submit
        </Button>
      </Box>
    </Flex>
  )
}

ArticleComments.propTypes = {
  comments: PropTypes.object.isRequired,
  articleID: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  captureComment: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired,
}

export default ArticleComments
