import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import FroalaEditor from 'react-froala-wysiwyg'
import Input from '../FormElements/Input'
import TextBox from '../FormElements/TextBox'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/plugins.pkgd.min.css'
import 'froala-editor/css/froala_editor.min.css'

const BookCompose = ({book, updateBook}) => {
  const Config = {
    fullPage: true,
    heightMin: 100,
    width: '100%',
    quickInsertTags: null,
    pastePlain: true,
    attribution: false,
    key: '3AC6eD4F6F4A3H3E3C-22vutF4voqI-7B9cdpF1F1E1D4F1A10A8C2C5E5==',
    placeholderText: 'Book summary',
  }

  return (
    <Flex width={1} justifyContent='center' flexWrap='wrap'>
      <Box p={2} width={1}>
        <Input
          label='Title'
          onChange={(e) => updateBook({...book, title: e.target.value})}
          value={book.title}
          inputProps={{
            type: 'text',
            id: 'title',
            autoComplete: 'title',
          }}
          // error={this.state.error.title}
        />
      </Box>
      <Box p={2} width={[2 / 3, 1 / 2]}>
        <Input
          label='Author'
          onChange={(e) => updateBook({...book, author: e.target.value})}
          value={book.author}
          inputProps={{
            type: 'text',
            id: 'author',
          }}
          // error={this.state.error.title}
        />
      </Box>
      <Box p={2} width={[1 / 3, 1 / 2]}>
        <Input
          label='Rating (1-10)'
          onChange={(e) => updateBook({...book, rating: e.target.value})}
          value={book.rating}
          inputProps={{
            type: 'number',
            id: 'rating',
          }}
          // error={this.state.error.title}
        />
      </Box>
      <Box p={2} width={1}>
        <TextBox
          label='Key Idea'
          onChange={(e) => updateBook({...book, keyIdea: e.target.value})}
          value={book.keyIdea}
          inputProps={{
            type: 'text',
            id: 'keyIdea',
            autoComplete: 'keyIdea',
            // onBlur: () => handleFiedlOnBlur(emailValidator)
          }}
          // error={this.state.error.title}
        />
      </Box>
      <Box p={2} width={1}>
        <FroalaEditor
          config={Config}
          onModelChange={(e) => updateBook({...book, summary: e})}
          model={book.summary}
        />
      </Box>
    </Flex>
  )
}

BookCompose.propTypes = {
  updateBook: PropTypes.func.isRequired,
  book: PropTypes.object.isRequired,
}

export default BookCompose
