import axios from 'axios'
import config from '../../../utils/configuration'
import passwordActions from './actionTypes'

const {target} = config

export const resetError = () => {
  return {
    type: passwordActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: passwordActions.SET_ERROR,
    error,
  }
}

export const toggleIsPasswordUpdated = (processed) => {
  return {
    type: passwordActions.TOGGLE_FORM_STATUS,
    processed,
  }
}

export const updatePassword = ({_id, password, newPassword}) => {
  return async (dispatch) => {
    dispatch(resetError())
    dispatch(toggleIsPasswordUpdated(false))
    return axios
      .post(`${target}/myAccount/updatePassword`, {_id, password, newPassword})
      .then((response) => {
        dispatch(toggleIsPasswordUpdated(true))
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(setError({form: 'Server encountered an issue'}))
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
