import React, {useEffect, useState} from 'react'
import {Flex, Box} from 'rebass'
import Button from '../FormElements/Button'
import HeadingNew from '../../components/FormElements/HeadingNew'

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null)

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault()
      console.log('we are being triggered :D')
      setSupportsPWA(true)
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  const onClick = (evt) => {
    evt.preventDefault()
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
  }
  if (!supportsPWA) {
    return null
  }
  return (
    <>
      <Flex
        style={{borderTop: '1px solid white'}}
        width={[1, 1, 9 / 10]}
        my='25px'
      />
      <Flex flexDirection='column' width={1}>
        <HeadingNew size='h2' palette='light' as='h2'>
          Add to homescreen
        </HeadingNew>
        <HeadingNew size='h3' palette='light' as='h3'>
          Sivv is a progressive web app so called be saved to the homescreen of your
          device to enable easier access.
        </HeadingNew>
        <Box mt='15px' mb='15px' width={[1 / 2, 1 / 2, 1 / 4]}>
          <Button onClick={onClick}>Add Sivv to Homescreen</Button>
        </Box>
      </Flex>
    </>
  )
}

export default InstallPWA
