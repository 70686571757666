import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Box, Flex, Image} from 'rebass'
import {sendEmail, reset} from '../../actions/forms/forgottenPassword'
import {newEmailValidator} from '../../utils/validators'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Input from '../../components/FormElements/Input'
import Button from '../../components/FormElements/Button'
import Text from '../../components/FormElements/Text'
import Container from '../../components/FormElements/Container'
import loginIllustration from '../../images/illustration/knowledge.png'

const Header = styled.div`
  margin-bottom: 16px;
`

const ForgottenPassword = ({
  token,
  error,
  isEmailSent,
  sendEmail,
  reset,
  history,
}) => {
  const [formData, setFormData] = useState({email: ''})
  const [formError, setFormError] = useState({})

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const handleFiedlOnBlur = (validation) => {
    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    // ------------------
    const emailError = newEmailValidator(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, emailError)
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady && sendEmail(formData.email)
  }

  const handleClickBackHome = () => {
    setFormData({...formData, email: ''})
    reset()
    history.push('/')
  }

  const showForm = !isEmailSent

  return (
    <Container>
      <Flex
        mt={3}
        mx={-2}
        p={3}
        flexDirection={['column', 'row']}
        justifyContent='center'
      >
        <Flex
          flexDirection='column'
          ml={[0, 0, 100]}
          width={[1, 1 / 2, 5 / 12]}
          justifyContent='center'
          displayFlex='flex'
          as='div'
        >
          <Box p={2}>
            <Header as='header'>
              <HeadingNew size='h1' palette='medium' as='h2'>
                Forgotten password
              </HeadingNew>
            </Header>
          </Box>

          {showForm ? (
            <>
              <Box p={2}>
                <Text pt={3} palette='light'>
                  To reset your password, submit your email address below.
                </Text>
              </Box>
              <form onSubmit={(e) => handleLoginSubmit(e)}>
                {error && error.form && (
                  <Box p={2}>
                    <Text errorForm>{error.form}</Text>
                  </Box>
                )}
                <Box p={2} pb='24px'>
                  <Input
                    label='Email address'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.email}
                    inputProps={{
                      type: 'email',
                      id: 'email',
                      autoComplete: 'false',
                      onBlur: () => handleFiedlOnBlur(newEmailValidator),
                    }}
                    error={formError && formError.email}
                  />
                </Box>
                <Box px={2} width={[1, 1 / 2, 1 / 2]} pb='24px'>
                  <Button onClick={handleLoginSubmit} palette='primary' fullWidth>
                    Send
                  </Button>
                </Box>
              </form>
            </>
          ) : (
            <>
              <Box p={2} pb='16px'>
                <Text as='p'>
                  An email with a reset link has been sent to {formData.email}.
                </Text>
              </Box>
              <Box px={2} width={1} pb='24px'>
                <Button onClick={handleClickBackHome} palette='secondary' fullWidth>
                  Return to homepage
                </Button>
              </Box>
            </>
          )}
        </Flex>
        <Flex
          flexDirection='column'
          justifyContent='center'
          p={2}
          px={[80, 2, 2]}
          width={[1, 1 / 2, 7 / 12]}
          ml={[0, 0, 80]}
          mt={[50, 50, 80]}
          textAlign='center'
        >
          <Box>
            <Image
              width={1}
              src={loginIllustration}
              alt='Discover data'
              title='Swirb gives tools to users'
            />
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

ForgottenPassword.propTypes = {
  token: PropTypes.string,
  error: PropTypes.object,
  isEmailSent: PropTypes.bool.isRequired,
  sendEmail: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  history: PropTypes.object,
}

const mapStateToProps = (state) => {
  const {token} = state.user
  const {error, isEmailSent} = state.forms.forgottenPassword
  return {token, error, isEmailSent}
}

export default connect(mapStateToProps, {sendEmail, reset})(ForgottenPassword)
