import React from 'react'
import PropTypes from 'prop-types'
import {Flex} from 'rebass'
import styled from 'styled-components'

const StyledFlex = styled(Flex)``

const Container = ({noPadding, minHeight, background, home, children, align, horizontalAlign, ...props}) => {

  return (
    <StyledFlex
      px={noPadding ? '0px' : ['15px', '75px']}
      py={noPadding ? ['10px', '15px'] : home ? ['50px', '75px'] : ['25px', '50px']}
      width={1}
      flexDirection='column'
      alignItems={horizontalAlign || 'space-between'}
      justifyContent={align || 'space-around'}
      {...props}
      style={{
        minHeight: minHeight ? 'calc(100vh - 70px)' : 'null',
        background: background || '#FFF',
      }}
    >
      {children}
    </StyledFlex>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  textAlign: PropTypes.string,
  displayFlex: PropTypes.string,
  minHeight: PropTypes.bool,
  background: PropTypes.string,
  noPadding: PropTypes.bool,
  home: PropTypes.bool,
}

export default Container
