import {
  UPDATE_QUERY,
  CLEAR_SEARCH,
  FETCH_RESULTS_NONE,
  FETCH_RESULTS_START,
  FETCH_RESULTS_SUCCESS,
  FETCH_RESULTS_ERROR,
  FETCH_ARTICLE_START,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_ERROR,
  VIEW_EMAIL_FORM,
  CAPTURE_EMAIL,
  EMAIL_ARTICLE_SUCCESS,
  EMAIL_ARTICLE_ERROR,
  RESEND_ARTICLE,
  FETCH_PROFILE_START,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_SUCCESS,
  LATEST_ARTICLES_SUCCESS,
  GET_COMMENTS_START,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_ERROR,
  CAPTURE_COMMENT,
  UPDATE_COMMENTS_SUCCESS,
  HIDE_COMMENTS,
  VIEW_LIKES_START,
  VIEW_LIKES_SUCCESS,
  VIEW_LIKES_ERROR,
  FETCH_GUIDE_START,
  FETCH_GUIDE_ERROR,
  FETCH_GUIDE_SUCCESS,
  CLEAR_GUIDE,
  FETCH_TOPICS_START,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_ERROR,
  FETCH_LIBRARY_START,
  FETCH_LIBRARY_SUCCESS,
  FETCH_LIBRARY_ERROR,
  FETCH_BLOG_START,
  FETCH_BLOG_SUCCESS,
  FETCH_BLOG_ERROR,
} from '../actions/searchActions'

import {UPDATE_ARTICLE_LIKES} from '../actions/articleActions'

const defaultState = {
  search: null,
  type: null,
  topic: null,
  theme: null,
  sourceType: null,
  // isEditingQuery: false,
  savedSearchQueryParameters: null,
  results: {
    total: 0,
    noResults: false,
    loading: false,
    error: false,
    time: 0,
    articles: [],
  },
  article: {},
  viewEmailForm: '',
  emailTarget: '',
  articleEmailed: false,
  articleEmailError: false,
  // viewComments: '',
  likes: {
    // articleID: '',
    isLoading: false,
    results: [],
    error: false,
  },
  comments: {
    articleID: '',
    isLoading: false,
    results: [],
    error: false,
  },
  comment: '',
  profile: {
    isloading: false,
    error: false,
    result: {
      firstName: '',
      lastName: '',
      created: '',
      followers: 0,
      following: 0,
      articles: [],
    },
  },
  latestArticles: [],
  guide: {
    isLoading: false,
    result: {},
    error: false,
  },
  topicList: {
    isLoading: false,
    result: [],
    error: false,
  },
  library: {
    isLoading: false,
    result: [],
    error: false,
  },
  blog: {
    isLoading: false,
    result: [],
    error: false,
  },
}

export default function search(state = defaultState, action) {
  switch (action.type) {
    // Update query object with new data
    case UPDATE_QUERY:
      return {
        ...state,
        // isEditingQuery: true,
        [action.key]: action.value,
      }

    case CLEAR_SEARCH:
      return {
        ...state,
        search: null,
        type: null,
        topic: null,
        theme: null,
        sourceType: null,
        viewEmailForm: '',
        emailTarget: '',
        articleEmailed: false,
        articleEmailError: false,
      }

    // Search query
    case FETCH_RESULTS_START:
      return {
        ...state,
        results: {
          ...state.results,
          loading: true,
          noResults: false,
          articles: [],
        },
      }

    case FETCH_RESULTS_SUCCESS:
      return {
        ...state,
        isEditingQuery: false,
        results: {
          ...state.results,
          error: false,
          loading: false,
          time: action.payload.time,
          articles:
            action.flag === 'replace'
              ? action.payload
              : [...state.results.articles, ...action.payload],
        },
      }

    case FETCH_RESULTS_NONE:
      return {
        ...state,
        results: {
          ...state.results,
          error: false,
          loading: false,
          noResults: true,
          total: 0,
          properties: [],
        },
      }

    case FETCH_RESULTS_ERROR:
      return {
        ...state,
        results: {
          ...state.results,
          loading: false,
          error: true,
        },
      }

    case UPDATE_ARTICLE_LIKES:
      return {
        ...state,
        article: {
          ...state.article,
          likes:
            state.article._id === action.ID
              ? action.likes.likes
              : state.article.likes,
          likeCount:
            state.article._id === action.ID
              ? action.likes.likeCount
              : state.article.likeCount,
        },
        results: {
          ...state.results,
          articles: state.results.articles.map((r) => {
            return r._id !== action.ID
              ? r
              : {...r, likes: action.likes.likes, likeCount: action.likes.likeCount}
          }),
        },
      }

    case FETCH_ARTICLE_START:
      return {
        ...state,
        article: {searching: true},
      }

    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.article,
      }

    case FETCH_ARTICLE_ERROR:
      return {
        ...state,
        article: {error: true},
      }

    case VIEW_EMAIL_FORM:
      return {
        ...state,
        comments: {...state.comments, articleID: ''},
        viewEmailForm:
          state.viewEmailForm === action.articleID ? '' : action.articleID,
        emailTarget: '',
        articleEmailed: false,
        articleEmailError: false,
      }

    case CAPTURE_EMAIL:
      return {
        ...state,
        emailTarget: action.email,
      }

    case EMAIL_ARTICLE_SUCCESS:
      return {
        ...state,
        articleEmailed: true,
        articleEmailError: false,
      }

    case RESEND_ARTICLE:
      return {
        ...state,
        articleEmailed: false,
        articleEmailError: false,
        emailTarget: '',
      }

    case EMAIL_ARTICLE_ERROR:
      return {
        ...state,
        articleEmailError: true,
      }

    case GET_COMMENTS_START:
      return {
        ...state,
        comment: '',
        viewEmailForm: '',
        comments: {
          articleID: state.comments.articleID === action.ID ? '' : action.ID,
          isLoading: true,
          results: [],
          error: false,
        },
      }

    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          results: action.comments,
          error: false,
        },
      }

    case GET_COMMENTS_ERROR:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          results: [],
          error: true,
        },
      }

    case VIEW_LIKES_START:
      return {
        ...state,
        likes: {
          isLoading: true,
          results: [],
          error: false,
        },
      }

    case VIEW_LIKES_SUCCESS:
      return {
        ...state,
        likes: {
          isLoading: false,
          results: action.data,
          error: false,
        },
      }

    case VIEW_LIKES_ERROR:
      return {
        ...state,
        likes: {
          isLoading: false,
          results: [],
          error: true,
        },
      }

    case HIDE_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          articleID: '',
        },
      }

    case CAPTURE_COMMENT:
      return {
        ...state,
        comment: action.comment,
      }

    case UPDATE_COMMENTS_SUCCESS:
      return {
        ...state,
        comment: '',
        comments: {
          ...state.comments,
          isLoading: false,
          results: action.comments,
          error: false,
        },
      }

    case FETCH_PROFILE_START:
      return {
        ...state,
        profile: {isLoading: true, error: false, result: {}},
      }

    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        profile: {isLoading: false, error: true, result: {}},
      }

    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {isLoading: false, error: false, result: action.result},
      }

    case FETCH_GUIDE_START:
      return {
        ...state,
        guide: {isLoading: true, error: false, result: {}},
      }

    case FETCH_GUIDE_ERROR:
      return {
        ...state,
        guide: {isLoading: false, error: true, result: {}},
      }

    case FETCH_GUIDE_SUCCESS:
      return {
        ...state,
        guide: {isLoading: false, error: false, result: action.guide},
      }

    case CLEAR_GUIDE:
      return {
        ...state,
        guide: {isLoading: false, error: false, result: {}},
      }

    case FETCH_TOPICS_START:
      return {
        ...state,
        topicList: {isLoading: true, error: false, result: []},
      }

    case FETCH_TOPICS_ERROR:
      return {
        ...state,
        topicList: {isLoading: false, error: true, result: []},
      }

    case FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        topicList: {isLoading: false, error: false, result: action.topics},
      }

    case FETCH_LIBRARY_START:
      return {
        ...state,
        library: {isLoading: true, error: false, result: []},
      }

    case FETCH_LIBRARY_ERROR:
      return {
        ...state,
        library: {isLoading: false, error: true, result: []},
      }

    case FETCH_LIBRARY_SUCCESS:
      return {
        ...state,
        library: {isLoading: false, error: false, result: action.topics},
      }

    case FETCH_BLOG_START:
      return {
        ...state,
        blog: {isLoading: true, error: false, result: []},
      }

    case FETCH_BLOG_ERROR:
      return {
        ...state,
        blog: {isLoading: false, error: true, result: []},
      }

    case FETCH_BLOG_SUCCESS:
      return {
        ...state,
        blog: {isLoading: false, error: false, result: action.posts},
      }

    case LATEST_ARTICLES_SUCCESS:
      return {
        ...state,
        latestArticles: action.results,
      }

    default:
      return state
  }
}
