import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import styled from 'styled-components'
import {Box, Flex, Image} from 'rebass'
import Button from '../../components/FormElements/Button'
import Container from '../../components/FormElements/Container'
import {verifyEmail} from '../../actions/user'
import HeadingNew from '../../components/FormElements/HeadingNew'
import loginIllustration from '../../images/illustration/knowledge.png'

const Header = styled.div`
  margin-bottom: 16px;
`

const VerifyEmail = ({verifyEmail, location}) => {
  useEffect(() => {
    verifyEmail(location.pathname.replace('/verify/', ''))
  }, [])

  return (
    <Container minHeight>
      <Flex flexDirection={['column', 'row']} justifyContent='center'>
        <Flex
          flexDirection='column'
          ml={[0, 0, 100]}
          width={[1, 1 / 2, 5 / 12]}
          justifyContent='center'
          displayFlex='flex'
          as='div'
        >
          <Box p={2}>
            <Header as='header'>
              <HeadingNew size='h1' palette='medium' as='h2'>
                Email Verified
              </HeadingNew>
            </Header>
          </Box>
          <Box px={2} width={[1, 1 / 2, 1 / 2]} pb='24px'>
            <Link to='/signin'>
              <Button palette='secondary' fullWidth>
                Sign In
              </Button>
            </Link>
          </Box>
        </Flex>
        <Flex
          flexDirection='column'
          justifyContent='center'
          p={2}
          px={[80, 2, 2]}
          width={[1, 1 / 2, 7 / 12]}
          ml={[0, 0, 80]}
          mt={[50, 50, 80]}
          textAlign='center'
        >
          <Box>
            <Image
              width={1}
              src={loginIllustration}
              alt='Discover knowledge'
              title="Sivv distills the world's best thinking"
            />
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

VerifyEmail.propTypes = {
  errors: PropTypes.object,
  location: PropTypes.object,
  verifyEmail: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  token: PropTypes.string,
}

const mapStateToProps = (state) => {
  const {errors, token} = state.user
  return {errors, token}
}

export default withRouter(connect(mapStateToProps, {verifyEmail})(VerifyEmail))
