import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Box, Flex} from 'rebass'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Image1 from '../images/infographic/Icon1.svg'
import Image2 from '../images/infographic/Icon2.svg'
import Image3 from '../images/infographic/Icon3.svg'
import Image4 from '../images/infographic/Icon4.svg'
import Image5 from '../images/infographic/Icon5.svg'
import Image6 from '../images/infographic/Icon6.svg'

const Header = styled.div`
  margin-top: 10px;
`

const StyledImage = styled.img`
  width: 75px;

  @media screen and (max-width: 40em) {
    width: 50px;
    float: left;
  }
`

const Reasons = [
  {
    text:
      'In most cases, the primary driver for reading non-fiction books is a desire to learn (as opposed to, say, a need for entertainment).',
    image: Image1,
  },
  {
    text:
      'Minimum length requirements and historical precedent mean that many books pad-out a core argument with unnecessary information.',
    image: Image2,
  },
  {
    text:
      'As a result, useful information is unnecessarily difficult and time-consuming to access. This, in turn, makes it harder to understand and retain.',
    image: Image3,
  },
  {
    text:
      'Moreover, different books often cover many of the same ideas while it can be difficult to determine where novel ideas fit within the wider topic.',
    image: Image4,
  },
  {
    text:
      'Once consumed, even useful information is easily forgotten. Without making and reviewing notes, many people fail to apply the ideas they encounter.',
    image: Image5,
  },
  {
    text:
      'Publishing lead-times mean that many books do not cover up-to-date research, tools or trends, while content can quickly become stale or even wrong.',
    image: Image6,
  },
]

const InformationInefficiencies = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container background='#005969'>
      <Flex flexDirection='column' style={{maxWidth: '800px', margin: 'auto'}}>
        <Box pb={2}>
          <Header as='header'>
            <HeadingNew size='h1' palette='white' as='h2'>
              The problem with non-fiction publishing
            </HeadingNew>
          </Header>
        </Box>

        <Box pb={2}>
          <HeadingNew size='h3' palette='white' as='h3' justify>
            At Sivv, we love books. There is very little that we would rather do than
            spend a day immersed within a great book, learning about how the world
            works and how we can better function within it. Nevertheless, we’ve come
            to believe that non-fiction books are hugely inefficient channels for
            information consumption. The problem, as we see it, is as follows:
          </HeadingNew>
        </Box>

        <Box pb={2}>
          <HeadingNew size='h3' palette='white' as='h3'>
            {Reasons.map((r, idx) => (
              <Flex alignItems='center' my={['25px', '45px']}>
                <StyledImage src={r.image} />
                <Box ml='25px' style={{textAlign: 'justify'}}>
                  {r.text}
                </Box>
              </Flex>
            ))}
          </HeadingNew>
        </Box>
        <Box pb={2}>
          <HeadingNew size='h3' palette='white' as='h3' justify>
            Think about it - if 100,000 people read a book and take, on average, ten
            hours to do so, that represents one million hours of invested effort. If
            the outcome of this is that some of those people (many won't finish) come
            away with a handful of useful ideas, many of which are quickly forgotten
            and never applied, that surely represents a massive inefficiency?
          </HeadingNew>
        </Box>
        <Box pb={2}>
          <HeadingNew size='h3' palette='white' as='h3' justify>
            Perhaps even more frustratingly, because of the barriers to information
            access that the standard book format establishes, many useful and
            possibly even life-changing ideas go entirely undiscovered by the
            majority of people. The problem is exacerbated by the challenges of
            modern life, which burdens many of us with ever-busier schedules and
            information overload.
          </HeadingNew>
        </Box>
        <Box pb={2}>
          <HeadingNew size='h3' palette='white' as='h3' justify>
            Many of the above issues apply to articles, podcasts, blog posts and
            research. Services providing book summaries are only a partial solution –
            they are useful if you want to know the ideas that a book covers but
            suffer from many of the same inefficiencies if you are trying to develop
            a comprehensive understanding of a topic.
          </HeadingNew>
        </Box>
        <Box pb={2}>
          <HeadingNew size='h3' palette='white' as='h3' justify>
            We founded Sivv to address these issues. We make useful ideas more
            accessible to help you achieve your goals more quickly and with less
            overwhelm. Our initial focus is on topics that are central to the
            personal and professional endeavours of most people and yet are largely
            untouched by formal education or training. Such ‘life skills’ include
            productivity, communication, decision-making, career development,
            learning and wellbeing.
          </HeadingNew>
        </Box>
      </Flex>
    </Container>
  )
}

export default InformationInefficiencies
