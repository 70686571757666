import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Box, Flex, Image} from 'rebass'
import {resetPassword, reset} from '../../actions/forms/resetPassword'
import {newPasswordValidator, passwordRepeatValidator} from '../../utils/validators'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Input from '../../components/FormElements/Input'
import Button from '../../components/FormElements/Button'
import Text from '../../components/FormElements/Text'
import Container from '../../components/FormElements/Container'
import loginIllustration from '../../images/illustration/knowledge.png'

const Header = styled.div`
  margin-bottom: 16px;
`
const ResetPassword = ({
  error,
  isPasswordReset,
  resetPassword,
  reset,
  history,
  match,
}) => {
  const [formData, setFormData] = useState({password: '', passwordRepeat: ''})
  const [formError, setFormError] = useState({})

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const handleFiedlOnBlur = (validation) => {
    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault()
    // ------------------
    const newPasswordError = newPasswordValidator(formData)
    const passwordRepeatError = passwordRepeatValidator(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(
      existingErrors,
      newPasswordError,
      passwordRepeatError
    )
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady && resetPassword(match.params.token, formData.password)
  }

  const handleClickSignIn = () => {
    setFormData({...formData, password: ''})
    reset()
    history.push('/signin')
  }

  const showForm = !isPasswordReset

  return (
    <Container minHeight>
      <Flex mt={3} mx={-2} flexDirection={['column', 'row']} justifyContent='center'>
        <Flex
          flexDirection='column'
          ml={[0, 0, 100]}
          width={[1, 1 / 2, 5 / 12]}
          justifyContent='center'
          displayFlex='flex'
          as='div'
        >
          <Box p={2}>
            <Header as='header'>
              <HeadingNew size='h1' palette='medium' as='h2'>
                {showForm ? 'Reset password' : 'Reset password confirmation.'}
              </HeadingNew>
            </Header>
          </Box>

          {showForm ? (
            <>
              <Box p={2}>
                <Text pt={3} palette='light'>
                  Please enter a new password below
                </Text>
              </Box>
              <form onSubmit={(e) => console.log(e)}>
                {error && error.form && (
                  <Box p={2}>
                    <Text errorForm>{error.form}</Text>
                  </Box>
                )}
                <Box p={2} pb='8px'>
                  <Input
                    label='New password'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.newPassword}
                    inputProps={{
                      type: 'password',
                      id: 'password',
                      autoComplete: 'false',
                      onBlur: () => handleFiedlOnBlur(newPasswordValidator),
                    }}
                    error={formError && formError.password}
                  />
                </Box>
                <Box p={2} pb='8px'>
                  <Input
                    label='Repeat new password'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.passwordRepeat}
                    inputProps={{
                      type: 'password',
                      id: 'passwordRepeat',
                      autoComplete: 'false',
                      onBlur: () => handleFiedlOnBlur(newPasswordValidator),
                    }}
                    error={formError && formError.passwordRepeat}
                  />
                </Box>
                <Box p={2} pb='24px'>
                  <Text palette='light'>
                    To help keep your account secure, your new password should
                    include lower and uppercase characters and at least one number.
                    It should also be at least 8 characters long.
                  </Text>
                </Box>
                <Box px={2} width={[1, 1 / 2, 1 / 2]} pb='24px'>
                  <Button
                    onClick={handleResetPasswordSubmit}
                    palette='primary'
                    fullWidth
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            </>
          ) : (
            <>
              <Box p={2} pb='24px'>
                <Text palette='light' as='p'>
                  Your password has been reset successfully.
                  <br />
                  Please click below to sign in.
                </Text>
              </Box>
              <Box px={2} width={[1, 1 / 2, 1 / 2]} pb='24px'>
                <Button onClick={handleClickSignIn} palette='primary' fullWidth>
                  Sign In
                </Button>
              </Box>
            </>
          )}
        </Flex>
        <Flex
          flexDirection='column'
          justifyContent='center'
          p={2}
          px={[80, 2, 2]}
          width={[1, 1 / 2, 7 / 12]}
          ml={[0, 0, 80]}
          mt={[50, 50, 80]}
          textAlign='center'
        >
          <Box>
            <Image
              width={1}
              src={loginIllustration}
              alt='Discover data'
              title='Swirb gives tools to users'
            />
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

ResetPassword.propTypes = {
  error: PropTypes.object,
  isPasswordReset: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
}

const mapStateToProps = (state) => {
  const {error, isPasswordReset} = state.forms.resetPassword
  return {error, isPasswordReset}
}

export default connect(mapStateToProps, {resetPassword, reset})(ResetPassword)
