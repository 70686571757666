import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import HeadingNew from '../HeadingNew'

const Container = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  padding: 2px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #a1bac4;
  border-right: ${(props) => (props.end ? 'none' : '1px solid #a1bac4')};
  ${(props) =>
    props.active &&
    css`
      color: #ffffff;
      background-color: #a1bac4;
    `}

  img {
    margin-right: 10px;
    height: 20px;
  }
`

const Tab = ({title, image, activeImage, active, onClick, end}) => {
  return (
    <Container active={active} onClick={onClick} end={end}>
      <img alt='tab' src={active ? activeImage : image} />
      <HeadingNew size='h3' palette='medium' as='h3'>
        {title}
      </HeadingNew>
    </Container>
  )
}

Tab.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  activeImage: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  end: PropTypes.bool,
}

export default Tab
