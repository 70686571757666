import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Box, Flex} from 'rebass'
import {
  authenticatePassword,
  deleteAccount,
} from '../../../actions/forms/deleteAccount'
import {logout} from '../../../actions/user'
import {passwordValidator} from '../../../utils/validators'
import Input from '../../../components/FormElements/Input'
import Button from '../../../components/FormElements/Button'
import Text from '../../../components/FormElements/Text'
import ToggleContent from '../../../components/FormElements/ToggleContent'
import HeadingNew from '../../../components/FormElements/HeadingNew'
import Modal from '../../../components/FormElements/Modal'
import {Separator} from '../../../components/FormElements/utils'
import {LinkRouter} from '../../../components/FormElements/Link'

const DeleteAccountForm = ({
  token,
  _id,
  authenticatePassword,
  deleteAccount,
  error,
  isAccountDeleted,
  logout,
  history,
}) => {
  const [formData, setFormData] = useState({
    password: '',
  })
  const [formError, setFormError] = useState({})
  const [showFeedback, toggleShowFeedback] = useState(false)

  // const logoutUser = (isAccountDeleted) => {
  //   if (isAccountDeleted) {
  //     logout()
  //     history.push('/')
  //   }
  // }

  useEffect(() => {
    // logoutUser(isAccountDeleted)
    if (isAccountDeleted) {
      logout()
      history.push('/')
    }
  }, [isAccountDeleted])

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const handleFiedlOnBlur = (validation) => {
    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleSubmitForm = async (e, displayModalCallback) => {
    e.preventDefault()

    // ------------------
    const passwordError = passwordValidator(formData)

    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, passwordError)
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady &&
      authenticatePassword(
        {
          _id,
          password: formData.password,
        },
        displayModalCallback
      )
  }

  const handleDeleteAccount = async (e, hideModalCallback) => {
    e.preventDefault()
    deleteAccount(
      {
        _id,
        password: formData.password,
      },
      hideModalCallback
    )
  }

  return (
    <section>
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <Text as='p' palette='light'>
          Deleting your account will permanently deactivate your account and delete
          all of your articles.
          <br />
          This cannot be undone.
        </Text>
        <Text as='p'>If you would like to proceed, enter your password below.</Text>
        {/* ==================== ERROR */}
        {error && error.form && (
          <Flex
            flexWrap='wrap'
            flexDirection='row'
            alignItems={['normal', 'flex-end']}
            mx={-2}
            mb={2}
          >
            <Box p={2} pb={0} width={[1, 1, 1 / 3]}>
              <Text errorForm>{error.form}</Text>
            </Box>
          </Flex>
        )}

        {/* ==================== FIELDS */}
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          mb={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Password *'
              onChange={(e) => handleInputChange(e)}
              value={formData.password}
              inputProps={{
                type: 'password',
                id: 'password',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(passwordValidator),
              }}
              error={formError && formError.password}
            />
          </Box>
        </Flex>
        <Text as='p'>
          <LinkRouter to='/forgottenPassword'>Forgot your password?</LinkRouter>
        </Text>
        <Separator />
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
        >
          <Box px={2} pb='24px' width={[1 / 2, 1 / 2, 1 / 4]}>
            {/* <Button
              onClick={(e) => handleSubmitForm(e)}
              // onClick={showModal}
              palette='primary'
              fullWidth
              feedback='Password updated'
              showFeedback={showFeedback}
            >
              Delete Account
            </Button> */}
            {/* ------------------------------- */}
            <ToggleContent
              toggle={(show) => (
                <Button
                  onClick={(e) => handleSubmitForm(e, show)}
                  palette='primary'
                  fullWidth
                  feedback='Password updated'
                  showFeedback={showFeedback}
                >
                  Delete Account
                </Button>
              )}
              content={(hide) => (
                <Modal closeModal={hide}>
                  <HeadingNew
                    size='h2'
                    palette='darkColor'
                    as='h2'
                    spacing='0 0 8px'
                  >
                    Confirm Deletion
                  </HeadingNew>
                  <Text as='p' palette='light'>
                    Please confirm you would like to delete your account.
                    <br />
                    You cannot undo this action.
                  </Text>
                  <Flex
                    flexWrap='wrap'
                    flexDirection='row'
                    alignItems={['normal', 'flex-end']}
                    mx={-2}
                  >
                    <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
                      <Button
                        onClick={(e) => handleDeleteAccount(e, hide)}
                        palette='primary'
                        size='medium'
                        fullWidth
                        feedback='Password updated'
                        showFeedback={showFeedback}
                      >
                        Delete Account
                      </Button>
                    </Box>
                    <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
                      <Button
                        onClick={hide}
                        palette='secondary'
                        size='medium'
                        fullWidth
                        feedback='Password updated'
                        showFeedback={showFeedback}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Flex>
                </Modal>
              )}
            />
            {/* ------------------------------- */}
          </Box>
        </Flex>
      </form>
    </section>
  )
}

DeleteAccountForm.propTypes = {
  token: PropTypes.string,
  _id: PropTypes.string.isRequired,
  error: PropTypes.object,
  isAccountDeleted: PropTypes.bool.isRequired,
  authenticatePassword: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  const {token, _id} = state.user
  const {error, isAccountDeleted} = state.forms.deleteAccount
  return {token, _id, error, isAccountDeleted}
}

export default withRouter(
  connect(mapStateToProps, {authenticatePassword, deleteAccount, logout})(
    DeleteAccountForm
  )
)
