import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import iconMinus from '../../../images/icons/minus.svg'
import iconPlus from '../../../images/icons/plus.svg'

import HeadingNew from '../HeadingNew'

const Expandable = styled.section`
  box-shadow: 0 0 6px rgba(196, 196, 196, 0.5);
  border-radius: 4px;
  width: 100%;
  margin-bottom: ${(props) => (props.narrow ? '10px' : '32px')};
  background: white;
`

const ExpandableHeading = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.narrow ? '5px' : '16px')};
  background: url(${iconPlus}) calc(100% - 16px) center no-repeat;
  background-size: 16px 16px;

  ${(props) =>
    props.isExpanded &&
    css`
      /* border-bottom: solid 1px #dadee6; */
      background: url(${iconMinus}) calc(100% - 16px) center no-repeat;
      background-size: 16px 16px;
    `}
`

const ExpandableItem = styled.div`
  padding: 0 16px;
  display: none;
  ${(props) =>
    props.isExpanded &&
    css`
      display: block;
    `}
`

const ExpandableCpt = ({title, children, isOpen, narrow}) => {
  const [isExpanded, toggleIsExpanded] = useState(isOpen)
  return (
    <Expandable narrow={narrow}>
      <ExpandableHeading
        isExpanded={isExpanded}
        onClick={() => toggleIsExpanded(!isExpanded)}
        narrow={narrow}
      >
        <HeadingNew size='h2' palette='light' as='h2'>
          {title}
        </HeadingNew>
      </ExpandableHeading>
      <ExpandableItem isExpanded={isExpanded}>{children}</ExpandableItem>
    </Expandable>
  )
}

ExpandableCpt.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  narrow: PropTypes.bool,
}

ExpandableCpt.defaultProps = {
  isOpen: false,
}

export default ExpandableCpt
