import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import Button from '../FormElements/Button'
import Input from '../FormElements/Input'
import HeadingNew from '../FormElements/HeadingNew'

const validator = require('email-validator')

const ArticleEmail = ({
  articleEmailed,
  articleEmailError,
  captureEmail,
  emailTarget,
  resendArticle,
  emailArticle,
  articleID,
  userID,
  invite,
}) => {
  const [error, setError] = useState(null)

  const submit = () => {
    const emailTest = validator.validate(emailTarget)
    if (emailTest) setError('Enter valid email')
    if (!error) {
      emailArticle(articleID, userID, emailTarget)
      setError(null)
    }
  }

  return (
    <>
      {!articleEmailed && !articleEmailError && (
        <Flex
          width={1}
          flexDirection={invite ? 'row' : ['column', 'row']}
          justifyContent='center'
          mt={invite ? 0 : 3}
        >
          {!invite && (
            <Box p={2} width={[1, 1 / 3]}>
              <HeadingNew size='h3' palette='light' weight='bold' as='p'>
                Email a link to this article
              </HeadingNew>
            </Box>
          )}
          <Box p={invite ? 0 : 2} width={invite ? [2 / 3, 1 / 2] : [1, 1 / 3]}>
            <Input
              label='Email address'
              onChange={(e) => captureEmail(e.target.value)}
              value={emailTarget}
              inputProps={{
                type: 'email',
                id: 'email',
                autoComplete: 'false',
                // onBlur: () => this.handleFiedlOnBlur()
              }}
              error={error}
            />
          </Box>
          <Box
            pl={2}
            pt={invite ? 0 : 2}
            width={invite ? [1 / 3, 1 / 2] : [1, 1 / 3]}
          >
            <Button onClick={() => submit()} fullWidth>
              Send
            </Button>
          </Box>
        </Flex>
      )}
      {articleEmailed && (
        <Flex
          width={1}
          flexDirection={invite ? 'row' : ['column', 'row']}
          justifyContent='center'
          mt={invite ? 0 : 3}
        >
          <Box p={2} width={invite ? 1 / 3 : [1, 1 / 3]}>
            <HeadingNew size='h3' palette='light' weight='bold' as='p'>
              Email Sent!
            </HeadingNew>
          </Box>
          <Box
            pl={2}
            pt={invite ? 0 : 2}
            width={invite ? [2 / 3, 1 / 2] : [1, 1 / 3]}
          >
            <Button onClick={() => resendArticle()} fullWidth>
              {!invite ? 'Send to someone else' : 'Invite someone else'}
            </Button>
          </Box>
        </Flex>
      )}
      {articleEmailError && (
        <Flex
          width={1}
          flexDirection={['column', 'row']}
          justifyContent='center'
          mt={3}
        >
          <Box p={2} width={[1, 1 / 3]}>
            <HeadingNew size='h3' palette='light' weight='bold' as='p'>
              Something went wrong...
            </HeadingNew>
          </Box>
          <Box pl={2} pt={2} width={[1, 1 / 3]}>
            <Button onClick={() => resendArticle()} fullWidth>
              Try again
            </Button>
          </Box>
        </Flex>
      )}
    </>
  )
}

ArticleEmail.propTypes = {
  articleEmailed: PropTypes.bool.isRequired,
  articleEmailError: PropTypes.bool.isRequired,
  captureEmail: PropTypes.func.isRequired,
  emailTarget: PropTypes.string.isRequired,
  resendArticle: PropTypes.func.isRequired,
  emailArticle: PropTypes.func.isRequired,
  articleID: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  invite: PropTypes.bool,
}

export default ArticleEmail
