import React from 'react'
import PropTypes from 'prop-types'
import styled, {css, keyframes} from 'styled-components'

const animationName = keyframes`
  0%{opacity: 0;}
  10%{opacity: 1;}
  90%{opacity: 1;}
  100%{opacity: 0;}
`
const StyledButton = styled.button`
  /* font-family: Quicksand, sans-serif;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  transition: all .1s ease-in;
  position: relative;
  overflow: hidden; */
  font-family: Quicksand, sans-serif;
  font-weight: 500;
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  transition: all 0.1s ease-in;
  position: relative;
  overflow: hidden;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  font-size: 1rem;
  line-height: 1.42857;
  text-decoration: none;
  color: ${({ invertColor }) => (invertColor ? '#de7033' : '#fff')};
  background: #de7033;
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  border: ${({ invertColor }) => (invertColor ? '2px solid #de7033' : 'null')};

  background: ${({ invertColor }) => (invertColor ? '#fff' : '#de7033')};
  &:hover {
    background: #e55b00;
  }
  width: ${({fullWidth}) => (fullWidth ? '100%' : 'auto')};

  /* ----------------------------------------- PALETTE */
  /* -- Primary -- */
  ${(props) =>
    props.palette === 'primary' &&
    css`
      color: ${({ invertColor }) => (invertColor ? '#de7033' : '#fff')};
      background: ${({ invertColor }) => (invertColor ? '#fff' : '#de7033')};
      &:hover {
        background: ${({ invertColor }) => (invertColor ? '#fff' : '#e55b00')};
      }
      &:active {
        background: #bf4c00;
      }
      &:disabled {
        cursor: none;
        background: #f3a794;
      }
    `}

  /* -- Secondary -- */
  ${(props) =>
    props.palette === 'secondary' &&
    css`
      color: #ff6600;
      background: #fff;
      box-shadow: inset 0px 0px 0px 1px #ff6600;
      &:hover {
        color: #e55b00;
        box-shadow: inset 0px 0px 0px 2px #e55b00;
      }
      &:active {
        color: #bf4c00;
        box-shadow: inset 0px 0px 0px 2px #bf4c00;
      }
      &:disabled {
        cursor: none;
        color: #f3a794;
        box-shadow: inset 0px 0px 0px 2px #f3a794;
      }
    `}

  /* ----------------------------------------- SIZE */
  ${(props) =>
    props.size === 'large' &&
    css`
      height: 48px;
      font-size: 16px;
      padding: 0 16px;
      line-height: 48px;
    `}
  ${(props) =>
    props.size === 'narrow' &&
    css`
      height: 42px;
      font-size: 16px;
      padding: 0 16px;
      line-height: 40px;
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      height: 38px;
      font-size: 14px;
      line-height: 38px;
    `}
  ${(props) =>
    props.size === 'small' &&
    css`
      height: 28px;
      font-size: 12px;
      line-height: 28px;
    `}

  /* ----------------------------------------- COMPOSITION */
  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius};
    `}

  /* ----------------------------------------- FEEDBACK */
  ${(props) =>
    props.feedback &&
    css`
    &:after {
      content: '${props.feedback}';
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      background: #00B349;
      position: absolute;
      z-index: 2;
      opacity: 0;
    }
  `}

  /* ----------------------------------------- FEEDBACK */
  ${(props) =>
    props.showFeedback &&
    css`
      &:after {
        -webkit-animation: ${animationName} 5s; /* Chrome, Safari, Opera */
        animation: ${animationName} 5s;
        animation-fill-mode: forwards;
      }
    `}
`

const Button = ({
  children,
  onClick,
  // ---------------
  fullWidth,
  size,
  invertColor,
  palette,
  disabled,
  borderRadius,
  feedback,
  showFeedback,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      fullWidth={fullWidth}
      palette={palette}
      size={size}
      invertColor={invertColor}
      disabled={disabled}
      borderRadius={borderRadius}
      feedback={feedback}
      showFeedback={showFeedback}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  palette: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.string,
  feedback: PropTypes.string,
  showFeedback: PropTypes.bool,
}

Button.defaultProps = {
  size: 'large',
  palette: 'primary',
}

export default Button
