import React from 'react'
import {Box, Flex} from 'rebass'
import styled from 'styled-components'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Radio from '../../components/FormElements/Radio'
import SelectNew from '../../components/FormElements/SelectNew'
import List from '../../images/icons/list.svg'
import ListSelected from '../../images/icons/list-selected.svg'
import Categorised from '../../images/icons/menu-blue.svg'
import CategorisedSelected from '../../images/icons/menu-orange.svg'

const Label = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 300;
  color: #31325f;
`

const LibraryFilter = ({
  filter,
  updateFilter,
  viewFormat,
  updateViewFormat,
  Subjects,
  updateSelectedSubject,
  SelectedSubject,
}) => {
  return (
    <Flex alignItems={['flex-start', 'flex-start', 'center']} mb={3} width={1}>
      <Flex
        alignItems={['flex-start', 'flex-start', 'center']}
        mr={['0px', '0px', '50px']}
        width={[1 / 3]}
        flexDirection={['column', 'column', 'row']}
      >
        <HeadingNew size='h3' palette='white' as='h3' style={{width: '65px'}}>
          Sort by:
        </HeadingNew>
        <Box ml={[1, 1, 3]} mt={[2, 2, 0]}>
          <Radio
            label='Title'
            name='title'
            inputProps={{
              checked: filter === 'title',
              onClick: () => (filter !== 'title' ? updateFilter('title') : null),
            }}
            light
          />
        </Box>
        <Box ml={[1, 1, 3]} mt={[1, 1, 0]}>
          <Radio
            label='Author'
            name='author'
            inputProps={{
              checked: filter === 'author',
              onClick: () => (filter !== 'author' ? updateFilter('author') : null),
            }}
            light
          />
        </Box>
      </Flex>
      <Flex
        alignItems={['flex-start', 'flex-start', 'center']}
        mr={['0px', '0px', '50px']}
        width={[1 / 3]}
        flexDirection={['column', 'column', 'row']}
      >
        <HeadingNew size='h3' palette='white' as='h3' style={{width: '65px'}}>
          View:
        </HeadingNew>
        <Flex
          ml={[1, 1, 3]}
          mt={[2, 2, 0]}
          alignItems='center'
          onClick={() => updateViewFormat('grid')}
          style={{cursor: 'pointer', color: '#fff'}}
        >
          <img
            src={viewFormat === 'grid' ? CategorisedSelected : Categorised}
            height='20px'
            style={{marginRight: '15px'}}
            alt='Grid view'
          />
          <Label style={{color: '#fff'}}>Grid</Label>
        </Flex>
        <Flex
          ml={[1, 1, 3]}
          mt={[2, 2, 0]}
          alignItems='center'
          onClick={() => updateViewFormat('list')}
          style={{cursor: 'pointer'}}
        >
          <img
            src={viewFormat === 'list' ? ListSelected : List}
            height='20px'
            style={{marginRight: '15px'}}
            alt='List view'
          />
          <Label style={{color: '#fff'}}>List</Label>
        </Flex>
      </Flex>
      <Flex
        alignItems={['flex-start', 'flex-start', 'center']}
        mr={['0px', '0px', '50px']}
        width={[1 / 3]}
        flexDirection={['column', 'column', 'row']}
      >
        <HeadingNew size='h3' palette='white' as='h3' style={{width: '65px'}}>
          Topic:
        </HeadingNew>
        <Box width='130px' ml={[1, 1, 3]} mt={[2, 2, 0]}>
          <SelectNew
            label='Subject'
            options={Subjects}
            placeholder='Any'
            name='theme'
            defaultValue={SelectedSubject}
            onChange={(name, value) => updateSelectedSubject(value)}
            narrow
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default LibraryFilter
