import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Redirect} from 'react-router-dom'
import {Box, Flex, Image} from 'rebass'
import {signIn, resetError} from '../../actions/forms/signIn'
import {newEmailValidator, passwordValidator} from '../../utils/validators'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Input from '../../components/FormElements/Input'
import Button from '../../components/FormElements/Button'
import Text from '../../components/FormElements/Text'
import Container from '../../components/FormElements/Container'
import {LinkRouter} from '../../components/FormElements/Link'
import loginIllustration from '../../images/illustration/knowledge.png'
import GoogleLogin from '../GoogleLogin'

const Header = styled.div`
  margin-bottom: 0px;
`

const SignIn = ({token, signIn, error, match}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [formData, setFormData] = useState({email: '', password: ''})
  const [formError, setFormError] = useState({})

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const handleFiedlOnBlur = (validation) => {
    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleSignInSubmit = async (e) => {
    e.preventDefault()
    const emailError = newEmailValidator(formData)
    const passwordError = passwordValidator(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, emailError, passwordError)
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady && signIn(formData.email, formData.password, null)
  }

  if (token)
    return (
      <Redirect to={match.params.redirect ? `/${match.params.redirect}` : '/'} />
    )

  return (
    <Container minHeight horizontalAlign='center' background='#e8eef4'>
      <Flex
        width={1}
        flexDirection={['column', 'row']}
        alignItems='center'
        style={{maxWidth: '900px'}}
      >
        <Flex
          flexDirection='column'
          width={[1, 1 / 2]}
          justifyContent='center'
          displayFlex='flex'
          as='div'
          px={[0, 4]}
        >
          <Box pl={2} pt={2} pb={[0, 2]}>
            <Header as='header'>
              <HeadingNew size='h2' palette='medium' as='h2'>
                Sign in to your account
              </HeadingNew>
            </Header>
          </Box>
          {error && error.form && (
            <Box p={2}>
              <Text errorForm>{error.form}</Text>
            </Box>
          )}
          <Box p={2}>
            <Input
              label='Email *'
              onChange={(e) => handleInputChange(e)}
              value={formData.email}
              inputProps={{
                type: 'email',
                id: 'email',
                autoComplete: 'false',
                onBlur: () => handleFiedlOnBlur(newEmailValidator),
              }}
              error={formError && formError.email}
            />
          </Box>
          <Box p={2}>
            <Input
              label='Password *'
              onChange={(e) => handleInputChange(e)}
              value={formData.password}
              inputProps={{
                type: 'password',
                id: 'password',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(passwordValidator),
              }}
              error={formError && formError.password}
            />
          </Box>
          <Flex width={1} flexDirection={['column', 'row']} mb='15px'>
            <Box px={2} py={[1, 2]} width={[1, 1 / 2, 1 / 2]}>
              <Button
                palette='primary'
                fullWidth
                onClick={(e) => handleSignInSubmit(e)}
              >
                Sign In
              </Button>
            </Box>
            <Box px={2} py={[1, 2]} width={[1, 1 / 2, 1 / 2]}>
              <GoogleLogin signIn={signIn} />
            </Box>
          </Flex>
          <Flex px={2} pb={3} justifyContent='space-between'>
            <LinkRouter to='/forgottenPassword'>Forgotten your password?</LinkRouter>
            <LinkRouter to='/signup'>Create an account</LinkRouter>
          </Flex>
        </Flex>
        <Flex
          width={[2 / 3, 1 / 2]}
          justifyContent='center'
          mt={['40px', '0px']}
          ml={['0px', '25px']}
        >
          <Image src={loginIllustration} width='100%' height='100%' />
        </Flex>
      </Flex>
    </Container>
  )
}

SignIn.propTypes = {
  error: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  signIn: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {token} = state.user
  const {error} = state.forms.signIn
  return {error, token}
}

export default connect(mapStateToProps, {signIn, resetError})(SignIn)

// if (home)
//   return (
//     <Flex
//       flexDirection='column'
//       width={1}
//       justifyContent='center'
//       displayFlex='flex'
//       as='div'
//       style={{border: '1px solid lightGrey', borderRadius: '5px', maxWidth: '450px'}}
//       px={3}
//     >
//       <Box pl={2} pt={2} pb={[0, 2]}>
//         <HeadingNew size='h3' palette='medium' as='h3'>
//           Sign in to your account
//         </HeadingNew>
//       </Box>
//       <form onSubmit={(e) => handleSignInSubmit(e)}>
//         {error && error.form && (
//           <Box p={2}>
//             <Text errorForm>{error.form}</Text>
//           </Box>
//         )}
//         <Box px={2}>
//           <Input
//             label='Email *'
//             onChange={(e) => handleInputChange(e)}
//             value={formData.email}
//             inputProps={{
//               type: 'email',
//               id: 'email',
//               autoComplete: 'false',
//               onBlur: () => handleFiedlOnBlur(newEmailValidator),
//             }}
//             error={formError && formError.email}
//           />
//         </Box>
//         <Box p={2}>
//           <Input
//             label='Password *'
//             onChange={(e) => handleInputChange(e)}
//             value={formData.password}
//             inputProps={{
//               type: 'password',
//               id: 'password',
//               autoComplete: 'new-password',
//               onBlur: () => handleFiedlOnBlur(passwordValidator),
//             }}
//             error={formError && formError.password}
//           />
//         </Box>
//         <Box p={2} width={1}>
//           <Button palette='primary' fullWidth>
//             Sign In
//           </Button>
//         </Box>
//         <Flex px={2} pb={3} justifyContent='space-between'>
//           <LinkRouter to='/forgottenPassword'>Forgotten your password?</LinkRouter>
//           <LinkRouter to='/signup'>Create an account</LinkRouter>
//         </Flex>
//       </form>
//     </Flex>
//   )
