import keyMirror from '../../../utils/keyMirror'

export default keyMirror(
  {
    RESET_ERROR: null,
    SET_ERROR: null,
    TOGGLE_FORM_STATUS: null,
    ADD_PREFERENCE: null,
    UPDATE_FEED_PREFERENCE: null,
  },
  'FORM_PREFERENCES'
)
