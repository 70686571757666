import axios from 'axios'
import config from '../../../utils/configuration'
import resetPasswordActions from './actionTypes'

const {target} = config

export const reset = () => {
  return {
    type: resetPasswordActions.RESET,
  }
}

export const resetError = () => {
  return {
    type: resetPasswordActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: resetPasswordActions.SET_ERROR,
    error,
  }
}

export const toggleIsPasswordReset = (processed) => {
  return {
    type: resetPasswordActions.TOGGLE_FORM_STATUS,
  }
}

export const resetPassword = (token, password) => {
  return async (dispatch) => {
    dispatch(resetError())
    return axios
      .post(`${target}/resetPassword`, {token, password})
      .then((response) => {
        dispatch(toggleIsPasswordReset())
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            dispatch(setError({form: 'This reset link has expired'}))
          } else if (error.response.status === 404) {
            dispatch(
              setError({
                form: 'There is no account associated with this email address',
              })
            )
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
