import React from 'react'
import styled, {keyframes} from 'styled-components'
import image from '../../../images/persist-progress-large-grey@2x.png'

const Container = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

`

const Rotate = styled.div`
  display: absolute;
  animation: ${rotate} 750ms linear infinite;
  width: 36px;
  height: 36px;
`

const Loader = () => {
  return (
    <Container>
      <Rotate>
        <img src={image} width='36px' height='36px' alt='loading' />
      </Rotate>
    </Container>
  )
}

export default Loader
