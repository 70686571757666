import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`

const StyledSelect = styled.select`
  height: 32px;
  appearance: none;
  -webkit-appearance: none;
  border: ${(props) => (props.error ? '1px solid #D0021B' : 'solid 1px #DADEE6')};
  border-radius: 4px;
  color: #282828;
  font-family: OpenSans, sans-serif;
  cursor: pointer;
  display: block;
  width: 100%;
  margin: 0; /* Safari */
  outline: 0;
  // box-shadow: 0 0 3px rgba(0,0,0,.12);
  // removes top shadow on iOS
  background-clip: padding-box;

  &:not(:focus):invalid {
    color: #282828;
  }

  background-clip: padding-box;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTQgOSI+ICAgIDxwYXRoIGZpbGw9IiM2NTcwODEiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTcgNS41ODZMMS43MDcuMjkzQTEgMSAwIDEgMCAuMjkzIDEuNzA3TDcgOC40MTRsNi43MDctNi43MDdBMSAxIDAgMSAwIDEyLjI5My4yOTNMNyA1LjU4NnoiLz48L3N2Zz4=);
  background-size: 11px 9px;
  background-position: right 6px bottom 8px;
  background-repeat: no-repeat;
  background-color: white;

  /* padding: 20px 20px 6px 8px; */
  padding: 6px 6px 2px 6px;

  // MOZ SPECIFIC: remove extra padding
  -moz-padding-start: 4px;

  @media screen and (min-width: 640px) {
    padding: 6px 6px 2px 6px;
    // MOZ SPECIFIC: remove extra padding
    -moz-padding-start: 5px;
  }

  &--error {
    border-color: red;
  }

  &:focus {
    & ~ .error {
      display: none;
    }
  }

  & ~ .error {
    display: block;
  }

  // Used in pagination (particular case)
  /* &--noLabel {
    height: 32px;
    padding: 0px 8px 0px 8px;
  } */

  :-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`
const Error = styled.div`
  font-size: 11px;
  color: #d0021b;
  position: absolute;
  right: 23px;
  bottom: 4px;
`

export default class SelectNew extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlurEvent: PropTypes.func,
    error: PropTypes.string,
    styleOptions: PropTypes.object,
    narrow: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.defaultValue || '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleChange(e) {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.props.onChange(this.props.name, this.state.value)
      }
    )
  }

  handleBlur(e) {
    if (this.props.onBlurEvent) {
      this.props.onBlurEvent()
    }
  }

  render() {
    const {options, error} = this.props

    return (
      <Wrapper>
        <StyledSelect
          required
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.defaultValue || ''}
          error={error}
        >
          <option value=''>{this.props.placeholder}</option>
          {options.map((option) => {
            return (
              <option
                key={option.value || option.name}
                value={option.value || option.name}
              >
                {option.label || option.name}
              </option>
            )
          })}
        </StyledSelect>
        {error && <Error>{error}</Error>}
      </Wrapper>
    )
  }
}
