import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Box, Image, Flex} from 'rebass'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Text from '../components/FormElements/Text'
import Button from '../components/FormElements/Button'
import Icons from '../images/icons/icons'

const HowToUse = ({intro, close, loggedIn}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sections = [
    {
      title: 'Access insights',
      icon: Icons.SearchOrange,
      text: 'Browse, search, save and comment on posts',
      linkTarget: '/',
    },
    {
      title: 'Set-up alerts',
      icon: Icons.alert,
      text: 'Create topic-specific email alerts',
      linkTarget: '/myPreferences',
    },
    {
      title: 'Book summaries',
      icon: Icons.LibraryOrange,
      text: 'Read or contribute to the Sivv library',
      linkTarget: '/myLibrary',
    },
    {
      title: 'Write something',
      icon: Icons.compose,
      text: 'Share great ideas with the Sivv community',
      linkTarget: '/submit',
    },
    // {
    //   title: 'Update details',
    //   icon: Icons.userDetails,
    //   text: 'Update your profile and account details',
    //   linkTarget: '/myDetails',
    // },
    // {
    //   title: 'Find People',
    //   icon: Icons.network,
    //   text: 'Connect with Sivv users and invite others to join',
    //   linkTarget: '/myNetwork',
    // },
    {
      title: 'Save articles',
      icon: Icons.fileOrange,
      text: 'Access any article that you save or write',
      linkTarget: '/myArticles',
    },
    {
      title: 'Manage subscription',
      icon: Icons.SubscriptionOrange,
      text: 'Upgrade and manage your subscription',
      linkTarget: '/mySubscription',
    },
  ]

  return (
    <Container
      background='#d4e0e5'
      style={{maxHeight: intro ? '90vh' : null, maxWidth: intro ? '100%' : null}}
      noPadding={loggedIn}
    >
      <Box>
        <HeadingNew size='h1' palette='medium' as='h2'>
          {intro ? 'Welcome to Sivv' : 'How to use Sivv'}
        </HeadingNew>
      </Box>
      <Box p={2} mb='10px'>
        <HeadingNew size='h3' palette='medium' as='h3'>
          {intro
            ? 'Thanks for signing-up! To help you get the most out of Sivv, see below for a quick overview of the key features. '
            : 'The below panel provides an overview of key Sivv features. '}
        </HeadingNew>
      </Box>
      <Flex flexWrap='wrap' justifyContent={'space-around'} mb={3}>
        {sections.map((s) => (
          <Flex
            flexWrap='wrap'
            width={[1, 4.5 / 10]}
            mb={['5px', '25px']}
            p={3}
            pl={['10%', '5%', '10%']}
          >
            <Flex width={1} alignItems='center' justifyContent='flex-start' mb={1}>
              <Image src={s.icon} width='25px' mr='15px' />
              <HeadingNew size='h2' palette='medium' as='h3'>
                {s.title}
              </HeadingNew>
            </Flex>
            <Text palette='medium'>
              {s.text} {}
              <Link style={{color: '#de7033'}} to={s.linkTarget}>
               here
              </Link>
              .
            </Text>
          </Flex>
        ))}
      </Flex>
      <HeadingNew size='h3' palette='medium' as='h3' centerText>
        For any questions, comments, issues or requests, please feel free to contact
        us at
        <a
          href='mailto:info@sivv.io'
          target='null'
          style={{color: '#de7033', cursor: 'pointer', textDecoration: 'none'}}
        >
          {} info@sivv.io
        </a>
        .
      </HeadingNew>
      {intro && (
        <Flex width={1} mt={3} justifyContent='center'>
          <Box width={[1, 1 / 2]}>
            <Button palette='primary' onClick={() => close()} fullWidth>
              Got it!
            </Button>
          </Box>
        </Flex>
      )}
    </Container>
  )
}

export default HowToUse
