import axios from 'axios'
import config from '../../utils/configuration'
import store from '../../store/configureStore'
import {user} from '../actionTypes'

const {target} = config

const {
  RESET_ERRORS,
  SET_ERRORS,
  STORE_USER,
  RESET_USER,
  SET_FORM_STATUS,
  RESET_FORM_STATUS,
  STORE_SEARCHES,
  SET_SAVED_SEARCH_ERROR,
  GET_CONTACTS_START,
  GET_CONTACTS_ERROR,
  UPDATE_USER_INFO,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  CONFIRM_GUIDE_VIEW,
} = user

axios.interceptors.request.use(function (config) {
  const {token} = store.getState().user
  config.headers.Authorization = token
  return config
})
//
// axios.interceptors.response.use(
//   response => response,
//   error => {
//     const { status } = error.response;
//     if (status === 401) {
//       store.dispatch(logout())
//     }
//   }
// )

export const resetErrors = () => {
  return {
    type: RESET_ERRORS,
  }
}

const setErrors = (errors) => {
  return {
    type: SET_ERRORS,
    errors,
  }
}

export const storeUser = (user) => {
  return {
    type: STORE_USER,
    user,
  }
}

export const storeSearches = (searches) => {
  return {
    type: STORE_SEARCHES,
    searches,
  }
}

export const setSavedSearchError = (error) => {
  return {
    type: SET_SAVED_SEARCH_ERROR,
    error,
  }
}

export const logout = () => {
  return {
    type: RESET_USER,
  }
}

export const confirmGuideView = () => ({type: CONFIRM_GUIDE_VIEW})

const setFormStatus = (formStatus = true) => {
  return {
    type: SET_FORM_STATUS,
    formStatus,
  }
}

export const resetFormStatus = () => {
  return {
    type: RESET_FORM_STATUS,
  }
}

export const getContactsStart = (label) => {
  return {
    type: GET_CONTACTS_START,
    label,
  }
}

export const getContactsError = (label, error) => {
  return {
    type: GET_CONTACTS_ERROR,
    label,
  }
}

export const updateUserInfo = (label, results) => {
  return {
    type: UPDATE_USER_INFO,
    label,
    results,
  }
}

// ***************************** Create Account

export const createAccount = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors())
      const response = await axios.post(`${target}/createNewAccount`, userData)
      if (response.status === 200) dispatch(setFormStatus())
    } catch (e) {
      console.log('Create Account Error', e)
      if (e.response && e.response.status === 401) {
        dispatch(setErrors({form: 'This email is already registered'}))
      } else {
        dispatch(
          setErrors({
            form:
              'The server encountered an issue - please email us at info@sivv.io.',
          })
        )
      }
    }
  }
}

// ***************************** Verify Email

export const verifyEmail = (code) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors())
      const response = await axios.post(`${target}/verifyEmail`, {code})
      if (response.status === 200)
        dispatch(storeUser({...response.data.user, token: response.data.token}))
    } catch (e) {
      console.log('Verify Email Error', e)
      dispatch(setErrors({form: 'Server unavailable'}))
    }
  }
}

// ***************************** New Email Verification

export const sendNewEmailVerification = (verificationCode) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors())
      axios.post(`${target}/sendNewEmailVerification`, {
        verificationCode,
      })
      // const r = response.status
      // if (r === 200) dispatch(storeUser({ ...response.data.user, token: response.data.token }));
      // if (r === 404 || r=== 500) dispatch(setErrors({ form: 'Server encountered an issue' }));
    } catch (e) {
      console.log('New Email Verification Error', e)
      dispatch(setErrors({form: 'Server unavailable'}))
    }
  }
}

// ***************************** Update Contacts

export const updateContacts = (userID, targetID) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${target}/updateContacts`, {
        userID,
        targetID,
      })
      if (response.status === 200)
        dispatch(updateUserInfo('following', response.data))
    } catch (e) {
      console.log('Update Contacts Error', e)
      dispatch(setErrors({form: 'Server unavailable'}))
    }
  }
}

// ***************************** Get Contacts

export const getContacts = (userID, label, query) => {
  return async (dispatch) => {
    try {
      dispatch(getContactsStart(label))
      const response = await axios.post(`${target}/getContacts`, {
        userID,
        label,
        query,
      })
      if (response.status === 200) dispatch(updateUserInfo(label, response.data))
    } catch (e) {
      console.log('Get Contacts Error', e)
    }
  }
}

// ***************************** Get User Articles

export const getUserArticles = (userID, label) => {
  return async (dispatch) => {
    try {
      dispatch(getContactsStart(label))
      const response = await axios.post(`${target}/getUserArticles`, {userID, label})
      if (response.status === 200) dispatch(updateUserInfo(label, response.data))
    } catch (e) {
      console.log('Get User Articles Error', e)
      dispatch(getContactsError(label, e))
    }
  }
}

// ***************************** Register Interest

export const registerInterest = (email, category, alertCategories) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${target}/registerInterest`, {
        email,
        category,
        alertCategories,
      })
      if (response && response.status === 200) dispatch(setFormStatus())
    } catch (e) {
      if (e.response && e.response.status === 401) {
        dispatch(
          setErrors({
            form:
              'There is already an account associated with that email address - log-in to manage your preferences',
          })
        )
      } else {
        dispatch(
          setErrors({
            form: 'The server is currently unavailable - please try again later or email us at info@sivv.io.',
          })
        )
      }
    }
  }
}

// ***************************** Email unsubscribe

export const unsubscribeSuccess = () => {
  return {
    type: UNSUBSCRIBE_SUCCESS,
  }
}

export const unsubscribeError = () => {
  return {
    type: UNSUBSCRIBE_ERROR,
  }
}

export const emailUnsubscribe = (id, email, type) => {
  return async (dispatch) => {
    try {
      const unsubscribe = await axios({
        method: 'get',
        url: `${target}/unsubscribe/${id}/${email}/${type}`,
      })
      if (unsubscribe.status === 200) dispatch(unsubscribeSuccess())
    } catch (error) {
      dispatch(unsubscribeError(error))
    }
  }
}
