import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Box, Flex} from 'rebass'
import Modal from '../../components/FormElements/Modal'
import Button from '../../components/FormElements/Button'
import Text from '../../components/FormElements/Text'
import Loader from '../../components/FormElements/Loader'
import Tab from '../../components/FormElements/Tab'
import Icons from '../../images/icons/icons'
import {setSavedSearchError, getUserArticles} from '../../actions/user'
import {
  articleEngagement,
  deleteArticle,
  editArticle,
} from '../../actions/articleActions'
import HeadingNew from '../../components/FormElements/HeadingNew'
import AccountArticle from '../../components/AccountArticle/AccountArticle'
import Container from '../../components/FormElements/Container'

const MySavedArticles = ({
  user,
  setSavedSearchError,
  articleEngagement,
  deleteArticle,
  editArticle,
  getUserArticles,
  history,
  draft,
}) => {
  const {myArticles, savedArticles, drafts} = user

  const [selectedArticle, selectArticle] = useState(null)
  const [tab, setTab] = useState('myArticles')
  const [edit, setEdit] = useState(null)
  useEffect(() => {
    getUserArticles(user._id, 'myArticles')
  }, [getUserArticles, user._id])

  const handleClick = (tab) => {
    if (tab === 'myArticles') getUserArticles(user._id, 'myArticles')
    if (tab === 'drafts') getUserArticles(user._id, 'drafts')
    if (tab === 'savedArticles') getUserArticles(user._id, 'savedArticles')
    setTab(tab)
  }

  const deleteThisArticle = (articleID) => {
    tab === 'savedArticles'
      ? articleEngagement(articleID, user._id, 'save')
      : selectArticle(articleID)
  }

  const confirmDeletion = () => {
    deleteArticle(tab, selectedArticle, user._id)
    selectArticle(null)
  }

  const editThisArticle = async (articleID, userID) => {
    const edit = await editArticle(articleID, user._id)
    setEdit(articleID)
  }

  const viewArticle = (id, title) => {
    const FormattedTitle = title
      .replace(/ /g, '-')
      .replace(/\//g, '')
      .replace(/%/g, '-per-cent-')
    history.push(`/article/${id}/${FormattedTitle}`)
  }

  const hide = () => {
    selectArticle(null)
  }

  if (draft._id === edit) history.push('/editArticle')

  const noArticles = (
    <HeadingNew size='h3' palette='medium' as='p'>
      None to display
    </HeadingNew>
  )

  const errorMessage = (
    <Flex mb={3} justifyContent='center'>
      <HeadingNew size='h2' palette='darkColor' as='h2'>
        Not available
      </HeadingNew>
    </Flex>
  )

  return (
    <Container background='#d4e0e5' minHeight align='flex-start'>
      <Box width={1} mb={3}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          My Articles
        </HeadingNew>
      </Box>
      <Flex mb={3}>
        <Tab
          onClick={() => handleClick('myArticles')}
          active={tab === 'myArticles'}
          image={Icons.published}
          activeImage={Icons.PublishedWhite}
          title='Published'
        />
        <Tab
          onClick={() => handleClick('drafts')}
          active={tab === 'drafts'}
          image={Icons.draft}
          activeImage={Icons.DraftWhite}
          title='Drafts'
        />
        <Tab
          onClick={() => handleClick('savedArticles')}
          active={tab === 'savedArticles'}
          image={Icons.save}
          activeImage={Icons.SaveWhite}
          title='Saved'
          end
        />
      </Flex>
      <Flex
        flexWrap='wrap'
        flexDirection='row'
        alignItems={['normal', 'flex-end']}
        mx={-2}
      >
        {selectedArticle && (
          <Modal closeModal={hide}>
            <Flex width={1} flexDirection='column' p='0px 15px 15px 15px'>
              <HeadingNew size='h2' palette='darkColor' as='h2' spacing='0 0 8px'>
                Confirm Deletion
              </HeadingNew>
              <Text as='p' palette='medium'>
                Are you sure that you want to delete this article?
              </Text>
              <Flex
                flexWrap='wrap'
                flexDirection='row'
                alignItems={['normal', 'flex-end']}
                mx={-2}
              >
                <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
                  <Button
                    onClick={() => confirmDeletion()}
                    palette='primary'
                    fullWidth
                  >
                    Delete
                  </Button>
                </Box>
                <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
                  <Button onClick={hide} palette='secondary' fullWidth>
                    Cancel
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Modal>
        )}
      </Flex>
      {tab === 'myArticles' && myArticles.error && errorMessage}
      {tab === 'myArticles' && myArticles.isLoading && <Loader />}
      {tab === 'myArticles' && myArticles.results.length === 0 && noArticles}
      {tab === 'myArticles' && myArticles.results.length > 0 && (
        <Flex width={1} flexWrap='wrap' justifyContent='space-around'>
          {myArticles.results.map((a, idx) => {
            return (
              <AccountArticle
                data={a}
                del={deleteThisArticle}
                edit={editThisArticle}
                viewArticle={viewArticle}
                key={idx}
              />
            )
          })}
        </Flex>
      )}
      {tab === 'drafts' && drafts.error && errorMessage}
      {tab === 'drafts' && drafts.isLoading && <Loader />}
      {tab === 'drafts' && drafts.results.length === 0 && noArticles}
      {tab === 'drafts' && drafts.results.length > 0 && (
        <Flex width={1} flexWrap='wrap' justifyContent='space-around'>
          {drafts.results.map((a, idx) => {
            return (
              <AccountArticle
                data={a}
                del={deleteThisArticle}
                edit={editThisArticle}
                viewArticle={viewArticle}
                key={idx}
                draft
              />
            )
          })}
        </Flex>
      )}
      {tab === 'savedArticles' && savedArticles.error && errorMessage}
      {tab === 'savedArticles' && savedArticles.isLoading && <Loader />}
      {tab === 'savedArticles' && savedArticles.results.length === 0 && noArticles}
      {tab === 'savedArticles' && savedArticles.results.length > 0 && (
        <Flex width={1} flexWrap='wrap' justifyContent='space-around'>
          {savedArticles.results.map((a, idx) => {
            return (
              <AccountArticle
                data={a}
                del={deleteThisArticle}
                edit={editThisArticle}
                savedArticles
                viewArticle={viewArticle}
                key={idx}
              />
            )
          })}
        </Flex>
      )}
    </Container>
  )
}

MySavedArticles.propTypes = {
  user: PropTypes.object,
  articleEngagement: PropTypes.func.isRequired,
  editArticle: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  setSavedSearchError: PropTypes.func.isRequired,
  getUserArticles: PropTypes.func.isRequired,
  history: PropTypes.object,
  draft: PropTypes.object,
}

const mapStateToProps = (state) => {
  const {user} = state
  return {
    user,
    draft: state.article.draft,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    setSavedSearchError,
    articleEngagement,
    deleteArticle,
    editArticle,
    getUserArticles,
  })(MySavedArticles)
)
