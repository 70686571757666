import React from 'react'
import {Link} from 'react-router-dom'
import {Flex, Box, Image} from 'rebass'
import EmailCapture from '../containers/EmailCapture'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Icons from '../images/icons/icons'
import Publications from './Publications2.jpg'

const Links = ({home}) => {
  const Options = [
    {
      title: 'Knowledge Feed',
      link: './feed',
    },
    {
      title: 'Book Summaries',
      link: './library',
    },
    {
      title: 'Sivv Guides',
      link: './guides',
    },
  ]

  const Books = [
    {
      title: 'Innovators Dilemma',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592847408917',
    },
    {
      title: 'Black Swan',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592847920511',
    },
    {
      title: 'Drive',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592038006966',
    },
    {
      title: 'Deep Work',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592847860864',
    },
    {
      title: 'Hooked',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592846996189',
    },
  ]

  const GuidesArray = [
    {
      text: 'Being Productive',
      image: 'ProductivityGuide',
      link: './guides/Being-Productive',
    },
    // {
    //   text: 'Managing Habits',
    //   image: 'HabitsGuide',
    //   link: './guides/Managing-Habits',
    // },
    {
      text: 'Effective Communication',
      image: 'CommunicationGuide',
      link: './guides/Effective-Communication',
    },
    {
      text: 'Career Development',
      image: 'CareerGuide',
      link: './guides/Career-Development',
    },
  ]

  return (
    <Container
      home={home}
      background='#e8eef4'
      style={{alignItems: 'center'}}
      horizontalAlign='center'
    >
      <Flex mt='10px'>
        <HeadingNew
          size='h0'
          palette='medium'
          as='h1'
          style={{lineHeight: '1.2', marginBottom: '15px'}}
          justified
        >
          Get smarter, faster
        </HeadingNew>
      </Flex>
      {Options.map((o) => (
        <>
          <Link
            to={o.link}
            style={{textDecoration: 'none', width: '100%', maxWidth: '550px'}}
            key={o.title}
          >
            <Flex width={1} py='15px' px='10px' alignItems='center'>
              {o.title === 'Knowledge Feed' && (
                <Image
                  src={Publications}
                  width='200px'
                  height='100%'
                  ml={0}
                  style={{
                    float: 'left',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    maxWidth: '200px',
                    minWidth: '200px',
                  }}
                />
              )}

              {o.title === 'Book Summaries' && (
                <Flex
                  width='200px'
                  style={{maxWidth: '200px', minWidth: '200px'}}
                  justifyContent={['space-between', 'space-between']}
                >
                  {Books.map((b) => (
                    <Box width={1 / 6} key={b.title}>
                      <img
                        src={b.image}
                        width='100%'
                        style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
                        alt={b.title}
                      />
                    </Box>
                  ))}
                </Flex>
              )}

              {o.title === 'Sivv Guides' && (
                <Flex
                  width='200x'
                  style={{maxWidth: '200px', minWidth: '200px'}}
                  justifyContent={['space-between', 'space-between']}
                >
                  {GuidesArray.map((b) => (
                    <Box width={1 / 4} key={b.title}>
                      <img
                        src={Icons[b.image]}
                        width='100%'
                        style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
                        alt={b.title}
                      />
                    </Box>
                  ))}
                </Flex>
              )}

              <HeadingNew
                size='h2'
                palette='medium'
                as='h2'
                style={{lineHeight: '1.1', marginLeft: '10px'}}
                justified
              >
                {o.title}
              </HeadingNew>
            </Flex>
          </Link>
          <div
            style={{
              borderTop: '1px solid #fff',
              width: '75%',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          />
        </>
      ))}

      <Flex
        width={1}
        py='15px'
        px='10px'
        style={{maxWidth: '550px'}}
        alignItems='center'
        flexDirection='column'
      >
        <HeadingNew size='h3' palette='medium' as='h3' justified>
          Sign-up to our free digest email to receive a selection of actionable
          insights each month:
        </HeadingNew>
        <EmailCapture category='Instagram' />
      </Flex>
    </Container>
  )
}

export default Links

// <Flex
//   width={1}
//   flexWrap='wrap'
//   justifyContent='space-between'
//   style={{maxWidth: '550px'}}
// >
//   {GuidesArray.map((g) => (
//     <Link
//       to={g.link}
//       style={{
//         textDecoration: 'none',
//         width: '48%',
//         border: '2px solid #33b8ce',
//         borderRadius: '5px',
//         marginTop: '25px',
//       }}
//       key={g.link}
//     >
//       <Flex
//         width={4.7 / 10}
//         p='15px'
//         justifyContent='center'
//         alignItems='center'
//         key={g}
//       >
//         <Image
//           width='40px'
//           effect='blur'
//           src={Icons[g.icon]}
//           style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
//         />
//         <Box width={1 / 2} ml='5px'>
//           <HeadingNew
//             size='h2'
//             palette='medium'
//             as='h2'
//             style={{lineHeight: '1.2', marginLeft: '7px'}}
//             justified
//           >
//             {g.text}
//           </HeadingNew>
//         </Box>
//       </Flex>
//     </Link>
//   ))}
// </Flex>
