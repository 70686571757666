import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {CardElement} from '@stripe/react-stripe-js'
import {Box, Flex} from 'rebass'
import Button from '../FormElements/Button'
import Checkbox from '../FormElements/Checkbox'
import Input from '../FormElements/Input'
// import Select from '../FormElements/Select'
import HeadingNew from '../FormElements/HeadingNew'
import Loader from '../FormElements/Loader'
import Radio from '../FormElements/Radio'
// import Countries from './countryList'
import StripeLogo from '../../images/stripe_logo.png'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const PaymentForm = ({
  paymentDetails,
  updatePaymentDetails,
  formError,
  handleSubmit,
  paymentStatus,
  close,
  errorMessage,
  paymentMessage,
  retry,
  product,
  loggedIn,
}) => {

  const stripeChange = (event) => {
    console.log(event);
    if (event.complete) {
      updatePaymentDetails({...paymentDetails, cardDetails: true})
    }
  }

  return (
    <Flex flexDirection='column' px={3} pb={3} width={1} alignItems='center'>
      <Box width={1} mb={3}>
        <HeadingNew size='h3' palette='medium' as='h3'>
          <b>{product === 'expert' && 'Buy expert subscription'}
          {['premium', 'monthly'].includes(product) && 'Buy premium subscription'}
          {!['expert', 'premium', 'monthly'].includes(product) &&
            `Buy the Sivv Guide to ${
              paymentDetails.guide === 'all'
                ? 'Being Productive, Managing Habits, Career Development & Effective Communication'
                : paymentDetails.guide
            }`}</b>
        </HeadingNew>
      </Box>

      {paymentStatus === 'error' && (
        <Flex flexDirection='column'>
          <Box my={1}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              Unfortunately we couldn't process your payment.
            </HeadingNew>
          </Box>
          <Box my={1}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              {errorMessage}
            </HeadingNew>
          </Box>
          <Box mt={3}>
            <Button onClick={() => retry()}>Try another method</Button>
          </Box>
        </Flex>
      )}

      {paymentStatus === 'success' && (
        <Flex flexDirection='column'>
          <Box my={1}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              {['expert', 'premium', 'monthly'].includes(product)
                ? 'Subscription confirmed!'
                : 'Payment confirmed!'}
            </HeadingNew>
          </Box>
          <Box my={1}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              {!['expert', 'premium', 'monthly'].includes(product)
                ? `Your ${
                    paymentDetails.guide === 'all' ? 'guides' : 'guide'
                  } will be sent to ${paymentDetails.email} shortly.`
                : loggedIn
                ? `Thank you for upgrading. You now have access to ${
                    ['premium', 'monthly'].includes(product)
                      ? 'the full Sivv archive and premium posts.'
                      : 'the full Sivv archive, premium posts and guides.'
                  }`
                : 'Thank you for joining Sivv. Please check your emails to verify your account.'}
            </HeadingNew>
          </Box>
          <Box my={1}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              For any questions please contact us at info@sivv.io.
            </HeadingNew>
          </Box>
          <Box my={1}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              Happy reading!
            </HeadingNew>
          </Box>
          <Box my={3}>
            <Button onClick={() => close(null)}>Close</Button>
          </Box>
        </Flex>
      )}

      {paymentStatus !== 'success' && paymentStatus !== 'error' && (
        <Flex flexDirection='column' width={1} style={{maxWidth: '350px'}}>
          {!['expert', 'premium', 'monthly'].includes(product) && (
            <Box my={2}>
              <Input
                label='Email'
                onChange={(e) =>
                  updatePaymentDetails({...paymentDetails, email: e.target.value})
                }
                value={paymentDetails.email}
                inputProps={{
                  type: 'email',
                  id: 'email',
                }}
                error={formError.email}
              />
            </Box>
          )}
          {['premium', 'monthly'].includes(product) && (
            <Flex
              my={2}
              px='11px'
              py='2px'
              style={{
                border: '1px solid #DADEE6',
                borderRadius: '4px',
                height: '48px',
                backgroundColor: '#fff'
              }}
              flexWrap='wrap'
            >
              <Box width={1} >Subscription option</Box>
              <Box width={1 / 2}>
                <Radio
                  label='Monthly ($5)'
                  name='Monthly'
                  inputProps={{
                    checked: paymentDetails.subscriptionType === 'monthly',
                    onChange: () =>
                      updatePaymentDetails({
                        ...paymentDetails,
                        subscriptionType: 'monthly',
                      }),
                  }}
                />
              </Box>
              <Box width={1 / 2}>
                <Radio
                  label='Annual ($50)'
                  name='Annual'
                  inputProps={{
                    checked: paymentDetails.subscriptionType === 'premium',
                    onChange: () =>
                      updatePaymentDetails({
                        ...paymentDetails,
                        subscriptionType: 'premium',
                      }),
                  }}
                />
              </Box>
            </Flex>
          )}
          {!loggedIn && ['expert', 'premium', 'monthly'].includes(product) && (
            <Flex flexWrap='wrap'>
              <Box width={1 / 2} my={2} pr={2}>
                <Input
                  label='First Name'
                  onChange={(e) =>
                    updatePaymentDetails({
                      ...paymentDetails,
                      firstName: e.target.value,
                    })
                  }
                  value={paymentDetails.firstName}
                  inputProps={{
                    type: 'firstName',
                    id: 'firstName',
                  }}
                  error={formError && formError.firstName}
                />
              </Box>

              <Box width={1 / 2} my={2}>
                <Input
                  label='Last Name'
                  onChange={(e) =>
                    updatePaymentDetails({
                      ...paymentDetails,
                      lastName: e.target.value,
                    })
                  }
                  value={paymentDetails.lastName}
                  inputProps={{
                    type: 'lastName',
                    id: 'lastName',
                  }}
                  error={formError && formError.lastName}
                />
              </Box>

              <Box width={1 / 2} my={2} pr={2}>
                <Input
                  label='Email'
                  onChange={(e) =>
                    updatePaymentDetails({...paymentDetails, email: e.target.value})
                  }
                  value={paymentDetails.email}
                  inputProps={{
                    type: 'email',
                    id: 'email',
                  }}
                  error={formError && formError.email}
                />
              </Box>

              <Box width={1 / 2} my={2}>
                <Input
                  label='Password'
                  onChange={(e) =>
                    updatePaymentDetails({
                      ...paymentDetails,
                      password: e.target.value,
                    })
                  }
                  value={paymentDetails.password}
                  inputProps={{
                    type: 'password',
                    id: 'password',
                  }}
                  error={formError && formError.password}
                />
              </Box>
            </Flex>
          )}
          <Box
            my={2}
            px='11px'
            py='2px'
            style={{
              borderRadius: '4px',
              height: '48px',
              backgroundColor: '#fff',
              border: formError.cardDetails ? '1px solid #D0021B' : '1px solid #DADEE6'
            }}
          >
            Card details
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => stripeChange(e)}
              invalid
            />
          </Box>
          <Box my={2}>
            <Input
              label='Name on card'
              onChange={(e) =>
                updatePaymentDetails({...paymentDetails, name: e.target.value})
              }
              value={paymentDetails.name}
              inputProps={{
                type: 'name',
                id: 'name',
              }}
              error={formError && formError.name}
            />
          </Box>
          {paymentStatus !== 'processing' && (
            <>
              <Box p={2} width={1}>
                <Checkbox
                  label={<Link to='/termsofuse'>Accept terms & conditions</Link>}
                  name='terms'
                  inputProps={{
                    id: 'terms',
                    name: 'terms',
                    onChange: () =>
                      updatePaymentDetails({
                        ...paymentDetails,
                        terms: !paymentDetails.terms,
                      }),
                    checked: paymentDetails.terms,
                  }}
                  fullWidth
                  error={formError && formError.terms}
                />
              </Box>
              {!['expert', 'premium', 'monthly'].includes(product) && !loggedIn && (
                <Box p={2} width={1}>
                  <Checkbox
                    label='Receive updates from Sivv'
                    name='updates'
                    inputProps={{
                      id: 'updates',
                      name: 'updates',
                      onChange: () =>
                        updatePaymentDetails({
                          ...paymentDetails,
                          updates: !paymentDetails.updates,
                        }),
                      checked: paymentDetails.updates,
                    }}
                    fullWidth
                  />
                </Box>
              )}
              <Box my={3} width={1}>
                <Button fullWidth onClick={(e) => handleSubmit(e)}>
                  {paymentMessage}
                </Button>
              </Box>
              <Flex
                my={1}
                width={1}
                alignItems='center'
                style={{fontSize: '10px', color: 'grey'}}
              >
                <img
                  src={StripeLogo}
                  width='15px'
                  alt='Stripe'
                  style={{marginRight: '3px'}}
                />
                Payment powered by{' '}
                <a
                  style={{marginLeft: '3px'}}
                  href='https://stripe.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {' '}
                  Stripe.
                </a>
              </Flex>
              {product === 'Subscription' && (
                <Flex
                  my={1}
                  width={1}
                  alignItems='center'
                  style={{fontSize: '10px', color: 'grey'}}
                >
                  Renews automatically every{' '}
                  {paymentDetails.subscriptionType === 'monthly' ? 'month' : 'year'}.
                  Cancel at any time.
                </Flex>
              )}
            </>
          )}
          {paymentStatus === 'processing' && <Loader />}
        </Flex>
      )}
    </Flex>
  )
}

PaymentForm.propTypes = {
  paymentDetails: PropTypes.object.isRequired,
  updatePaymentDetails: PropTypes.func.isRequired,
  formError: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string,
  close: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  errorMessage: PropTypes.string,
  paymentMessage: PropTypes.string.isRequired,
  retry: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
}

export default PaymentForm
