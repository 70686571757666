const validator = require('email-validator')

export const isEmail = (value) => {
  if (!value) {
    return false
  }
  return validator.validate(value)
}

const isEmpty = (value) => typeof value === 'string' && !value.trim().length

export const isPasswordStrong = (password) => {
  // Password must be at least 8 characters,
  // at least one upper case, one lower case, and one numeric digit.
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  return regex.test(password)
}

const isStringEqual = (valueReference, valueToCompare) =>
  valueToCompare === valueReference

export const newEmailValidator = ({email}) => {
  // Email validation rules
  let emailError = isEmpty(email) ? {email: 'Enter email'} : null

  emailError =
    emailError || (email && !isEmail(email) ? {email: 'Enter a valid email'} : null)

  // To be sure to clean the previous error
  emailError = emailError || {email: null}

  return {...emailError}
}

export const newEmailRepeatValidator = ({email, emailRepeat}) => {
  // Code validation rules
  let emailRepeatError = isEmpty(emailRepeat) ? {emailRepeat: 'Enter email'} : null

  emailRepeatError =
    emailRepeatError ||
    (!!emailRepeat && !isEmail(emailRepeat)
      ? {emailRepeat: 'Enter a valid email'}
      : null)

  emailRepeatError =
    emailRepeatError ||
    (!!emailRepeat && !isStringEqual(email, emailRepeat)
      ? {emailRepeat: 'Emails are not matching'}
      : null)

  // To be sure to clean the previous error
  emailRepeatError = emailRepeatError || {emailRepeat: null}

  return {...emailRepeatError}
}

export const newFirstNameValidator = ({firstName}) => {
  // First name validation rules
  let firstNameError = isEmpty(firstName) ? {firstName: 'Enter first name'} : null

  // To be sure to clean the previous error
  firstNameError = firstNameError || {firstName: null}

  return {...firstNameError}
}

export const lastNameValidator = ({lastName}) => {
  // Last name validation rules
  let lastNameError = isEmpty(lastName) ? {lastName: 'Enter last name'} : null

  // To be sure to clean the previous error
  lastNameError = lastNameError || {lastName: null}

  return {...lastNameError}
}

export const passwordValidator = ({password}) => {
  // Code validation rules
  let passwordError = isEmpty(password) ? {password: 'Enter password'} : null

  // To be sure to clean the previous error
  passwordError = passwordError || {password: null}

  return {...passwordError}
}

export const passwordRepeatValidator = ({password, passwordRepeat}) => {
  // Code validation rules
  let passwordRepeatError = isEmpty(passwordRepeat)
    ? {passwordRepeat: 'Enter password'}
    : null

  passwordRepeatError =
    passwordRepeatError ||
    (!!passwordRepeat && !isStringEqual(password, passwordRepeat)
      ? {passwordRepeat: 'Passwords are not matching'}
      : null)

  // To be sure to clean the previous error
  passwordRepeatError = passwordRepeatError || {passwordRepeat: null}

  return {...passwordRepeatError}
}

export const newPasswordValidator = ({newPassword}) => {
  // Code validation rules
  let newPasswordError = isEmpty(newPassword)
    ? {newPassword: 'Enter password'}
    : null

  // Check only: 1 uppercase / 1 lowercase / 1 digit / minimum 8 characters
  newPasswordError =
    newPasswordError ||
    (!!newPassword && !isPasswordStrong(newPassword)
      ? {newPassword: 'Enter a stronger password'}
      : null)

  // To be sure to clean the previous error
  newPasswordError = newPasswordError || {newPassword: null}

  return {...newPasswordError}
}

export const newPasswordRepeatValidator = ({newPassword, newPasswordRepeat}) => {
  // New password repeat validation rules
  let newPasswordRepeatError = isEmpty(newPasswordRepeat)
    ? {newPasswordRepeat: 'Enter password'}
    : null

  newPasswordRepeatError =
    newPasswordRepeatError ||
    (!!newPasswordRepeat && !isStringEqual(newPassword, newPasswordRepeat)
      ? {newPasswordRepeat: 'Passwords are not matching'}
      : null)

  // To be sure to clean the previous error
  newPasswordRepeatError = newPasswordRepeatError || {newPasswordRepeat: null}

  return {...newPasswordRepeatError}
}
