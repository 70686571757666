import React, {useEffect} from 'react'
import {Flex} from 'rebass'

const Articles = [
  {
    title:
      'Little kids burn so much energy, they’re like a different species, study finds',
    _id: '611d3f7f0ea14263308448b8',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGqwvlaVf9NJSPUr9ANOV32L2NeUHlV5IVay4LF0rhhpCjQEmaostkY9_0TrstEnOXgtA&usqp=CAU',
    sourceURL:
      'https://www.sciencemag.org/news/2021/08/little-kids-burn-so-much-energy-they-re-different-species-study-finds',
  },
  {
    title: 'Drowning Doesn’t Look Like Drowning',
    _id: '6116a2fb0ea14263308448ae',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo: 'https://slate.com/media/sites/slate-com/icon.180x180.png',
    sourceURL:
      'https://slate.com/technology/2013/06/rescuing-drowning-children-how-to-know-when-someone-is-in-trouble-in-the-water.html',
  },
  {
    title: 'What I learnt roasting 200 landing pages in 12 months',
    _id: '61126d260ea14263308448a4',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo: 'https://blog.roastmylandingpage.com/favicon.png',
    sourceURL: 'https://blog.roastmylandingpage.com/landing-page-roasts/',
  },
  {
    title: 'Seven career-building life hacks',
    _id: '610c12f00ea142633084489a',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://www.ft.com/__origami/service/image/v2/images/raw/ftlogo-v1%3Abrand-ft-logo-square-coloured?source=update-logos&format=png&width=180&height=180',
    sourceURL: 'https://www.ft.com/content/bb98b7b0-924b-43c9-a2d6-ec1f3b897639',
  },
  {
    title: 'Will These Places Survive a Collapse? ',
    _id: '610ac7f10ea1426330844897',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://www.nytimes.com/vi-assets/static-assets/ios-ipad-144x144-28865b72953380a40aa43318108876cb.png',
    sourceURL:
      'https://www.nytimes.com/2021/08/03/us/collapse-of-civilization-study-new-zealand.html',
  },
  {
    title: "Replace 'I’m Sorry' With 'Thank You'",
    _id: '6107da4a0ea1426330844890',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8yMzQ2MzE1Ni9vcmlnaW4ucG5nIiwiZXhwaXJlc19hdCI6MTY3MzMzMzM1NX0.DFHxJmbLB3Gd6mYCoxIOXW5mhN7by582RSmrUhYk7eA/img.png?width=192&height=192',
    sourceURL: 'https://www.theodysseyonline.com/im-sorry-with-thank-you',
  },
  {
    title: 'The Pratfall Effect',
    _id: '60f9bca70ea1426330844876',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://www.brescia.edu/wp-content/uploads/cropped-brescia-university-site-icon-270x270.png',
    sourceURL: 'https://www.brescia.edu/2017/06/pratfall-effect/',
  },
  {
    title: 'Is reusable always best?',
    _id: '60e76a930ea1426330844854',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://socialmedia.umich.edu/wp-content/uploads/sites/30/mc-image-cache/2014/02/block_M.jpg',
    sourceURL:
      'https://news.umich.edu/is-reusable-always-best-comparing-environmental-impacts-of-reusable-vs-single-use-kitchenware/',
  },
  {
    title: 'How to Build Trust: A Practical Guide',
    _id: '60e4dcd50ea1426330844851',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://www.nytimes.com/vi-assets/static-assets/ios-ipad-144x144-28865b72953380a40aa43318108876cb.png',
    sourceURL:
      'https://www.nytimes.com/2021/06/10/opinion/distrust-respect-safety.html',
  },
  {
    title: 'The economics of dollar stores',
    _id: '60dc55800ea1426330844840',
    digest: '',
    theme: '',
    newsletter: true,
    sourceLogo:
      'https://thehustle.co/wp-content/uploads/2020/04/cropped-logomark_blackbox-300x300.jpg',
    sourceURL: 'https://thehustle.co/the-economics-of-dollar-stores/',
  },
]

const SampleNewsletter = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      width={1}
      justifyContent='center'
      px={['10px', '10px', 0]}
      style={{boxSizing: 'border-box'}}
    >
      <Flex
        width={1}
        flexDirection='column'
        my='50px'
        p={['15px', '20px']}
        style={{
          maxWidth: '700px',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            textDecoration: 'none',
            color: '#0a1f44',
            fontFamily: 'OpenSans, sans-serif',
            fontWeight: 325,
            fontSize: '16px',
            lineHeight: '28px',
            width: '100%',
            maxWidth: '700px',
            margin: 'auto',
            marginBottom: '25px',
          }}
        >
          Hi there! <br /> Here's your latest selection of Sivv posts:
        </div>
        {Articles.map((a) => (
          <Flex
            flexDirection='column'
            style={{width: '100%', maxWidth: '700px', margin: 'auto'}}
            key={a.title}
          >
            <div style={{backgroundColor: '#fff', padding: '0px 15px'}}>
              <p style={{fontFamily: 'OpenSans, sans-serif', margin: 0}}>
                <a
                  href={`https://sivv.io/feed/?${a._id}`}
                  style={{
                    textDecoration: 'none',
                    color: '#0a1f44',
                    fontFamily: 'OpenSans, sans-serif',
                    fontWeight: 325,
                    fontSize: '18px',
                    lineHeight: '28px',
                  }}
                >
                  {a.title}
                </a>
              </p>
              <div style={{width: '100%', marginTop: '5px'}}>
                <img
                  src={a.sourceLogo}
                  height='25px'
                  style={{marginRight: '5px', float: 'left', height: '25px'}}
                  alt='source'
                />

                {a.sourceURL && a.sourceURL.includes('http') ? (
                  <a
                    href={a.sourceURL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      textDecoration: 'none',
                      fontSize: '15px',
                      color: '#FF6600',
                      fontFamily: 'OpenSans, sans-serif',
                      fontWeight: 325,
                    }}
                  >
                    {a.sourceURL.split('://')[1].split('/')[0].replace('www.', '')}
                  </a>
                ) : (
                  <span
                    style={{
                      textDecoration: 'none',
                      fontSize: '15px',
                      color: '#FF6600',
                      fontFamily: 'OpenSans, sans-serif',
                      fontWeight: 325,
                    }}
                  >
                    {a.sourceTitle}
                  </span>
                )}
                <a
                  href={`https://sivv.io/feed/?${a._id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='readLink'
                  style={{
                    marginLeft: 'auto',
                    fontSize: '12px',
                    color: '#FF6600',
                    fontFamily: 'OpenSans, sans-serif',
                    fontWeight: 325,
                    float: 'right',
                  }}
                >
                  Read
                </a>
              </div>
            </div>
            <div
              style={{
                borderTop: '1px solid #f2f3f5',
                width: '75%',
                margin: 'auto',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
          </Flex>
        ))}
        <div
          style={{
            textDecoration: 'none',
            color: '#0a1f44',
            fontFamily: 'OpenSans, sans-serif',
            fontWeight: 325,
            fontSize: '16px',
            lineHeight: '28px',
            width: '100%',
            maxWidth: '700px',
            margin: 'auto',
            marginTop: '15px',
            textAlign: 'justify',
          }}
        >
          We also recently covered the difficulties of creative thinking, Amazon's
          leadership principles, the unappreciated challenges of climate change and
          much more. <a href='https://www.sivv.io/signin'>Sign-in</a> to read more.
          <br />
          <br />
          Happy reading!
          <br />
          <br />
          The team at Sivv
        </div>

        <div
          style={{
            textDecoration: 'none',
            color: '#0a1f44',
            fontFamily: 'OpenSans, sans-serif',
            fontWeight: 325,
            fontSize: '16px',
            lineHeight: '28px',
            width: '100%',
            maxWidth: '700px',
            margin: 'auto',
            marginTop: '25px',
            textAlign: 'justify',
          }}
        >
          <span style={{color: '#FF6600'}}>UPGRADE TO PREMIUM</span>
          <br />
          You are currently receiving the free version of the newsletter. Premium
          subscribers get access to more content, a weekly newsletter, Sivv Guides,
          tailored alerts and much more.{' '}
          <a href='https://www.sivv.io/subscription'>Upgrade now.</a>
        </div>

        <div
          style={{
            textDecoration: 'none',
            color: '#0a1f44',
            fontFamily: 'OpenSans, sans-serif',
            fontWeight: 325,
            fontSize: '16px',
            lineHeight: '28px',
            width: '100%',
            maxWidth: '700px',
            margin: 'auto',
            marginTop: '25px',
            textAlign: 'justify',
          }}
        >
          <span style={{color: '#FF6600'}}>SIVV GUIDES</span>
          <br />
          Sivv guides curate and summarise the most useful insights from the world's
          best books, articles and research, allowing you to access thousands of
          actionable ideas in a fraction of the time it would otherwise take.
          <br />
          <br />
          <Flex width={1} justifyContent='space-around'>
            {[
              'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1627563451445',
              'https://sivv-images.s3.eu-west-2.amazonaws.com/5dc1c506f80bb44a189b4497_1631721916724',
              'https://sivv-images.s3.eu-west-2.amazonaws.com/5dc1c506f80bb44a189b4497_1631721984216',
            ].map((i) => (
              <div style={{width: '25%'}}>
                <img
                  src={i}
                  width='100%'
                  height='100%'
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                  alt='Sivv guide'
                  key={i}
                />
              </div>
            ))}
          </Flex>
          <br />
          <br />
          <Flex width={1} justifyContent='center'>
            <a
              href='https://www.sivv.io/guides'
              style={{
                color: '#ffffff',
                fontFamily: 'OpenSans, sans-serif',
                fontSize: '12px',
                padding: '10px',
                backgroundColor: '#FF6600',
                borderRadius: '5px',
                textDecoration: 'none',
                margin: 'auto',
              }}
            >
              FIND OUT MORE
            </a>
          </Flex>
        </div>

        <div
          style={{
            textAlign: 'center',
            width: '100%',
            maxWidth: '700px',
            backgroundColor: '#3a444d',
            padding: '25px',
            boxSizing: 'border-box',
            margin: 'auto',
            marginTop: '35px',
          }}
        >
          <p
            style={{
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: 300,
              textAlign: 'center',
              fontFamily: 'OpenSans, sans-serif',
            }}
          >
            Sivv distills the world's best thinking into actionable insights.
          </p>
          <p
            style={{
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: 300,
              textAlign: 'center',
              fontFamily: 'OpenSans, sans-serif',
              marginBottom: '10px',
            }}
          >
            Find out more at{' '}
            <a style={{color: '#FF6600'}} href='https://www.sivv.io'>
              www.sivv.io
            </a>
            .
          </p>
          <img
            width='125px'
            src='https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1626970244340'
            style={{marginTop: '10px'}}
            alt='Sivv logo'
          />
          <p
            style={{
              fontFamily: 'OpenSans, sans-serif',
              fontSize: '9px',
              color: '#fff',
              fontWeight: 300,
              fontStyle: 'italic',
              textAlign: 'center',
            }}
          >
            Get smarter, faster.
          </p>
          <p
            style={{
              fontFamily: 'OpenSans, sans-serif',
              paddingBottom: '10px',
              paddingTop: '10px',
              fontSize: '8px',
              color: '#999999',
              fontWeight: 300,
              textAlign: 'center',
            }}
          >
            © 2021 Sivv. All rights reserved.
          </p>
        </div>
      </Flex>
    </Flex>
  )
}

export default SampleNewsletter
