import axios from 'axios'
import config from '../../../utils/configuration'
import personalInfoActions from './actionTypes'
import {storeUser} from '../../user'

const {target} = config

export const resetError = () => {
  return {
    type: personalInfoActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: personalInfoActions.SET_ERROR,
    error,
  }
}

export const toggleIsPersonalInfoUpdated = (processed) => {
  return {
    type: personalInfoActions.TOGGLE_FORM_STATUS,
    processed,
  }
}

export const updatePersonalInfo = ({
  _id,
  firstName,
  lastName,
  about,
  image,
  website,
  location,
  organisation,
  position,
}) => {
  return async (dispatch) => {
    dispatch(resetError())
    dispatch(toggleIsPersonalInfoUpdated(false))
    return axios
      .post(`${target}/myAccount/updatePersonalInfo`, {
        _id,
        firstName,
        lastName,
        about,
        image,
        website,
        location,
        organisation,
        position
      })
      .then((response) => {
        const details = response.data
        dispatch(storeUser({...details}))
        dispatch(toggleIsPersonalInfoUpdated(true))
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(setError({form: 'Server encountered an issue'}))
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
