import React from 'react'
import {Flex, Box, Image} from 'rebass'
import Fade from 'react-reveal/Fade'
import HeadingNew from '../components/FormElements/HeadingNew'
import Icons from '../images/icons/icons'
import Container from '../components/FormElements/Container'

// <Flex
//   width={position === 2 ? [1 / 3, 1 / 4] : [3 / 10, 1 / 4]}
//   justifyContent='flex-start'
//   alignItems='center'
//   px={[0, 2]}
//   flexDirection='column'
// >
//   <Image src={Icons[icon]} height={['35px', '45px']} mb='5px' />
//   <HeadingNew
//     size='h3'
//     palette='white'
//     as='h3'
//     style={{lineHeight: '1.2'}}
//     centerText
//   >
//     {text}
//   </HeadingNew>
// </Flex>

const BenefitsPanel = (icon, text) => {
  return (
    <Flex
      width={['80px', 3 / 10, 3 / 10]}
      justifyContent='flex-start'
      alignItems='center'
      px={[0, 2]}
      flexDirection={['column']}
      mb={['25px', '0px']}
    >
      <Image src={Icons[icon]} height={['45px', '55px']} mb={['5px', '5px']} />
      <HeadingNew
        size='h3'
        palette='white'
        as='h3'
        style={{lineHeight: '1.2'}}
        centerText
      >
        {text}
      </HeadingNew>
    </Flex>
  )
}

const Benefits = () => {
  return (
    <Container home background='#61bfbf'>
      <Flex
        width={1}
        justifyContent={['space-between', 'space-around']}
        flexWrap='wrap'
      >
        <Box mb={['35px', '45px', '45px']} width={1}>
          <HeadingNew
            size='h2'
            palette='white'
            as='h2'
            style={{lineHeight: '1.3'}}
            centerText
          >
            Use Sivv to gain quick and easy access to transformative ideas that will
            help you achieve your goals
          </HeadingNew>
        </Box>

        <Flex
          width={1}
          flexDirection={['row']}
          alignItems={['center', 'flex-start']}
          justifyContent={['space-between', 'space-around']}
        >
          {BenefitsPanel('knowledge', 'Read less, learn more')}
          {BenefitsPanel('thought', 'End information overload', 2)}
          {BenefitsPanel('time', 'Save time, effort & money')}
        </Flex>
      </Flex>
    </Container>
  )
}

export default Benefits
