/* eslint react/prop-types: 0 */

import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const PrivateRoute = (props) => {
  const {component: Component, ...rest} = props
  return (
    <Route
      {...rest}
      render={(props) => {
        return rest.token !== null ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: {from: props.location},
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
