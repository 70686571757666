import styled, {css} from 'styled-components'
import {primary} from '../../../styles/fonts'

export default styled.div`
  color: #de7033;
  font-family: ${primary};
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
  margin-right: 15px;
  transition: all 0.1s ease-in;
  cursor: pointer;
  &:hover {
    color: #ff6600;
  }
  ${(props) =>
    props.active &&
    css`
      color: #ff6600;
    `}
`
