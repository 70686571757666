import React, {useState, useEffect} from 'react'
import {Box, Flex, Image} from 'rebass'
import EmailCapture from '../EmailCapture'
// -- Components
import HeadingNew from '../../components/FormElements/HeadingNew'
import Checkbox from '../../components/FormElements/Checkbox'

import Icons from '../../images/icons/icons'

const Courses = () => {
  const Guides = [
    {title: 'Managing Habits', icon: 'Habits'},
    {title: 'Productivity', icon: 'Productivity'},
    {title: 'Communication', icon: 'Communication'},
    {title: 'Creativity', icon: 'Creativity'},
    {title: 'Decision Making', icon: 'Decision Making'},
    {title: 'Career Success', icon: 'professionalDevelopment'},
    {title: 'Entrepreneurship', icon: 'personalDevelopment'},
    {title: 'Wellbeing', icon: 'wellbeing'},
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [interests, toggleInterests] = useState([])

  const updateInterests = (i) => {
    interests.includes(i)
      ? toggleInterests(interests.filter((o) => o !== i))
      : toggleInterests([...interests, i])
  }

  return (
    <Flex
      width={1}
      py='50px'
      px={['15px', '75px']}
      justifyContent='center'
      alignItems='center'
      flexDirection={['column']}
      mb={['15px', 0]}
      style={{background: '#e8eef4'}}
    >
      <Box width={1} style={{maxWidth: '900px'}}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          Sivv courses
        </HeadingNew>
      </Box>
      <Box width={1} style={{maxWidth: '900px'}} mt={['10px', '25px']}>
        <HeadingNew size='h3' palette='medium' as='h3'>
          We will soon be launching a series of courses for individuals and
          businesses. These apply the latest techniques from learning science to help
          participants develop essential life skills, including:
        </HeadingNew>
      </Box>
      <Flex
        width={1}
        flexDirection='row'
        justifyContent='space-around'
        align-items='flex-start'
        flexWrap='wrap'
        my={3}
        style={{maxWidth: '900px'}}
      >
        {Guides.map((g) => (
          <Flex
            width={[4.8 / 10, 1 / 4.5]}
            py={3}
            justifyContent={['flex-start']}
            alignItems='center'
            flexDirection={['row', 'column']}
            mb={[1, 3, 0]}
            key={g.title}
          >
            <Image
              src={Icons[g.icon]}
              width={[1 / 6, 1 / 3, 1 / 3]}
              mb={[0, 2]}
              mr={[2, 3, 0]}
              ml={[2, 0]}
            />
            <HeadingNew size='h3' palette='medium' as='h3' centerText>
              {g.title}
            </HeadingNew>
          </Flex>
        ))}
      </Flex>
      <Box p={2} width={1}>
        <HeadingNew size='h3' palette='medium' as='h3' centerText>
          Register your interest below and we will let you know when our courses
          launch.
        </HeadingNew>
      </Box>
      <Flex width={1} flexWrap='wrap' my={['15px', '35px']} style={{maxWidth: '900px'}}>
        {Guides.map((g) => (
          <Flex
            p={2}
            width={[1 / 3, 1 / 3, 1 / 4]}
            pl={[0, 0, '25px']}
            key={g.title}
          >
            <Checkbox
              label={g.title}
              name='terms'
              inputProps={{
                id: g.title,
                name: g.title,
                onChange: () => updateInterests(g.title),
                checked: interests.includes(g.title),
              }}
              fullWidth
            />
          </Flex>
        ))}
      </Flex>
      <EmailCapture category='courses' alertCategories={interests} landing />
    </Flex>
  )
}

export default Courses
