import axios from 'axios'
import config from '../../../utils/configuration'
import signInActions from './actionTypes'
import {storeUser} from '../../user'

const {target, appVersion} = config

export const resetError = () => {
  return {
    type: signInActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: signInActions.SET_ERROR,
    error,
  }
}

export const setFormStatus = (loading) => {
  return {
    type: signInActions.SET_FORM_STATUS,
    loading,
  }
}

export const signIn = (email, password, GoogleToken) => {
  return async (dispatch) => {
    dispatch(resetError())
    return axios
      .post(`${target}/signin`, {email: email ? email.toLowerCase() : null, password, GoogleToken, appVersion})
      .then((response) => {
        const details = response.data
        dispatch(storeUser({...details.user, token: details.token}))
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(
              setError({
                form: 'There is no account associated with this email address',
              })
            )
          }
          if (error.response.status === 403) {
            dispatch(
              setError({
                form:
                  'This email address has not been verified - please check your email account',
              })
            )
          }
          if (error.response.status === 401) {
            dispatch(setError({form: 'Incorrect password'}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
