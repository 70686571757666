import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.95); */
  background: rgba(255, 255, 255, 1);
  z-index: 10000;
  box-shadow: ${({isActive}) =>
    isActive ? '0 1px 5px rgba(0,0,0, .2)' : '0 1px 5px rgba(0,0,0, .05)'};
  content: 48em;
  transition: all 0.2s ease-out;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Nav = styled.nav`
  margin: 0 auto;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 48em) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`

export const Options = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.breakpoints[1]}) {
    /* width: auto; */
    width: ${({authenticated}) => (authenticated ? '50%' : '100%')};
  }

  @media screen and (max-width: 48em) {
    /* width: auto; */
    width: 100%;
  }
`

export const ToggleMobileNav = styled.div`
  cursor: pointer;
  @media screen and (min-width: 48em) {
    display: none;
  }
`

export const List = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 18px 0 0 0;
  padding: 0;
  flex-direction: column;
  display: ${({isToggled}) => (isToggled ? 'flex' : 'none')};

  @media screen and (min-width: 48em) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
  }
`

export const Item = styled.li`
  padding: 0;
  border-bottom: solid 1px #DADEE6;
  position: relative;

  span {
    display: block;
    border-bottom: solid 1px #DADEE6;
    position: relative;
    padding: 10px 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;

    svg {
      display: none;
    }

    @media screen and (min-width: 48em) {
      svg {
        display: inline-block;
        margin-right: 5px;
        margin-top: 0;
        float: left;
        stroke: #000;
        fill: none;
        stroke-width: 2;
      }
    }

    ${(props) =>
      props.active &&
      css`
        @media screen and (min-width: 48em) {
          :not(.non-link)&:after {
            content: '';
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
            height: 2px;
            border-radius: 2px;
            background: #de7033;
          }
        }
      `}

    span.non-link {
      border: none;
    }
  }
  @media screen and (min-width: 48em) {
    border: none;
    padding: 0 20px 0 0;
    &:last-child {
      padding: 0;
    }
    &:hover {
      color: #de7033;
      & > ul {
        display: block !important;
      }
      .non-link {
        color: black;
        &:hover {
          color: #de7033;
        }
      }
    }

    span {
      display: inline;
      border: none;
    }
  }
`

export const StyledHeader = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3a444d;
  /* height: 24px; */
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 20px;
  /* padding: 10px 10px 10px 0; */
  cursor: pointer;
`

export const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 10px 10px 0;
  cursor: pointer;
  /* color: ${({active, disabled}) =>
    disabled ? 'grey' : active ? 'black' : 'black'}; */
  /* pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')}; */
  color: ${({active}) => (active ? '#de7033' : 'black')};
  position: relative;
  &:hover {
    color: #FF6600;
  }

  @media screen and (min-width: 48em) {
    /* color: black; */
    padding: 1px 2px 5px;
  }

  /* -- ACTIVE -- */
  ${(props) =>
    props.active &&
    css`
      color: #de7033;
      @media screen and (min-width: 48em) {
        &:after {
          /* content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          border-radius: 2px;
          background: #de7033; */
          /* color: #de7033; */
        }
      }
    `}
`

export const StyledLinkButton = styled(Link)`
  display: block;
  color: black;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 10px 10px 0;
  &:hover {
    color: #de7033;
  }

  @media screen and (min-width: 48em) {
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color: #fff;
    background: #de7033;
    text-align: center;
    position: relative;
    font-size: 14px;
    padding: 5px 8px;
    text-decoration: none;
    margin-top: -5px;

    &:hover {
      background: #e55b00;
      color: #fff;
    }

    /* ----------------------------------------- SIZE */
    ${(props) =>
      props.palette === 'secondary' &&
      css`
        color: #de7033;
        background: #fff;
        box-shadow: inset 0px 0px 0px 1px #de7033;
        &:hover {
          color: #e55b00;
          background: #fff;
          box-shadow: inset 0px 0px 0px 2px #e55b00;
        }
      `}
  }
`

export const StyledLinkButtonNew = styled(Link)`
  /* display: block; */
  /* color: black; */
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  width: 75px;
  margin-left: 10px;
  /* font-size: 14px;
  padding: 10px 10px 10px 0;
  &:hover {
    color: #FF6600;
  } */

  display: inline-block;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: #fff;
  background: #de7033;
  text-align: center;
  position: relative;
  font-size: 14px;
  padding: 5px 8px;
  text-decoration: none;
  /* margin-top: -5px; */

  &:hover {
    background: #e55b00;
    color: #fff;
  }

  ${(props) =>
    props.active &&
    css`
      color: #de7033;
      background: #fff;
      box-shadow: inset 0px 0px 0px 1px #de7033;
      &:hover {
        color: #e55b00;
        background: #fff;
        box-shadow: inset 0px 0px 0px 2px #e55b00;
      }
    `}
`

export const SubList = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  display: 'flex';

  @media screen and (min-width: 48em) {
    display: block;
    position: absolute;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px #dadee6;
    top: 25px;
    width: 200px;
    right: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 10;
    background: white;
    display: none;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      bottom: -1px;
      left: -1px;
      right: -1px;
      height: 8px;
      /* background: linear-gradient(306deg, #52c9f9, #3fe8a1); */
      background: #3a444d;
      width: calc(100% + 2px);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

export const SubItem = styled.li`
  padding: 0 0 0 16px;
  border-bottom: solid 1px #DADEE6;
  position: relative;
  &:last-child {
    border-bottom: 0;
    span {
      border-bottom: 0;
    }
  }
  @media screen and (min-width: 48em) {
    border: none;
    padding: 10px;
    display: block;
    min-width: 140px;
  }
`
