import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {logout} from '../../actions/user'
import {clearDraft} from '../../actions/articleActions'
import {clearSearch, newSearch, updateQuery} from '../../actions/searchActions'
import logo from './logo.png'

import {
  HeaderWrapper,
  Container,
  Nav,
  Options,
  ToggleMobileNav,
  List,
  Item,
  StyledHeader,
  StyledLink,
  StyledLinkButton,
  StyledLinkButtonNew,
  SubList,
  SubItem,
} from './styles'

const Header = ({
  history,
  location,
  logout,
  token,
  _id,
  clearDraft,
  clearSearch,
  newSearch,
}) => {
  const [isToggled, setToggle] = useState(false)
  const isAuthenticated = !!token
  const [isScrollActive, setScrollActive] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    document.documentElement.scrollTop > 60
      ? setScrollActive(true)
      : setScrollActive(false)
  }

  function handleClickSign(destination) {
    setToggle(false)
    document.documentElement.scrollTop = 0
    history.push(destination)
  }

  function handleClickSignOut() {
    setToggle(false)
    history.push('/')
    logout()
  }

  const allLinks = [
    {
      label: 'Feed',
      link: '/feed',
      restricted: false,
    },
    {
      label: 'Library',
      link: '/library',
      restricted: false,
    },
    {
      label: 'Guides',
      link: '/guides',
      restricted: false,
    },
    {
      label: 'Newsletter',
      link: '/newsletter',
      restricted: false,
    },
    {
      label: 'Courses',
      link: '/courses',
      restricted: false,
    },
    // {
    //   label: 'About',
    //   link: '/about',
    //   restricted: false,
    //   hideWhenLoggIn: true,
    // },
    {
      label: 'Submit',
      restricted: true,
      link: '/submit',
    },
    // {
    //   label: 'Sign in',
    //   link: '/signIn',
    //   onlyShowOnToggle: true,
    // },
    // {
    //   label: 'Join',
    //   link: '/subscription',
    //   onlyShowOnToggle: true,
    // },
  ]

  const clearSearchResults = () => {
    window.scrollTo(0, 0)
    clearSearch()
    newSearch('-', '*', '*', '*', '*', null, _id, 's')
  }

  const links = isAuthenticated
    ? allLinks.filter((link) => !link.onlyShowOnToggle && !link.hideWhenLoggIn)
    : allLinks.filter((link) => !link.restricted)

  return (
    <HeaderWrapper role='banner' isActive={isScrollActive}>
      <Container>
        <Nav>
          <Options authenticated={isAuthenticated}>
            <StyledHeader
              to='/'
              onClick={() => location.pathname === '/' && clearSearchResults()}
            >
              <img
                src={logo}
                height='55px'
                style={{marginRight: '5px', border: '1'}}
                alt='Sivv logo'
              />{' '}
              <span
                style={{
                  fontSize: '10px',
                  marginLeft: '-15px',
                  fontStyle: 'italic',
                  marginTop: '3px',
                }}
              >
                Get smarter, faster
              </span>
            </StyledHeader>
            <ToggleMobileNav onClick={() => setToggle(!isToggled)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 48 48'
              >
                <g fill='#282828'>
                  <path d='M2.074 9.134a1.068 1.068 0 1 1 0-2.134h42.852a1.068 1.068 0 0 1 0 2.134H2.074zM2.074 25.575a1.068 1.068 0 1 1 0-2.135h42.852a1.068 1.068 0 0 1 0 2.134H2.074zM2.074 42.015a1.068 1.068 0 0 1 0-2.134h42.852a1.068 1.068 0 1 1 0 2.134H2.074z' />
                </g>
              </svg>
            </ToggleMobileNav>
          </Options>
          <List isToggled={isToggled}>
            {links
              .filter((l) => (!isToggled ? !l.onlyShowOnToggle : l))
              .map((linkItem, idLink) => {
                const {link, label} = linkItem
                return (
                  <Item key={idLink}>
                    {/* {location.pathname } -> {link} */}
                    <StyledLink
                      data-testid='header-link'
                      to={link}
                      onClick={() => {
                        setToggle(false)
                        label === 'Compose' && clearDraft()
                      }}
                      active={location.pathname === link ? 1 : 0}
                    >
                      {label}
                    </StyledLink>
                  </Item>
                )
              })}
            {isAuthenticated && (
              <Item>
                <span>
                  {/* <img src={iconUser} alt='User' width='18' height='18' /> */}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 48 48'
                  >
                    <g>
                      <ellipse cx='24.132' cy='13.173' rx='10.316' ry='11.423' />
                      <path d='M45.514 40.38c0-6.046-11.27-12.071-21.382-12.071-10.112 0-21.382 6.025-21.382 12.07 0 2.535 9.5 4.561 21.382 4.561 11.883 0 21.382-2.026 21.382-4.56z' />
                    </g>
                  </svg>
                </span>
                <SubList>
                  <SubItem>
                    <StyledLink
                      data-testid='header-link'
                      to='/myDetails'
                      onClick={() => setToggle(false)}
                      active={location.pathname === '/myDetails'}
                    >
                      My Details
                    </StyledLink>
                  </SubItem>
                  <SubItem>
                    <StyledLink
                      data-testid='header-link'
                      to='/myPreferences'
                      onClick={() => setToggle(false)}
                      active={location.pathname === '/myPreferences'}
                    >
                      My Preferences
                    </StyledLink>
                  </SubItem>
                  <SubItem>
                    <StyledLink
                      data-testid='header-link'
                      to='/myArticles'
                      onClick={() => setToggle(false)}
                      active={location.pathname === '/myArticles'}
                    >
                      My Articles
                    </StyledLink>
                  </SubItem>
                  <SubItem>
                    <StyledLink
                      data-testid='header-link'
                      to='/mySubscription'
                      onClick={() => setToggle(false)}
                      active={location.pathname === '/mySubscription'}
                    >
                      My Subscription
                    </StyledLink>
                  </SubItem>
                  <SubItem>
                    <StyledLink
                      data-testid='header-link'
                      to='/howToUse'
                      onClick={() => setToggle(false)}
                      active={location.pathname === '/howToUse'}
                    >
                      Help
                    </StyledLink>
                  </SubItem>
                  <SubItem>
                    <span
                      className='non-link'
                      data-testid='header-link'
                      style={{cursor: 'pointer'}}
                      onClick={() => handleClickSignOut()}
                    >
                      Sign Out
                    </span>
                  </SubItem>
                </SubList>
              </Item>
            )}
            {/* {!isToggled && !isAuthenticated && (
              <>
                <Item>
                  <StyledLinkButtonNew
                    data-testid='header-link'
                    to='/signin'
                    onClick={() => handleClickSign('/signin')}
                  >
                    Sign In
                  </StyledLinkButtonNew>
                </Item>
                <Item>
                  <StyledLinkButtonNew
                    palette='secondary'
                    data-testid='header-link'
                    to='/subscription'
                    onClick={() => handleClickSign('/subscription')}
                  >
                    Join
                  </StyledLinkButtonNew>
                </Item>
              </>
            )} */}
          </List>
        </Nav>
      </Container>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  clearDraft: PropTypes.func.isRequired,
  newSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  token: PropTypes.string,
  _id: PropTypes.string,
}

const mapStateToProps = (state) => {
  const {token, _id} = state.user

  return {
    token,
    _id,
  }
}

export default withRouter(
  connect(mapStateToProps, {logout, clearDraft, newSearch, clearSearch})(Header)
)

export const HeaderComponent = Header

// <SubItem>
//   <StyledLink
//     data-testid='header-link'
//     to='/myLibrary'
//     onClick={() => setToggle(false)}
//     active={location.pathname === '/myLibrary'}
//   >
//     Library
//   </StyledLink>
// </SubItem>
// <SubItem>
//   <StyledLink
//     data-testid='header-link'
//     to='/myGuides'
//     onClick={() => setToggle(false)}
//     active={location.pathname === '/myGuides'}
//   >
//     Guides
//   </StyledLink>
// </SubItem>
// <SubItem>
//   <StyledLink
//     data-testid='header-link'
//     to='/myProfile'
//     onClick={() => setToggle(false)}
//   >
//     My Profile
//   </StyledLink>
// </SubItem>
// <SubItem>
//   <StyledLink
//     data-testid='header-link'
//     to='/myNetwork'
//     onClick={() => setToggle(false)}
//   >
//     My Network
//   </StyledLink>
// </SubItem>

// : (
//   <div style={{ display: 'flex', alignItems: 'center'}}>
//     <StyledLinkButtonNew
//       data-testid='header-link'
//       to='/signin'
//       active={location.pathname === '/signin'}
//       onClick={() => handleClickSign('/signin')}
//     >
//       Sign In
//     </StyledLinkButtonNew>
//     <StyledLinkButtonNew
//       palette='secondary'
//       data-testid='header-link'
//       to='/signUp'
//       active={location.pathname === '/signUp'}
//       onClick={() => handleClickSign('/signup')}
//     >
//       Join
//     </StyledLinkButtonNew>
//   </div>
// )
