import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const Label = styled.label`
  display: block;
  position: absolute;
  left: 9px;
  top: 16px;
  background: white;
  transition: all 0.1s ease-in;
  @media screen and (min-width: 640px) {
    left: 17px;
  }
  ${(props) =>
    props.value &&
    css`
      font-size: 11px;
      top: 4px;
      color: #657081;
    `}
`

const StyledTextArea = styled.textarea`
  padding: 26px 8px 11px;
  background: white;
  border-radius: 4px;
  border: ${(props) => (props.error ? '1px solid #D0021B' : 'solid 1px #DADEE6')};
  height: ${(props) => (props.height ? props.height : '48px')};
  box-sizing: border-box;
  width: 100%;
  outline: none;
  transition: all 0.1s ease-in;
  color: #282828;
  font-family: OpenSans, sans-serif;
  resize: none;
  // removes top shadow on iOS
  background-clip: padding-box;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #eff5f8;
    border-radius: 25px;
  }

  @media screen and (min-width: 640px) {
    padding: 26px 16px 11px;
  }

  &--error {
    border-color: #d0021b;
  }

  &:focus {
    & ~ label {
      font-size: 11px;
      top: 4px;
      color: #657081;
    }
    & ~ .error {
      display: none;
    }

    & ~ .passwordToggle {
      top: 6px;
      img {
        width: 16px;
      }
    }
  }

  &:disabled {
    opacity: 0.7;
  }
`
const Error = styled.div`
  font-size: 11px;
  color: #d0021b;
  position: absolute;
  right: 8px;
  bottom: 4px;
`

const TextBox = ({
  label,
  value,
  error,
  onChange,
  inputProps,
  styleOptions,
  limit,
  height
}) => {
  return (
    <Wrapper>
      <StyledTextArea
        rows='14'
        cols='10'
        wrap='soft'
        maxLength={limit}
        height={height}
        // onFocus={this.onFocusField}
        onChange={onChange}
        value={value}
        {...inputProps}
        error={error}
      />
      {label && (
        <Label htmlFor={inputProps.id} value={value}>
          {label}
        </Label>
      )}
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

TextBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  height: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object.isRequired,
  styleOptions: PropTypes.object,
  hasText: PropTypes.bool,
  limit: PropTypes.number,
}

export default TextBox
