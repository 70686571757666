export const loadState = () => {
  /*
    Local storage clearance option:
    Before loading version is checked:
    - if version outdated: clear
    - if version doesn't exist: clear
    => force to use latest state shapes
  */
  const currentLocalStorageVersion = '1.02'
  const versionLocalStorage = localStorage.getItem('version')
  const isLocalStorageOutDated =
    !versionLocalStorage || versionLocalStorage !== currentLocalStorageVersion
  if (isLocalStorageOutDated) {
    localStorage.clear()
    localStorage.setItem('version', currentLocalStorageVersion)
  }

  /*
    Once version checked, redux state can then use it safely
  */
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      article: state.article,
      user: state.user,
    })
    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log(err)
  }
}
