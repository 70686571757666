import React from 'react'
import {Flex, Box} from 'rebass'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
// import HomeLink from '../components/FormElements/HomeLink'
import Button from '../components/FormElements/Button'

const StyledLink = styled(Link)`
  width: 100%;
`

const LandingLibrary = () => {
  const Books = [
    {
      title: 'Innovators Dilemma',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592847408917',
    },
    {
      title: 'Black Swan',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592847920511',
    },
    {
      title: 'Drive',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592038006966',
    },
    {
      title: 'Deep Work',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592847860864',
    },
    {
      title: 'Hooked',
      image:
        'https://sivv-images.s3.eu-west-2.amazonaws.com/5e9803ce776f43b218a3717a_1592846996189',
    },
  ]

  return (
    <Container home background='#82a6b2'>
      <HeadingNew size='h1' palette='white' as='h1'>
        Book Summaries
      </HeadingNew>
      <HeadingNew size='h3' palette='white' as='h3'>
         A growing library of concise, crowdsourced summaries
      </HeadingNew>
      <Flex
        width={1}
        flexDirection={['column', 'row', 'row']}
        justifyContent='space-around'
        mt={['25px', '40px']}
        mb={['0px', '20px']}
      >
        <Flex width={[1, 2 / 3]} justifyContent={['space-around', 'space-between']}>
          {Books.map((b) => (
            <Box width={1 / 6} key={b.title}>
              <img src={b.image} width='100%' alt={b.title} />
            </Box>
          ))}
        </Flex>
        <Flex
          width={[1 , 1 / 3]}
          justifyContent='center'
          alignItems='center'
          mt={['25px', '0px']}
          ml={[0, 3]}
          p={[0, 3]}
        >
          <Box width={[1 / 2, 1]}>
            <StyledLink to='/library'>
              <Button  onClick={() => console.log('')} fullWidth>Start reading</Button>
            </StyledLink>
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

export default LandingLibrary
