import React from 'react'
import {Link} from 'react-router-dom'
import {Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Icons from '../../images/icons/icons'

const BlogAdvert = ({tag}) => {
  const advertData = {
    productivity: {
      icon: 'ProductivityGuide',
      title: 'The Sivv Guide to Being Productive',
      description: "a compendium of the world's best productivity hacks",
      link: '../guides/Being-Productive',
    },
    communication: {
      icon: 'CommunicationGuide',
      title: 'The Sivv Guide to Effective Communication',
      description: "a compendium of the world's best communication techniques",
      link: '../guides/Effective-Communication',
    },
    career: {
      icon: 'CareerGuide',
      title: 'The Sivv Guide to Career Development',
      description: "a compendium of the world's best career advice",
      link: '../guides/Career-Development',
    },
  }

  return (
    <Flex width={[1, 1]} my='15px' p={[0, 3]} alignItems='center'>
      <img
        src={Icons[advertData[tag].icon]}
        width='75px'
        height='100px'
        float='left'
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          marginRight: '15px',
          minWidth: '75px',
          minHeight: '100px'
        }}
        alt='Sivv guide'
      />
      <HeadingNew size='h3' palette='orange' as='h3' style={{textAlign: 'justify'}}>
        This article is an excerpt from{' '}
        <Link
          to={advertData[tag].link}
          style={{color: '#FF6600', textDecoration: 'none'}}
        >
          <b>{advertData[tag].title}</b>
        </Link>
        , {advertData[tag].description}. Find out more{' '}
        <Link to={advertData[tag].link}>here</Link>.
      </HeadingNew>
    </Flex>
  )
}

export default BlogAdvert
