import axios from 'axios'
import config from '../../../utils/configuration'
import emailActions from './actionTypes'
import {storeUser} from '../../user'

const {target} = config

export const resetError = () => {
  return {
    type: emailActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: emailActions.SET_ERROR,
    error,
  }
}

export const toggleIsEmailUpdated = (processed) => {
  return {
    type: emailActions.TOGGLE_FORM_STATUS,
    processed,
  }
}

export const updateEmail = ({_id, email, password}) => {
  return async (dispatch) => {
    dispatch(resetError())
    dispatch(toggleIsEmailUpdated(false))
    return axios
      .post(`${target}/myAccount/updateEmail`, {_id, email, password})
      .then((response) => {
        const details = response.data
        dispatch(storeUser({...details}))
        dispatch(toggleIsEmailUpdated(true))
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(setError({form: 'Server encountered an issue'}))
          } else if (error.response.status === 409) {
            dispatch(setError({form: 'That email address is already registered'}))
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
