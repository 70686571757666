import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  font-weight: 300;
  color: #31325f;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    font-size: 22px;
    border-radius: 50%;
    background: white;
  }
`

const Label = styled.span`
  display: inline-block;
  margin-right: 10px;
  color: ${(props) => (props.light ? '#fff' : 'auto')};
`

const Radio = ({label, name, inputProps, light}) => {
  return (
    <Container>
      <Label light={light}>{label}</Label>
      <input type='radio' name={name} {...inputProps} />
      <span className='checkmark' />
    </Container>
  )
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputProps: PropTypes.object.isRequired,
  light: PropTypes.bool,

}

export default Radio
