import {TRACKER} from '../actions/trackerActions'

const defaultState = {
  sameVisit: false,
}

export default function tracker(state = defaultState, action) {
  switch (action.type) {
    case TRACKER:
      return {
        ...state,
        sameVisit: true,
      }
    default:
      return state
  }
}
