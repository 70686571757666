import React from 'react'
import {Box, Flex, Image} from 'rebass'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import Icons from '../images/icons/icons'
import HeadingNew from '../components/FormElements/HeadingNew'
import Button from '../components/FormElements/Button'

const BundleImageHolder = styled.div`
  width: 50px;
  @media screen and (min-width: 52em) {
    width: 15%;
  }
`

const StyledLink = styled(Link)`
  width: 100%;
`
//
// width={1}
// justifyContent='space-around'
// alignItems={['center', 'flex-start']}
// style={{}}
// px={3}

const FeedAdvertNew = () => {
  return (
    <Flex
      flexDirection='column'
      width={1}
      style={{maxWidth: '850px'}}
      mb='40px'
      px={['15px']}
    >
      <HeadingNew size='h1' as='h1'>
        Sivv Guides
      </HeadingNew>
      <HeadingNew size='h3' as='h3'>
        Sivv guides summarise and curate the key takeaways from the world’s best books, articles and research
      </HeadingNew>
      <Flex
        width={1}
        justifyContent='space-between'
        mt={['25px', '40px']}
        mb={['25px', '20px']}
      >
        {[
          'ProductivityGuide',
          'CommunicationGuide',
          'HabitsGuide',
          'CareerGuide',
          'DecisionGuide',
        ].map((a) => (
          <BundleImageHolder key={a}>
            <Image
              src={Icons[a]}
              width='100%'
              mb={[0, 2]}
              mt={2}
              ml={0}
              style={{
                float: 'left',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            />
          </BundleImageHolder>
        ))}
      </Flex>
      <Flex width={1} justifyContent='center' mt={['10px', '25px']}>
        <Box width={[1 / 2, 1 / 3]}>
          <StyledLink to='/guides'>
            <Button onClick={() => console.log('')} fullWidth>
              Find out more
            </Button>
          </StyledLink>
        </Box>
      </Flex>
    </Flex>
  )
}

export default FeedAdvertNew
