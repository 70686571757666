import React, {useEffect} from 'react'
import {withRouter, Link as RouterLink} from 'react-router-dom'
import {Box, Flex} from 'rebass'
import PropTypes from 'prop-types'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Button from '../components/FormElements/Button'
import Icons from '../images/icons/icons'
import Preferences from '../containers/Form/ChangePreferences'
import LandingGuides from './LandingGuides'

const Premium = ({upgrade, upgradeSubscription, history}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Options = [
    {
      title: 'Basic',
      cost: 'Free',
      includes: 'Create a basic account to:',
      button: 'Create account',
      features: [
        'Access new content each week',
        'Submit, save and discuss posts',
        'Receive a regular digest email',
      ],
      action: () => history.push('/signUp'),
      hideWhenSignedIn: true,
    },
    {
      title: 'Premium',
      cost: '$5 / month or $50 / year',
      includes: 'Become a premium subscriber to:',
      // button: 'Join now',
      button: upgrade ? 'Upgrade now' : 'Buy now',
      features: [
        'Access all new content',
        'Gain full archive access',
        'Set-up tailored email alerts',
      ],
      action: () => upgradeSubscription('premium'),
    },
    {
      title: 'Expert',
      cost: '$75 / year',
      includes: 'Upgrade to expert to:',
      // button: 'Start Learning',
      button: upgrade ? 'Upgrade now' : 'Buy now',

      features: [
        <div>
          Access all{' '}
          <RouterLink
            style={{textDecoration: 'none', color: '#de7033'}}
            to='./guides'
          >
            Sivv Guides
          </RouterLink>{' '}
        </div>,
        'Receive unlimited updates',
        'Access new Sivv initiatives',
      ],
      action: () => upgradeSubscription('expert'),
    },
  ]

  // https://www.blendb2b.com/hubspot-website-pricing

  return (
    <Container minHeight background='#e8eef4' horizontalAlign='center'>
      <Flex width={1} flexDirection='column' style={{maxWidth: '1000px'}}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          {upgrade ? 'Upgrade Options' : 'Subscription Options'}
        </HeadingNew>
        <Flex
          width={1}
          flexDirection={['column', 'column', 'row']}
          justifyContent='space-around'
          alignItems='center'
          mt={['25px', '50px']}
        >
          {Options.filter((o) => (upgrade ? !o.hideWhenSignedIn : o)).map((o) => (
            <Flex
              width={[9 / 10, 1 / 2, 1 / 4]}
              p={3}
              flexDirection='column'
              style={{border: '1px solid #fff', minWidth: '300px', maxWidth: '350px'}}
              mb={['35px', '35px', 0]}
              mx={2}
              key={o.title}
            >
              <HeadingNew size='h2' palette='medium' as='h2' centerText>
                {o.title}
              </HeadingNew>
              <Box mt={3} pt={3} style={{borderTop: '1px solid #fff'}}>
                <HeadingNew size='h3' palette='medium' as='h3'>
                  {o.includes}
                </HeadingNew>
              </Box>
              <Flex flexDirection='column' style={{minHeight: '140px'}}>
                {o.features.map((f) => (
                  <Flex my={2} alignItems='center' key={f}>
                    <img
                      src={Icons.SubscriptionArrow}
                      width='15px'
                      style={{marginRight: '5px'}}
                      alt='arrow'
                    />
                    <HeadingNew size='h3' palette='medium' as='h3'>
                      {f}
                    </HeadingNew>
                  </Flex>
                ))}
              </Flex>
              <Box pt={3} pb={2} style={{borderTop: '1px solid #fff'}}>
                <HeadingNew size='h3' palette='medium' as='h3' centerText>
                  {o.cost}
                </HeadingNew>
              </Box>
              <Button onClick={o.action}>{o.button}</Button>
            </Flex>
          ))}
        </Flex>
        <Box width={1} mt={['25px', '50px']}>
          <HeadingNew size='h3' palette='medium' as='h3' centerText>
            For information on subscriptions for teams, contact us at
            <a
              href='mailto:info@sivv.io'
              target='null'
              style={{color: '#de7033', cursor: 'pointer', textDecoration: 'none'}}
            >
              {} info@sivv.io
            </a>
            .
          </HeadingNew>
        </Box>
        <Box width={1} mt={['25px', '50px']}>
          <HeadingNew size='h3' palette='medium' as='h3' centerText>
            If you don't want to subscribe but would still like to support our work,
            you can make a small contribution
            {' '}
            <a
              href='https://www.buymeacoffee.com/Sivv'
              target='null'
              style={{color: '#de7033', cursor: 'pointer', textDecoration: 'none'}}
            >
              here
            </a>
            .
          </HeadingNew>
        </Box>
      </Flex>
      <div
        style={{
          borderTop: '1px solid #fff',
          width: '75%',
          maxWidth: '700px',
          marginTop: '50px',
        }}
      />
      <LandingGuides background='#e8eef4' maxWidth='900px' />
      <div
        style={{
          borderTop: '1px solid #fff',
          width: '75%',
          maxWidth: '700px',
        }}
      />
      <Preferences />
    </Container>
  )
}

Premium.propTypes = {
  home: PropTypes.bool,
  upgrade: PropTypes.bool,
}

export default withRouter(Premium)
