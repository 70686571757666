import actionTypes from '../../actions/forms/personalInfo/actionTypes'

const initialState = {
  error: null,
  isPersonalInfoUpdated: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_ERROR:
      return {
        ...state,
        error: null,
      }

    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actionTypes.TOGGLE_FORM_STATUS:
      return {
        ...state,
        isPersonalInfoUpdated: action.processed,
      }

    default:
      return state
  }
}
