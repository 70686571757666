import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import './App.css'
import Admin from './containers/Admin'
import AdminCompose from './containers/Admin/adminCompose'
import Blog from './containers/Blog'
import CreateAccount from './containers/CreateAccount'
import Courses from './containers/Courses'
import EmailUnsubscribe from './containers/EmailUnsubscribe'
import ErrorBoundary from './components/errorBoundary/errorBoundary'
// import Extra from './staticPages/Extra'
import Footer from './components/Footer/Footer'
import ForgottenPassword from './containers/ForgottenPassword'
import MyContent from './containers/MyContent'
import Header from './components/Header'
import LandingPage from './staticPages/LandingPage'
import Library from './containers/Library'
import MySubscription from './containers/MySubscription'
import Newsletter from './containers/Newsletter'
// import EmailAlerts from './containers/Form/ChangePreferences'
import SampleDownload from './containers/SampleDownload'
import SignIn from './containers/SignIn'
import PageNotFound from './components/pageNotFound'
import ResetPassword from './containers/ResetPassword'
import TermsOfUse from './staticPages/TermsOfUse'
import PrivacyPolicy from './staticPages/UpdatedPP'
import SampleNewsletter from './staticPages/SampleNewsletter'
import HowItWorks from './staticPages/HowItWorks'
import Links from './staticPages/Links'
import About from './staticPages/About'
import InformationInefficiencies from './staticPages/InformationInefficiencies'
import PrivateRoute from './components/privateRoute'
import AdminRoute from './components/adminRoute'
import VerifyEmail from './containers/VerifyEmail'
import MyAccount from './containers/MyAccount'
import ThemeContainer from './containers/ThemeContainer'
import ViewArticleContainer from './containers/ViewArticleContainer'

class App extends Component {
  static propTypes = {
    token: PropTypes.string,
    account: PropTypes.string,
  }

  render() {
    const Token = this.props.token
    const Account = this.props.account
    const AppLinks = [
      // -- PAGES
      {private: 0, path: '/article/:id/:title', component: ViewArticleContainer},
      {private: 0, path: '/library/:title?', component: Library},
      {private: 0, path: '/blog/:title?', component: Blog},
      {private: 0, path: '/newsletter', component: Newsletter},
      {private: 0, path: '/courses', component: Courses},
      {private: 0, path: '/links', component: Links},
      // {private: 0, path: '/email-alerts', component: EmailAlerts},
      // {private: 0, path: '/extra', component: Extra},
      {private: 0, path: '/negotiating-salary', component: SampleDownload},
      {private: 0, path: '/sample-newsletter', component: SampleNewsletter},

      // -- ACCOUNT:
      {private: 0, path: '/signin', component: SignIn},
      {private: 0, path: '/signin/:redirect', component: SignIn},
      {private: 0, path: '/signup', component: CreateAccount},
      {private: 0, path: '/forgottenPassword', component: ForgottenPassword},
      {private: 0, path: '/resetPassword/:token', component: ResetPassword},
      {private: 0, path: '/verify/:id', component: VerifyEmail},
      {
        private: 0,
        path: '/unsubscribe/:type/:id/:email',
        component: EmailUnsubscribe,
      },
      // -- NEW ACCOUNT
      {private: 1, path: '/myDetails', component: MyAccount},
      {private: 1, path: '/myArticles', component: MyAccount},
      {private: 1, path: '/editArticle', component: MyAccount},
      {private: 1, path: '/mySubscription', component: MyAccount},
      {private: 1, path: '/myPreferences', component: MyAccount},
      {private: 1, path: '/submit', component: MyAccount},
      // {private: 0, path: '/browse/:category?', component: ThemeContainer},
      {private: 0, path: '/feed', component: ThemeContainer},
      {private: 1, path: '/howToUse', component: MyAccount},
      // -- ADMIN
      {private: 1, admin: 1, path: '/2019admin', component: Admin},
      {private: 1, admin: 1, path: '/2019adminCompose', component: AdminCompose},
      // -- Info
      {private: 0, path: '/termsOfUse', component: TermsOfUse},
      {private: 0, path: '/privacyPolicy', component: PrivacyPolicy},
      {private: 0, path: '/about', component: About},
      {private: 0, path: '/subscription', component: MySubscription},
      {private: 0, path: '/guides/:title?', component: MyContent},
      {private: 0, path: '/how-it-works', component: HowItWorks},
      {
        private: 0,
        path: '/information-inefficiencies',
        component: InformationInefficiencies,
      },
    ]

    return (
      <Router>
        <div id='app'>
          <Route path='/' component={Header} />
          <ErrorBoundary>
            <Switch>
              <Route
                token={Token}
                exact
                path='/'
                component={Token ? MyAccount : LandingPage}
              />
              {AppLinks.map((l, idx) => {
                return l.admin ? (
                  <AdminRoute
                    token={Token}
                    exact
                    path={l.path}
                    component={l.component}
                    key={idx}
                    account={Account}
                  />
                ) : l.private ? (
                  <PrivateRoute
                    token={Token}
                    exact
                    path={l.path}
                    component={l.component}
                    key={idx}
                  />
                ) : (
                  <Route exact path={l.path} component={l.component} key={idx} />
                )
              })}
              <PrivateRoute token={Token} component={PageNotFound} />
            </Switch>
          </ErrorBoundary>
          <Route path='/' component={Footer} />
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  account: state.user.subscription.type,
})

export default connect(mapStateToProps, null)(App)
