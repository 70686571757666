import React from 'react'
import {Box, Flex, Image} from 'rebass'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Button from '../components/FormElements/Button'
import Publications from './Publications2.gif'

const StyledLink = styled(Link)`
  width: 100%;
`

const LandingFeed = () => {
  return (
    <Container home background='#e8eef4'>
      <HeadingNew size='h1' as='h1'>
        Knowledge Feed
      </HeadingNew>
      <HeadingNew size='h3' as='h3'>
        Summaries of cutting-edge research, interesting blog posts and articles from
        leading publications
      </HeadingNew>
      <Flex
        width={1}
        justifyContent='space-between'
        mt={['25px', '40px']}
        mb={['25px', '20px']}
      >
        <Image
          src={Publications}
          width='100%'
          height='100%'
          mb={[0, 2]}
          mt={2}
          ml={0}
          style={{
            float: 'left',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
        />
      </Flex>
      <Flex width={1} justifyContent='center' mt={['10px', '25px']}>
        <Box width={[1 / 2, 1 / 3]}>
          <StyledLink to='/feed'>
            <Button onClick={() => console.log('')} fullWidth>
              Browse now
            </Button>
          </StyledLink>
        </Box>
      </Flex>
    </Container>
  )
}

export default LandingFeed
