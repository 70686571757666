import React, {useEffect} from 'react'
import {Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import BlogAdvert from './blogAdvert'
import SideAdvert from './sideAdvert'
import {
  SubHeading,
  Quote,
  QuoteHeading,
  SidePanel,
  NewParagraph,
  NumberedPoint,
  StyledLink,
  StyledLi,
  StyledSpan,
  Source,
} from './styles'

const Communication = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      width={1}
      flexDirection='column'
      style={{maxWidth: '700px'}}
      px='20px'
      pb='50px'
    >
      <HeadingNew size='h1' palette='medium' as='h1'>
        How to give a speech
      </HeadingNew>
      <HeadingNew size='h3' palette='medium' as='h3' style={{textAlign: 'justify'}}>
        <Quote>
          <QuoteHeading>
            ‘The ability to speak is a shortcut to distinction. It puts a person in
            the limelight, raises one head and shoulders above the crowd. And the
            person who can speak acceptably is usually given credit for an ability
            out of all proportion to what he or she really possesses.’
          </QuoteHeading>
          <br />- Lowell Thomas, writer and broadcaster
        </Quote>
        <BlogAdvert tag='communication' />
        Whether as part of a sales pitch or toasting a relative at a family
        celebration, public speaking fills many of us with dread. While practice is
        typically the best way to become an accomplished speaker, you can use the
        approaches and techniques detailed in this section to help you streamline
        your content, enhance your delivery, reduce your anxieties and improve the
        end result.
        <SubHeading>CONTENT</SubHeading>
        Your aim as a speaker should be to convey something that is useful,
        entertaining and / or interesting for your audience. Briar Goldberg, director
        of speaker coaching at conference organiser TED, recommends<sup>1</sup>{' '}
        adopting an ‘audience before content’ mentality and thinking though questions
        such as:
        <StyledLi>
          <StyledSpan>
            Why are these people taking time out of their busy schedules to listen to
            me speak?
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            What do they hope (or need) to gain from this presentation?
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>What are their expectations coming in?</StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            What can I say in order to meet or exceed those expectations?
          </StyledSpan>
        </StyledLi>
        As Goldberg points out, you can enhance your understanding of the
        expectations and needs of your audience by talking to some of them in advance
        or asking event organisers about the profile of attendees and the way that
        the event has been marketed.
        <NewParagraph />
        Journalist and TED curator Chris Anderson has observed thousands of talks
        from many of the world's leading public speakers. While all are unique in
        some way, he argues<sup>2</sup> that the most effective talks tend to share
        the following four features:
        <NumberedPoint>
          1. <b>Focus on one major idea.</b> In most talks you can only really
          provide the context and examples necessary to properly explain a single
          idea (in any case, audience members are unlikely to retain more than a
          small number of takeaways). All elements of a talk (including jokes,
          stories or statistics) should relate back to this. It can be tempting to
          try to convey everything that you know about a subject but doing so will
          simply overwhelm your audience.
        </NumberedPoint>
        <NumberedPoint>
          2. <b>Give listeners a reason to care.</b> Before you can convey your idea,
          you need to win permission from the audience to do so. The best way to do
          this is to stir curiosity. Anderson suggests using ‘intriguing, provocative
          questions to identify why something doesn’t make sense and needs
          explaining.' As he notes, 'if you can reveal a disconnection in someone’s
          world-view they’ll feel the need to bridge that knowledge gap and once
          you’ve sparked that desire it will be so much easier to start building your
          idea'.
        </NumberedPoint>
        <NumberedPoint>
          3.{' '}
          <b>
            Build the idea, piece by piece, using concepts that the audience already
            understands.
          </b>{' '}
          Many speakers forget that the terms and concepts they use in their
          day-to-day life are entirely unfamiliar to others. Vivid metaphors can be
          useful for explaining new or complex ideas, while you should test talks
          with trusted friends to identify anything that may be confusing.
        </NumberedPoint>
        <NumberedPoint>
          4. <b>Convey an idea worth sharing.</b> If the idea that you are discussing
          only benefits you or your organisation, the audience is unlikely to engage.
          Anderson suggests that presenting information as generally applicable
          takeaways (e.g., ‘what we have learned’) rather than a description of
          success (e.g., ‘look at how successful we have been’) will make the
          audience more receptive. Ideas that have the potential to benefit others,
          change their perspective or inspire them to do something will almost
          certainly capture attention.
        </NumberedPoint>
        <NewParagraph />
        During any speech, audience members may be unable or unwilling to ask
        questions, so you should make it as easy as possible for them to
        follow-along. For example, you can introduce your speech with a short
        overview of what you will cover, include regular recaps and end with a clear
        summary of the key points that you discussed. Many speeches will be recorded
        (perhaps covertly) and can be easily shared, so you should also consider the
        needs of audiences beyond those physically present (and be wary of the risk
        of anything you say being taken out of context).
        <NewParagraph />
        <SidePanel>
          <SubHeading sp>ANTICIPATING TECHNICAL ISSUES</SubHeading>
          To reduce the chances of encountering a technical glitch during your talk:
          <StyledLi sp>
            <StyledSpan>
              Check that all relevant equipment works ahead of the speech.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Run through the presentation in its entirety and bring a back-up copy
              in case the organiser’s computer malfunctions.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Turn off the projector if it is not being used to ensure that it will
              not display anything irrelevant or distracting.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Consider showing a video rather than giving a live demonstration (this
              will reduce the number of things that can go wrong).
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Test the microphone and ensure that you are standing at an appropriate
              distance from it. If wearing a microphone, make sure to turn it off and
              remove it once you have finished speaking.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Consider using a second screen, separate from the computer displaying
              your presentation, to show notes or other useful information.
            </StyledSpan>
          </StyledLi>
          If glitches do occur, realise that most audiences will be understanding and
          make sure not to panic.
        </SidePanel>
        Effective speeches typically incorporate a strong ending that makes it clear
        that you have finished<sup>a</sup>. British politician and noted orator
        William Hague argues<sup>3</sup> that an effective ending summarises the
        speech without repeating too much of what has already been covered (e.g.,
        with a new anecdote) and works as a stand-alone component that you can jump
        to directly if you run out of time. A 30 second ‘elevator pitch’ that
        concisely covers your key points is ideal and can also be useful when
        answering questions. Moreover, as Carmine Gallo notes in ‘The Presentation
        Secrets of Steve Jobs’<sup>4</sup>, your audience (including any members of
        the press) will be more likely to accurately relay (or respond to) what you
        tell them if you provide a short and memorable takeaway or call to action
        <sup>b</sup>. Professor Patrick Henry Winston of Massachusetts Institute of
        Technology (MIT) advises<sup>5</sup> against ending a speech by thanking the
        audience for listening, as this suggests that they did so out of politeness
        and underplays the value that you have (in theory) delivered.
        <NewParagraph />
        If you have any influence over the venue in which you are speaking, you
        should ensure that it is well-lit and cool (to keep your audience alert) and
        of an appropriate size, so that it feels neither overcrowded nor
        under-attended (which has negative connotations). Similarly, it is best to
        avoid speaking slots that are very early, very late or either side of lunch,
        when attendance tends to be lower and the audience is less likely to be alert
        and focused.
        <SideAdvert />
        <SubHeading>DELIVERY</SubHeading>
        <Quote>
          <QuoteHeading>
            ‘It’s not what you say that matters but the manner in which you say it;
            there lies the secret of the ages.’
          </QuoteHeading>
          <br /> - William Carlos Williams, Pulitzer-prize winning poet
        </Quote>
        If delivered poorly, even highly compelling content can be rendered
        ineffective, so it is vital that you practice your speech before giving it.
        You can start by practicing your delivery to an empty room and then, ideally,
        in front of people that are representative of the ultimate audience and in
        the same venue (or one similar) as the speech itself. While doing this, it
        can be useful to look for signs of engagement or boredom (e.g., nodding,
        smiling or yawning) that indicate which parts of the speech are resonating
        and which need to be changed.
        <NewParagraph />
        In the absence of a test audience, videoing your speech and reviewing it
        yourself represents the next best option (practicing in front of a mirror
        tends to be too distracting). Either approach should identify potential
        improvements and highlight issues relating to delivery and content, including
        distracting mannerisms or statements that are unclear or that do not
        translate well from written to spoken format.
        <NewParagraph />
        Developing a strong familiarity with your material will help you deal with
        questions and interruptions without losing your flow and enable you to spend
        less time looking at your notes and more time connecting with your audience.
        However, memorising speeches (or, worse, reading them from a script or
        teleprompter) can hinder your ability to improvise or adapt your content to
        reflect audience feedback, and may result in a less natural or even wooden
        delivery. Some of the most powerful speeches in history, including Martin
        Luther King’s ‘I have a dream’ refrain, have been wholly or partially
        improvised.
        <SidePanel>
          <SubHeading sp>USING NOTES</SubHeading>
          Entrepreneur Otegha Uwagba argues<sup>6</sup> that, while presenting from
          memory can look slick and polished, using notes during a speech can provide
          a welcome confidence boost, especially if you are nervous or have had
          little time to prepare. After all, looking at notes occasionally should not
          hamper your delivery or make you seem less credible and is preferable to
          getting muddled during the speech. She suggests using cue cards with
          prompts, key words or the starts of sentences to jog your memory (these
          should be bound together to keep them in order).
          <NewParagraph />
          In ‘The Presentation Secrets of Steve Jobs’ Carmine Gallo suggests that it
          is better to use presentation slides (instead of hand-held notes) as cues.
          If you do need to use notes, he recommends positioning them so that you can
          glance at them inconspicuously during the speech.
        </SidePanel>
        <NewParagraph />
        In ‘TED Talks’<sup>7</sup> Chris Anderson notes that even if your speech is
        ‘unscripted’, your preparation should leave you with a good idea of much of
        what you are going to say<sup>c</sup>. He suggests that your preparation
        should focus on perfecting your transitions so that you do not skip anything
        important and notes that you may find it useful to memorise bullet points
        that you can put into your own words when presenting.
        <NewParagraph />
        You should take care to maintain an appropriate tempo (slowing down when
        addressing non-expert or international audiences) and project your voice
        <sup>d</sup>. Particularly when nervous, many people have a tendency to speak
        too quickly and quietly. Deliberately varying your pace, volume and tone of
        voice (e.g., speeding-up to convey excitement or slowing-down to emphasise
        importance) can help to keep your audience engaged (few things deter this
        more effectively than a monotonous delivery).
        <NewParagraph />
        Many of us unknowingly use an upward inflection at the end of sentences
        (known as ‘uptalk’), which makes everything we say sound like a question.
        This can also make us seem less professional or even insecure, so is
        something to watch out for. Similarly, you should avoid using ‘filler’ noises
        like ‘um’ and ‘ah’, which can frustrate your audience and make you appear
        anxious, unprepared, uncertain of what you are saying or incompetent. You can
        reduce your tendency to use filler noises by practicing the speech, removing
        distractions, inhaling when you catch yourself using them and carefully
        planning transitions between topics or slides<sup>8</sup>.
        <NewParagraph />
        Communication during a speech is not entirely one way and you can use
        audience reaction or interaction to gauge how you are performing. If, for
        example, your jokes do not appear to be resonating, it is probably a good
        idea to omit them from the rest of the speech. Some speakers even place
        ‘spotters’ in the audience to provide real-time feedback on their delivery
        (e.g., relating to tempo and volume) and guidance on time constraints.
        <NewParagraph />
        Certain oratory techniques, when applied in moderation, can significantly
        enhance a speech. As communication expert Stephen Cohen notes<sup>9</sup>,
        ‘just as music can stir the emotions, language that appeals to the ear can
        lift people’s sights and spirits, inspiring them to do things that they would
        otherwise not’. In ‘You Talkin’ To Me?: Rhetoric from Aristotle to Obama’
        <sup>10</sup>, Sam Leith outlines a number of useful techniques, including:
        <StyledLi>
          <StyledSpan>
            <b>Anaphora / epistrophe</b>. Repetition of words or phrases at the
            beginning or end of successive statements, for example Martin Luther
            King’s repeated use of ‘I have a dream’.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            <b>Chiasmus</b>. Reversing the first half of a statement in the second
            half, for example John F. Kennedy’s ‘Ask not what your country can do for
            you – ask what you can do for your country’.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            <b>Hypophora</b>. Raising a question and then immediately providing an
            answer.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            <b>Tricolon</b>. Using three words or phrases that are linked to each
            other and delivered one after another in quick succession, for example
            Benjamin Franklin’s ‘Tell me and I forget. Teach me and I remember.
            Involve me and I learn’.
          </StyledSpan>
        </StyledLi>
        Deliberate pauses can also be used to enhance the impact of important
        statements.
        <NewParagraph />
        Expressing a vulnerability (even if this involves admitting that you are
        feeling nervous or overwhelmed) or telling a joke or anecdote near the
        beginning of a speech can help you connect with your audience and avoid
        appearing insincere or aloof. Stories are also a good way to engage people,
        help them relate to the subject being covered and remember your talk.
        Engaging stories tend to follow a standard ‘narrative arc’, which generally
        involves establishing a relatable character, introducing a challenge then
        describing how it was successfully resolved and the lesson that was learned.
        <NewParagraph />
        Incorporating regular interaction (e.g., by stimulating laughter or clapping
        or asking a question) should help to keep your audience engaged. Note however
        that humour should be used with care as a joke that flops (or, even worse,
        offends) will leave the audience feeling awkward and can be difficult to
        recover from.
        <NewParagraph />
        Above all, speakers deemed to be charismatic<sup>e</sup> are most likely to
        engage their audience and convey their message effectively. Charisma can be a
        difficult concept to define but may be thought of as ‘the ability to
        communicate a clear, visionary, and inspirational message that captivates and
        motivates an audience’<sup>11</sup>.
        <NewParagraph />
        Perhaps surprisingly, research
        <sup>12</sup> suggests that charisma is not entirely innate and can be
        enhanced through the following verbal and non-verbal tactics:
        <StyledLi>
          <StyledSpan>
            Using metaphors, similes and analogies to enable the audience to
            understand, relate to and remember the message.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Using stories and anecdotes to connect with the audience and make the
            message more engaging.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Demonstrating credibility and alignment with the audience by articulating
            moral convictions and shared sentiments.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Setting ambitious goals and conveying confidence that they can be
            achieved.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>Using contrasts, which clarify the message.</StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Three-part lists, which are easy to remember, suggest a pattern and
            convey a sense of completeness.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Rhetorical questions, which arouse anticipation and boost engagement.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Using body language (e.g., expressive hand gestures), facial expressions
            and an animated voice (varying tone and volume or using pauses, which
            convey control) to demonstrate emotion and passion.
          </StyledSpan>
        </StyledLi>
        Creating a sense of urgency, invoking history, discussing sacrifices and
        using humour can, to a lesser extent, also enhance charisma in some contexts.
        <NewParagraph />
        Research<sup>13</sup> also suggests that you can boost your influence and
        perceived prestige by lowering the pitch of your voice. This technique is
        particularly useful if you are nervous, as stress tightens your vocal cords
        and, in doing so, increases your pitch. Before speaking, you may also find it
        helpful to ‘wake-up’ your voice by reciting ‘tongue-twisters’ or taking a
        short walk. Similarly, British politician William Hague notes that
        maintaining physical fitness is useful as it enables you to deliver long
        sentences without breaking for breath. It is also important to stay well
        hydrated, so you should arrange to have a bottle of water within easy reach
        during any talk
        <sup>f</sup>.
        <NewParagraph />
        During a speech, your non-verbal communication strongly influences how you
        are perceived and, in some cases, how you feel. Ideally, you should:
        <StyledLi>
          <StyledSpan>
            Smile and consciously maintain positive body language, using natural hand
            gestures and facial expressions (smiling is also naturally calming) that
            convey confidence, passion or excitement for the subject matter and
            appreciation for the opportunity to speak.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Avoid standing behind a podium, which creates a barrier between you and
            the audience.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Maintain regular eye-contact with your audience, making sure to turn your
            gaze to different sections and avoid focusing on the same person
            repeatedly.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Organise a timer that you can check discreetly. This allows you to keep
            track of time without constantly checking your watch.
          </StyledSpan>
        </StyledLi>
        Particularly in large venues, you may also need to exaggerate your gestures
        and facial expressions to make them clear for everyone present.
        <NewParagraph />
        <SidePanel>
          <SubHeading sp>WHAT TO WEAR</SubHeading>
          You should dress in smart, comfortable clothes that make you feel confident
          but are not distracting. In ‘TED Talks’ Chris Anderson recommends dressing
          in a similar manner to your audience but slightly smarter and wearing
          something bright that can be seen from the back of the venue. He also
          recommends wearing a belt so that you have something to clip a microphone
          to, avoiding noisy jewellery and practicing in the clothes you plan to wear
          to spot any potential issues.
        </SidePanel>
        For many of us, public speaking can be a source of considerable anxiety that
        can jeopardise our performance. This is often exacerbated by our natural
        tendency to overestimate how much other people are paying attention to us and
        how harshly they judge our mistakes (psychologists refer to this as the
        ‘spotlight effect’). Research suggests that rituals (i.e., predefined
        sequences of symbolic actions) can reduce our anxiety and increase our focus
        before we speak<sup>14</sup>, perhaps because they help us feel disciplined
        and focused. Reframing the speech as a conversation and trying to stay
        focused on the present moment (i.e., not fixating on the future implications)
        or the reason why you are presenting may also help<sup>15</sup>. Moreover,
        ‘self-distancing’ techniques, such as assessing your emotions in the third
        person (e.g., ‘Bob feels anxious’ rather than ‘I feel anxious’) have been
        shown to diminish anxiety associated with public speaking and improve
        performance<sup>16</sup>. As an extreme form of self-distancing, many
        performers adopt an on-stage alter ego, which research indicates may actually
        be beneficial<sup>17</sup>. Simple breathing exercises (e.g., three cycles of
        breathing-in for three seconds, then out for three seconds) can counter our
        tendency to take shallow breaths when anxious and help us calm down, while
        reframing anxiety as excitement may have a similar effect<sup>g</sup>. Some
        people also find it helpful to identify some friendly faces in the audience
        and focus on these as they speak, while having a script to hand as a back-up
        plan may provide additional reassurance.
        <SubHeading>PRESENTATIONS</SubHeading>
        An accompanying presentation can enhance a speech but is not always
        desirable. For instance, a pre-prepared presentation defines a structure that
        can be difficult to deviate from, representing an unhelpful restriction in
        some situations.
        <NewParagraph />
        When designing a presentation, we can avoid a number of common mistakes by:
        <StyledLi>
          <StyledSpan>
            Using a slide template to maintain consistency of alignment, colour
            scheme and font.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Including slide numbers to make the presentation easier for audience
            members to reference.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Ensuring that all text (including labels on charts) is legible. To do so,
            we should use a font that is simple and clear, select a text colour that
            has a strong contrast with the background, and avoid underlining or
            italicising, which can make words harder to read.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Maintaining a margin on each slide, which improves the look of the
            presentation and mitigates the cropping applied by some projectors or
            screens.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Altering content to reflect the specific screen or venue size.
          </StyledSpan>
        </StyledLi>
        In ‘TED Talks’ Chris Anderson suggests that presentations should be designed
        with a 16:9 aspect ratio (which will fit with most screens or projectors) and
        warns against using templates provided by presentation software, as the
        result will feel generic.
        <NewParagraph />
        In addition, Oxford University careers adviser Jonathan Black offers
        <sup>18</sup> the following advice for crafting an effective presentation:
        <StyledLi>
          <StyledSpan>
            Tell people what you are going to tell them (using an agenda slide), tell
            them (the body of the presentation) then tell them what you told them
            (with a summary slide).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Content should follow a logical progression, with each slide conveying
            one main idea and used for at least one minute.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Slide backgrounds should be simple and text limited (ideally highlighting
            key statistics or numbers, which may be missed or misheard).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Use graphics to show content that would be too difficult or time
            consuming to describe (e.g., maps or photographs) and avoid distracting
            animations.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Explain what charts or graphics show before discussing why this is
            important.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Help audience members understand where in the presentation they are. For
            instance, display a progress tracker or signal how many slides will be
            reviewed or how long the presentation will last at the beginning.
          </StyledSpan>
        </StyledLi>
        Black also notes that audiences do not always consume all of the content
        provided and recommends that we design our presentations to make it as easy
        as possible for them to get the main gist. A good way to achieve this is to
        follow a structure that provides what Barbara Minto<sup>h</sup> describes as
        ‘horizontal’ and ‘vertical’ logic: each slide should have a (non-generic)
        headline that conveys its key point (so the headlines alone combine to form a
        logical argument), with content below each conveying why it is important and
        providing supporting evidence. Each slide should also be easy to understand
        without further explanation.
        <SidePanel>
          <SubHeading sp>THE 10/20/30 RULE</SubHeading>
          Author and investor Guy Kawasaki suggests<sup>19</sup> that you follow what
          he calls the ‘10/20/30 rule’ and ensure that presentations comprise ten
          slides, last no more than 20 minutes and use a font size no smaller than 30
          points (thus limiting the amount of information on each slide). This
          approach forces you to include only the most salient information and
          develop a solid understanding of your material (as you cannot rely solely
          on the slide content). As he points out, if your audience realises that you
          are simply reading out the text on your slides, they will start reading
          themselves and everyone will end up out of sync. The rule also encompasses
          Kawasaki’s suggestion that you should assume that you have less time than
          you have officially been allocated. Late arrivals and technical issues
          often cause delays, while strictly limiting the length of the presentation
          helps to save time for discussion and questions, which are typically far
          more valuable.
        </SidePanel>
        <NewParagraph />
        Similarly, Professor Henry Winston of MIT notes that most people are
        incapable of reading and listening concurrently (the human brain contains
        only one language processor) so are likely to stop listening to a speaker if
        slides contain too much information. Slides should therefore be kept simple
        and, when they do include important information, we should pause to give the
        audience time to read them.
        <NewParagraph />
        Your audience is less likely to become distracted while trying to take notes
        if you provide an email address through which they can request a copy of your
        presentation (this is also a good way to expand your network). Alternatively,
        you can prepare a printed copy or summary of the presentation (or further
        information on something that you have covered) and distribute it once you
        have finished speaking.
        <NewParagraph />
        Winston further suggests that you should be careful when interacting with
        your presentation. For example, if you stand on the opposite side of a stage
        to the screen you will force the audience to move their gaze back and forth
        between yourself and the presentation. Moreover, engaging with or pointing to
        a presentation (e.g., using a laser pointer) can result in long periods in
        which the audience is looking at the back of your head, diminishing your
        connection and making it harder to hear what you say. If you do want to draw
        attention to specific elements on your slides, you should instead highlight
        these with colourful borders or arrows.
        <NewParagraph />
        Finally, Winston highlights the importance of using your last slide wisely.
        This may be displayed for a long period so simply including ‘thank you’ or
        ‘questions’ represents a wasted opportunity to emphasise your key message
        (and provide your contact details, if relevant).
        <NewParagraph />
        Interesting or unexpected props, demonstrations or surprises can make a
        presentation more engaging and memorable. For example, Microsoft founder Bill
        Gates generated significant amounts of publicity when he released live
        mosquitos into the auditorium during a TED talk<sup>20</sup> on malaria.
        There is, however, a risk that such stunts backfire if you are not careful.
        <NewParagraph />
        In ‘The Presentation Secrets of Steve Jobs’ Carmine Gallo suggests that you
        plan to incorporate videos or demonstrations after around 10 minutes of
        speaking, which is the point at which most people in an audience start to
        lose concentration (TED talks are deliberately capped at 18 minutes for this
        reason). Such ‘breaks’ can be an effective way to reengage the audience. To
        add additional variety or increase the credibility of your message, it can
        sometimes be helpful to involve other people, such as an expert or customer,
        who can join onstage or via a live video call.
      </HeadingNew>

      <Flex width={1} flexDirection='column' mt='10px'>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          style={{textAlign: 'justify'}}
        >
          <SubHeading>Footnotes</SubHeading>
          <Source>
            a. A failure to do so can lead to awkward situations in which the
            audience is uncertain about whether they should continue listening or
            clap.
          </Source>
          <Source>
            b. Conveying a time constraint (e.g., ‘sign-up by the end of today to
            make a difference’) is likely to make any call to action more effective.
          </Source>
          <Source>
            c. Some speakers opt to define and memorise just the beginning and ending
            of their speech, given that these are the most important elements.
          </Source>
          <Source>
            d. Consider checking with the audience that they can hear you at the
            beginning of the speech.
          </Source>
          <Source>
            e. Note that charisma can be dangerous, for instance if it is used to
            manipulate opinion or hide incompetence.
          </Source>
          <Source>
            f. While alcohol may help to calm any nerves, it is dehydrating and may
            hamper your ability to answer questions effectively.
          </Source>
          <Source>g. See the Sivv Guide to Wellbeing for more on this.</Source>
          <Source>
            h. See our review of{' '}
            <StyledLink to='/library/The-Pyramid-Principle'>
              The Pyramid Principle
            </StyledLink>
            .
          </Source>
        </HeadingNew>
      </Flex>
      <Flex width={1} flexDirection='column' mt='10px'>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          style={{textAlign: 'justify'}}
        >
          <SubHeading>Sources</SubHeading>
          <Source>
            1. Goldber, B (2019), Before your next presentation or speech, here’s the
            first thing you must think about, Ted.com
          </Source>
          <Source>
            2. Anderson, C (2016), TED's secret to great public speaking,
            ideas.ted.com
          </Source>
          <Source>
            3. Burton, C (2018), GQ How to Win at Life: The expert guide to excelling
            at everything you do, Firefly Books
          </Source>
          <Source>
            4. Gallo, C (2009), The Presentation Secrets of Steve Jobs: How to Be
            Insanely Great in Front of Any Audience, McGraw-Hill Education
          </Source>
          <Source>5. Winston, P (2018), How to Speak , mit.edu</Source>
          <Source>6. Uwagba, O (2017), Little Black Book, Fourth Estate</Source>
          <Source>
            7. Anderson, C (2018), TED Talks: The official TED guide to public
            speaking, Hodder and Stoughton
          </Source>
          <Source>
            8. McKay, B and McKay, K (2012), Becoming Well-Spoken: How to Minimize
            Your Uh’s and Um’s, artofmanliness.com
          </Source>
          <Source>9. Clegg, A (2013), The subtle secrets of charisma, ft.com</Source>
          <Source>
            10. Leith, S (2018), You Talkin’ To Me?: Rhetoric from Aristotle to
            Obama, Profile Books
          </Source>
          <Source>
            11. Antonakis, J, Fenley, M and Liechti, S (2012), Learning Charisma,
            hbr.org
          </Source>
          <Source>
            12. Antonakis, J, Fenley, M and Liechti, S (2011), Can Charisma Be
            Taught? Tests of Two Interventions, Academy of Management Learning &
            Education, 2011, Vol. 10, No. 3, 374–396.
            http://dx.doi.org/10.5465/amle.2010.0012 (reported in Kinni, T (2019),
            Becoming your most charismatic self, strategy-business.com)
          </Source>
          <Source>
            13. Cheng, JT et al (2016), Listen, follow me: Dynamic vocal signals of
            dominance predict emergent social rank in humans. Journal of Experimental
            Psychology: General, 145(5), 1-12 https://doi.org/10.1037/xge0000166
          </Source>
          <Source>
            14. Wood Brooks, A et al (2016), Don’t stop believing: Rituals improve
            performance by decreasing anxiety, Organizational Behavior and Human
            Decision Processes 137 (2016) 71-85
          </Source>
          <Source>
            15. Abrahams, M (2014), Think Fast, Talk Smart: Communication Techniques,
            youtube.com
          </Source>
          <Source>
            16. Kross, E et al (2014), Self-talk as a regulatory mechanism: how you
            do it matters, J Pers Soc Psychol 2014 Feb;106(2):304-24, DOI:
            10.1037/a0035173
          </Source>
          <Source>
            17. Robson, D (2020), The ‘Batman Effect’: How having an alter ego
            empowers you, bbc.com
          </Source>
          <Source>
            18. Black, J and Leith, S (2019), How to present like a professional,
            ft.com
          </Source>
          <Source>
            19. Kawasaki, G (2004), The 10/20/30 Rule of PowerPoint, guykawasaki.com
          </Source>
          <Source>
            20. Gates, B (2009), Mosquitos, Malaria and Education, TED.com
          </Source>
        </HeadingNew>
      </Flex>
    </Flex>
  )
}

export default Communication
