import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import {Flex} from 'rebass'
import logo from '../../images/sivv_logo_white.png'
import Instagram from './instagram.png'

import {primary} from '../../styles/fonts'

const StyledLink = styled(Link)`
  color: #fff;
  font-family: ${primary};
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  margin-right: 15px;
  transition: all 0.1s ease-in;
  &:hover {
    /* text-decoration: underline; */
    color: #61bfbf;
  }
  ${(props) =>
    props.active &&
    css`
      color: #61bfbf;
    `}
`
const StyledMailto = styled.a`
  color: #fff;
  font-family: ${primary};
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  transition: all 0.1s ease-in;
  &:hover {
    color: #61bfbf;
  }
`

const Footer = ({location, token}) => {
  const isAuthenticated = !!token

  const allLinks = [
    {
      label: 'About',
      link: '/about',
      restricted: false,
    },
    // {
    //   label: 'Browse',
    //   link: '/browse',
    //   restricted: true,
    // },
    {
      label: 'Feed',
      link: '/feed',
      restricted: false,
    },
    // {
    //   label: 'Sign In',
    //   link: '/signin',
    //   restricted: false,
    // },
    // {
    //   label: 'Sign Up',
    //   link: '/subscription',
    //   restricted: false,
    // },
    {
      label: 'Guides',
      link: '/guides',
      restricted: false,
    },
    {
      label: 'Library',
      link: '/library',
      restricted: false,
    },
    {
      label: 'Blog',
      link: '/blog',
      restricted: false,
    },
    {
      label: 'Compose',
      link: '/composeArticle',
      restricted: true,
    },
    {
      label: 'My Account',
      link: '/myDetails',
      restricted: true,
    },
  ]

  const legalLinks = [
    {
      label: 'Terms of use',
      link: '/termsOfUse',
      restricted: false,
    },
    {
      label: 'Privacy policy',
      link: '/privacyPolicy',
      restricted: false,
    },
  ]

  const links = isAuthenticated
    ? allLinks.filter((link) => link.restricted)
    : allLinks.filter((link) => !link.restricted)

  // if (token || window.location.pathname === '/browse') {
  //   return null
  // }

  return (
    <footer>
      <Flex
        width={1}
        style={{background: '#5e7d8c'}}
        flexDirection='column'
        pb='25px'
      >
        <Flex
          width={1}
          mt={3}
          p={3}
          flexDirection={['column', 'column', 'row']}
          alignItems='center'
        >
          <Flex
            justifyContent={['space-around', 'space-around', 'center']}
            p={[3, 3, 1]}
            width={[1, 1, 1 / 3]}
          >
            {links.map((linkItem, idLink) => {
              const {link, label} = linkItem
              return (
                <StyledLink
                  key={idLink}
                  data-testid='header-link'
                  to={link}
                  active={location.pathname === link ? 1 : 0}
                >
                  {label}
                </StyledLink>
              )
            })}
          </Flex>
          <Flex
            justifyContent='center'
            alignItems='center'
            p={[3, 3, 1]}
            width={[1, 1, 1 / 3]}
            style={{color: '#fff'}}
          >
            <StyledMailto
              href='mailto:info@sivv.io?subject=Hello Sivv'
              style={{marginRight: '10px'}}
            >
              info@sivv.io
            </StyledMailto>
            |
            <a href='https://www.instagram.com/sivv_insights/' target='null'>
              <img
                src={Instagram}
                width='15px'
                style={{marginLeft: '10px'}}
                alt='Follow Sivv on Instagram'
              />
            </a>
          </Flex>
          <Flex justifyContent='center' p={[3, 3, 1]} width={[1, 1, 1 / 3]}>
            {legalLinks.map((linkItem, idLink) => {
              const {link, label} = linkItem
              return (
                <StyledLink
                  key={idLink}
                  data-testid='header-link'
                  to={link}
                  active={location.pathname === link ? 1 : 0}
                >
                  {label}
                </StyledLink>
              )
            })}
          </Flex>
        </Flex>
        <Flex
          width={1}
          flexDirection='column'
          alignItems='center'
          mb='25px'
          mt='10px'
        >
          <img src={logo} width='150px' alt='Sivv logo' />
          <span
            style={{
              color: '#fff',
              fontSize: '12px',
              fontFamily: {primary},
              fontStyle: 'italic',
              marginTop: '10px',
            }}
          >
            Get smarter, faster
          </span>
        </Flex>
        <Flex justifyContent='center' p={2} width={1}>
          <span style={{color: '#fff', fontSize: '10px'}}>
            © {new Date().getYear() + 1900} Sivv. All rights reserved.
          </span>
        </Flex>
      </Flex>
    </footer>
  )
}

Footer.propTypes = {
  location: PropTypes.object.isRequired,
  token: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
  }
}

export default withRouter(connect(mapStateToProps, null)(Footer))
