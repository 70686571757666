import React from 'react'
import {Box, Flex, Image} from 'rebass'
import EmailCapture from '../EmailCapture'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Icons from '../../images/icons/icons.js'

const Options = {
  '/negotiating-salary': {
    category: 'salary',
    title: 'Negotiating salary',
    image: 'CareerGuide',
    text: <HeadingNew size='h3' palette='medium' as='h3'>
        This guide provides an overview of the world's best tips and advice on
        negotiating a salary increase.
    </HeadingNew>
  }
}

const Download = window.location.pathname

const SampleDownload = () => {
  return (
    <Flex
      width={1}
      py={['50px', '75px', '100px']}
      px={['15px', '75px']}
      justifyContent='center'
      alignItems='center'
      flexDirection={['column']}
      mb={['15px', 0]}
    >
      <Box width={1} style={{maxWidth: '900px'}}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          The Sivv Guide to {Options[Download].title}
        </HeadingNew>
      </Box>
      <Box width={1} style={{maxWidth: '900px'}} mt={['10px', '25px']}>
        <HeadingNew size='h3' palette='medium' as='h3'>
          Sivv summarises actionable insights from leading books, articles and
          research to help you get smarter, faster.
        </HeadingNew>
      </Box>
      <Flex
        width={[1, 1, 3 / 4]}
        justifyContent='center'
        my={['25px', '50px']}
      >
        <Image
          src={Icons[Options[Download].image]}
          width='50%'
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            maxWidth: '300px'
          }}
        />
      </Flex>
      <Box width={1} style={{maxWidth: '900px'}} mt={['10px', '25px']}>
        {Options[Download].text}
      </Box>
      <Flex width={1} flexDirection={['column', 'column', 'row']} style={{maxWidth: '700px'}}>
        <EmailCapture category={Options[Download].category} />
      </Flex>
    </Flex>
  )
}

export default SampleDownload
