const config = {
  target: 'https://www.sivv.io',
  stripe_key: 'pk_live_7QpWYR0YPJZsWjIV8URZo0V200wFgo2bFW',
  // stripe_key: 'pk_test_RlIYGF5nTWlRq7u58mbygtfC00nm5wCG5R',

  appVersion: 3.5,
  // turns logger on (development) or off
  deployment: 'production',
}

// target: 'http://localhost:3001' or 'https://www.sivv.io'
// const stripePromise = loadStripe('pk_live_7QpWYR0YPJZsWjIV8URZo0V200wFgo2bFW')
// const stripePromise = loadStripe('pk_test_RlIYGF5nTWlRq7u58mbygtfC00nm5wCG5R')

export default config
