import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Box, Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Loader from '../../components/FormElements/Loader'
import LatestArticles from '../LatestArticles'
import {
  getArticle,
  viewEmailForm,
  emailArticle,
  captureEmail,
  resendArticle,
  viewComments,
  hideComments,
  captureComment,
  submitComment,
  flagArticle,
  viewLikes,
} from '../../actions/searchActions'
import {articleEngagement} from '../../actions/articleActions'
import Article from '../../components/Article/Article'
import LandingLibrary from '../../staticPages/LandingLibrary'
import LandingGuides from '../../staticPages/LandingGuides'
import Modal from '../../components/FormElements/Modal'
import Newsletter from '../Newsletter'

const ViewArticleContainer = ({
  article,
  user,
  match,
  history,
  viewEmailForm1,
  emailTarget,
  articleEmailed,
  articleEmailError,
  comments,
  comment,
  likes,
  articleEngagement,
  getArticle,
  viewEmailForm,
  emailArticle,
  captureEmail,
  resendArticle,
  viewComments,
  hideComments,
  captureComment,
  submitComment,
  flagArticle,
  viewLikes,
}) => {


  const [showModal, hideModal] = useState(false)

  const closeModal = () => {
    hideModal(false)
  }

  useEffect(() => {
    getArticle(match.params.id, user._id)
    window.scrollTo(0, 0)
    setTimeout(() => {
      hideModal(true)
    }, 5000)
  }, [])

  const viewProfile = (id, firstName, lastName) => {
    history.push(`/profile/${id}/${firstName}-${lastName}`)
  }

  const expandArticle = (id) => {
    history.push(`/signup`)
  }

  let metaTitle = 'Sivv Article'
  let metaDescription = 'Sivv Article'

  if (article.error) {
    history.push(`/feed`)
  }

  

  if (article) {
    metaTitle = article.title
    metaDescription = article.digest
  }

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      style={{minHeight: 'calc(100vh - 150px)'}}
    >
      {article.searching && <Loader />}
      <Box pt='25px' px={['15px', '50px']}>
        {article.title && (
          <Article
            data={article}
            user={user}
            articleEngagement={articleEngagement}
            viewEmailForm={viewEmailForm}
            emailArticle={emailArticle}
            email={viewEmailForm1 === article._id}
            captureEmail={captureEmail}
            emailTarget={emailTarget}
            articleEmailed={articleEmailed}
            articleEmailError={articleEmailError}
            resendArticle={resendArticle}
            expandArticle={expandArticle}
            showComments={comments.articleID === article._id}
            viewComments={viewComments}
            hideComments={hideComments}
            captureComment={captureComment}
            submitComment={submitComment}
            comment={comment}
            comments={comments}
            flagArticle={flagArticle}
            viewLikes={viewLikes}
            likes={likes}
            viewProfile={viewProfile}
            singleArticle
          />
        )}
      </Box>
      {article.error && (
        <div className='noResults' style={{height: '300px'}}>
          <HeadingNew size='h3' palette='medium' as='h3' centerText>
            Apologies - none of our articles match that query.
          </HeadingNew>
        </div>
      )}
      {article && !article.error && (
        <LatestArticles
          related
          articleID={article._id}
          theme={article.theme}
          token={user.token}
        />
      )}
      <LandingGuides background='#e8eef4' />
      <LandingLibrary />
      {showModal && (
        <Modal closeModal={closeModal}>
          <Newsletter popUp />
        </Modal>
      )}
    </Flex>
  )
}

ViewArticleContainer.propTypes = {
  article: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  emailTarget: PropTypes.string,
  viewEmailForm1: PropTypes.string,
  articleEmailed: PropTypes.bool.isRequired,
  articleEmailError: PropTypes.bool.isRequired,
  comments: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  comment: PropTypes.string,
  likes: PropTypes.object.isRequired,
  articleEngagement: PropTypes.func.isRequired,
  getArticle: PropTypes.func.isRequired,
  viewEmailForm: PropTypes.func.isRequired,
  emailArticle: PropTypes.func.isRequired,
  captureEmail: PropTypes.func.isRequired,
  resendArticle: PropTypes.func.isRequired,
  viewComments: PropTypes.func.isRequired,
  hideComments: PropTypes.func.isRequired,
  captureComment: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
  viewLikes: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    article: state.search.article,
    emailTarget: state.search.emailTarget,
    viewEmailForm1: state.search.viewEmailForm,
    comments: state.search.comments,
    comment: state.search.comment,
    likes: state.search.likes,
    articleEmailed: state.search.articleEmailed,
    articleEmailError: state.search.articleEmailError,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    articleEngagement,
    getArticle,
    viewEmailForm,
    emailArticle,
    captureEmail,
    resendArticle,
    viewComments,
    hideComments,
    captureComment,
    submitComment,
    flagArticle,
    viewLikes,
  })(ViewArticleContainer)
)
