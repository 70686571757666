import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Box, Flex, Image} from 'rebass'
import styled from 'styled-components'
import {getLibrary, suggestUpdate, recordView} from '../../actions/searchActions'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Container from '../../components/FormElements/Container'
import Loader from '../../components/FormElements/Loader'
import {LinkRouter1} from '../../components/FormElements/Link'
import LandingGuides from '../../staticPages/LandingGuides'
import LibraryFilter from './libraryFilter'
import LibrarySubmission from './librarySubmission'

const Parser = require('html-react-parser')

const ImageContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 40em) {
    width: 33%;
    margin-bottom: 5px;
  }
`

const Library = ({
  getLibrary,
  suggestUpdate,
  recordView,
  library,
  token,
  filterApplied,
  userID,
  loggedIn,
  history,
  theme,
}) => {
  useEffect(() => {
    getLibrary(userID)
    window.scrollTo(0, 0)
  }, [getLibrary])

  const [filter, updateFilter] = useState('title')
  const [viewFormat, updateViewFormat] = useState(theme ? 'grid' : 'list')
  const [viewSummary, changeViewSummary] = useState(null)
  const [suggestion, captureSuggestion] = useState(null)
  const [submission, confirmSubmission] = useState(false)
  const [formError, updateFormError] = useState(false)
  const [summaryError, updateSummaryError] = useState(false)
  const [userInput, toggleUserInput] = useState('comment')

  const [book, updateBook] = useState({
    title: '',
    author: '',
    keyIdea: '',
    summary: '',
    rating: '',
  })

  const [SelectedSubject, updateSelectedSubject] = useState(null)
  const Subjects = [
    {label: 'Business', value: 'business'},
    {label: 'Career', value: 'career'},
    {label: 'Communication', value: 'communication'},
    {label: 'Creativity', value: 'creativity'},
    {label: 'Decision Making', value: 'decision-making'},
    {label: 'Entrepreneurship', value: 'entrepreneurship'},
    {label: 'Learning', value: 'learning'},
    {label: 'Management', value: 'management'},
    {label: 'Productivity', value: 'productivity'},
    {label: 'Reasoning', value: 'reasoning'},
    {label: 'Technology', value: 'technology'},
    {label: 'Wellbeing', value: 'wellbeing'},
  ]

  let books = library.result
  if (SelectedSubject)
    books = books.filter((b) => b.tags && b.tags.includes(SelectedSubject))
  if (filter === 'rating') books.sort((a, b) => b.rating - a.rating)
  if (filter === 'title')
    books.sort((a, b) => {
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    })

  if (filter === 'author')
    books.sort((a, b) => {
      const authorA = a.author.split('&')[0].split(' ')[1]
      const authorB = b.author.split('&')[0].split(' ')[1]
      if (authorA < authorB) return -1
      if (authorA > authorB) return 1
      return 0
    })

  const specifier = window.location.pathname.split('ibrary/')[1]
  if (specifier) {
    books = books.filter(
      (b) =>
        b.title.replace(/[^\w]/g, '') ===
        specifier
          .replace(/%E2%80%99/g, '')
          .replace(/%C3%A9/g, '')
          .replace(/[^\w]/g, '')
    )
  }

  const toggleBook = (book) => {
    if (viewSummary !== book) recordView('library', book, userID)
    changeViewSummary(viewSummary === book ? null : book)
    const anchor = document.getElementById(book)
    if (anchor) {
      setTimeout(
        () => window.scrollTo({top: anchor.offsetTop - 65, behavior: 'smooth'}),
        10
      )
    }
  }

  if (filterApplied)
    books = books.filter((b) => (b.tags ? b.tags.includes(filterApplied) : false))

  const submitUpdate = () => {
    if (suggestion) {
      suggestUpdate({suggestion, userID})
      confirmSubmission(true)
      captureSuggestion(null)
      updateFormError(null)
    }
    if (!suggestion) {
      updateFormError('Please write something')
    }
  }

  const submitSummary = () => {
    if (book.title && book.author && book.rating && book.keyIdea) {
      suggestUpdate({book, userID})
      confirmSubmission(true)
      captureSuggestion(null)
      updateSummaryError(null)
    } else {
      updateSummaryError('Something is missing')
    }
  }

  const toggle = (label) => {
    updateFormError(null)
    updateSummaryError(null)
    confirmSubmission(false)
    toggleUserInput(label)
    updateBook({
      title: '',
      author: '',
      keyIdea: '',
      summary: '',
      rating: '',
    })
  }

  const refresh = () => {
    updateBook({
      title: '',
      author: '',
      keyIdea: '',
      summary: '',
      rating: '',
    })
    confirmSubmission(false)
  }

  const viewFromTile = (title) => {
    const FormattedTitle = title
      .replace(/ /g, '-')
      .replace(/\//g, '')
      .replace(/%/g, '-per-cent-')
    const path = window.location.pathname === '/myLibrary' ? 'myLibrary' : 'library'
    history.push(`/${path}/${FormattedTitle}`)
    window.scrollTo(0, 0)
    // TRACK
    recordView('library', FormattedTitle, userID)
  }

  return (
    <>
      <Container
        background={theme ? '#fff' : '#005969'}
        minHeight={!filterApplied}
        noPadding={loggedIn || filterApplied}
        align='flex-start'
      >
        {!filterApplied && (
          <Box width={1} style={{maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Box width={1} mb={3}>
              <HeadingNew size='h1' palette='white' as='h1'>
                Sivv Library {specifier && books[0] && `/ ${books[0].title}`}
              </HeadingNew>
              <HeadingNew size='h3' palette='white' as='h3'>
                Key takeaways from non-fiction books.{' '}
                {/* {!token && (
                  <>
                    <LinkRouter1 to='/signin'>Sign-in</LinkRouter1> to comment or
                    contribute.
                  </>
                )} */}
              </HeadingNew>
            </Box>
            {specifier && (
              <Flex p={2} justifycontent='flex-start'>
                <HeadingNew size='h3' palette='white' as='h3'>
                  <LinkRouter1 to='/library'>Back to library</LinkRouter1>
                </HeadingNew>
              </Flex>
            )}
            {books.length === 0 && !library.isLoading && (
              <Flex width={1} justifyContent='center'>
                <HeadingNew size='h3' palette='white' as='h3'>
                  No book found at this address
                </HeadingNew>
              </Flex>
            )}
            {!specifier && (
              <LibraryFilter
                filter={filter}
                updateFilter={updateFilter}
                viewFormat={viewFormat}
                updateViewFormat={updateViewFormat}
                Subjects={Subjects}
                updateSelectedSubject={updateSelectedSubject}
                SelectedSubject={SelectedSubject}
              />
            )}
          </Box>
        )}

        <Flex
          width={1}
          flexDirection={viewFormat === 'list' ? 'column' : 'row'}
          alignItems='center'
          justifyContent={specifier ? 'center' : 'space-between'}
          flexWrap='wrap'
          mx='auto'
          style={{maxWidth: '1000px'}}
        >
          {library.isLoading && (
            <Flex
              style={{minHeight: '70vh'}}
              alignItems='center'
              justifyContent='center'
            >
              <Loader />
            </Flex>
          )}
          {library.error && (
            <HeadingNew size='h3' palette='white' as='h3' style={{ marginTop: '200px'}}>
              Unfortunately this feature is currently unavailable. Please try again
              later.
            </HeadingNew>
          )}
          {books.map((b) =>
            viewFormat === 'grid' && !specifier ? (
              <ImageContainer>
                <Image
                  src={b.image}
                  width='80%'
                  height='100%'
                  onClick={() => viewFromTile(b.title)}
                  style={{cursor: 'pointer'}}
                  key={b.title}
                />
              </ImageContainer>
            ) : (
              <Flex
                width={1}
                style={{
                  border: '1px solid #dee2e6',
                  maxWidth: filterApplied ? '1000px' : '900px',
                  borderRadius: '5px',
                  background: '#fff',
                }}
                p={3}
                my={3}
                flexDirection='column'
                id={b.title}
                key={b.title}
              >
                <Flex width={1}>
                  <Box width='100px'>
                    <a target='null' href={b.link}>
                      <Image src={b.image} width='100%' />
                    </a>
                  </Box>
                  <Flex width={1} flexDirection='column' pl={3}>
                    <HeadingNew size='h2' palette='medium' as='h2' condensed>
                      {b.title}
                    </HeadingNew>
                    <HeadingNew size='h3' palette='medium' as='h3'>
                      {b.author}
                    </HeadingNew>
                    <HeadingNew size='h3' palette='medium'>
                      Key idea: {b.keyIdea}
                    </HeadingNew>
                    {!specifier && (
                      <div
                        onClick={() => toggleBook(b.title)}
                        style={{
                          marginTop: '5px',
                          cursor: 'pointer',
                          color: '#ff6600',
                        }}
                      >
                        {viewSummary === b.title
                          ? '- Hide summary'
                          : '+ View summary'}
                      </div>
                    )}
                  </Flex>
                </Flex>
                {(viewSummary === b.title || specifier) && (
                  <Box width={1} mt={3} p={[1, 3, 3]}>
                    <HeadingNew size='h3' palette='medium' as='h3'>
                      Summary
                    </HeadingNew>
                    <HeadingNew size='h3' palette='medium'>
                      {Parser(
                        b.summary.split(/<body\b[^>]*>/)[1].split('</body')[0]
                      )}
                    </HeadingNew>
                    <a target='null' href={b.link}>
                      Buy a copy of {b.title}
                    </a>
                  </Box>
                )}
              </Flex>
            )
          )}
          {viewFormat === 'grid' && !specifier && <div style={{flex: 'auto'}} />}
        </Flex>
        {token && !theme && (
          <LibrarySubmission
            specifier={specifier}
            toggle={toggle}
            userInput={userInput}
            submission={submission}
            updateBook={updateBook}
            book={book}
            captureSuggestion={captureSuggestion}
            formError={formError}
            submitUpdate={submitUpdate}
            submitSummary={submitSummary}
            summaryError={summaryError}
            refresh={refresh}
          />
        )}
      </Container>
      {!token && !filterApplied && <LandingGuides />}
    </>
  )
}

Library.propTypes = {
  library: PropTypes.object.isRequired,
  getLibrary: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    library: state.search.library,
    token: state.user.token,
    userID: state.user._id,
  }
}

export default withRouter(
  connect(mapStateToProps, {getLibrary, suggestUpdate, recordView})(Library)
)
