import axios from 'axios'
import config from '../utils/configuration'

import {updateUserInfo} from './user'

// Action Creators
export const CLEAR_DRAFT = 'CLEAR_DRAFT'
export const SAVE_DRAFT = 'SAVE_DRAFT'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'
export const ADD_ARTICLE_TYPE = 'ADD_ARTICLE_TYPE'
export const SAVE_ARTICLE_START = 'SAVE_ARTICLE_START'
export const SAVE_ARTICLE_END = 'SAVE_ARTICLE_END'
export const SAVE_ARTICLE_EMPTY = 'SAVE_ARTICLE_EMPTY'
export const SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS'
export const SAVE_ARTICLE_ERROR = 'SAVE_ARTICLE_ERROR'
export const UPDATE_ARTICLE_LIKES = 'UPDATE_ARTICLE_LIKES'
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS'
export const UPLOAD_START = 'UPLOAD_START'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const CLEAR_IMAGE = 'CLEAR_IMAGE'
export const UPLOAD_PROFILE_SUCCESS = 'UPLOAD_PROFILE_SUCCESS'

// function makeActionCreator(type, ...argNames) {
//   return function(...args) {
//     const action = { type }
//     argNames.forEach((arg, index) => {
//       action[argNames[index]] = args[index]
//     })
//     return action
//   }
// }
//
// const ADD_TODO = 'ADD_TODO'
// const EDIT_TODO = 'EDIT_TODO'
// const REMOVE_TODO = 'REMOVE_TODO'
//
// export const addTodo = makeActionCreator(ADD_TODO, 'text')
// export const editTodo = makeActionCreator(EDIT_TODO, 'id', 'text')
// export const removeTodo = makeActionCreator(REMOVE_TODO, 'id')

// ***************************** Save Article

export const clearDraft = () => {
  return {
    type: CLEAR_DRAFT,
  }
}

export const saveDraft = (id, content) => {
  return {
    type: SAVE_DRAFT,
    id,
    content,
  }
}

export const addCategory = (category, label) => {
  return {
    type: ADD_CATEGORY,
    category,
    label,
  }
}

export const removeCategory = (category, label) => {
  return {
    type: REMOVE_CATEGORY,
    category,
    label,
  }
}

export const addArticleType = (name, value) => {
  return {
    type: ADD_ARTICLE_TYPE,
    name,
    value,
  }
}

// Dispatched if user attempts to save an empty article
const saveArticleEmpty = () => {
  return {
    type: SAVE_ARTICLE_EMPTY,
  }
}

const saveArticleStart = () => {
  return {
    type: SAVE_ARTICLE_START,
  }
}

const saveArticleSuccess = (_id, status) => {
  return {
    type: SAVE_ARTICLE_SUCCESS,
    _id,
    status,
  }
}

const saveArticleError = () => {
  return {
    type: SAVE_ARTICLE_ERROR,
  }
}

export const saveArticle = (article, status, userID) => {
  const art = article
  art.status = status
  art.user = userID
  const {title, summary} = article
  // Add to require summary
  // if (title.length < 1 || summary.length < 1) {
  //   return async (dispatch) => {
  //     dispatch(saveArticleEmpty())
  //   }
  // }
  return async (dispatch) => {
    try {
      dispatch(saveArticleStart())
      const response = await axios.post(`${config.target}/save`, art)
      if (response.status === 200) {
        const label = status === 'draft' ? 'drafts' : 'myArticles'
        dispatch(updateUserInfo(label, response.data.articles))
        dispatch(saveArticleSuccess(response.data._id, status))
      }
    } catch (e) {
      console.log('Save Article Error', e)
      dispatch(saveArticleError())
    }
  }
}

// ***************************** Article Engagement

const updateArticleLikes = (ID, likes) => {
  return {
    type: UPDATE_ARTICLE_LIKES,
    ID,
    likes,
  }
}

export const articleEngagement = (articleID, userID, flag) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${config.target}/articleEngagement`, {
        articleID,
        userID,
        flag,
      })
      if (response.status === 200) {
        if (flag === 'like') dispatch(updateArticleLikes(articleID, response.data))
        if (flag === 'share')
          dispatch(updateUserInfo('sharedArticles', response.data.data))
        if (flag === 'save')
          dispatch(updateUserInfo('savedArticles', response.data.data))
      }
    } catch (e) {
      console.log('Like Article Error', e)
    }
  }
}

// ***************************** Delete article

export const deleteArticle = (label, articleID, userID) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${config.target}/deleteArticle`, {
        articleID,
        userID,
        label,
      })
      if (response.status === 200) dispatch(updateUserInfo(label, response.data))
    } catch (e) {
      console.log('Delete Article Error', e)
    }
  }
}

// ***************************** Edit article

const editArticleSuccess = (article) => {
  return {
    type: EDIT_ARTICLE_SUCCESS,
    article,
  }
}

export const editArticle = (articleID, userID) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${config.target}/getArticleByID/${articleID}`
      )
      if (response.status === 200) dispatch(editArticleSuccess(response.data))
    } catch (e) {
      console.log('Edit Article Error', e)
    }
  }
}

// ***************************** Upload Image

const uploadStart = () => {
  return {
    type: UPLOAD_START,
  }
}

const uploadSuccess = (url) => {
  return {
    type: UPLOAD_SUCCESS,
    url,
  }
}

const uploadProfileSuccess = (url) => {
  return {
    type: UPLOAD_PROFILE_SUCCESS,
    url,
  }
}

const uploadError = (url) => {
  return {
    type: UPLOAD_ERROR,
    url,
  }
}

export const clearImage = () => {
  return {
    type: CLEAR_IMAGE,
  }
}

export const uploadLogo = (data, flag) => {
  return async (dispatch) => {
    try {
      dispatch(uploadStart())
      const upload = await axios.post(`${config.target}/uploadImage`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (upload.status === 200) {
        dispatch(uploadSuccess(upload.data.url))
        if (flag) dispatch(uploadProfileSuccess(upload.data.url))
      }
      if (upload.status === 500) {
        dispatch(uploadError())
      }
    } catch (e) {
      dispatch(uploadError())
    }
  }
}

// axios.post(`${target}/uploadLogo`, data, {
//        onUploadProgress: ProgressEvent => {
//          this.setState({
//            loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
//        })
//    },
// })
