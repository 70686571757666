import React from 'react'
import styled from 'styled-components'
import {Box, Flex} from 'rebass'
import HeadingNew from '../components/FormElements/HeadingNew'
import Text from '../components/FormElements/Text'

const Item = styled.li`
  color: #657081;
  margin-left: 15px;
`

const TermsOfUse = () => {
  return (
    <Flex
      width={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      p={['25px', '50px']}
    >
      <Flex flexDirection='column' style={{maxWidth: '900px'}}>
        <Box p={2}>
          <HeadingNew size='h1' palette='medium' as='h2'>
            Sivv Terms of Use
          </HeadingNew>
        </Box>
        <Box p={2}>
          <Text pt={3} palette='light'>
            In using this website you are deemed to have read and agreed to the
            following terms and conditions:
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            General
          </HeadingNew>
          <Text pt={3} palette='light'>
            Access to and use of this website and the products and services available
            through this website (the "Services") are subject to these terms, and
            conditions (the "Terms of Business"). By using the Services, you are
            agreeing to all of the Terms of Business, as may be updated by us from
            time to time.
          </Text>
          <Text pt={3} palette='light'>
            We will not be liable if this website is unavailable at any time or for
            any period. From time to time, we may restrict access to some parts or
            all of this website. This website also contains links to other websites,
            which are not operated by us. We have no control over such sites and we
            accept no responsibility for them or for any loss or damage that may
            arise from your use of them. Your use of those sites will be subject to
            the terms of use and service contained within each such site.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Terminology
          </HeadingNew>
          <Text pt={3} palette='light'>
            The following terminology applies to these Terms of Business, Privacy
            Statement and Disclaimer Notice and any or all agreements: “You” and
            “Your” refers to you, the person accessing this website and accepting the
            Company’s terms and conditions. “The Company”, “Ourselves”, “We” and
            "Us", refers to, Sivv Limited, our Company. “Party”, “Parties”, or “Us”,
            refers to both you and ourselves, or either you or ourselves. Any use of
            the above terminology or other words in the singular, plural,
            capitalisation and/or he/she or they, are taken as interchangeable and
            therefore as referring to the same.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Intellectual Property
          </HeadingNew>
          <Text pt={3} palette='light'>
            The intellectual property rights in all software and content made
            available to you on or through this website remains the property of Sivv
            Limited or its licensors and are protected by copyright laws and treaties
            around the world. All such rights are reserved by us and our licensors.
            You may store, print and display the content supplied solely for your own
            personal use. You are not permitted to publish, manipulate, distribute or
            otherwise reproduce, in any format, any of the content or copies of the
            content supplied to you or which appears on this website nor may you use
            any such content in connection with any business or commercial
            enterprise.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Privacy Statement and Confidentiality
          </HeadingNew>
          <Text pt={3} palette='light'>
            We are committed to protecting your privacy. We constantly review our
            systems and data to ensure the best possible service. Records are
            regarded as confidential and therefore will not be divulged to any third
            party, unless if legally requested or required to do so, by or to, the
            appropriate authorities. You have the right to request sight of, and
            copies of any relevant personal records we keep, on the proviso that we
            are given reasonable notice of such a request.
          </Text>
          <Text pt={3} palette='light'>
            We will not sell, share, or rent your personal information to any third
            party or use your e-mail address for unsolicited mail.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h2' palette='medium' as='h2'>
            Disclaimer Notice
          </HeadingNew>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Exclusions and Limitations
          </HeadingNew>
          <Text pt={3} palette='light'>
            The information on this website is provided on an "as is" basis without
            any guarantees, conditions or warranties as to its accuracy. To the
            fullest extent permitted by law, we:
          </Text>
          <Item>
            exclude all representations and warranties relating to this website and
            its contents or which is or may be provided by any affiliate or any third
            party, including in relation to any inaccuracies or omissions in this
            website and/or our literature; and
          </Item>
          <Item>
            exclude all liability for damages arising out of or in connection with
            your use of this website. This includes, without limitation, direct loss,
            loss of business or profits (whether or not the loss of such profits was
            foreseeable, arose in the normal course of things or you have advised
            this Company of the possibility of such potential loss), damage caused to
            your computer, computer software, systems and programs and the data
            thereon or any other direct or indirect, consequential and incidental
            damages.
          </Item>
          <Text pt={3} palette='light'>
            This Company does not however exclude liability for death or personal
            injury caused by its negligence. The above exclusions and limitations
            apply only to the extent permitted by law.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Availability
          </HeadingNew>
          <Text pt={3} palette='light'>
            You are solely responsible for evaluating the fitness for a particular
            purpose of any downloads, reports, information and text available through
            this site. Redistribution or republication of any part of this site or
            its content is prohibited, without the express written consent of the
            Company. The Company does not warrant that the service from this site
            will be uninterrupted, timely or error free, although it is provided to
            the best ability. By using this service you indemnify this Company, its
            employees, agents and affiliates against any loss or damage, in whatever
            manner, howsoever caused.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Log Files
          </HeadingNew>
          <Text pt={3} palette='light'>
            We use IP addresses to analyse trends, administer the site, track user’s
            movement, and gather broad demographic information for aggregate use. IP
            addresses are not linked to personally identifiable information.
            Additionally, for systems administration, detecting usage patterns and
            troubleshooting purposes, our web servers automatically log standard
            access information including browser type, access times/open mail, URL
            requested, and referral URL. This information is not shared with third
            parties and is used only within this Company on a need-to-know basis. Any
            individually identifiable information related to this data will never be
            used in any way different to that stated above without your explicit
            permission.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Links to this website
          </HeadingNew>
          <Text pt={3} palette='light'>
            You may not create a link to any page of this website without our prior
            written consent. If you do create a link to a page of this website you do
            so at your own risk and the exclusions and limitations set out above will
            apply to your use of this website by linking to it.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Links from this website
          </HeadingNew>
          <Text pt={3} palette='light'>
            We do not monitor or review the content of other party’s websites which
            are linked to from this website. Opinions expressed or material appearing
            on such websites are not necessarily shared or endorsed by us and should
            not be regarded as the publisher of such opinions or material. Please be
            aware that we are not responsible for the privacy practices, or content,
            of these sites. We encourage our users to be aware when they leave our
            site to read the privacy statements of these sites. You should evaluate
            the security and trustworthiness of any other site connected to this site
            or accessed through this site yourself, before disclosing any personal
            information to them. This Company will not accept any responsibility for
            any loss or damage in whatever manner, howsoever caused, resulting from
            your disclosure to third parties of personal information.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Copyright Notice
          </HeadingNew>
          <Text pt={3} palette='light'>
            Copyright and other relevant intellectual property rights exist on all
            text relating to the Company’s services and the full content of this
            website.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Waiver
          </HeadingNew>
          <Text pt={3} palette='light'>
            Failure of either Party to insist upon strict performance of any
            provision of this or any Agreement or the failure of either Party to
            exercise any right or remedy to which it, he or they are entitled
            hereunder shall not constitute a waiver thereof and shall not cause a
            diminution of the obligations under this or any Agreement. No waiver of
            any of the provisions of this or any Agreement shall be effective unless
            it is expressly stated to be such and signed by both Parties.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Notification of Changes
          </HeadingNew>
          <Text pt={3} palette='light'>
            We reserve the right to change these conditions from time to time as we
            see fit and your continued use of the site will signify your acceptance
            of any adjustment to these terms. If there are any changes to our privacy
            policy, we will announce that these changes have been made on our home
            page and on other key pages on our site. If there are any changes in how
            we use our site customers’ personally identifiable Information,
            notification by e-mail or postal mail will be made to those affected by
            this change. Any changes to our privacy policy will be posted on our web
            site 15 days prior to these changes taking place. You are therefore
            advised to re-read this statement on a regular basis.
          </Text>
          <Text pt={3} palette='light'>
            These terms and conditions form part of any agreement between you and
            ourselves. Your accessing of this website indicates your understanding,
            agreement to and acceptance, of the Disclaimer Notice and the full Terms
            and Conditions contained herein. Your statutory Consumer Rights are
            unaffected.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Indemnity
          </HeadingNew>
          <Text pt={3} palette='light'>
            You agree to indemnify, defend and hold harmless Sivv Limited, its
            directors, officers, employees, consultants, agents, and affiliates, from
            any and all third party claims, liability, damages and/or costs
            (including, but not limited to, legal fees) arising from your use of this
            website or your breach of the Terms of Business.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Complaints
          </HeadingNew>
          <Text pt={3} palette='light'>
            We operate a complaints handling procedure which we will use to try to
            resolve disputes when they first arise, please let us know if you have
            any complaints or comments.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Entire Agreement
          </HeadingNew>
          <Text pt={3} palette='light'>
            The above Terms of Business constitute the entire agreement of the
            parties and supersede any and all preceding and contemporaneous
            agreements between you and us. Any waiver of any provision of the Terms
            of Business will be effective only if in writing and signed by us.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            General
          </HeadingNew>
          <Text pt={3} palette='light'>
            The laws of England and Wales govern these terms and conditions and any
            non-contractual obligations in relation to these terms and conditions. By
            accessing this website you consent to these terms and conditions and to
            the exclusive jurisdiction of the English courts in all disputes arising
            out of such access. If any of these terms are deemed invalid or
            unenforceable for any reason (including, but not limited to the
            exclusions and limitations set out above), then the invalid or
            unenforceable provision will be severed from these terms and the
            remaining terms will continue to apply. Failure of the Company to enforce
            any of the provisions set out in these Terms and Conditions and any
            Agreement, or failure to exercise any option to terminate, shall not be
            construed as waiver of such provisions and shall not affect the validity
            of these Terms and Conditions or of any Agreement or any part thereof, or
            the right thereafter to enforce each and every provision. These Terms and
            Conditions shall not be amended, modified, varied or supplemented except
            in writing and signed by duly authorised representatives of the Company.
          </Text>
        </Box>

        <Box p={2}>
          <Text pt={3} palette='light'>
            © Sivv {new Date().getYear() + 1900} All Rights Reserved
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default TermsOfUse
