import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import styled from 'styled-components'
import ChangePreferences from '../Form/ChangePreferences'
import MyDetails from '../MyDetails'
import MySavedArticles from '../MySavedArticles'
import ComposeArticle from '../ComposeArticle'
import MyContent from '../MyContent'
import MySubscription from '../MySubscription'
import SearchResultsContainer from '../SearchResultsContainer'
import Library from '../Library'
import HowToUse from '../../staticPages/HowToUse'
// import Icons from '../../images/icons/icons'
import {logout, confirmGuideView} from '../../actions/user'
import {clearDraft} from '../../actions/articleActions'

// const sections = [
//   {label: 'Browse', icon: Icons.browse, linkTarget: '/', isActive: true},
//   {label: 'My Articles', icon: Icons.file, linkTarget: '/myArticles'},
//   {label: 'Library', icon: Icons.Library, linkTarget: '/myLibrary'},
//   {label: 'Guides', icon: Icons.content, linkTarget: '/myGuides'},
//   {
//     label: 'Preferences',
//     icon: Icons.alert,
//     linkTarget: '/myPreferences',
//     isActive: true,
//   },
//   {
//     label: 'My Subscription',
//     icon: Icons.subscription,
//     linkTarget: '/mySubscription',
//   },
// ]

const Wrapper = styled.div`
  margin-top: 70px;
  display: flex;
  min-height: 100%;
`

const Content = styled.div`
  transition: none;
  background-color: ${(props) => props.background};
  width: 100%;
`

const Container = styled.div`
  /* padding: 5px 16px 16px 16px; */
  padding: ${(props) => (props.feed ? '0px 0px 16px 0px' : '0px 0px 0px 0px')};
  width: 100%;
  min-height: calc(100vh - 150px);
  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-top: ${(props) => (props.feed ? '0px' : '0px')};
  }
  @media screen and (min-width: 1000px) {
    max-width: 1000px;
    margin: auto;
  }
`
const MyAccount = ({history, logout, clearDraft, confirmGuideView, firstVisit}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // const handleClickSignOut = () => {
  //   logout()
  //   history.push('/')
  // }

  const sectionAccount = window.location.pathname

  // {firstVisit && (
  //   <Modal article noClose >
  //     <HowToUse intro close={confirmGuideView} />
  //   </Modal>
  // )}

  return (
    <Wrapper>
      <Content
        background={
          sectionAccount.includes('/browse') || sectionAccount === '/'
            ? '#fff'
            : '#d4e0e5'
        }
      >
        <Container
          feed={sectionAccount === '/' || sectionAccount.includes('/theme')}
        >
          {(sectionAccount === '/' || sectionAccount.includes('/theme')) && (
            <SearchResultsContainer />
          )}
          {sectionAccount.includes('/browse') && <SearchResultsContainer browse />}
          {sectionAccount === '/myPreferences' && <ChangePreferences />}
          {sectionAccount === '/myDetails' && <MyDetails />}
          {sectionAccount === '/myArticles' && <MySavedArticles />}
          {sectionAccount === '/submit' && <ComposeArticle submit />}
          {sectionAccount === '/editArticle' && <ComposeArticle edit />}
          {sectionAccount.includes('/myLibrary') && <Library loggedIn />}
          {sectionAccount === '/myGuides' && <MyContent loggedIn />}
          {sectionAccount === '/mySubscription' && <MySubscription />}
          {sectionAccount === '/howToUse' && <HowToUse />}
        </Container>
      </Content>
    </Wrapper>
  )
}

MyAccount.propTypes = {
  logout: PropTypes.func.isRequired,
  clearDraft: PropTypes.func.isRequired,
  confirmGuideView: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    firstVisit: state.user.firstVisit,
  }
}

export default withRouter(
  connect(mapStateToProps, {logout, clearDraft, confirmGuideView})(MyAccount)
)
