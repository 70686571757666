const timeTransition = '.1s'
const timeTransitionMedium = '.08s'
const timeTransitionSmall = '.05s'
export default {
  // ================================== REBASS
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: '#4A90E2',
    lightgray: '#f6f6ff',
    primary: '#ff6600',
    error: '#D0021B',
    success: '#5FB600',
    warning: '#FF9300',
  },
  space: [0, 4, 8, 12, 16, 20, 24, 32, 64, 128, 256],
  fonts: {
    openSans: 'OpenSans, sans-serif',
    quickSand: 'Quicksand, sans-serif',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  buttons: {
    // ===================== PRIMARY
    primary: {
      color: '#fff',
      backgroundColor: '#ff6600',
      height: '48px',
      // padding: '16px',
      transition: `background ${timeTransition} ease-in`,
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '16px',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: '#E55B00',
      },
      '&:active': {
        backgroundColor: '#BF4C00',
      },
      '&:disabled': {
        backgroundColor: '#F3A794',
      },
    },
    // ===================== PRIMARY MEDIUM
    primaryMedium: {
      color: '#fff',
      backgroundColor: '#ff6600',
      height: '38px',
      // padding: '12px',
      transition: `background ${timeTransitionMedium} ease-in`,
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '14px',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: '#E55B00',
      },
      '&:active': {
        backgroundColor: '#BF4C00',
      },
      '&:disabled': {
        backgroundColor: '#F3A794',
      },
    },
    // ===================== PRIMARY SMALL
    primarySmall: {
      color: '#fff',
      backgroundColor: '#ff6600',
      height: '28px',
      padding: '4px',
      transition: `background ${timeTransitionSmall} ease-in`,
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: '#E55B00',
      },
      '&:active': {
        backgroundColor: '#BF4C00',
      },
      '&:disabled': {
        backgroundColor: '#F3A794',
      },
    },
    // ===================== PRIMARY
    secondary: {
      color: '#FF6600',
      backgroundColor: '#FFF',
      height: '48px',
      transition: `all ${timeTransition} ease-in`,
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '16px',
      fontWeight: 'normal',
      boxShadow: 'inset 0px 0px 0px 1px #FF6600',
      '&:hover': {
        color: '#E55B00',
        boxShadow: 'inset 0px 0px 0px 2px #E55B00',
      },
      '&:active': {
        color: '#BF4C00',
        boxShadow: 'inset 0px 0px 0px 2px #BF4C00',
      },
      '&:disabled': {
        color: '#F3A794',
        boxShadow: 'inset 0px 0px 0px 1px #F3A794',
      },
    },
    // ===================== PRIMARY MEDIUM
    secondaryMedium: {
      color: '#FF6600',
      backgroundColor: '#FFF',
      height: '38px',
      transition: `all ${timeTransitionMedium} ease-in`,
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '16px',
      fontWeight: 'normal',
      boxShadow: 'inset 0px 0px 0px 1px #FF6600',
      '&:hover': {
        color: '#E55B00',
        boxShadow: 'inset 0px 0px 0px 2px #E55B00',
      },
      '&:active': {
        color: '#BF4C00',
        boxShadow: 'inset 0px 0px 0px 2px #BF4C00',
      },
      '&:disabled': {
        color: '#F3A794',
        boxShadow: 'inset 0px 0px 0px 1px #F3A794',
      },
    },
    // ===================== PRIMARY SMALL
    secondarySmall: {
      color: '#FF6600',
      backgroundColor: '#FFF',
      height: '28px',
      transition: `all ${timeTransitionSmall} ease-in`,
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      padding: '4px',
      fontWeight: 'normal',
      boxShadow: 'inset 0px 0px 0px 1px #FF6600',
      '&:hover': {
        color: '#E55B00',
        boxShadow: 'inset 0px 0px 0px 2px #E55B00',
      },
      '&:active': {
        color: '#BF4C00',
        boxShadow: 'inset 0px 0px 0px 2px #BF4C00',
      },
      '&:disabled': {
        color: '#F3A794',
        boxShadow: 'inset 0px 0px 0px 1px #F3A794',
      },
    },
    outline: {
      color: 'blue',
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
  },
}
