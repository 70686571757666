import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Flex, Box} from 'rebass'
import {registerInterest, resetErrors, resetFormStatus} from '../../actions/user'
import {newEmailValidator} from '../../utils/validators'
import Button from '../../components/FormElements/Button'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Input from '../../components/FormElements/Input'
import Text from '../../components/FormElements/Text'

const EmailCapture = ({
  token,
  errors,
  registerInterest,
  registerStatus,
  resetErrors,
  resetFormStatus,
  formStatus,
  category,
  alertCategories,
  related,
  landing,
  themePage,
  callToAction
}) => {
  const [formData, setFormData] = useState('')
  const [formError, setFormError] = useState({})

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData(e.target.value)
  }

  const register = async (e) => {
    e.preventDefault()
    // ------------------
    const emailError = newEmailValidator({email: formData})
    let selectionError
    if (category === 'alert') {
      if (alertCategories.length < 1) selectionError = {email: 'No alerts selected'}
    }
    const errors = Object.assign(emailError, selectionError)
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady && registerInterest(formData, category, alertCategories)
  }

  // Ensure to clean errors before unmounting
  useEffect(() => {
    resetErrors()
    resetFormStatus()
  }, [])

  return (
    <Flex
      flexDirection={themePage ? 'column' : ['column', 'row']}
      justifyContent={['center', 'center']}
      alignItems='center'
      width={1}
      mt={related ? ['25px', '0px'] : landing ? ['10px', '0px'] : '25px'}
      flexWrap='wrap'
    >
      {callToAction && (
        <Box p={2} width={1}>
          <HeadingNew
            size='h3'
            palette='dark'
            as='h3'
            centerText
          >
            {callToAction}
          </HeadingNew>
        </Box>
      )}
      {errors && errors.form && (
        <Box p={2}>
          <Text errorForm>{errors.form}</Text>
        </Box>
      )}
      {!formStatus && !errors.form && (
        <Box
          p={2}
          width={
            related
              ? [1, 1 / 2]
              : themePage
              ? 1
              : landing
              ? [1, 1 / 2, 3 / 4]
              : [1, 1 / 2, 1 / 3]
          }
          style={{ maxWidth: '500px'}}
        >
          <Input
            label='Email *'
            onChange={(e) => handleInputChange(e)}
            value={formData.email}
            hasText={formData.email !== ''}
            inputProps={{
              type: 'text',
              id: 'email-newsletter',
              name: 'email',
              autoComplete: 'email',
              // onBlur: () => handleFiedlOnBlur(emailValidator)
            }}
            error={formError && formError.email}
          />
        </Box>
      )}
      {!formStatus && !errors.form && (
        <Box
          p={2}
          width={
            related ? [1, 1 / 2] : landing ? [1, 1 / 2, 3 / 4] : [1, 1 / 2, 1 / 3]
          }
          style={{ maxWidth: '500px'}}
        >
          <Button onClick={(e) => register(e)} palette='primary' fullWidth>
            {category === 'newsletter' ? 'Subscribe' : 'Submit'}
          </Button>
        </Box>
      )}
      {formStatus && (
        <Box p={2}>
          <HeadingNew
            size='h3'
            palette='orange'
            as='h3'
            centerText
          >
            Thank you for your interest! Please check your email to confirm your
            subscription.
          </HeadingNew>
        </Box>
      )}
    </Flex>
  )
}

EmailCapture.propTypes = {
  token: PropTypes.string,
  errors: PropTypes.object,
  registerInterest: PropTypes.func.isRequired,
  registerStatus: PropTypes.bool,
  formStatus: PropTypes.bool,
  resetErrors: PropTypes.func.isRequired,
  resetFormStatus: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  alertCategories: PropTypes.array,
  related: PropTypes.bool,
  landing: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const {errors, registerStatus, formStatus} = state.user
  return {errors, registerStatus, formStatus}
}

export default connect(mapStateToProps, {
  registerInterest,
  resetErrors,
  resetFormStatus,
})(EmailCapture)
