const Formatter = {
  dateFormatter(date) {
    const a = date.split('T')[0].split('-')
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const dateFormatted = `${a[2]} ${months[a[1] - 1]} ${a[0]}`
    return dateFormatted
  },
}

export default Formatter
