import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import {viewGuide, clearGuide, getTopicList} from '../../actions/searchActions'
import {storeUser} from '../../actions/user'
import PaymentForm from '../../components/PaymentForm'
import config from '../../utils/configuration'

const validator = require('email-validator')

const PaymentContainer = ({
  user,
  guide,
  viewGuide,
  clearGuide,
  getTopicList,
  topicList,
  storeUser,
  product,
  closeModal,
  home,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [paymentDetails, updatePaymentDetails] = useState({
    email: null,
    name: null,
    country: null,
    firstName: null,
    lastName: null,
    guide: product,
    terms: false,
    password: null,
    updates: true,
    subscriptionType: product,
  })
  const [paymentStatus, updatePaymentStatus] = useState(null)
  const [paymentError, updatePaymentError] = useState('')
  const [invoiceID, updateInvoiceID] = useState(null)
  const [invoiceStatus, updateInvoiceStatus] = useState(null)
  const [customerID, updateCustomerID] = useState(null)
  const [thisIsRetry, toggleRetry] = useState(false)

  const loggedIn = user._id

  const handleSinglePayment = async (event) => {
    // Prevents page being refreshed
    try {
      event.preventDefault()
      const check = {}
      check.email = !validator.validate(paymentDetails.email)
      check.name = !paymentDetails.name
      // check.country = !paymentDetails.country
      check.terms = !paymentDetails.terms
      check.cardDetails = !paymentDetails.cardDetails
      updateFormError(check)
      const isFormReady = Object.values(check).every((value) => value === false)
      if (!stripe || !elements) {
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }
      if (isFormReady) {
        updatePaymentStatus('processing')
        const sec = await axios.post(`${config.target}/clientSecret`, paymentDetails)
        const result = await stripe.confirmCardPayment(sec.data, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: paymentDetails.name,
            },
          },
        })
        if (result.error) {
          updatePaymentStatus('error')
          updatePaymentError(result.error.message)
          toggleRetry(true)
        } else if (result.paymentIntent.status === 'succeeded') {
          updatePaymentStatus('success')
        }
      }
    } catch (e) {
      console.log(e)
      updatePaymentStatus('error')
    }
  }

  const getNewSubDetails = async () => {
    const userDetails = await axios.post(`${config.target}/subscriptionDetails`, {
      id: user._id,
    })

    storeUser(userDetails.data)
  }

  const generateCustomer = async () => {
    try {
      const customerDetails = {}

      if (loggedIn) {
        customerDetails.email = user.email
        customerDetails.id = user._id
      }

      if (!loggedIn) {
        customerDetails.email = paymentDetails.email
        customerDetails.firstName = paymentDetails.firstName
        customerDetails.lastName = paymentDetails.lastName
        customerDetails.password = paymentDetails.password
      }

      const customer = await axios.post(
        `${config.target}/createCustomer`,
        customerDetails
      )

      if (customer.data.status === 'Already Registered') {
        updatePaymentError(
          'There is already an account linked to the email address you provided. Please log-in to manage your subscription.'
        )
        updatePaymentStatus('error')
      }

      if (customer.status === 200) {
        updateCustomerID(customer.data.id)
        return customer.data.id
      }
    } catch (e) {
      updatePaymentStatus('error')
    }
  }

  const handleSubscription = async (e) => {
    try {
      e.preventDefault()
      if (!stripe || !elements) return

      // Validate form entries. Check that name on CC has been provided if logged-in.
      const check = {}
      if (loggedIn) {
        check.name = !paymentDetails.name
      }
      // check.country = !paymentDetails.country
      if (!loggedIn) {
        check.email = !validator.validate(paymentDetails.email)
        check.firstName = !paymentDetails.firstName
        check.lastName = !paymentDetails.lastName
        check.password = !paymentDetails.password
        check.terms = !paymentDetails.terms
        check.name = !paymentDetails.name
      }
      check.cardDetails = !paymentDetails.cardDetails

      updateFormError(check)
      const isFormReady = Object.values(check).every((value) => value === false)

      if (isFormReady) {
        updatePaymentStatus('processing')

        const customer = customerID || (await generateCustomer())

        const cardPayment = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })

        if (cardPayment.error) {
          toggleRetry(true)
          updatePaymentStatus('error')
          updatePaymentError(cardPayment.error.message)
        } else {
          let createSubscription
          if (thisIsRetry && invoiceStatus === 'requires_payment_method') {
            console.log('This is a retry')
            createSubscription = await axios.post(
              `${config.target}/retrySubscription`,
              {
                customerID: customer,
                paymentMethodID: cardPayment.paymentMethod.id,
                invoiceID,
              }
            )
          }
          if (!(thisIsRetry && invoiceStatus === 'requires_payment_method')) {
            console.log('This is a first subscription')
            createSubscription = await axios.post(
              `${config.target}/createSubscription`,
              {
                customerID: customer,
                paymentMethodID: cardPayment.paymentMethod.id,
                priceID: paymentDetails.subscriptionType,
              }
            )
          }

          // Handle successful subscription
          if (
            createSubscription.data &&
            createSubscription.data.subscription &&
            createSubscription.data.subscription.status === 'active'
          ) {
            updatePaymentStatus('success')
            if (loggedIn) storeUser(createSubscription.data)
            return
          }

          // Handle subscription that needs customer action
          const paymentIntent =
            thisIsRetry && invoiceStatus === 'requires_payment_method'
              ? createSubscription.data.payment_intent
              : createSubscription.data.latest_invoice.payment_intent

          if (
            paymentIntent.status === 'requires_action' ||
            (thisIsRetry && paymentIntent.status === 'requires_payment_method')
          ) {
            const confirmDetails = await stripe.confirmCardPayment(
              paymentIntent.client_secret,
              {
                payment_method: cardPayment.paymentMethod.id,
              }
            )
            console.log('Confirm Details', confirmDetails)

            if (confirmDetails.error) {
              updatePaymentStatus('error')
              updatePaymentError(confirmDetails.error.message)
              toggleRetry(true)
              updateInvoiceID(createSubscription.data.latest_invoice.id)
              updateInvoiceStatus('requires_payment_method')
            }
            if (
              confirmDetails.paymentIntent &&
              confirmDetails.paymentIntent.status === 'succeeded'
            ) {
              updatePaymentStatus('success')
              if (loggedIn) {
                getNewSubDetails()
              }
            }
          }

          // Handle subscription that needs payment method
          if (
            createSubscription.data &&
            createSubscription.data.latest_invoice &&
            createSubscription.data.latest_invoice.payment_intent.status ===
              'requires_payment_method'
          ) {
            updateInvoiceID(createSubscription.data.latest_invoice.id)
            updateInvoiceStatus(
              createSubscription.data.latest_invoice.payment_intent.status
            )
            toggleRetry(true)
            updatePaymentStatus('error')
            updatePaymentError('Your card was declined')
          }
        }
      }
    } catch (e) {
      updatePaymentStatus('error')
    }
  }

  const retryPayment = () => {
    updatePaymentStatus(null)
    updatePaymentError('')
    console.log('Retrying Payment', thisIsRetry)
    if (!thisIsRetry) {
      updatePaymentDetails({
        email: null,
        name: null,
        firstName: null,
        lastName: null,
        password: null,
        country: null,
        guide: product,
        terms: false,
        updates: true,
        subscriptionType: 'monthly',
      })
    }
  }

  const [formError, updateFormError] = useState({})

  let paymentMessage = 'Pay $29'
  if (paymentDetails.guide === 'all') paymentMessage = 'Pay $89'
  if (paymentDetails.subscriptionType === 'expert') paymentMessage = 'Pay $75'
  if (paymentDetails.subscriptionType === 'premium') paymentMessage = 'Pay $50'
  if (paymentDetails.subscriptionType === 'monthly') paymentMessage = 'Pay $5'


  return (
    <PaymentForm
      paymentDetails={paymentDetails}
      updatePaymentDetails={updatePaymentDetails}
      handleSubmit={
        ['expert', 'premium', 'monthly'].includes(product)
          ? handleSubscription
          : handleSinglePayment
      }
      formError={formError}
      paymentStatus={paymentStatus}
      close={closeModal}
      errorMessage={paymentError}
      paymentMessage={paymentMessage}
      retry={retryPayment}
      product={product}
      loggedIn={loggedIn}
    />
  )
}

PaymentContainer.propTypes = {
  user: PropTypes.object.isRequired,
  guide: PropTypes.object.isRequired,
  topicList: PropTypes.object.isRequired,
  viewGuide: PropTypes.func.isRequired,
  clearGuide: PropTypes.func.isRequired,
  getTopicList: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    guide: state.search.guide,
    topicList: state.search.topicList,
  }
}

export default withRouter(
  connect(mapStateToProps, {viewGuide, clearGuide, getTopicList, storeUser})(
    PaymentContainer
  )
)

// https://stripe.com/docs/billing/subscriptions/fixed-price
// https://github.com/stripe-samples/subscription-use-cases/blob/master/fixed-price-subscriptions/server/node/server.js
// https://github.com/stripe-samples/subscription-use-cases/blob/master/fixed-price-subscriptions/client/vanillajs/script.js
