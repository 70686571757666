import React, {useEffect} from 'react'
import {GoogleLogin} from 'react-google-login'
import { gapi } from "gapi-script";
import styled from 'styled-components'
// import {useGoogleLogin} from '@react-oauth/google'
import GoogleLogo from './google.png'

const StyledGoogleButton = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  // marginTop: '25px',
  // padding: '9px',
  padding: '0.75rem 1rem',
  marginTop: '10px',
  // borderRadius: '10px',
  // borderRadius: '5px',
  // boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
  border: '1px solid #DADEE6',
  borderRadius: '100px',
  cursor: 'pointer',
  fontSize: '12px',
  // borderColor: 'transparent',
  backgroundColor: 'white',
  color: '#4285f4',
})

const StyledImage = styled('img')({
  height: '23px',
  width: '23px',
  postition: 'absolute',
  marginRight: '5px',
})

const GoogleLoginButton = ({signIn, createAccount, create}) => {

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '646673792658-h3vddu6tmm756orev5kr2rpoii5vpoas.apps.googleusercontent.com',
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);


  const onSuccess = async (response) => {
    console.log('Succeeded', response);
    const {email} = response.profileObj
    const {tokenId} = response
    create ? createAccount({googleToken: tokenId, googleProfile: response.profileObj}) : signIn(null, null, tokenId)

  }

  const onFailure = async (response) => {
    console.log('Failed', response);
  }

  return (
    <>
      <GoogleLogin
        clientId="646673792658-h3vddu6tmm756orev5kr2rpoii5vpoas.apps.googleusercontent.com"
        render={(renderProps) => (
          <StyledGoogleButton
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <StyledImage src={GoogleLogo} alt='Google' />
            {create ? 'Sign up with Google' : 'Sign in with Google'}
          </StyledGoogleButton>
        )}
        buttonText='Login'
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy='single_host_origin'
      />
    </>
  )
}

export default GoogleLoginButton


// const [responseStatus, updateResponseStatus] = useState(null

// const Glogin = useGoogleLogin({
//   onSuccess: (response) => create ? createAccount({googleToken: response.access_token}) : signIn(null, null, response.access_token)
// })
//
// return (
//   <StyledGoogleButton onClick={() => Glogin()}>
//     <StyledImage src={GoogleLogo} alt='Google' />
//       {create ? 'Sign up with Google' : 'Sign in with Google'}
//   </StyledGoogleButton>
// )

// const onSuccess = async (response) => {
//   console.log('Succeeded', response);
//   // const {email} = response.profileObj
//   // const {tokenId} = response
//   // const userLoginResponse = await login(email, null, true, tokenId)
//   // updateResponseStatus(userLoginResponse.responseMessage)
// }
//
// const onFailure = async (response) => {
//   console.log('Failed', response);
//   // const {email} = response.profileObj
//   // const {tokenId} = response
//   // const userLoginResponse = await login(email, null, true, tokenId)
//   // updateResponseStatus(userLoginResponse.responseMessage)
// }

// return (
//   <>
//     <GoogleLogin
//       clientId="646673792658-h3vddu6tmm756orev5kr2rpoii5vpoas.apps.googleusercontent.com"
//       render={(renderProps) => (
//         <StyledGoogleButton
//           onClick={renderProps.onClick}
//           disabled={renderProps.disabled}
//         >
//           <StyledImage src={GoogleLogo} alt='Google' />
//           Sign in with Google
//         </StyledGoogleButton>
//       )}
//       buttonText='Login'
//       onSuccess={onSuccess}
//       onFailure={onFailure}
//       cookiePolicy='single_host_origin'
//     />
//   </>
// )
