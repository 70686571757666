import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Box, Flex} from 'rebass'
import {updatePersonalInfo, resetError} from '../../../actions/forms/personalInfo'
import {newFirstNameValidator, lastNameValidator} from '../../../utils/validators'
import Input from '../../../components/FormElements/Input'
// import TextBox from '../../../components/FormElements/TextBox'
import Button from '../../../components/FormElements/Button'
import Text from '../../../components/FormElements/Text'
import HeadingNew from '../../../components/FormElements/HeadingNew'
import {Separator} from '../../../components/FormElements/utils'
// import FileUpload from '../../FileUpload'

const PersonalInfoForm = ({
  token,
  _id,
  firstName,
  lastName,
  image,
  about,
  website,
  location,
  organisation,
  position,
  updatePersonalInfo,
  error,
  isPersonalInfoUpdated,
}) => {
  const [formData, setFormData] = useState({
    firstName,
    lastName,
    about,
    location,
    website,
    organisation,
    position,
  })
  const [formError, setFormError] = useState({})
  const [showFeedback, toggleShowFeedback] = useState(false)

  useEffect(() => {
    toggleFeedback(isPersonalInfoUpdated)
  }, [isPersonalInfoUpdated])

  const toggleFeedback = (isUpdated) => {
    if (isUpdated) {
      toggleShowFeedback(true)
      setTimeout(function () {
        toggleShowFeedback(false)
      }, 4500)
    }
  }

  const handleInputChange = (e) => {
    if (e.target) {
      e.preventDefault()
      setFormData({...formData, [e.target.id]: e.target.value})
    } else {
      setFormData({...formData, about: e})
    }
  }

  const handleFiedlOnBlur = (validation) => {
    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    // ------------------
    const firstNameError = newFirstNameValidator(formData)
    const lastNameError = lastNameValidator(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, firstNameError, lastNameError)
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady &&
      updatePersonalInfo({
        _id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        about: formData.about,
        location: formData.location,
        website: formData.website,
        organisation: formData.organisation,
        position: formData.position,
        image,
      })
  }

  return (
    <section>
      <Text as='p' palette='light'>
        Manage your personal information
      </Text>
      <br />
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <HeadingNew size='h3' palette='darkColor' as='h2' spacing='0 0 4px'>
          Name
        </HeadingNew>
        {/* ==================== ERROR */}
        {error && error.form && (
          <Flex
            flexWrap='wrap'
            flexDirection='row'
            alignItems={['normal', 'flex-end']}
            mb={2}
          >
            <Box p={2} pb={0} width={[1, 1, 2 / 3]}>
              <Text errorForm>{error.form}</Text>
            </Box>
          </Flex>
        )}

        {/* ==================== FIELDS */}
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          mb={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='First Name'
              onChange={(e) => handleInputChange(e)}
              value={formData.firstName}
              inputProps={{
                type: 'text',
                id: 'firstName',
                autoComplete: 'false',
                onBlur: () => handleFiedlOnBlur(newFirstNameValidator),
              }}
              error={formError && formError.firstName}
            />
          </Box>
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Last Name'
              onChange={(e) => handleInputChange(e)}
              value={formData.lastName}
              inputProps={{
                type: 'text',
                id: 'lastName',
                autoComplete: 'false',
                onBlur: () => handleFiedlOnBlur(lastNameValidator),
              }}
              error={formError && formError.lastName}
            />
          </Box>
        </Flex>
        <Separator />
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
        >
          <Box px={2} pb='24px' width={[1 / 2, 1 / 2, 1 / 3]}>
            <Button
              onClick={(e) => handleSubmitForm(e)}
              palette='primary'
              fullWidth
              feedback='Personal Info updated'
              showFeedback={showFeedback}
            >
              Update
            </Button>
          </Box>
        </Flex>
      </form>
    </section>
  )
}

PersonalInfoForm.propTypes = {
  token: PropTypes.string,
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.string,
  about: PropTypes.string,
  website: PropTypes.string,
  location: PropTypes.string,
  organisation: PropTypes.string,
  position: PropTypes.string,
  error: PropTypes.object,
  isPersonalInfoUpdated: PropTypes.bool.isRequired,
  updatePersonalInfo: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {token, _id, firstName, lastName, about, image, website, location, organisation, position} = state.user
  const {error, isPersonalInfoUpdated} = state.forms.personalInfo
  return {
    token,
    _id,
    firstName,
    lastName,
    about,
    image,
    website,
    location,
    organisation,
    position,
    error,
    isPersonalInfoUpdated,
  }
}

export default connect(mapStateToProps, {updatePersonalInfo, resetError})(
  PersonalInfoForm
)
//
// <FroalaEditor
//   config={editorConfig.about}
//   onModelChange={(e) => handleInputChange(e)}
//   model={formData.about}
//   style={{minHeight: '100px'}}
// />


// <Box mb={2} width={1} style={{border: '1px solid red'}}>
//   <HeadingNew size='h3' palette='darkColor' as='h2' spacing='0 0 8px'>
//     About
//   </HeadingNew>
//   <Box width={[1, 2 / 3]}>
//     <TextBox
//       label='A description of you / your interests that will appear in your public profile'
//       onChange={(e) => handleInputChange(e.target.value, 'about')}
//       value={formData.about}
//       limit={250}
//       inputProps={{
//         type: 'text',
//         id: 'comment',
//         autoComplete: 'false',
//         // onBlur: () => this.handleFiedlOnBlur()
//       }}
//     />
//   </Box>
//   <Flex
//     flexWrap='wrap'
//     flexDirection='row'
//     alignItems={['normal', 'flex-end']}
//     mx={-2}
//   >
//     <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
//       <Input
//         label='Location'
//         onChange={(e) => handleInputChange(e)}
//         value={formData.location}
//         inputProps={{
//           type: 'text',
//           id: 'location',
//           autoComplete: 'false',
//           onBlur: () => handleFiedlOnBlur(newFirstNameValidator),
//         }}
//         error={formError && formError.location}
//       />
//     </Box>
//     <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
//       <Input
//         label='Website'
//         onChange={(e) => handleInputChange(e)}
//         value={formData.website}
//         inputProps={{
//           type: 'text',
//           id: 'website',
//           autoComplete: 'false',
//           onBlur: () => handleFiedlOnBlur(lastNameValidator),
//         }}
//         error={formError && formError.website}
//       />
//     </Box>
//   </Flex>
// </Box>
// <Box mb={2} width={1}>
//   <HeadingNew size='h3' palette='darkColor' as='h2' spacing='0 0 8px'>
//     Professional
//   </HeadingNew>
//   <Flex
//     flexWrap='wrap'
//     flexDirection='row'
//     alignItems={['normal', 'flex-end']}
//     mx={-2}
//     mb={2}
//   >
//     <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
//       <Input
//         label='Organisation'
//         onChange={(e) => handleInputChange(e)}
//         value={formData.organisation}
//         inputProps={{
//           type: 'text',
//           id: 'organisation',
//           autoComplete: 'false',
//         }}
//         error={formError && formError.organisation}
//       />
//     </Box>
//     <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
//       <Input
//         label='Position'
//         onChange={(e) => handleInputChange(e)}
//         value={formData.position}
//         inputProps={{
//           type: 'text',
//           id: 'position',
//           autoComplete: 'false',
//         }}
//         error={formError && formError.position}
//       />
//     </Box>
//   </Flex>
//
// </Box>
// <FileUpload profile image={image} />
