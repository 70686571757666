import {createStore, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {logger} from 'redux-logger'
import reducer from '../reducers/rootReducers'
import {loadState, saveState} from '../localStorage'
import config from '../utils/configuration'

const persistedState = loadState()

const middleWares = [thunk]
if (config.deployment === 'development') middleWares.push(logger)

const enhancers = compose(
  // applyMiddleware(thunk, logger),
  applyMiddleware(...middleWares),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
)

const store = createStore(
  reducer,
  persistedState,
  enhancers
)

store.subscribe(() => {
  saveState({
    user: store.getState().user,
    article: store.getState().article
  })
})

export default store
