import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Flex} from 'rebass'
import HeadingNew from '../FormElements/HeadingNew'
import Text from '../FormElements/Text'
import AccountLink from '../FormElements/AccountLink'
import Formatter from '../../utils/formatter'

const Title = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;

  span:first-of-type {
    width: 50%;
  }

  span {
    width: 33%;
  }
`
const Options = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  width: 100px;
  align-self: flex-end;
`

const AccountArticle = ({
  data,
  del,
  draft,
  edit,
  savedArticles,
  viewArticle,
  profile,
}) => {
  return (
    <Flex
      width={[1, 4 / 10, 3 / 10]}
      flexDirection='column'
      p='16px'
      alignItems='center'
      style={{
        // background: 'medium',
        border: '1px solid #82a6b2',
        borderRadius: '4px',
        // boxShadow: '0 0 6px 0 rgba(196, 196, 196, 0.5)',
      }}
      mb='10px'
    >
      <Title>
        <HeadingNew size='h3' palette='medium' as='h3'>
          {data.title}
        </HeadingNew>
      </Title>
      <Title>
        <span>
          <Text palette='medium'>
            {data.date && Formatter.dateFormatter(data.date)}
          </Text>
        </span>
        <span>
          <Text palette='medium'>
            {data.likeCount > 0
              ? `${data.likeCount} like${data.likeCount > 1 ? 's' : ''}`
              : null}
          </Text>
        </span>
        <span>
          <Text palette='light'>
            {data.commentCount > 0
              ? `${data.commentCount} comment${data.commentCount > 1 ? 's' : ''}`
              : null}
          </Text>
        </span>
      </Title>
      <Options>
        {!draft && (
          <AccountLink
            onClick={() => viewArticle(data._id, data.title.replace(/ /g, '-'))}
          >
            View
          </AccountLink>
        )}
        {!profile && (
          <AccountLink onClick={() => del(data._id)}>
            {savedArticles ? 'Remove' : 'Delete'}
          </AccountLink>
        )}
        {!savedArticles && !profile && (
          <AccountLink onClick={() => edit(data._id)}>Edit</AccountLink>
        )}
      </Options>
    </Flex>
  )
}

AccountArticle.propTypes = {
  data: PropTypes.object.isRequired,
  del: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  viewArticle: PropTypes.func.isRequired,
  savedArticles: PropTypes.bool,
  profile: PropTypes.bool,
  draft: PropTypes.bool,
}

export default AccountArticle
