import React, {useEffect} from 'react'
import {Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import BlogAdvert from './blogAdvert'
import SideAdvert from './sideAdvert'
import {
  SubHeading,
  SidePanel,
  NewParagraph,
  StyledLink,
  StyledLi,
  StyledSpan,
  Source,
} from './styles'

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      width={1}
      flexDirection='column'
      style={{maxWidth: '700px'}}
      px='20px'
      pb='25px'
    >
      <HeadingNew size='h1' palette='medium' as='h1'>
        How to negotiate a pay rise
      </HeadingNew>
      <HeadingNew size='h3' palette='medium' as='h3' style={{textAlign: 'justify'}}>
        <BlogAdvert tag='career' />
        Regardless of the sector in which you work or position you hold, there is a
        good chance that you could be earning more than you currently do.
        Nevertheless, many people feel uncomfortable asking for and negotiating a
        raise, perhaps because they do not want to be seen as greedy or
        unappreciative, or fear that their request will be rejected. However, even if
        you are fully deserving of a pay rise, you cannot assume that your boss or
        organisation will be proactive in offering you one. In fact, without actively
        asking for what you want and feel that you deserve, your employer is likely
        to assume that you are perfectly happy with the status quo.
        <SubHeading>PREPARATION</SubHeading>
        Before you approach your employer with a request for a pay rise, you should
        clarify exactly why you deserve one. For instance, you may have improved
        significantly, consistently exceeded your objectives or taken on more
        responsibility since starting your role and are now effectively occupying a
        more senior position. You may be working longer hours than you are being paid
        for, market rates may have increased, or you may have recently gained a new
        qualification. Similarly, your salary may no longer adequately reflect the
        amount of experience that you have, while your employer may have a
        contractual obligation to periodically increase or review your pay. At the
        very least, you need to be meeting the objectives that you have been set
        while your request must be justified from the perspective of your employer,
        not simply based on the fact that your personal circumstances have changed
        <sup>a</sup> or because you feel that you have been ‘working hard’ (which is
        expected)<sup>1</sup>.
        <SideAdvert />
        <NewParagraph />
        To back-up your case, compile evidence of your contributions or value to your
        employer. Ideally, this should quantify your impact but qualitative sources
        such as ’thank you’ emails from clients can also be useful<sup>b</sup>. To
        help frame your request, seek feedback from colleagues on your strengths,
        weaknesses and reputation within your organisation, and present your evidence
        in a well organised, clear and structured format so that your boss can easily
        use it to make your case among other key decision makers.
        <NewParagraph />
        Any request relating to salary needs to be well calibrated – too high and it
        will reduce your credibility, too low and it will signal inexperience
        <sup>2</sup>. So, before presenting your case, try to develop an accurate
        understanding of industry standards and benchmarks. The opaque nature of job
        markets means that accurate salary data can be difficult to access, but you
        should at least be able to determine the salaries of comparable job openings.
        Websites such as Glassdoor.com, industry websites that run annual salary
        surveys, recruiters, mentors and trusted colleagues<sup>c</sup> can all
        provide useful guidance but be mindful that salary levels can differ
        considerably according to location, company and sector.
        <SidePanel>
          <SubHeading sp>ASKING FOR A PROMOTION</SubHeading>Writing in the Harvard
          Business Review, Rebecca Knight<sup>3</sup> offers the following tips for
          securing a promotion (which should automatically lead to a pay rise):
          <StyledLi sp>
            <StyledSpan>
              Establish exactly what it is that you want from the promotion. For
              instance, do you want more power or money, to move into an existing
              role or create a new one, or make a vertical or lateral move?
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Look at precedents (i.e., other people that made a similar move) for
              clues on effective strategies.  Develop someone to take-on your
              existing role. This shows leadership and addresses a major concern that
              your boss is likely to have in relation to your potential promotion.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Show that you are ready. Put together a plan for how you would spend
              the first 90 days in the new job. Take steps to demonstrate or build
              any required expertise and ask for feedback on how you are progressing.
            </StyledSpan>
          </StyledLi>
          As with seeking a pay-rise, you should also be mindful of both the pros and
          cons of getting a promotion. For instance, many people find that the
          additional management responsibility that accompanies many promotions
          quickly becomes tiresome.
        </SidePanel>
        <NewParagraph />
        As you formulate your request, try to factor-in the motivations and
        constraints of all the relevant decision-makers<sup>d</sup> . Your request is
        more likely to be successful if you can show how it will benefit each of them
        individually, as well as the wider organisation. For instance, you could try
        to frame a pay rise as a win-win opportunity and highlight how your role can
        develop to address the key issues currently being faced by your employer.
        <NewParagraph />
        To make sure that you are able to effectively deliver the case that you have
        built, consider rehearsing the conversation with a friend or colleague and
        practicing your assertiveness or negotiation skills in less important
        contexts. Try also to anticipate any objections that may arise and
        think-through effective counter arguments.
        <SubHeading>MAKING THE REQUEST</SubHeading>
        To make the initial request, arrange a dedicated, in-person meeting with your
        manager. Make the purpose of the meeting clear, otherwise it may take them by
        surprise and provoke a negative reaction.
        <NewParagraph />
        The timing of the request is also important. Ideally, you should ask just
        after a personal success, or when things are going well for your
        organisation. For instance, you are likely to feel more confident and be
        viewed as more deserving of a pay rise following the delivery of a successful
        project or a positive performance review<sup>e</sup>. You should also try to
        align your request with the budget cycles and internal policies of your
        employer. This may mean starting the process several months before promotions
        are announced or pay rises awarded.
        <NewParagraph />
        To ensure that you make your case as effectively as possible:
        <StyledLi>
          <StyledSpan>
            Approach the conversation confidently and avoid sounding apologetic or
            undeserving (particularly if you have compiled a compelling body of
            evidence to support your request).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            View the process as a collaborative discussion rather than a
            confrontation and realise that it is a request that your manager is
            likely to expect and may even respect, given that it conveys
            self-confidence and proactivity.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            If making the request leaves you feeling greedy or self-conscious, focus
            on the broader benefits of getting a pay-rise for your family, community
            or charities that you support<sup>4</sup>.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Make your case clearly and concisely, focusing on your key arguments
            (listing tenuous reasons can make the request less compelling) and listen
            carefully to and observe the reaction that this elicits<sup>f</sup>.
          </StyledSpan>
        </StyledLi>
        While the subject of salary can be emotive, you should be mindful of the fact
        that you will continue to work with your manager regardless of the outcome of
        the discussion. It is therefore sensible to avoid doing or saying anything
        that negatively impacts your relationship with her. Your manager is likely to
        play a prominent role in promoting your case among other decision makers and
        so tactics such as issuing an ultimatum are unlikely to help. Remember also
        that the people tasked with making the decision are likely to be operating
        under a number of constraints that may hinder their ability to meet your
        request.
        <NewParagraph />
        After the initial meeting, follow-up with an email that summarises the
        discussion and asks for clarification of the next steps. Written confirmation
        of the process will be useful if building a case over time or in the event
        that your manager unexpectedly moves on from her current role. Some employers
        may agree to a rise but delay acting on it so, if the negotiation goes well
        and a pay rise is sanctioned, clarify how it will be implemented and get
        written confirmation of this.
        <SubHeading>DEALING WITH REJECTION</SubHeading>
        Unfortunately, requests for a pay rise are not always successful. Even when
        your employer acknowledges that you are deserving of a raise, a number of
        factors can get in the way of it being sanctioned, including the financial
        position of the organisation and the presence of salary bands. If a request
        for a specific raise is declined, you can ask if any increase can be
        sanctioned. If this is not possible:
        <StyledLi>
          <StyledSpan>
            Establish how circumstances need to change or what you need to do in
            order to get the raise (thus highlighting your commitment).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Request help with achieving this (e.g., through involvement on new
            projects or additional training).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Define the timeframe for the next review (thus highlighting your
            determination).
          </StyledSpan>
        </StyledLi>
        As an alternative, your employer may be amenable to adding or extending other
        benefits, such as flexible working arrangements, a more generous expense
        allocation or training (think through which of these options, if any, would
        be acceptable before your initial discussion). In ‘The Career Playbook’
        <sup>5</sup> James Citrin suggests that you could even propose to undertake a
        special project that, if successful, will be rewarded with a bonus.
        <SubHeading>ACKNOWLEDGE THE PROS AND CONS</SubHeading>
        Before making a request for a pay rise, remember that salary increases, even
        if entirely justified, can create additional expectations and so may be
        accompanied by an increase in workload. Moreover, there is a good chance that
        you will find that having more money makes less of a difference to your life
        than you anticipated. Research<sup>6</sup> suggests that, while earning more
        money can enhance the way that we assess your life if asked about it, beyond
        a certain level it does little to enhance your day-to-day happiness. This may
        be because earning more money does not necessarily allow us to do more of the
        things that actually make us happy, such as spending time with loved ones,
        avoiding disease and enjoying leisure (earning more often means that we have
        less free time). In addition, as we earn more, our ability to purchase more
        positive experiences may be offset by some downsides, such as a reduced
        ability to savour small pleasures. We also quickly become accustomed to most
        changes in our circumstances, so any initial boost in happiness that comes
        from having more disposable income is likely to quickly diminish,
        particularly if we spend our money on material possessions.
        <NewParagraph />
        Similarly, in ‘So Good They Can’t Ignore You’<sup>7</sup> Cal Newport
        suggests that we should think carefully about taking a promotion or moving up
        the organisational hierarchy. While doing so typically results in additional
        benefits such as more money, it also tends to come at the expense of our
        freedom and so is not necessarily a better outcome<sup>g</sup>.
        <NewParagraph />
        We should also beware of ‘fauxmotions’ (which confer a better title and more
        responsibility, with little or no pay rise) and perks that are designed to
        keep us in the office. Moreover, we should acknowledge the possibility that
        we may not actually be suited to a more senior role. As Peter Drucker argues
        in ‘Managing Oneself’<sup>8</sup>, many people are better suited to being
        subordinates rather than leaders and advisers rather than decision makers. As
        he notes, many people that are excellent advisers struggle with the burden of
        taking a decision, which is why the 'number two' in an organisation often
        fails when promoted to the top job.
        <SubHeading>NEW JOBS</SubHeading>
        If you really are intent on maximising your income, the most effective
        approach often involves switching employer. Research<sup>10</sup> suggests
        that external hires tend to be paid as much as 20% more than internal
        promotions into the same job (reflecting the risk and hassle of moving), even
        though their initial performance tends to be worse and they are more likely
        to quickly move on. Moving job can, however, come with potential drawbacks
        including the loss of accumulated social capital.
        <SidePanel>
          <SubHeading sp>DEFLECTING DIFFICULT QUESTIONS</SubHeading> In one 2019
          study<sup>9</sup> psychologists attempted to establish the most effective
          approach to dealing with challenging questions in situations such as
          interviews, where most people employ one of the following techniques:
          <StyledLi sp>
            <StyledSpan>
              <b>Answer honestly.</b> While this can enhance your likability and
              develop trust, in some situations (such as disclosing your current
              salary during a job interview) it introduces a risk of incurring an
              economic cost.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              <b>Decline to answer.</b> This has been shown to negatively impact how
              you are perceived (in terms of likability and trust) more than
              providing an answer that is deemed to be negative.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              <b>Lie.</b> This is risky, as the consequences can be severe if your
              deception is discovered.
            </StyledSpan>
          </StyledLi>
          The study, however, looked at an alternative – deflection, which involves
          responding to a direct question with another that shifts focus back to the
          interrogator. For example, in an interview you could respond to the
          question ‘do you have another offer?’ with ‘do you plan to make me an
          offer?’.
          <NewParagraph />
          Across eight experiments, the study found that deflection was a more
          effective tactic than lying or declining to answer and, in certain cases,
          led to a better economic and interpersonal outcome than an honest
          disclosure (in part because it conveys curiosity). Deflecting questions
          that were on topic and humorous were found to be most effective.
        </SidePanel>
        <NewParagraph />
        New employers will often ask for your salary history and base an offer on
        this, but you have no obligation to provide this information and in many
        places it is illegal to ask for it (your salary should be based on the fair
        market rate rather than what you currently earn). If you are asked to provide
        this information or asked about salary expectations, consider responding in
        one of the following ways<sup>11</sup>:
        <StyledLi>
          <StyledSpan>
            Point out that the new position differs from the old one, then suggest
            that you discuss what the new role entails before determining the salary
            level that would be appropriate.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            State that you would like to learn more about the role or determine
            whether you are a good fit before discussing salary.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Say that you would appreciate if the offer could be based on the
            employer’s budget for the position or fair market rate.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Note that your salary requirement would depend on the benefits package.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Deflect the questions by asking something like ‘what do you normally pay
            someone in this position?’.
          </StyledSpan>
        </StyledLi>
        In ‘Little Black Book’<sup>12</sup> entrepreneur Otegha Uwagba suggests that
        you should avoid being pinned to a salary figure too early in the
        conversation, as this creates a risk that you undersell yourself or price
        yourself out of consideration<sup>h</sup>. She suggests that, if pressed for
        an answer, you should offer a range. And if the scope of the role changes
        during the recruitment process or it becomes clear that it is more involved
        than you initially thought, you should not be afraid to update your desired
        salary and explain why.
        <NewParagraph />
        Alternatively, some recruiters advise that modest inflation<sup>i</sup> of
        your current salary is the best approach when moving to a new organisation,
        which may be reluctant to meet your desired salary given that you are
        untested and will require an upfront investment to train and familiarise with
        new systems, company practices and clients. As one leading recruiter explains
        <sup>13</sup>:
        <NewParagraph />
        <i>
          ‘Take an example scenario – Bob earns £40,000 per annum but wants to get
          close to £50,000 per annum. When asked in interview what his salary
          expectations are – my advice would be to be direct and respond with
          something along the lines of: “I’m on £44,000 per annum plus a good end of
          year bonus and benefits, so all-in, it comes to about £47,500. I’m looking
          to continue to press on in my career – both from a development and earnings
          perspective – so I’m looking for £50,000 – which, incidentally, is also the
          figure that a number of recruiters and head-hunters are suggesting is
          market-rate just now”.
          <br />
          If you consider all the variables there – the salary, the bonus, the
          benefits, the external market, the thirst for development / progression –
          suddenly the idea of being able to bolt £10,000 extra on your earnings,
          doesn’t seem as outlandish or unachievable.’{' '}
        </i>
        <NewParagraph />
        When you receive a job (or promotion) offer it can be tempting to accept
        immediately, particularly if the salary seems reasonable or you are
        unemployed or desperate to leave your current job. While it may seem like you
        will be in a better position to negotiate once you are embedded in a role and
        have demonstrated your capabilities, your starting salary anchors future
        raises, meaning that the cost of failing to negotiate the best possible deal
        when you join a company can be extremely high. Moreover, if you fail to
        secure the best possible deal you are likely to feel undervalued and become
        resentful.
        <NewParagraph />
        When you receive an offer, consider:
        <StyledLi>
          <StyledSpan>
            Expressing gratitude but remaining non-committal and asking for time to
            review it. The more enthusiastic you appear, the less likely your
            prospective employer is to negotiate.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Creating the impression that you are in demand elsewhere.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Asking clarifying questions. For instance, asking how the employer
            calculated the number can provide some insights into whether there is
            scope to negotiate<sup>14</sup>.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Explore any reservations that you have and turn down the offer if you
            decide that the role is not right for you.
          </StyledSpan>
        </StyledLi>
        <SidePanel>
          <SubHeading sp>A RECRUITER’S PERSPECTIVE</SubHeading> While recruiters may
          balk at high wage demands, former Netflix Chief Talent Officer Patty McCord
          argues<sup>15</sup> that paying more for top candidates is often worth it,
          not just because they are likely to perform well but because doing so
          prevents them from joining a competitor and encourages other talented
          people to stay or join.
          <NewParagraph />
          McCourt believes that the 80 / 20 rule applies to teams (i.e., the top 20%
          of team members are generally responsible for the majority of outputs) so,
          where budget is an issue, recommends that organisations identify the most
          important positions and pay what is necessary to fill them with excellent
          people.
          <NewParagraph />
          Moreover, she notes that organisations must maintain an accurate
          understanding of market conditions in order to ensure that the salaries
          they offer remain competitive. Particularly in fast-moving industries,
          salary benchmarking and boundaries based on historical figures can quickly
          become outdated.
        </SidePanel>
        Many of us resist pushing for a better deal because we fear that by doing so
        we risk losing the opportunity. However, employers will generally make an
        initial offer towards the lower end of their budgets, so there is almost
        always scope to negotiate (many employers will expect this<sup>j</sup>). The
        recruitment process is also highly involved for the recruiting company, so by
        the time you receive an offer your prospective employer will have made a
        significant investment and is likely to have a strong desire to secure your
        services. Nevertheless, coming across as aggressive or overly money-focused
        during any negotiation can derail the process: while rescinding an offer will
        be frustrating for any prospective employer, it will be viewed as a far
        better option than making a mistaken hire.
        <NewParagraph />
        When assessing a job offer, make sure that you fully understand the benefits
        being offered. In particular, bonus or equity incentive schemes are often
        structured in such a way that the headline pay-out they promise is extremely
        difficult to realise. You should also establish how you can expect your
        salary (and pension contributions) to grow as you continue in the role and on
        what basis promotions and pay rises will be determined.
        <NewParagraph />
        Your assessment of a job offer should also look beyond monetary aspects to
        consider factors including holiday allowance, parental leave, the notice
        period, non-compete clauses and the length of your commute. Many of these are
        also likely to be open to negotiation. In ‘The new rules of work’
        <sup>16</sup> Alexandra Cavoulacos and Kathryn Minshew even recommend
        negotiating your title, which can make a significant difference in your next
        job search. In some cases, the potential long-term benefits<sup>k</sup> on
        offer, such as the training you will receive or ‘brand name’ of the employer,
        may justify a short-term salary sacrifice.
        <SubHeading>NEGOTIATING AS A FREELANCER</SubHeading>
        In ‘Little Black Book‘ entrepreneur Otegha Uwagba suggests that if pitching
        for a stand-alone project or contract work, you should first ask for the
        client’s budget. This allows you to craft your approach without pricing
        yourself too low or high. She recommends regularly reviewing your rates to
        reflect your experience and achievements and argues that you should not be
        afraid to increase these with existing clients (although you should do so no
        more than once a year). One way to broach the topic with existing clients is
        to indicate the upcoming price increase but offer to do one more project at
        the existing rate as a gesture of goodwill. If a client refuses or seems
        reluctant to pay more, plan to phase them out and replace them with someone
        willing to pay the new rates.
        <NewParagraph />
        If working as a freelancer or contractor, she notes, you should always make
        sure that there is a written contract in place before starting with a new
        client. This should include:
        <StyledLi>
          <StyledSpan>A clear outline of what will be delivered.</StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Details of how any work that falls outside of this scope will be billed.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            A payment schedule (aim to get 30%-50% paid upfront).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Timings and deadlines, including obligations for clients to provide
            timely feedback and responses (to prevent the project from being dragged
            out).
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Details of IP ownership and, if appropriate, licensing. Ideally, IP
            should not be transferred until full payment is received.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            An itemised break-down of deliverables and estimated costings for each.
            This means that the change of fee will be clear if part of the project is
            cancelled or extended.
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            A cancellation clause that ensures a fee is due if the project is
            cancelled.
          </StyledSpan>
        </StyledLi>
        <NewParagraph />
        In ‘The Secrets of Consulting’<sup>17</sup>, Gerald M. Weinberg highlights
        the importance of pricing your time correctly when you work for yourself. He
        recommends setting an hourly rate that is around five times your target
        salary, reflecting the likelihood that you will spend around 25% of your time
        marketing and lose 25% to ‘slack’, half of what you earn will go to
        administration and expenses and 20% should be kept as a contingency reserve.
      </HeadingNew>

      <Flex width={1} flexDirection='column' mt='10px'>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          style={{textAlign: 'justify'}}
        >
          <SubHeading>Footnotes</SubHeading>

          <Source>
            a. In other words, framed in terms of what you deserve rather than what
            you simply want.
          </Source>
          <Source>
            b. Try to make a point of recording these as you work, for instance by
            emailing yourself an overview of your achievements at the end of each
            week.
          </Source>
          <Source>
            c. Avoid referring to the salaries of colleagues during the process –
            such information is intended to be confidential (so any disclosure may
            have repercussions) and the difference may very well be justified, unless
            it reflects some form of discrimination.
          </Source>
          <Source>
            d. Try to build relationships with everyone likely to be involved in the
            decision, not just your direct boss.
          </Source>
          <Source>
            e. In some cases, however, your negotiating position can be strengthened
            in the wake of crisis events (e.g., a key employee leaving to go to a
            competitor).
          </Source>
          <Source>
            f. See the{' '}
            <StyledLink to='/guides/Effective-Communication'>
              Sivv Guide to Effective Communication
            </StyledLink>{' '}
            on managing body language.
          </Source>
          <Source>
            g. Note, however, that many organisations adopt an ‘up or out’ approach,
            so will seek to move on anyone that is not deemed to be progressing.
          </Source>
          <Source>
            h. Nevertheless, a large body of research shows that the first figure
            mentioned in negotiations is hugely influential as it ‘anchors’ the
            subsequent conversation. You may therefore put yourself in a stronger
            position if you define that figure (when it is appropriate to do so). For
            instance, it will probably be easier to secure a salary of $55k if the
            first figure mentioned is $60k rather than $50k.
          </Source>
          <Source>
            i. There is a limit to how much more than the advertised salary you can
            ask for, but many experts recommend targeting a figure that is 20% higher
            than your current / target income, which allows for some negotiating
            down.
          </Source>
          <Source>
            j. While you do not want to seem motivated solely by money, an inability
            to defend your own interests may be interpreted as evidence that you will
            not be able to defend those of your employer.
          </Source>
          <Source>
            k. Such questions are best left until after you have received an offer.
          </Source>
        </HeadingNew>
      </Flex>
      <Flex width={1} flexDirection='column' mt='10px'>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          style={{textAlign: 'justify'}}
        >
          <SubHeading>Sources</SubHeading>
          <Source>
            1. Brewer, K (2015), Guardian Jobs advice on how to negotiate a pay rise,
            jobs.theguardian.com
          </Source>
          <Source>
            2. Shilton, AC (2018), How to Be an Ace Salary Negotiator (Even if You
            Hate Conflict), nytimes.com
          </Source>
          <Source>3. Knight, R (2018), How to Ask for a Promotion, hbr.org</Source>
          <Source>
            4. Wong, K (2018), You Need a Raise. How Do You Start the Conversation?,
            nytimes.com
          </Source>
          <Source>5. Citrin, J (2015), The Career Playbook, Currency</Source>
          <Source>
            6. Kahneman, D and Deaton, A (2010), High income improves evaluation of
            life but not emotional well-being, PNAS September 21, 2010 107 (38)
            16489-16493; https://doi.org/10.1073/pnas.1011492107
          </Source>
          <Source>
            7. Newport, C (2016), So Good They Can’t Ignore You, Piatkus
          </Source>
          <Source>
            8. Drucker, PF (2008), Managing Oneself, Harvard Business Review Press
          </Source>
          <Source>
            9. Bitterly TB and Schweitzer ME (2019), The economic and interpersonal
            consequences of deflecting direct questions., J Pers Soc Psychol. 2020
            May;118(5):945-990. doi: 10.1037/pspi0000200
          </Source>
          <Source>
            10. Bidwell, M (2011), Paying More to Get Less: The Effects of External
            Hiring versus Internal Mobility, Administrative Science Quarterly,
            Volume: 56 issue: 3, page(s): 369-407,
            https://doi.org/10.1177/0001839211433562
          </Source>
          <Source>
            11. American Association of University Women, Work Smart & Start Smart:
            Salary Negotiation, aauw.org
          </Source>
          <Source>12. Uwagba, O (2017), Little Black Book, Fourth Estate</Source>
          <Source>13. Moreland, J (2018)</Source>
          <Source>
            14. Westad, A (2020), Salary Negotiation: The only article you’ll need to
            read, hiperpool.com
          </Source>
          <Source>15. McCord, P (2018), How to Hire, hbr.org</Source>
          <Source>
            16. Cavoulacos, A and Minshew, K (2017), The New Rules of Work: The
            ultimate career guide for the modern workplace, Orion Spring
          </Source>
          <Source>
            17. Weinberg, GM (1985), The Secrets of Consulting: A Guide to Giving and
            Getting Advice Successfully, John Wiley & Sons
          </Source>
        </HeadingNew>
      </Flex>
    </Flex>
  )
}

export default Career
