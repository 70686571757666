const Toolbar = {
  moreText: {
    buttons: ['bold', 'italic', 'underline'],
    buttonsVisible: 5,
  },
  moreParagraph: {
    buttons: ['alignLeft', 'alignCenter', 'formatOL', 'formatUL'],
    buttonsVisible: 6,
  },
  moreRich: {
    buttons: ['insertLink'],
    buttonsVisible: 1,
  },
  moreMisc: {
    buttons: ['fullscreen'],
    align: 'right',
    buttonsVisible: 1,
  },
}

const Fkey = '3AC6eD4F6F4A3H3E3C-22vutF4voqI-7B9cdpF1F1E1D4F1A10A8C2C5E5=='

const ToolbarSmall = [
  'bold',
  'italic',
  'underline',
  'formatOL',
  'formatUL',
  'insertLink',
  'fullscreen',
]

const CommonSettings = {
  charCounterCount: true,
  fullPage: true,
  width: '100%',
  quickInsertTags: null,
  pastePlain: true,
  attribution: false,
  toolbarButtons: Toolbar,
  toolbarButtonsSM: Toolbar,
  toolbarButtonsXS: ToolbarSmall,
  key: Fkey,
}

const editorConfig = {
  background: {
    placeholderText:
      'Is there anything that a non-expert would need to know to understand this?',
    charCounterCount: true,
    charCounterMax: 500,
    heightMin: 100,
    ...CommonSettings,
  },

  summary: {
    placeholderText: 'A concise overview of the key takeaway',
    charCounterCount: true,
    heightMin: 200,
    ...CommonSettings,
  },

  details: {
    placeholderText: 'More information on the key idea',
    charCounterCount: true,
    charCounterMax: 1500,
    heightMin: 200,
    ...CommonSettings,
  },

  applications: {
    placeholderText: 'What are the main applications and keys to implementation?',
    charCounterCount: true,
    charCounterMax: 1000,
    heightMin: 200,
    ...CommonSettings,
  },

  examples: {
    placeholderText: 'Examples demonstrating the idea in question',
    charCounterCount: true,
    charCounterMax: 500,
    heightMin: 100,
    ...CommonSettings,
  },

  other: {
    placeholderText:
      'Where can people find out more? What other topics are relevant?',
    charCounterCount: true,
    charCounterMax: 500,
    heightMin: 100,
    ...CommonSettings,
  },
}

export default editorConfig

// background: {
//   placeholderText:
//     'Is there anything that a non-expert would need to know to understand this?',
//   charCounterCount: true,
//   charCounterMax: 500,
//   fullPage: true,
//   heightMin: 100,
//   width: '100%',
//   quickInsertTags: null,
//   pastePlain: true,
//   attribution: false,
//   toolbarButtons: Toolbar,
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },

// summary: {
//   placeholderText: 'A concise overview of the key takeaway',
//   charCounterCount: true,
//   // charCounterMax: 1500,
//   fullPage: true,
//   heightMin: 200,
//   width: '100%',
//   quickInsertTags: null,
//   attribution: false,
//   pastePlain: true,
//   fontSize: ['10'],
//   border: 'none',
//   fontFamily: {
//     'Quicksand,sans-serif': 'Quicksand',
//   },
//   colorsText: ['red'],
//   listAdvancedTypes: true,
//   toolbarButtons: Toolbar,
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },

// details: {
//   placeholderText: 'More information on the key idea',
//   charCounterCount: true,
//   charCounterMax: 1500,
//   fullPage: true,
//   heightMin: 200,
//   width: '100%',
//   quickInsertTags: null,
//   attribution: false,
//   pastePlain: true,
//   fontSize: ['10'],
//   border: 'none',
//   fontFamily: {
//     'Quicksand,sans-serif': 'Quicksand',
//   },
//   colorsText: ['red'],
//   listAdvancedTypes: true,
//   toolbarButtons: Toolbar,
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },

// applications: {
//   placeholderText: 'What are the main applications and keys to implementation?',
//   // charCounterCount: true,
//   charCounterMax: 1000,
//   attribution: false,
//   fullPage: true,
//   heightMin: 200,
//   width: '100%',
//   quickInsertTags: null,
//   pastePlain: true,
//   toolbarButtons: Toolbar,
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },
//
// examples: {
//   placeholderText: 'Examples demonstrating the idea in question',
//   attribution: false,
//   charCounterCount: true,
//   charCounterMax: 500,
//   fullPage: true,
//   heightMin: 100,
//   width: '100%',
//   quickInsertTags: null,
//   pastePlain: true,
//   toolbarButtons: Toolbar,
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },

// other: {
//   placeholderText:
//     'Where can people find out more? What other topics are relevant?',
//   attribution: false,
//   charCounterCount: true,
//   charCounterMax: 500,
//   fullPage: true,
//   heightMin: 100,
//   width: '100%',
//   quickInsertTags: null,
//   pastePlain: true,
//   toolbarButtons: Toolbar,
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },

// about: {
//   placeholderText:
//     'A description of you / your interests that will appear in your public profile',
//   attribution: false,
//   charCounterCount: true,
//   charCounterMax: 500,
//   fullPage: true,
//   heightMin: 125,
//   width: '100%',
//   quickInsertTags: null,
//   pastePlain: true,
//   toolbarButtons: {
//     moreText: {
//       buttons: ['bold', 'italic', 'underline'],
//       buttonsVisible: 5,
//     },
//     moreRich: {
//       buttons: ['insertLink'],
//       buttonsVisible: 1,
//     },
//   },
//   toolbarButtonsSM: Toolbar,
//   toolbarButtonsXS: ToolbarSmall,
//   key: Fkey,
// },

// viewer: {
//   events: {
//     'froalaEditor.initialized': function (e, editor) {
//       editor.edit.off()
//       editor.toolbar.hide()
//     },
//     // 'froalaEditor.initialized': function (e, editor) {
//     //   editor.toolbar.hide();
//     // },
//   },
//   quickInsertTags: null,
//   width: '100%',
// },
