import React from 'react'
import {Box, Flex, Image} from 'rebass'
import styled from 'styled-components'
import Icons from '../../images/icons/icons'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Button from '../../components/FormElements/Button'

const BundleImageHolder = styled.div`
  width: 75px;
  @media screen and (min-width: 52em) {
    width: 125px;
  }
`

const Bundle = ({addGuide}) => {
  return (
    <Flex
      style={{border: '1px solid #f2f3f5', maxWidth: '1000px'}}
      p={3}
      justifyContent='space-around'
      flexWrap='wrap'
      my={3}
      id='bundle'
    >
      {['ProductivityGuide', 'CommunicationGuide', 'HabitsGuide', 'CareerGuide'].map(
        (a) => (
          <BundleImageHolder key={a}>
            <Image
              src={Icons[a]}
              width='100%'
              mb={[0, 2]}
              mt={2}
              ml={0}
              style={{
                maxWidth: '125px',
                maxHeight: '200px',
                float: 'left',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            />
          </BundleImageHolder>
        )
      )}
      <Flex
        flexDirection='column'
        width='auto'
        justifyContent='space-around'
        alignItems='center'
        p={[2, 0]}
      >
        <Box my='25px' style={{maxWidth: '900px'}}>
          <HeadingNew size='h3' palette='medium' as='h3' align='center'>
            Buy the Sivv guides to Managing Habits, Effective Communication, Career
            Development and Being Productive together and save 25%.
          </HeadingNew>
        </Box>
        <Box width={1} style={{maxWidth: '400px'}}>
          <Button id='btnopen' fullWidth onClick={() => addGuide('all')}>
            Buy now ($89)
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Bundle
