import actionTypes from '../../actions/forms/password/actionTypes'

const initialState = {
  error: null,
  isPasswordUpdated: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_ERROR:
      return {
        ...state,
        error: null,
      }

    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actionTypes.TOGGLE_FORM_STATUS:
      return {
        ...state,
        isPasswordUpdated: action.processed,
      }

    default:
      return state
  }
}
