import React from 'react'
import HeadingNew from '../FormElements/HeadingNew'

import {ArticleContainer, HomeSummaryContainer} from './styles'

const HomeArticle = ({
  viewArticle,
  title,
  digest,
  sourceLogo,
  sourceURL,
  theme,
  topics,
  type,
  landing,
}) => {

  return (
    <ArticleContainer
      style={{
        height: 'auto',
        background: landing ? '#2e4369' : '#fff',
        border: landing ? '1px solid #37517e' : '1px solid #dee2e6',
      }}
      home
    >
      <HeadingNew size='h3' palette={landing ? 'white' : 'medium'} as='h3' condensed>
        {sourceLogo && (
          <img
            src={sourceLogo}
            height='20px'
            style={{marginRight: '7px', marginTop: '3px', float: 'left'}}
            alt='source'
          />
        )}
        {title}
      </HeadingNew>
      <HomeSummaryContainer>{digest}</HomeSummaryContainer>
    </ArticleContainer>
  )
}

export default HomeArticle
