/*
  Heading:
  - can be any element (preferably H, potentially p for subtitle)
  - using fonts definitions from 'styles/fonts'
  - using colors definitions from 'styles/colors'

  Params:
  size: h1 | h2 | h3 (NO DEFAULT)
  weight: regular | bold (default to regular)
  palette: dark | medium | light (default to dark)

  Example:
  <HeadingNew as='h1' size='h1' weight='bold' palette='light'>Title</HeadingNew>
*/

import styled, {css} from 'styled-components'
import {
  // size
  h0,
  h1,
  h2,
  h3,
  // weight
  regularTitle as regular,
  boldTitle as bold,
} from '../../../styles/fonts'
import {
  darkTitle as darkColor,
  mediumTitle as mediumColor,
  lightTitle as lightColor,
} from '../../../styles/colors'

const availableSize = {h0, h1, h2, h3}
const availableColour = {
  dark: darkColor,
  medium: mediumColor,
  light: lightColor,
  white: '#FFFFFF',
  orange: '#FF6600',
}
const availableWeight = {regular, bold}

export default styled.div`
  ${(props) => availableSize[props.size]};
  font-weight: ${(props) =>
    props.weight ? availableWeight[props.weight] : regular};
  color: ${(props) =>
    props.palette ? availableColour[props.palette] : availableColour.dark};
  text-align: ${(props) => (props.centerText ? 'center' : props.justify ? 'justify' : 'left')};
  line-height: ${(props) => props.condensed && 1.5};
  /* ----------------------------------------- SPACING */
  ${(props) =>
    props.spacing &&
    css`
      margin: ${props.spacing};
    `}

  /* ----------------------------------------- SIZE */
  ${(props) =>
    props.titleHighlight &&
    css`
      display: inline-block;
      padding: 16px;
      background: #29b0e8;
      border-radius: 4px 4px 0 0;
      margin: 0;
      position: relative;
      color: white;
      line-height: 1;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 16px solid #29b0e8;
        position: absolute;
        z-index: 2;
        left: 48px;
        bottom: -16px;
      }
    `}
`
