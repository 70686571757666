import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect, Link} from 'react-router-dom'
import {Flex, Box, Image} from 'rebass'
import {createAccount, resetErrors, resetFormStatus} from '../../actions/user'
import {
  newFirstNameValidator,
  lastNameValidator,
  newEmailValidator,
  passwordValidator,
  // passwordRepeatValidator,
} from '../../utils/validators'
import GoogleLogin from '../GoogleLogin'

import Button from '../../components/FormElements/Button'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Input from '../../components/FormElements/Input'
import Checkbox from '../../components/FormElements/Checkbox'
import Text from '../../components/FormElements/Text'
import Container from '../../components/FormElements/Container'
import {LinkRouter} from '../../components/FormElements/Link'
import loginIllustration from '../../images/illustration/knowledge.png'

const Header = styled.div`
  margin-bottom: 16px;
`

const CreateAccount = ({
  token,
  errors,
  lpEmail,
  createAccount,
  formStatus,
  resetErrors,
  resetFormStatus,
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: lpEmail || '',
    password: '',
    terms: false,
  })
  const [formError, setFormError] = useState({})

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const checkTerms = (formData) => {
    let a
    if (formData.terms) a = {terms: null}
    if (!formData.terms) a = {terms: 'Accepts terms'}
    return a
  }

  const handleCreateAccountSubmit = async (e) => {
    e.preventDefault()
    const firstNameError = newFirstNameValidator(formData)
    const lastNameError = lastNameValidator(formData)
    const emailError = newEmailValidator(formData)
    const passwordError = passwordValidator(formData)
    // const passwordRepeatError = passwordRepeatValidator(formData)
    const termsError = checkTerms(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(
      existingErrors,
      firstNameError,
      lastNameError,
      emailError,
      passwordError,
      // passwordRepeatError,
      termsError
    )
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    // && formData.terms
    isFormReady && createAccount(formData)
  }

  // Ensure to clean errors before unmounting
  useEffect(() => {
    resetErrors()
    resetFormStatus()
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {token && <Redirect to='/' />}
      <Container minHeight background='#e8eef4'>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent='center'
          alignItems='center'
        >
          <Flex
            flexDirection='column'
            width={[1, 1 / 2, 1 / 2]}
            justifyContent='center'
            displayFlex='flex'
            as='div'
            style={{maxWidth: '400px'}}
          >
            {!formStatus && (
              <>
                <Box p={2}>
                  <Header as='header'>
                    <HeadingNew size='h1' palette='medium' as='h2'>
                      Create Account
                    </HeadingNew>
                  </Header>
                </Box>
                {errors && errors.form && (
                  <Box p={2}>
                    <Text errorForm>{errors.form}</Text>
                  </Box>
                )}
                <Flex>
                  <Box width={1 / 2} p={2}>
                    <Input
                      label='First name *'
                      onChange={(e) => handleInputChange(e)}
                      value={formData.firstName}
                      inputProps={{
                        type: 'text',
                        id: 'firstName',
                        name: 'firstName',
                        autoComplete: 'new-password',
                        // onBlur: () => handleFiedlOnBlur(newFirstNameValidator),
                      }}
                      error={formError && formError.firstName}
                    />
                  </Box>
                  <Box width={1 / 2} p={2}>
                    <Input
                      label='Last name *'
                      onChange={(e) => handleInputChange(e)}
                      value={formData.lastName}
                      hasText={formData.lastName !== ''}
                      inputProps={{
                        type: 'text',
                        id: 'lastName',
                        name: 'lastName',
                        autoComplete: 'new-password',
                        // onBlur: () => handleFiedlOnBlur(lastNameValidator),
                      }}
                      error={formError && formError.lastName}
                    />
                  </Box>
                </Flex>
                <Box p={2}>
                  <Input
                    label='Email *'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.email}
                    hasText={formData.email !== ''}
                    inputProps={{
                      type: 'text',
                      id: 'email',
                      name: 'email',
                      autoComplete: 'new-password',
                      // onBlur: () => handleFiedlOnBlur(newEmailValidator),
                    }}
                    error={formError && formError.email}
                  />
                </Box>
                <Box p={2}>
                  <Input
                    label='Password *'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.password}
                    hasText={formData.password !== ''}
                    inputProps={{
                      type: 'password',
                      id: 'password',
                      name: 'password',
                      autoComplete: 'new-password',
                      // onBlur: () => handleFiedlOnBlur(passwordValidator),
                    }}
                    error={formError && formError.password}
                  />
                </Box>
                <Box p={2} width={1}>
                  <Checkbox
                    label={<Link to='/termsofuse'>Accept terms & conditions</Link>}
                    name='terms'
                    inputProps={{
                      id: 'terms',
                      name: 'terms',
                      onChange: (e) =>
                        setFormData({...formData, terms: !formData.terms}),
                      checked: formData.checked,
                    }}
                    fullWidth
                    error={formError && formError.terms}
                  />
                </Box>
                <Box p={2} pb='15px'>
                  <LinkRouter to='/signin'>Already have an account?</LinkRouter>
                </Box>
                <Flex width={1} flexDirection={['column', 'row']}>
                  <Box px={2} width={[1, 1 / 2, 1 / 2]}>
                    <Button
                      onClick={(e) => handleCreateAccountSubmit(e)}
                      palette='primary'
                      fullWidth
                    >
                      Create Account
                    </Button>
                  </Box>
                  <Box px={2} width={[1, 1 / 2, 1 / 2]}>
                    <GoogleLogin create createAccount={createAccount} />
                  </Box>
                </Flex>
              </>
            )}
            {formStatus && (
              <>
                <Box p={2}>
                  <Header as='header'>
                    <HeadingNew size='h1' palette='medium' as='h2'>
                      Account created
                    </HeadingNew>
                  </Header>
                </Box>
                <Box px={2}>
                  <Text>
                    Thank you for your request. Please check your emails to confirm
                    your account.
                  </Text>
                </Box>
              </>
            )}
          </Flex>
          <Flex
            flexDirection='column'
            justifyContent='center'
            p={2}
            px={[80, 2, 2]}
            width={[1, 1 / 2, 1 / 2]}
            ml={[0, 0, 80]}
            mt={[25, 0, 0]}
            textAlign='center'
          >
            <Box>
              <Image
                width={1}
                src={loginIllustration}
                alt='Knowledge'
                title='Knowledge'
                style={{maxWidth: '400px'}}
              />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </>
  )
}

CreateAccount.propTypes = {
  token: PropTypes.string,
  errors: PropTypes.object,
  createAccount: PropTypes.func.isRequired,
  formStatus: PropTypes.bool,
  resetErrors: PropTypes.func.isRequired,
  resetFormStatus: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {token, errors, formStatus, lpEmail} = state.user
  return {token, errors, formStatus, lpEmail}
}

export default connect(mapStateToProps, {
  createAccount,
  resetErrors,
  resetFormStatus,
})(CreateAccount)
