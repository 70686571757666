import styled from 'styled-components'

export const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  /* min-width: 600px; */
  max-width: 750px;
  line-height: 1.5;
  margin-bottom: ${(props) => (props.home ? '25px' : '0px')};
  padding: ${(props) =>
    props.singleArticle || props.home ? '25px 16px' : '0px 25px 0px 25px'};
  box-sizing: border-box;
  border-radius: ${(props) => (props.singleArticle ? '0px' : '4px')};
  background: ${(props) => (props.singleArticle ? 'none' : '#fff')};
  @media screen and (max-width: 600px) {
    padding: ${(props) =>
      props.singleArticle || props.home ? '25px 16px' : '0px 16px 0px 16px'};
    /* min-width: 300px; */
  }
`

export const TopicsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
`

export const ArticleInteractionContainer = styled.div`
    /* height: 35px; */
    /* width: 190px; */
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 25px;
    @media screen and (max-width:600px) {
        /* width: 90%;
        margin: auto; */
    `

export const Diagram = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;

  @media screen and (max-width: 600px) {
    max-height: 250px;
  }
`

export const Tag = styled.div`
  font-size: 10px;
  color: #a9a3a3;
  margin-right: 10px;
`

export const ActionIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const NumberContainer = styled.div`
  font-size: 8px;
  cursor: pointer;
  min-width: 10px;
  margin-left: 3px;
`

export const BrowseButton = styled.div`
  cursor: pointer;
  color: #de7033;
  border: 1px solid #de7033;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;

  :hover {
    background: #de7033;
    color: #fff;
  }
`

export const Hider = styled.div`
  margin: auto;
  @media screen and (max-width: 52em) {
    display: none;
  }
`

export const Shower = styled.div`
  margin: auto;
  @media screen and (min-width: 52em) {
    display: none;
  }
`

export const SummaryContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #0a1f44;
  max-height: 82px;
  font-family: OpenSans, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`

export const FullContainer = styled.div`
  color: #0a1f44;
  font-family: OpenSans, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`

export const ArticleTitle = styled.div`
  color: #0a1f44;
  font-family: OpenSans, sans-serif;
  font-weight: 325;
  font-size: 20px;
  line-height: 28px;
`

export const ArticleSeparator = styled.div`
  border-top: 1px solid #f2f3f5;
  width: 75%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const HomeSummaryContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #fff;
  max-height: 35px;
  font-family: OpenSans, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`
