import {createGlobalStyle} from 'styled-components'
import {normalize, rem} from 'polished'

const GlobalStyles = createGlobalStyle`

  ${normalize()}

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: OpenSans, sans-serif;
    font-size: ${rem(13)};
    color: #282828;
    margin-top: 60px;
    line-height: ${rem(18)};
  }
`

export default GlobalStyles
