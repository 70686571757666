import {
  SAVE_DRAFT,
  ADD_CATEGORY,
  REMOVE_CATEGORY,
  ADD_ARTICLE_TYPE,
  SAVE_ARTICLE_START,
  SAVE_ARTICLE_EMPTY,
  SAVE_ARTICLE_SUCCESS,
  SAVE_ARTICLE_ERROR,
  EDIT_ARTICLE_SUCCESS,
  CLEAR_DRAFT,
  UPLOAD_START,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  CLEAR_IMAGE,
} from '../actions/articleActions'
import Categories from './categories'

const defaultState = {
  article: [],
  draft: {
    title: '',
    sourceType: '',
    sourceTitle: '',
    digest: '',
    sourceURL: '',
    viaType: '',
    viaTitle: '',
    viaURL: '',
    author: '',
    summary: '',
    details: '',
    background: '',
    applications: '',
    examples: '',
    other: '',
    theme: '',
    topics: '',
    type: '',
    _id: '',
    status: '',
    image: '',
    premium: false,
    newsletter: false,
    relatedTitle: '',
    relatedURL: '',
  },
  draftTags: [],
  topics: Categories.topics,
  theme: Categories.themes,
  type: Categories.types,
  sourceType: Categories.sourceTypes,
  saving: false,
  error: false,
  feedback: {message: null, time: null},
  uploading: false,
  uploadError: false,
}

const article = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_DRAFT:
      return defaultState

    case SAVE_DRAFT:
      return {
        ...state,
        draft: {...state.draft, [action.id]: action.content},
      }

    case ADD_CATEGORY:
      return {
        ...state,
        [action.category]: state[action.category].filter(
          (c) => c.label !== action.label
        ),
        draft: {
          ...state.draft,
          [action.category]: state.draft[action.category].concat(
            ` #${action.label}`
          ),
        },
      }

    case REMOVE_CATEGORY:
      return {
        ...state,
        [action.category]: [
          ...state[action.category],
          {label: action.label, value: action.label},
        ],
        draft: {
          ...state.draft,
          [action.category]: state.draft[action.category].filter(
            (c) => c !== action.label
          ),
        },
      }

    case ADD_ARTICLE_TYPE:
      return {
        ...state,
        draft: {...state.draft, [action.name]: action.value},
      }

    case SAVE_ARTICLE_START:
      return {
        ...state,
        saving: true,
        error: false,
      }

    case SAVE_ARTICLE_ERROR:
      return {
        ...state,
        error: true,
        saving: false,
        feedback: {message: 'Something went wrong - please try again'},
      }

    case SAVE_ARTICLE_SUCCESS:
      return {
        ...state,
        error: false,
        feedback: {
          message:
            action.status === 'published'
              ? 'Article published'
              : action.status === 'unpublish'
              ? 'Article unpublished'
              : 'Article saved',
          time: new Date().getTime(),
        },
        draft: {...state.draft, _id: action._id},
      }

    case SAVE_ARTICLE_EMPTY:
      return {
        ...state,
        feedback: {message: 'Please write something', time: new Date().getTime()},
      }

    case EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        draft: action.article,
      }

    case UPLOAD_START:
      return {
        ...state,
        uploading: true,
        uploadError: false,
      }

    case UPLOAD_SUCCESS:
      return {
        ...state,
        draft: {...state.draft, image: action.url},
        uploading: false,
        uploadError: false,
      }

    case UPLOAD_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: true,
      }

    case CLEAR_IMAGE:
      return {
        ...state,
        draft: {...state.draft, image: ''},
      }

    default:
  }
  return state
}

export default article
