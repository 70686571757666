import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Flex, Image} from 'rebass'
import {
  viewGuide,
  clearGuide,
  getTopicList,
  downloadGuide,
  downloadSample,
  recordView,
} from '../../actions/searchActions'
import EmailCapture from '../EmailCapture'
import PaymentContainer from '../PaymentContainer'
import HeadingNew from '../../components/FormElements/HeadingNew'
import {LinkRouter1} from '../../components/FormElements/Link'
import Loader from '../../components/FormElements/Loader'
import Modal from '../../components/FormElements/Modal'
import Button from '../../components/FormElements/Button'
import Container from '../../components/FormElements/Container'
import Bundle from './bundle'
import Guide from './guide'
import LandingPageGuides from '../../staticPages/LandingPageGuides'
import Icons from '../../images/icons/icons'
import guideList from './guides.json'

const Content = ({
  user,
  guide,
  viewGuide,
  clearGuide,
  getTopicList,
  topicList,
  downloadGuide,
  downloadSample,
  recordView,
  filterApplied,
  loggedIn,
}) => {
  const [buyForm, showBuyForm] = useState(null)
  const [showUpgrade, toggleShowUpgrade] = useState(false)

  useEffect(() => {
    // getTopicList()
    window.scrollTo(0, 0)
  }, [])

  const addGuide = (title) => {
    title === 'all' ? showBuyForm('all') : toggleShowUpgrade(title)
  }

  const confirmUpgrade = (confirmation) => {
    const title = showUpgrade
    toggleShowUpgrade(false)
    confirmation ? showBuyForm('all') : showBuyForm(title)
  }

  const PremiumUser =
    user && user.subscription && ['expert', 'admin'].includes(user.subscription.type)

  // let guideList = []
  // if (topicList.result && topicList.result.length > 1) guideList = topicList.result
  const specifier = window.location.pathname.split('uides/')[1]
  if (specifier) {
    guideList = guideList.filter(
      (b) =>
        b.title.replace(/[^\w]/g, '') ===
        specifier
          .replace(/%E2%80%99/g, '')
          .replace(/%C3%A9/g, '')
          .replace(/[^\w]/g, '')
    )
  }
  if (filterApplied) {
    guideList = guideList.filter((b) => b.title.toLowerCase() === filterApplied)
  }

  const SpecifierTranlator = {
    'Being-Productive': 'Being Productive',
    'Effective-Communication': 'Effective Communication',
  }

  return (
    <>
      <LandingPageGuides />
      <Container noPadding={loggedIn} alignItems='center'>
        <Flex width={1} flexDirection='column'>
          <div id='landingAnchor' />
          {specifier && (
            <HeadingNew size='h3' palette='white' as='h3'>
              <LinkRouter1 to='/guides'>View all guides</LinkRouter1>
            </HeadingNew>
          )}
        </Flex>

        {topicList.isLoading && <Loader />}

        {!topicList.isLoading && !guide.result.text && (
          <Flex flexDirection='column' alignItems='center'>
            {guideList
              .filter((a) => a.status !== 'Draft')
              .sort((a, b) => {
                if (a.status === 'Draft') return 2
                if (a.title < b.title) return -1
                if (a.title > b.title) return 1
                return 0
              })
              .map((a) => {
                return (
                  <Guide
                    key={a.title}
                    guide={a}
                    addGuide={addGuide}
                    downloadGuide={downloadGuide}
                    recordView={recordView}
                    user={user}
                    PremiumUser={PremiumUser}
                  />
                )
              })}
          </Flex>
        )}

        {!topicList.isLoading && !PremiumUser && <Bundle addGuide={addGuide} />}
        {showUpgrade && (
          <Modal closeModal={() => toggleShowUpgrade(false)}>
            <Flex
              width={1}
              p={['0px', '10px']}
              flexDirection='column'
              alignItems='center'
            >
              <HeadingNew size='h2' palette='medium' as='h2'>
                <b>Do you want to get even smarter?</b>
              </HeadingNew>
              <HeadingNew size='h3' palette='medium' as='h3'>
                Buy this guide as part of our Expert Bundle - which includes our
                guides to Managing Habits, Effective Communication, Career
                Development and Being Productive - and save 25%!
              </HeadingNew>
              <Flex width={1} justifyContent='space-around' my='10px'>
                {[
                  'ProductivityGuide',
                  'CommunicationGuide',
                  'HabitsGuide',
                  'CareerGuide',
                ].map((a) => (
                  <Image
                    src={Icons[a]}
                    width='20%'
                    mb={[0, 2]}
                    mt={2}
                    ml={0}
                    style={{
                      maxWidth: '75px',
                      maxHeight: '100px',
                      float: 'left',
                      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    }}
                  />
                ))}
              </Flex>
              <Flex width={4.5 / 5} my='10px'>
                <Button fullWidth onClick={() => confirmUpgrade(true)}>
                  {' '}
                  Upgrade to the Expert Bundle ($89)
                </Button>
              </Flex>
              <Flex width={4.5 / 5} my='10px'>
                <Button fullWidth onClick={() => confirmUpgrade(false)}>
                  {' '}
                  Stick with just this guide ($29)
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )}

        {buyForm && (
          <Modal closeModal={() => showBuyForm(null)}>
            <PaymentContainer product={buyForm} closeModal={showBuyForm} />
          </Modal>
        )}
        {guideList.length === 0 && !topicList.isLoading && (
          <Flex width={1} justifyContent='center'>
            <HeadingNew size='h3' palette='medium' as='h3'>
              No guide found at this address
            </HeadingNew>
          </Flex>
        )}

        <Flex
          flexDirection='column'
          mt='50px'
          width={[1]}
          style={{maxWidth: '1000px'}}
        >
          <HeadingNew size='h2' palette='medium' as='h2'>
            Coming soon
          </HeadingNew>
          <Flex width={1} mt={['25px', '50px']} justifyContent='space-around'>
            {['DecisionGuide', 'StartUpGuide', 'WellbeingGuide'].map((g) => (
              <Image
                src={Icons[g]}
                key={g}
                width={['100px', '150px', '200px']}
                height={['150px', '225px', '300px']}
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              />
            ))}
          </Flex>
        </Flex>

        {!loggedIn && !specifier && !filterApplied && (
          <Flex width={1} my='50px' flexDirection='column' alignItems='center'>
            <Flex px={2}>
              <HeadingNew size='h3' palette='medium' as='h3'>
                If you are interested in an upcoming guide, submit your details below
                to receive an update when it becomes available:
              </HeadingNew>
            </Flex>
            <EmailCapture category='guides' />
          </Flex>
        )}
      </Container>
    </>
  )
}

Content.propTypes = {
  user: PropTypes.object.isRequired,
  guide: PropTypes.object.isRequired,
  topicList: PropTypes.object.isRequired,
  viewGuide: PropTypes.func.isRequired,
  clearGuide: PropTypes.func.isRequired,
  getTopicList: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    guide: state.search.guide,
    topicList: state.search.topicList,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    viewGuide,
    clearGuide,
    getTopicList,
    downloadGuide,
    downloadSample,
    recordView,
  })(Content)
)
