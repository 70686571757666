import Knowledge from './knowledge.svg'
import Next from './next.svg'
import Thought from './thought.svg'
import Time from './time.svg'
import Focus from './focus.svg'
import Thinking from './thinking.svg'
import ThinkingOrange from './thinking-orange.svg'
import Filter from './filter1.svg'
import AlertWhite from './alarm-white.svg'
import EmailWhite from './email-white.svg'
import EmailDark from './email-dark.svg'
import Article from './article.svg'
import ArticleDark from './article-dark.svg'
import Archive from './archive.svg'
import ArchiveDark from './archive-dark.svg'
// Account
// import iconUserProfile from '../../images/identity/icon-user.svg'
import UserDetails from './user-profile.svg'
import Alert from './alert.svg'
import Browse from './search.svg'
import Premium from './premium.svg'
// import BrowseWhite from './search-white.svg'
import Content from './content.svg'
import Compose from './edit.svg'
import File from './file-black.svg'
import FileOrange from './file-orange.svg'
import Network from './network-black.svg'
import NetworkWhite from './network.svg'
import SignOut from './sign-out.svg'
import Subscription from './subscription.svg'
import SubscriptionArrow from './subscriptionArrow.svg'
import Group from './group.svg'
import Draft from './draft.svg'
import DraftWhite from './draft-white.svg'
import Published from './published.svg'
import PublishedWhite from './published-white.svg'
import RightArrow from './right-arrow.svg'
import RightArrowDouble from './right-arrow-double.svg'
import DownArrow from './down-arrow.svg'
import SearchPeople from './searchPeople.svg'
import ListSelected from './list-selected.svg'
import Search from './search-white.svg'
import Library from './library.svg'
import Question from './question.svg'
import LibraryOrange from './libraryOrange.svg'
import SearchOrange from './searchOrange.svg'
import SubscriptionOrange from './subscriptionOrange.svg'

// Article
import Like from './like.svg'
import LikeSelected from './like-selected.svg'
import Share from './share.svg'
import ShareSelected from './share-selected.svg'
import Save from './save.svg'
import SaveSelected from './save-selected.svg'
import SaveWhite from './save-white.svg'
import Email from './email.svg'
import EmailSelected from './email-selected.svg'
import User from '../identity/icon-user.svg'
import Link from './link.svg'
import Comment from './comment.svg'
import CommentSelected from './comment-selected.svg'
// Themes
import Business from './themes/business-orange.svg'
import Wellbeing from './themes/wellbeing-orange.svg'
import PersonalDevelopment from './themes/personalDevelopment-orange.svg'
import ProfessionalDevelopment from './themes/professionalDevelopment-orange.svg'
import Psychology from './themes/psychology-orange.svg'
import Science from './themes/science-orange.svg'
import Miscellaneous from './themes/miscellaneous.svg'
// Types
import Idea from './idea.svg'
import Innovation from './innovation.svg'
import Model from './model.svg'
import Quote from './quote.svg'
import Report from './report.svg'
import Resource from './resource.svg'
import Statistic from './statistic.svg'
import Theory from './theory.svg'
import Tip from './Tip.svg'
import Trend from './trend.svg'
// Guides
import Habits from './guides/habits.svg'
import Communication from './guides/communicating.svg'
import Productivity from './guides/productivity.svg'
import Recruiting from './guides/recruitment.svg'
import Fundraising from './guides/fundraising.svg'
import Creativity from './guides/creativity.svg'
import Decision from './guides/decision.svg'
import Management from './guides/management.svg'
import Learning from './guides/learning.svg'
// Guides Images
import HabitsGuide from './guideImages/Habits_Guide.jpg'
import ProductivityGuide from './guideImages/Productivity_Guide.jpg'
import CommunicationGuide from './guideImages/Communication_Guide.jpg'
import DecisionGuide from './guideImages/Decision_Guide.jpg'
import CareerGuide from './guideImages/Career_Guide.jpg'
import StartUpGuide from './guideImages/StartUp_Guide.jpg'
import WellbeingGuide from './guideImages/Wellbeing_Guide.jpg'

const Icons = {
  alertWhite: AlertWhite,
  filter: Filter,
  focus: Focus,
  knowledge: Knowledge,
  next: Next,
  thinking: Thinking,
  ThinkingOrange,
  thought: Thought,
  time: Time,
  emailWhite: EmailWhite,
  EmailDark,
  article: Article,
  ArticleDark,
  RightArrow,
  RightArrowDouble,
  DownArrow,
  archive: Archive,
  ArchiveDark,
  ListSelected,
  FileOrange,
  // Account
  alert: Alert,
  browse: Browse,
  content: Content,
  compose: Compose,
  draft: Draft,
  DraftWhite,
  file: File,
  fileOrange: FileOrange,
  group: Group,
  network: Network,
  signOut: SignOut,
  subscription: Subscription,
  user: User,
  userDetails: UserDetails,
  NetworkWhite,
  Search,
  Premium,
  Library,
  Question,
  LibraryOrange,
  SearchOrange,
  SubscriptionOrange,
  SubscriptionArrow,
  // Article
  comment: Comment,
  commentSelected: CommentSelected,
  email: Email,
  emailSelected: EmailSelected,
  like: Like,
  likeSelected: LikeSelected,
  Link,
  published: Published,
  PublishedWhite,
  save: Save,
  saveSelected: SaveSelected,
  SaveWhite,
  searchPeople: SearchPeople,
  share: Share,
  shareSelected: ShareSelected,
  // Themes
  business: Business,
  miscellaneous: Miscellaneous,
  personalDevelopment: PersonalDevelopment,
  professionalDevelopment: ProfessionalDevelopment,
  psychology: Psychology,
  science: Science,
  wellbeing: Wellbeing,
  // types
  idea: Idea,
  innovation: Innovation,
  model: Model,
  quote: Quote,
  report: Report,
  resource: Resource,
  statistic: Statistic,
  theory: Theory,
  tip: Tip,
  trend: Trend,
  // Guides
  Habits,
  Productivity,
  Communication,
  Recruiting,
  Fundraising,
  Creativity,
  'Decision Making': Decision,
  Management,
  Learning,
  // Guide Images
  HabitsGuide,
  ProductivityGuide,
  CommunicationGuide,
  DecisionGuide,
  CareerGuide,
  StartUpGuide,
  WellbeingGuide
}

export default Icons
