import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import Input from '../FormElements/Input'
import Checkbox from '../FormElements/Checkbox'
import HeadingNew from '../FormElements/HeadingNew'

const ComposeAdmin = ({
  adminCategorise,
  captureInput,
  premium,
  newsletter,
  relatedTitle,
  relatedURL
}) => {
  return (
    <Flex flexWrap='wrap' flexDirection='row' width={1}>
      <Box pr={2} pt={3} flex={['auto', 'auto', '1 1 auto']} width={[1 / 2]}>
        <Checkbox
          label='Premium'
          name='premium'
          inputProps={{
            id: 'premium',
            name: 'premium',
            onChange: (e) => adminCategorise('premium'),
            checked: premium,
          }}
        />
      </Box>
      <Box pr={[0, 0, 2]} pt={4} flex={['auto', 'auto', '1 1 auto']} width={[1 / 2]}>
        <Checkbox
          label='Newsletter'
          name='newsletter'
          inputProps={{
            id: 'newsletter',
            name: 'newsletter',
            onChange: (e) => adminCategorise('newsletter'),
            checked: newsletter,
          }}
        />
      </Box>
      <HeadingNew size='h3' palette='medium' as='h2' spacing='0 0 8px'>
        Related
      </HeadingNew>
      <Flex flexWrap='wrap' flexDirection='row' width={1} mt={1} mb={2}>
        <Box pr={[0, 0, 2]} width={[1, 1, 4 / 10]} mb={[2, 2, 0]}>
          <Input
            label='See also (title)'
            onChange={(e) => captureInput(e, 'relatedTitle')}
            value={relatedTitle}
            inputProps={{
              type: 'text',
              id: 'relatedTitle',
            }}
          />
        </Box>
        <Box pr={[0, 0, 2]} width={[1, 1, 4 / 10]}>
          <Input
            label='See also (url)'
            onChange={(e) => captureInput(e, 'relatedURL')}
            value={relatedURL}
            inputProps={{
              type: 'text',
              id: 'relatedURL',
            }}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

ComposeAdmin.propTypes = {
  adminCategorise: PropTypes.func.isRequired,
  captureInput: PropTypes.func.isRequired,
  premium: PropTypes.bool,
  newsletter: PropTypes.bool,
  relatedTitle: PropTypes.string,
  relatedURL: PropTypes.string
}

export default ComposeAdmin
