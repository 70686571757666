/**
 * Reducer for user
 *
 * @module shared/reducers/user
 */
import {user as userActions} from '../../actions/actionTypes'
import actionTypes from '../../actions/forms/preferences/actionTypes'

import {PAYMENT_SUCCESS, CANCELLATION_CONFIRMED} from '../../actions/paymentActions'

import {UPLOAD_PROFILE_SUCCESS, CLEAR_IMAGE} from '../../actions/articleActions'

const {
  RESET_ERRORS,
  SET_ERRORS,
  STORE_USER,
  RESET_USER,
  SET_FORM_STATUS,
  RESET_FORM_STATUS,
  GET_CONTACTS_START,
  GET_CONTACTS_ERROR,
  UPDATE_USER_INFO,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  CONFIRM_GUIDE_VIEW,
} = userActions

const defaultState = {
  _id: null,
  firstName: null,
  lastName: null,
  about: null,
  email: null,
  website: null,
  location: null,
  organisation: null,
  position: null,
  token: null,
  image: '',
  isLoading: false,
  myArticles: {
    isLoading: false,
    error: false,
    results: [],
  },
  feedPreferences: [],
  alertPreferences: [],
  emailPreference: 'Weekly',
  savedArticles: {
    isLoading: false,
    error: false,
    results: [],
  },
  sharedArticles: {
    isLoading: false,
    error: false,
    results: [],
  },
  errors: {
    form: null,
  },
  formStatus: null,
  followers: {
    isLoading: false,
    error: false,
    results: [],
  },
  following: {
    isLoading: false,
    error: false,
    results: [],
  },
  network: {
    isLoading: false,
    error: false,
    results: [],
  },
  subscription: {
    type: 'basic',
    created: '',
    cardType: '',
    last4: '',
    exp_month: '',
    exp_year: '',
    nextPaymentDate: '',
    subscriptionOption: '',
    amount: '',
    current_period_end: '',
  },
  unsubscribeConfirmed: false,
  unsubscribeError: false,
  lpEmail: null,
  firstVisit: true,
}

const user = (state = defaultState, action) => {
  switch (action.type) {
    case STORE_USER:
      return {...state, ...action.user}

    case RESET_USER:
      return defaultState

    case SET_ERRORS:
      return {...state, errors: action.errors}

    case RESET_ERRORS:
      return {...state, errors: {}}

    case SET_FORM_STATUS:
      return {...state, formStatus: action.formStatus}

    case RESET_FORM_STATUS:
      return {...state, formStatus: null}

    case GET_CONTACTS_START:
      return {
        ...state,
        [action.label]: {isLoading: true, error: false, results: []},
      }

    case GET_CONTACTS_ERROR:
      return {
        ...state,
        [action.label]: {isLoading: false, error: true, results: []},
      }

    case UPDATE_USER_INFO:
      return {
        ...state,
        [action.label]: {isLoading: false, error: false, results: action.results},
      }

    case actionTypes.ADD_PREFERENCE:
      return {
        ...state,
        alertPreferences: state.alertPreferences.includes(action.label)
          ? state.alertPreferences.filter((p) => p !== action.label)
          : [...state.alertPreferences, action.label],
      }

    case actionTypes.UPDATE_FEED_PREFERENCE:
      return {
        ...state,
        feedPreferences: [action.selection],
      }

    case PAYMENT_SUCCESS:
      return {
        ...state,
        subscription: action.details,
      }

    case CANCELLATION_CONFIRMED:
      return {
        ...state,
        subscription: {...state.subscription, status: 'cancelled'},
      }

    case UPLOAD_PROFILE_SUCCESS:
      return {
        ...state,
        image: action.url,
      }

    case CLEAR_IMAGE:
      return {
        ...state,
        image: '',
      }

    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribeConfirmed: true,
        unsubscribeError: false,
      }

    case UNSUBSCRIBE_ERROR:
      return {
        ...state,
        unsubscribeConfirmed: false,
        unsubscribeError: true,
      }

    case CONFIRM_GUIDE_VIEW:
      return {
        ...state,
        firstVisit: false,
      }

    default:
      return state
  }
}

export default user
