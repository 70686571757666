import axios from 'axios'
import config from '../../../utils/configuration'
import forgottenPasswordActions from './actionTypes'

const {target} = config

export const reset = () => {
  return {
    type: forgottenPasswordActions.RESET,
  }
}

export const resetError = () => {
  return {
    type: forgottenPasswordActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: forgottenPasswordActions.SET_ERROR,
    error,
  }
}

export const setFormStatus = (processed) => {
  return {
    type: forgottenPasswordActions.TOGGLE_IS_EMAIL_SENT,
  }
}

export const sendEmail = (email) => {
  return async (dispatch) => {
    dispatch(resetError())
    return axios
      .post(`${target}/forgottenPassword`, {email: email.toLowerCase()})
      .then((response) => {
        dispatch(setFormStatus())
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(
              setError({
                form: 'There is no account associated with that email address.',
              })
            )
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
