import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import {Flex} from 'rebass'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import {getBlog} from '../../actions/searchActions'
import HeadingNew from '../../components/FormElements/HeadingNew'
import LandingGuides from '../../staticPages/LandingGuides'
import LandingLibrary from '../../staticPages/LandingLibrary'
import LandingFeed from '../../staticPages/LandingFeed'
import GuideAdvert from '../../staticPages/GuideAdvert'
import Productivity from './productivity'
import Career from './career'
import Communication from './communication'

const Separator = styled.div`
  border-top: 1px solid #f2f3f5;
  width: 75%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 600px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ff6600;
`

const Blog = ({getBlog, blog}) => {
  useEffect(() => {
    getBlog()
    window.scrollTo(0, 0)
  }, [getBlog])

  const specifier = window.location.pathname.split('blog/')[1]
  let metaTitle = 'Sivv Blog'
  let metaDescription = 'Sivv Blog'

  const BlogOptions = [
    {
      title: 'How to Tame Email',
      description: 'A review of the best approaches to getting email under control',
      intro:
        'Many of us spend a significant portion of our time dealing with email. One study found that the average knowledge worker spends around 28% of their day managing their inbox, while respondents to a survey conducted in 2019 reported spending more than five hours each day checking work and personal accounts. We therefore stand to realise significant productivity gains though improving our approach to composing, screening and processing email.',
      link: '/blog/How-to-Tame-Email',
    },
    {
      title: 'How to Give a Speech',
      description: 'A review of the best approaches to presenting',
      intro:
        'Whether as part of a sales pitch or toasting a relative at a family celebration, public speaking fills many of us with dread. While practice is typically the best way to become an accomplished speaker, you can use a number of approaches and techniques to help you streamline your content, enhance your delivery, reduce your anxieties and improve the end result.',
      link: '/blog/How-to-Give-a-Speech',
    },
    {
      title: 'How to Negotiate a Pay Rise',
      description: 'A review of the best approaches to getting a pay rise',
      intro:
        'Regardless of the sector in which you work or position you hold, there is a good chance that you could be earning more than you currently do. Nevertheless, many people feel uncomfortable asking for and negotiating a raise, perhaps because they do not want to be seen as greedy or unappreciative, or fear that their request will be rejected. However, even if you are fully deserving of a pay rise, you cannot assume that your boss or organisation will be proactive in offering you one. In fact, without actively asking for what you want and feel that you deserve, your employer is likely to assume that you are perfectly happy with the status quo.',
      link: '/blog/How-to-Negotiate-a-Pay-Rise',
    },
  ]

  if (specifier === 'How-to-Tame-Email') {
    metaTitle = BlogOptions[0].title
    metaDescription = BlogOptions[0].description
  }
  if (specifier === 'How-to-Give-a-Speech') {
    metaTitle = BlogOptions[1].title
    metaDescription = BlogOptions[1].description
  }
  if (specifier === 'How-to-Negotiate-a-Pay-Rise') {
    metaTitle = BlogOptions[2].title
    metaDescription = BlogOptions[2].description
  }

  return (
    <>
      <MetaTags>
        <title>{metaTitle}</title>
        <meta name='description' content={metaDescription} />
        <meta property='og:title' content={metaTitle} />
        <meta property='og:image' content='../../images/sivv_logo.png' />
      </MetaTags>
      <Flex
        width={1}
        mt={['125px', '150px']}
        flexDirection='column'
        alignItems='center'
      >
        {specifier === 'How-to-Tame-Email' && <Productivity />}
        {specifier === 'How-to-Negotiate-a-Pay-Rise' && <Career />}
        {specifier === 'How-to-Give-a-Speech' && <Communication />}

        {!specifier &&
          BlogOptions.map((b) => (
            <Flex width={1} flexDirection='column' alignItems='center' key={b.title}>
              <Flex
                width={1}
                p='25px'
                flexDirection='column'
                style={{maxWidth: '700px'}}
              >
                <HeadingNew size='h2' palette='medium' as='h2'>
                  {b.title}
                </HeadingNew>
                <HeadingNew size='h3' palette='medium' as='h3' justify>
                  {b.intro} <StyledLink to={b.link}>Read.</StyledLink>
                </HeadingNew>
              </Flex>
              <Separator />
            </Flex>
          ))}

        {specifier &&
          ![
            'How-to-Tame-Email',
            'How-to-Negotiate-a-Pay-Rise',
            'How-to-Give-a-Speech',
          ].includes(specifier) && (
            <Flex my='100px'>
              <HeadingNew size='h3' palette='medium' as='h3'>
                No post found at this address
              </HeadingNew>
            </Flex>
          )}
      </Flex>

      {specifier === 'How-to-Tame-Email' && (
        <GuideAdvert filterApplied='productivity' />
      )}
      {specifier === 'How-to-Give-a-Speech' && (
        <GuideAdvert filterApplied='communication' />
      )}
      {specifier === 'How-to-Negotiate-a-Pay-Rise' && (
        <GuideAdvert filterApplied='career' />
      )}
      {specifier && <LandingFeed />}
      {!specifier && <LandingGuides />}
      <LandingLibrary />
      {!specifier && <LandingFeed />}
    </>
  )
}

Blog.propTypes = {
  blog: PropTypes.object.isRequired,
  getBlog: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    blog: state.search.blog,
  }
}

export default withRouter(connect(mapStateToProps, {getBlog})(Blog))
