import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import {Provider} from 'react-redux'
import {ThemeProvider} from 'styled-components'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {GoogleOAuthProvider} from '@react-oauth/google'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store/configureStore'
import theme from './styles/theme'
import Global from './styles/GlobalStyles'
import config from './utils/configuration'

const stripePromise = loadStripe(config.stripe_key)

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId='646673792658-h3vddu6tmm756orev5kr2rpoii5vpoas.apps.googleusercontent.com'>
          <>
            <App />
            <Global />
          </>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </Elements>
  </Provider>,
  document.getElementById('root')
)

// serviceWorker.unregister()
serviceWorker.register()
