import keyMirror from '../../utils/keyMirror'

export default keyMirror(
  {
    RESET_ERRORS: null,
    SET_ERRORS: null,
    STORE_USER: null,
    RESET_USER: null,
    SET_FORM_STATUS: null,
    RESET_FORM_STATUS: null,
    STORE_SEARCHES: null,
    SET_SAVED_SEARCH_ERROR: null,
    GET_CONTACTS_START: null,
    GET_CONTACTS_ERROR: null,
    UPDATE_USER_INFO: null,
    REGISTER_INTEREST_SUCCESS: null,
    UNSUBSCRIBE_SUCCESS: null,
    UNSUBSCRIBE_ERROR: null,
    CONFIRM_GUIDE_VIEW: null,
  },
  'USER'
)
