import axios from 'axios'
import config from '../../../utils/configuration'
import preferencesActions from './actionTypes'
import {storeUser} from '../../user'

const {target} = config

export const resetError = () => {
  return {
    type: preferencesActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: preferencesActions.SET_ERROR,
    error,
  }
}

export const toggleIsPreferencesUpdated = (processed) => {
  return {
    type: preferencesActions.TOGGLE_FORM_STATUS,
    processed,
  }
}

export const addPreference = (label) => {
  return {
    type: preferencesActions.ADD_PREFERENCE,
    label,
  }
}

export const updateFeedPreference = (selection) => {
  return {
    type: preferencesActions.UPDATE_FEED_PREFERENCE,
    selection,
  }
}

export const updatePreferences = (id, alertPreferences, feedPreferences) => {
  return async (dispatch) => {
    dispatch(resetError())
    dispatch(toggleIsPreferencesUpdated(false))
    return axios
      .post(`${target}/myAccount/updatePreferences`, {
        id,
        alertPreferences,
        feedPreferences,
      })
      .then((response) => {
        const details = response.data
        dispatch(storeUser({...details}))
        dispatch(toggleIsPreferencesUpdated(true))
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(setError({form: 'Server encountered an issue'}))
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
