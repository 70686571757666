import React from 'react'
import PropTypes from 'prop-types'
import {Flex} from 'rebass'
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  WorkplaceIcon,
} from 'react-share'

const ArticleSocialShare = ({title, url}) => {
  return (
    <Flex width={[1, 1 / 2]} justifyContent='space-around' mt={3}>
      <TwitterShareButton title={title} url={url}>
        <TwitterIcon size={25} round />
      </TwitterShareButton>
      <FacebookShareButton title={title} url={url}>
        <FacebookIcon size={25} round />
      </FacebookShareButton>
      <LinkedinShareButton title={title} url={url}>
        <LinkedinIcon size={25} round />
      </LinkedinShareButton>
      <RedditShareButton title={title} url={url}>
        <RedditIcon size={25} round />
      </RedditShareButton>
      <WorkplaceShareButton title={title} url={url}>
        <WorkplaceIcon size={25} round />
      </WorkplaceShareButton>
    </Flex>
  )
}

ArticleSocialShare.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default ArticleSocialShare
