import React from 'react'
import styled from 'styled-components'
import {Box, Flex} from 'rebass'
import HeadingNew from '../components/FormElements/HeadingNew'
import Text from '../components/FormElements/Text'

const Item = styled.li`
  color: #657081;
  margin-left: 15px;
`

const PrivacyPolicy = () => {
  return (
    <Flex
      width={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      p={['25px', '50px']}
    >
      <Flex flexDirection='column' style={{maxWidth: '900px'}}>
        <Box p={2}>
          <HeadingNew size='h1' palette='medium' as='h2'>
            Sivv: Our Privacy Policy
          </HeadingNew>
        </Box>
        <Box p={2}>
          <Text pt={3} palette='light'>
            This privacy policy will explain how our organization uses the personal
            data we collect from you when you use our website.
          </Text>
        </Box>
        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Topics:
          </HeadingNew>
          <Item>What data do we collect?</Item>
          <Item>How do we collect your data?</Item>
          <Item>How do we collect your data?</Item>
          <Item>How will we use your data?</Item>
          <Item>How do we store your data?</Item>
          <Item>Marketing</Item>
          <Item>What are your data protection rights?</Item>
          <Item>What are cookies?</Item>
          <Item>How do we use cookies?</Item>
          <Item>What types of cookies do we use?</Item>
          <Item>How to manage your cookies</Item>
          <Item>Privacy policies of other websites</Item>
          <Item>Changes to our privacy policy</Item>
          <Item>How to contact us</Item>
          <Item>How to contact the appropriate authorities</Item>
        </Box>
        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            What data do we collect?
          </HeadingNew>
          <Text pt={3} palette='light'>
            Sivv collects the following data:
          </Text>
          <Item>
            Personal identification information (name, email address, phone number,
            etc.) and other related data
          </Item>
        </Box>
        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How do we collect your data?
          </HeadingNew>
          <Text pt={3} palette='light'>
            You directly provide us with most of the data we collect. We collect data
            and process data when you:
          </Text>
          <Item>Register online.</Item>
          <Item>Voluntarily complete provide feedback via email.</Item>
          <Item>Use or view our website via your browser’s cookies.</Item>
        </Box>
        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How will we use your data?
          </HeadingNew>
          <Text pt={3} palette='light'>
            We collect your data so that we can:
          </Text>
          <Item>
            Email you with newsletters, reports and other information you may find
            useful, including responding to your queries.
          </Item>
          <Text pt={3} palette='light'>
            If you agree, we will share your data with our partner companies so that
            they may offer you their products and services.
          </Text>
          <Text pt={3} palette='light'>
            Your data, and also the resulting information from such data, may
            directly or indirectly be sent to credit reference agencies to prevent
            fraudulent purchases.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How do we store your data?
          </HeadingNew>
          <Text pt={3} palette='light'>
            We securely store your data with a third party service provider located
            in England using industry standard security measures.
          </Text>
          <Text pt={3} palette='light'>
            We will keep your data on for so long as it is required in connection
            with our products and services or unless you contact us requesting that
            your data be deleted. If you would like to request that your data be
            deleted, please contact us.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Marketing
          </HeadingNew>
          <Text pt={3} palette='light'>
            We would like to send you information about products and services of ours
            that we think you might like.
          </Text>
          <Text pt={3} palette='light'>
            If you have agreed to receive marketing, you may always opt out at a
            later date.
          </Text>
          <Text pt={3} palette='light'>
            You have the right at any time to stop us from contacting you for
            marketing purposes.
          </Text>
          <Text pt={3} palette='light'>
            If you no longer wish to be contacted for marketing purposes, please
            email us.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            What are your data protection rights?
          </HeadingNew>
          <Text pt={3} palette='light'>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </Text>
          <Text pt={3} palette='light'>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </Text>
          <Text pt={3} palette='light'>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request we complete the information you believe is incomplete.
          </Text>
          <Text pt={3} palette='light'>
            The right to erasure – You have the right to request that we erase your
            personal data, under certain conditions.
          </Text>
          <Text pt={3} palette='light'>
            The right to restrict processing – You have the right to request that we
            restrict the processing of your personal data, under certain conditions.
          </Text>
          <Text pt={3} palette='light'>
            The right to object to processing – You have the right to object to our
            processing of your personal data, under certain conditions.
          </Text>
          <Text pt={3} palette='light'>
            The right to data portability – You have the right to request that we
            transfer the data that we have collected to another organization, or
            directly to you, under certain conditions.
          </Text>
          <Text pt={3} palette='light'>
            If you make a request, we have one month to respond to you. If you would
            like to exercise any of these rights, please contact us at our email:
            info@sivv.io.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Cookies
          </HeadingNew>
          <Text pt={3} palette='light'>
            Cookies are text files placed on your computer to collect standard
            Internet log information and visitor behavior information. When you visit
            our websites, we may collect information from you automatically through
            cookies or similar technology.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How do we use cookies?
          </HeadingNew>
          <Text pt={3} palette='light'>
            We use cookies in a range of ways to improve your experience on our
            website, including understanding how you use our website.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            What types of cookies do we use?
          </HeadingNew>
          <Text pt={3} palette='light'>
            There are a number of different types of cookies, however, our website
            uses:
          </Text>
          <Item>
            Functionality – we use these cookies so that we recognize you on our
            website and remember your previously selected preferences. These could
            include what language you prefer and location you are in. A mix of
            first-party and third-party cookies are used.
          </Item>
          <Item>
            Advertising – we use these cookies to collect information about your
            visit to our website, the content you viewed, the links you followed and
            information about your browser, device, and your IP address. We may share
            some limited aspects of this data with third parties for advertising
            purposes. This means that when you visit another website, you may be
            shown advertising based on your browsing patterns on our website.
          </Item>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How to manage cookies
          </HeadingNew>
          <Text pt={3} palette='light'>
            You can set your browser not to accept cookies, and the above website
            tells you how to remove cookies from your browser. However, in a few
            cases, some of our website features may not function as a result.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Privacy policies of other websites
          </HeadingNew>
          <Text pt={3} palette='light'>
            This website may contain links to other websites. Our privacy policy
            applies only to our website, so if you click on a link to another
            website, you should read their privacy policy.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            Changes to our privacy policy
          </HeadingNew>
          <Text pt={3} palette='light'>
            We keep our privacy policy under regular review and place any updates on
            this web page. This privacy policy was last updated on 23 June 2019.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How to contact us
          </HeadingNew>
          <Text pt={3} palette='light'>
            If you have any questions about our privacy policy, the data we hold on
            you, or you would like to exercise one of your data protection rights,
            please do not hesitate to contact us.
          </Text>
          <Text pt={3} palette='light'>
            Email us at: <a href='mailto:info@sivv.io'>info@sivv.io</a>.
          </Text>
        </Box>

        <Box p={2}>
          <HeadingNew size='h3' palette='medium' as='h2'>
            How to contact the appropriate authority
          </HeadingNew>
          <Text pt={3} palette='light'>
            Should you wish to report a complaint or if you feel that Sivv has not
            addressed your concern in a satisfactory manner, you may contact the
            Information Commissioner’s Office:
          </Text>
          <Text pt={3} palette='light'>
            Information Commissioner’s Office
          </Text>
          <Text pt={3} palette='light'>
            Wycliffe House
          </Text>
          <Text pt={3} palette='light'>
            Water Lane
          </Text>
          <Text pt={3} palette='light'>
            Wilmslow
          </Text>
          <Text pt={3} palette='light'>
            Cheshire
          </Text>
          <Text pt={3} palette='light'>
            SK9 5AF
          </Text>
          <Text pt={3} palette='light'>
            Helpline number: 0303 123 1113
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default PrivacyPolicy
