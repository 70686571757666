import React from 'react'
import {Box, Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Button from '../../components/FormElements/Button'
import TextBox from '../../components/FormElements/TextBox'
import BookCompose from '../../components/BookCompose'

const LibrarySubmission = ({
  specifier,
  toggle,
  userInput,
  submission,
  updateBook,
  book,
  captureSuggestion,
  formError,
  submitUpdate,
  submitSummary,
  summaryError,
  refresh,
}) => {
  return (
    <Flex
      width={1}
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
    >
      <Flex
        mt={3}
        width={[1, 1, '600px']}
        style={{border: '1px solid #007a87'}}
      >
        <Box
          width={1 / 2}
          onClick={() => toggle('comment')}
          py={2}
          style={{
            cursor: 'pointer',
            background: userInput === 'comment' ? '#007a87' : '#005969',
          }}
        >
          <HeadingNew size='h2' palette='white' as='h2' centerText>
            Suggest an update
          </HeadingNew>
        </Box>
        {!specifier && (
          <Box
            width={1 / 2}
            onClick={() => toggle('submit')}
            py={2}
            style={{
              cursor: 'pointer',
              background: userInput === 'submit' ? '#007a87' : '#005969',
            }}
          >
            <HeadingNew size='h2' palette='white' as='h2' centerText>
              Submit a summary
            </HeadingNew>
          </Box>
        )}
      </Flex>
      <Flex mt={3} width={[1, 1, '600px']} flexDirection='column'>
        {userInput === 'submit' && !submission && (
          <BookCompose updateBook={updateBook} book={book} />
        )}
        {userInput === 'comment' && !submission && (
          <Box my={2} style={{height: '150px'}}>
            <TextBox
              height='150px'
              onChange={(e) => captureSuggestion(e.target.value)}
              error={formError}
            />
          </Box>
        )}
        {submission && (
          <Box my={2}>
            <HeadingNew size='h3' palette='white' as='h3'>
              Thank you! We will review your submission and get back to you soon.
            </HeadingNew>
          </Box>
        )}
        <Flex mt={3} width={[1, 1, '600px']} flexDirection='column'>
          <Box my={2}>
            <Button
              onClick={() =>
                submission
                  ? refresh()
                  : userInput === 'comment'
                  ? submitUpdate()
                  : submitSummary()
              }
            >
              {!submission ? 'Submit' : 'Submit another suggestion'}
            </Button>
            {summaryError && (
              <Box my={2}>
                <HeadingNew size='h3' as='h3' style={{color: 'red'}}>
                  Something is missing
                </HeadingNew>
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LibrarySubmission
