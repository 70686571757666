import React from 'react'
import {Flex, Box} from 'rebass'
import styled from 'styled-components'
import SearchResultsContainer from '../SearchResultsContainer'
import Library from '../Library'
import GuideAdvert from '../../staticPages/GuideAdvert'
import HeadingNew from '../../components/FormElements/HeadingNew'

const SourceHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-color: #37517e #fff;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  /* ::-webkit-scrollbar-track {
    background: #2e4369;
  } */

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #37517e;
    border-radius: 25px;
  }
  & -ms-overflow-style: none;
  @media screen and (max-width: 52em) {
    width: 100%;
    max-height: 50vh;
  }

}`

const SourceHolder2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-color: #37517e #fff;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #37517e;
    border-radius: 25px;
  }
  & -ms-overflow-style: none;
  @media screen and (max-width: 52em) {
    width: 100%;
  }

}`

const ThemeContainer = () => {
  const FilterApplied = window.location.pathname.split('/browse/')[1]

  if (!FilterApplied || FilterApplied === 'research')
    return <SearchResultsContainer />

  return (
    <Flex width={1} flexDirection={['column', 'column', 'row']} p={2}>
      <Flex width={[1, 1, 1 / 2]} flexDirection='column'>
        <Box pl={[2, 3, 3]} pt={3} mb={1}>
          <HeadingNew size='h1' palette='medium' as='h1'>
            Recent posts on {FilterApplied}
          </HeadingNew>
        </Box>
        <SourceHolder>
          <SearchResultsContainer themePage />
        </SourceHolder>
      </Flex>
      <Flex width={[1, 1, 1 / 2]} flexDirection='column'>
        <GuideAdvert filterApplied={FilterApplied} theme />
        <Box p={[2, 3, 3]}>
          <HeadingNew size='h1' palette='medium' as='h1'>
            Book summaries
          </HeadingNew>
        </Box>
        <SourceHolder2>
          <Library filterApplied={FilterApplied} theme />
        </SourceHolder2>
      </Flex>
    </Flex>
  )
}

export default ThemeContainer
