import styled from 'styled-components'
import {Link as RouterLink} from 'react-router-dom'

export const LinkRouter = styled(RouterLink)`
  text-decoration: none;
  color: #0070c9;

  &:hover {
    text-decoration: underline;
  }
`

export const LinkRouter1 = styled(RouterLink)`
  text-decoration: none;
  color: #de7033;

  &:hover {
    text-decoration: underline;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: #0070c9;

  &:hover {
    text-decoration: underline;
  }
`
