const Categories = {
  topics: [
    // { label: 'Behavioural Science', value: 'Behavioural Science' },
    {label: 'Career', value: 'Career'},
    // {label: 'Communicating', value: 'Communicating'},
    {label: 'Communication', value: 'Communication'},
    {label: 'Creativity', value: 'Creativity'},
    {label: 'Decision-making', value: 'Decision-making'},
    {label: 'Entrepreneurship', value: 'Entrepreneurship'},
    {label: 'Finance & Investing', value: 'Finance & Investing'},
    {label: 'Habits', value: 'Habits'},
    {label: 'Innovation', value: 'Innovation'},
    {label: 'Leadership', value: 'Leadership'},
    {label: 'Learning', value: 'Learning'},
    {label: 'Management', value: 'Management'},
    {label: 'Marketing', value: 'Marketing'},
    {label: 'Operations', value: 'Operations'},
    {label: 'Reasoning', value: 'Reasoning'},
    {label: 'Parenting', value: 'Parenting'},
    {label: 'Productivity', value: 'Productivity'},
    {label: 'Problem Solving', value: 'Problem Solving'},
    {label: 'Persuasion & Negotiation', value: 'Persuasion & Negotiation'},
    {label: 'Sales', value: 'Sales'},
    {label: 'Start-ups', value: 'Start-ups'},
    {label: 'Strategy', value: 'Strategy'},
    {label: 'Sustainability / ESG', value: 'Sustainability / ESG'},
    {label: 'Technology', value: 'Technology'},
    {label: 'Wellbeing', value: 'Wellbeing'},
  ],
  themes: [
    {label: 'Behavioural Science', value: 'Behavioural Science'},
    {label: 'Business', value: 'Business'},
    {label: 'Miscellaneous', value: 'Miscellaneous'},
    {label: 'Personal Development', value: 'Personal Development'},
    {label: 'Professional Growth', value: 'Professional Growth'},
    {label: 'Science & Technology', value: 'Science & Technology'},
    {label: 'Wellbeing', value: 'Wellbeing'},
  ],
  types: [
    {label: 'Advice / Tip', value: 'Advice / Tip'},
    {label: 'Book', value: 'Book'},
    {label: 'Framework / Model', value: 'Framework / Model'},
    {label: 'Idea / Concept', value: 'Idea / Concept'},
    {label: 'Innovation', value: 'Innovation'},
    {label: 'Quote', value: 'Quote'},
    {label: 'Report / Study', value: 'Report / Study'},
    {label: 'Resource', value: 'Resource'},
    {label: 'Statistic / Fact', value: 'Statistic / Fact'},
    {label: 'Theory', value: 'Theory'},
    {label: 'Trend', value: 'Trend'},
  ],
  sourceTypes: [
    {label: 'Article', value: 'Article'},
    {label: 'Book', value: 'Book'},
    {label: 'Report / Study', value: 'Report / Study'},
    {label: 'Documentary', value: 'Documentary'},
    {label: 'Conference / Event', value: 'Conference / Event'},
    {label: 'Personal Experience', value: 'Personal Experience'},
    {label: 'Podcast', value: 'Podcast'},
    {label: 'Video', value: 'Video'},
    {label: 'Other', value: 'Other'},
  ],
}

export default Categories
