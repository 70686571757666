import axios from 'axios'
import config from '../../../utils/configuration'
import deleteAccountActions from './actionTypes'

const {target} = config

export const resetError = () => {
  return {
    type: deleteAccountActions.RESET_ERROR,
  }
}

const setError = (error) => {
  return {
    type: deleteAccountActions.SET_ERROR,
    error,
  }
}

export const toggleIsAccountDeleted = (processed) => {
  return {
    type: deleteAccountActions.TOGGLE_FORM_STATUS,
    processed,
  }
}

export const authenticatePassword = ({_id, password}, displayModalCallback) => {
  return async (dispatch) => {
    dispatch(resetError())
    return axios
      .post(`${target}/myAccount/verifyPassword`, {_id, password})
      .then((response) => {
        displayModalCallback()
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(setError({form: 'Server encountered an issue'}))
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}

export const deleteAccount = ({_id, password}, hideModalCallback) => {
  return async (dispatch) => {
    dispatch(resetError())
    return axios
      .post(`${target}/myAccount/deleteAccount`, {_id, password})
      .then((response) => {
        dispatch(toggleIsAccountDeleted(true))
      })
      .catch((error) => {
        hideModalCallback()
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            dispatch(setError({form: 'Server encountered an issue'}))
          } else {
            dispatch(setError({form: error.response.data || error.message}))
          }
        } else {
          dispatch(setError({form: 'Server unavailable'}))
        }
      })
  }
}
