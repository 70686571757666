import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Box, Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Text from '../../components/FormElements/Text'
import Button from '../../components/FormElements/Button'
import Modal from '../../components/FormElements/Modal'
import Container from '../../components/FormElements/Container'

const PaymentSuccess = ({cancelSubscription, subscription}) => {
  // const created = new Date(subscription.start)
  const {status, subscriptionType, currentPeriodEnd, cardDetails} = subscription
  const ending = new Date(currentPeriodEnd)
  const [selectDelete, showDelete] = useState(false)

  const hide = () => {
    showDelete(false)
  }

  return (
    <Container background='#d4e0e5'>
      <HeadingNew size='h1' palette='medium' as='h1'>
        Subscription details
      </HeadingNew>
      <Flex
        width={1}
        justifyContent='space-between'
        p={3}
        flexWrap='wrap'
        mt={3}
      >
        <Box px={2} width={1}>
          <HeadingNew size='h3' palette='medium' as='p'>
            {status === 'active'
              ? `Your ${subscriptionType === 'expert' ? 'expert' : 'premium'} subscription is active`
              : `Your ${subscriptionType === 'expert' ? 'expert' : 'premium'} subscription has been cancelled.`}
          </HeadingNew>
          <div
            style={{
              marginBottom: '10px',
              marginTop: '10px',
            }}
          />
          {status === 'active' && (
            <HeadingNew size='h3' palette='medium' as='p'>
              Your next payment (of{' '}
              {subscriptionType === 'monthly'
                ? '$5'
                : subscriptionType === 'premium'
                ? '$50'
                : '$75'}{' '}
              ) is due on {ending.getDate()}/{ending.getMonth() + 1}/
              {ending.getYear() - 100}.
            </HeadingNew>
          )}
          {status === 'cancelled' && (
            <HeadingNew size='h3' palette='medium' as='p'>
              Your access to premium features will expire on {ending.getDate()}/
              {ending.getMonth() + 1}/{ending.getYear() - 100}.
            </HeadingNew>
          )}
          <div
            style={{
              marginBottom: '10px',
              marginTop: '10px',
            }}
          />

          {status === 'active' && cardDetails && (
            <HeadingNew size='h3' palette='medium' as='p'>
              Saved Card: {cardDetails.brand} xxxx{cardDetails.last4} exp:{' '}
              {cardDetails.exp_month}/{cardDetails.exp_year}
            </HeadingNew>
          )}
          {status === 'active' && cardDetails && (
            <div
              style={{
                  marginBottom: '10px',
                marginTop: '10px',
              }}
            />
          )}
          <HeadingNew size='h3' palette='medium' as='p'>
            If you have any queries or encounter any issues in relation to you
            subscription please contact us at
            <a
              href='mailto:info@sivv.io'
              target='null'
              style={{color: '#de7033', cursor: 'pointer', textDecoration: 'none'}}
            >
              {} info@sivv.io
            </a>
            .
          </HeadingNew>
          {status !== 'cancelled' && (
            <>
              <div
                style={{
                      marginBottom: '10px',
                  marginTop: '10px',
                }}
              />
              <HeadingNew
                size='h3'
                palette='medium'
                as='p'
                style={{cursor: 'pointer'}}
                onClick={() => showDelete(true)}
              >
                To cancel your subscription <b>click here</b>.
              </HeadingNew>
            </>
          )}
        </Box>
        {selectDelete && status !== 'cancelled' && (
          <Modal closeModal={hide}>
            <HeadingNew size='h2' palette='darkColor' as='h2' spacing='0 0 8px'>
              Confirm Cancellation
            </HeadingNew>
            <Text as='p' palette='light'>
              Are you sure that you want to cancel your subscription?
            </Text>
            <Flex
              flexWrap='wrap'
              flexDirection='row'
              alignItems={['normal', 'flex-end']}
              mx={-2}
            >
              <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
                <Button
                  onClick={() => cancelSubscription()}
                  palette='primary'
                  size='medium'
                  fullWidth
                  feedback='Password updated'
                >
                  Cancel subscription
                </Button>
              </Box>
              <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
                <Button
                  onClick={hide}
                  palette='secondary'
                  size='medium'
                  fullWidth
                  feedback='Password updated'
                >
                  Close
                </Button>
              </Box>
            </Flex>
          </Modal>
        )}
      </Flex>
    </Container>
  )
}

PaymentSuccess.propTypes = {
  success: PropTypes.bool.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
}

export default PaymentSuccess
