import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Box, Flex} from 'rebass'
import {LinkRouter1} from '../components/FormElements/Link'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'

const Header = styled.div`
  margin-top: 10px;
`

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Reasons = [
    {
      heading: '1 - Inappropriate Education',
      text:
        "Most people receive little or no formal training or education relating to many of life's fundamental skills, such as working productively, communicating effectively or making good decisions.",
    },
    {
      heading: '2 - Information Overload',
      text:
        <div>
          Useful ideas, strategies and techniques are often buried within long books
          or lost in the noise of the internet. Sourcing them can be hugely time
          consuming,
          <LinkRouter1 to='/information-inefficiencies'>
            {' '}
            inefficient
          </LinkRouter1> and overwhelming.
        </div>,
    },
    {
      heading: '3 - Missed Opportunities',
      text:
        'As a result, many of us are less effective than we could be. Sivv addresses these inefficiencies to make it easier for everyone to pursue their personal and professional goals.',
    },
  ]


  const Questions = [
    {
      question: 'What type of content do you cover?',
      answer:
        'We focus on ideas that are useful (i.e. that can be practically employed to make meaningful improvements to one or more areas of personal or professional endeavour), generally-applicable (useful for most people rather than just a small niche) and timeless (the value does not decay with time, as with news). These could take one of several forms, including advice, a theory, a framework or results form a study.',
    },
    {
      question: 'How do you source ideas?',
      answer:
        'We review hundreds of sources each day including leading newspapers, journals, blogs and books.',
    },
    {
      question: 'How do you produce the summaries?',
      answer:
        'We use a combination of technology and human brain power to write and curate all Sivv content.',
    },
  ]

  return (
    <Container background='#e8eef4'>
      <Flex flexDirection='column' style={{maxWidth: '800px', margin: 'auto'}}>
        <Box pb={2}>
          <Header as='header'>
            <HeadingNew size='h1' palette='medium' as='h2'>
              About Sivv
            </HeadingNew>
          </Header>
        </Box>
        <Box pb={2}>
          <HeadingNew size='h3' palette='medium' as='h3'>
            Sivv was established to address three issues:
          </HeadingNew>
        </Box>

        {Reasons.map((o) => (
          <Flex
            key={o.heading} width={1} flexDirection='column' p={['10px', '25px']}
          >
            <HeadingNew size='h3' palette='medium' as='h3'>
              <b>{o.heading}</b>
            </HeadingNew>
            <HeadingNew size='h3' palette='medium' as='h3' condensed justify>
              {o.text}
            </HeadingNew>
          </Flex>
        ))}

        <Box py={2}>
          <HeadingNew size='h3' palette='medium' as='h3' justify>
            We are developing a range of services that enable busy people to
            outsource a large part of the time and effort that they normally spend on
            seeking knowledge. We find, summarise and curate the most useful ideas
            from books, articles and research to help people save time and learn more
            efficiently. This service forms the basis of a 'community of curious
            minds' that is focused entirely on finding, sharing and discussing useful
            knowledge.
          </HeadingNew>
        </Box>

        <Flex
          flexDirection='column'
          width={1}
          justifyContent='center'
          displayFlex='flex'
          as='div'
        >
          <Box pb={2}>
            <Header as='header'>
              <HeadingNew size='h1' palette='medium' as='h2'>
                Frequently Asked Questions
              </HeadingNew>
            </Header>
          </Box>
          {Questions.map((q) => (
            <Box p={['10px', '25px']}>
              <HeadingNew size='h3' palette='medium' as='h3' justify>
                <span style={{color: '#de7033'}}>{q.question}</span> {q.answer}
              </HeadingNew>
            </Box>
          ))}
        </Flex>

        <Flex
          flexDirection='column'
          width={[1, 3 / 4]}
          justifyContent='center'
          displayFlex='flex'
          as='div'
        >
          <Box pb={2}>
            <Header as='header'>
              <HeadingNew size='h1' palette='medium' as='h2'>
                Contact us
              </HeadingNew>
            </Header>
          </Box>
          <Box pb={2}>
            <HeadingNew size='h3' palette='medium' as='h3'>
              For more information, questions or feedback please contact us at{' '}
              <a style={{color: '#de7033'}} href='mailto:info@sivv.io'>
                info@sivv.io
              </a>
              .
            </HeadingNew>
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

export default About
