import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {Box, Flex, Image} from 'rebass'
import styled from 'styled-components'
import Icons from '../../images/icons/icons'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Button from '../../components/FormElements/Button'

// const SmallScreenHide = styled.div`
//   width: 100%;
//   @media screen and (max-width: 52em) {
//     display: none;
//   }
// `

const MediumScreenHide = styled.div`
  width: 100%;
  @media screen and (max-width: 40em) {
    display: none;
  }
`

const MediumScreenShow = styled.div`
  width: 100%;
  @media screen and (min-width: 40em) {
    display: none;
  }
`

// const BigScreenHide = styled.div`
//   line-height: 1.4;
//   @media screen and (min-width: 52em) {
//     display: none;
//   }
// `

// const BookContainer = styled.div`
//   width: 10%;
//   @media screen and (min-width: 52em) {
//     width: 10%;
//   }
// `

const BookContainer1 = styled.div`
  width: 10%;
  @media screen and (min-width: 52em) {
    width: 12.5%;
  }
`
const StyledFlex = styled(Flex)`
  align-items: center;
  margin-bottom: 10px;
  width: 50%;
  @media screen and (min-width: 52em) {
    width: 220px;
  }
`

const StyledHeadingNew = styled(HeadingNew)`
  font-size: 13px;
  @media screen and (min-width: 52em) {
    font-size: 16px;
  }
`

// function shuffleArray(array) {
//     for (let i = array.length - 1; i > 0; i--) {
//         const j = Math.floor(Math.random() * (i + 1));
//         [array[i], array[j]] = [array[j], array[i]];
//     }
// }

const Guide = ({guide, addGuide, downloadGuide, recordView, user, PremiumUser}) => {
  const IconTranslator = {
    'Managing Habits': Icons.Habits,
    'Effective Communication': Icons.Communication,
    'Being Productive': Icons.Productivity,
    'Career Development': Icons.professionalDevelopment,
    'Decision-Making': Icons['Decision Making'],
    // 'Enhancing Creativity': Icons.Creativity,
    // 'Mastering Management': Icons.Management,
  }

  const GuideImageTranslator = {
    'Managing Habits': Icons.HabitsGuide,
    'Effective Communication': Icons.CommunicationGuide,
    'Being Productive': Icons.ProductivityGuide,
    'Career Development': Icons.CareerGuide,
    'Decision-Making': Icons.DecisionGuide,
    // 'Enhancing Creativity': Icons.ProductivityGuide,
    // 'Mastering Management': Icons.ProductivityGuide,
  }

  const GuideSampleTranslator = {
    'Managing Habits': '/Sivv_Guide_Managing_Habits_(Sample).pdf',
    'Effective Communication': '/Sivv_Guide_Effective_Communication_(Sample).pdf',
    'Being Productive': '/Sivv_Guide_Being_Productive_(Sample).pdf',
    'Career Development': '/Sivv_Guide_Career_Development_(Sample).pdf',
    // 'Decision-Making': Icons.DecisionGuide,
    // 'Enhancing Creativity': Icons.ProductivityGuide,
    // 'Mastering Management': Icons.ProductivityGuide,
  }

  let label
  let action
  if (guide.status !== 'Published') {
    label = 'Coming soon'
    action = null
  }
  if (guide.status === 'Published' && !PremiumUser) {
    label = 'Buy now ($29)'
    action = () => addGuide(guide.title)
  }
  if (guide.status === 'Published' && PremiumUser) {
    label = 'Download'
    action = () => downloadGuide(guide.title, user.token)
  }

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      width={1}
      style={{
        maxWidth: '1000px',
        borderBottom: '1px solid #f2f3f5',
      }}
      mt='25px'
      mb={['25px', '50px']}
      p={1}
      pb='50px'
      key={guide.title}
    >
      <Flex width={1} alignItems='center' mb={3} pb={1}>
        <Image src={IconTranslator[guide.title]} width='40px' mr={2} />
        <HeadingNew size='h1' palette='medium' as='h2'>
          {guide.title}
        </HeadingNew>
      </Flex>

      <MediumScreenShow>
        <StyledHeadingNew size='h3' palette='medium' as='h3' justify condensed>
          {guide.description}
        </StyledHeadingNew>
      </MediumScreenShow>

      <Flex
        width={1}
        flexDirection={['column', 'row', 'row']}
        alignItems={['center', 'flex-start']}
        mt={['10px', '0px']}
      >
        <Flex
          flexDirection={['row', 'column', 'column']}
          alignItems='center'
          justifyContent='space-around'
          width={[1, 1 / 3, 1 / 3]}
          mr={[0, 3, 3]}
        >
          <Image
            src={GuideImageTranslator[guide.title]}
            width={[4 / 10, 1, 1]}
            mb={[1, 3]}
            style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
          />
          <Flex
            width={[1 / 2, 1, 1]}
            flexDirection={['column']}
          >

            {guide.status === 'Published' && (
              <MediumScreenShow>
                <div
                  style={{
                    color: '#FFF',
                    background: '#FF6600',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    padding: '7px',
                    width: '100%',
                    textAlign: 'center',
                    borderRadius: '3px',
                    fontSize: '14px',
                    border: '1px solid #FF6600',
                    marginBottom: '15px',
                  }}
                  onClick={action}
                >
                  {label}
                </div>
              </MediumScreenShow>
            )}

            {guide.status === 'Published' && (
            <MediumScreenShow>
              <Flex width={1}>
              <RouterLink
                to={GuideSampleTranslator[guide.title]}
                target='_blank'
                download
                style={{
                  color: '#FF6600',
                  background: '#FFF',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  padding: '7px',
                  width: '100%',
                  textAlign: 'center',
                  borderRadius: '3px',
                  fontSize: '14px',
                  border: '1px solid #FF6600',
                }}
                onClick={() =>
                  recordView('guideSampleDownloads', guide.title, user._id)
                }
              >
                Download sample
              </RouterLink>
              </Flex>
              </MediumScreenShow>
            )}

            <MediumScreenShow>
              {guide.readingTime && (
                <Flex width={1} justifyContent='center'>
                  <StyledHeadingNew
                    size='h3'
                    palette='medium'
                    as='h3'
                    style={{fontSize: '10px', marginTop: '15px'}}
                  >
                    Reading time: {guide.readingTime} mins ({guide.wordcount} pages).
                  </StyledHeadingNew>
                </Flex>
              )}
            </MediumScreenShow>

          </Flex>
        </Flex>


        <Box
          flexDirection='column'
          width={[1, 1 / 2, 1 / 2]}
          mt={[3, 0, 0]}
          pl={[0, 2, 2]}
          flexGrow={1}
          justifyContent='space-between'
        >
       <MediumScreenHide>

            <StyledHeadingNew size='h3' palette='medium' as='h3' justify condensed>
          {guide.description} The tips, strategies and research covered will help you: 
        </StyledHeadingNew>
        </MediumScreenHide>

        <MediumScreenShow>

          <StyledHeadingNew size='h3' palette='medium' as='h3' justify condensed>
          The tips, strategies and research covered will help you: 
          </StyledHeadingNew>
          </MediumScreenShow>

          <Flex flexWrap='wrap' mt={[2, 3, 3]} justifyContent='space-around'>
            {guide.takeaways &&
              guide.takeaways.map((p) => (
                <StyledFlex key={p} mb={[2, 2, 3]}>
                  <img
                    src={Icons.SubscriptionArrow}
                    width='15px'
                    style={{marginRight: '5px'}}
                    alt='arrow'
                  />
                  <StyledHeadingNew
                    size='h3'
                    palette='medium'
                    as='h3'
                    style={{lineHeight: 1.2}}
                  >
                    {p}
                  </StyledHeadingNew>
                </StyledFlex>
              ))}
          </Flex>

          {/* <Flex width={1} flexDirection='column' mt={[2, 3, 3]}>
            {guide.status === 'Published' && (
              <StyledHeadingNew
                size='h3'
                palette='medium'
                as='h3'
                justify
                condensed
                style={{fontSize: '12px'}}
              >
                Key insights from:
              </StyledHeadingNew>
            )}

            <Flex width={1} justifyContent='center' style={{ border: '1px solid blue'}}>
              <Flex width={8 / 10} flexWrap='wrap'>
                {guide.bookSources &&
                  guide.bookSources.slice(0, 16).map((b) => (
                    <BookContainer1 key={b.title}>
                      {b.link ? (
                        <a target='null' href={b.link}>
                          <img src={b.image} width='100%' height='100%' alt={b.title} />
                        </a>
                      ) : (
                        <img src={b.image} width='100%' height='100%' alt={b.title} />
                      )}
                    </BookContainer1>
                  ))}
              </Flex>
            </Flex>
          </Flex> */}

          <MediumScreenHide>
            {guide.readingTime && (
              <Flex width={1} justifyContent='center' mt='10px' mb='10px'>
                <StyledHeadingNew
                  size='h3'
                  palette='medium'
                  as='h3'
                  style={{fontSize: '12px'}}
                >
                  Reading time: {guide.readingTime} mins ({guide.wordcount} pages).
                </StyledHeadingNew>
              </Flex>
            )}
          </MediumScreenHide>

          <MediumScreenHide>
            <Flex width={1} alignItems='center' mt={2} flexDirection='column'>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <Button id='btnopen' onClick={action} fullWidth>
                  {label} 
                </Button>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]} mt='15px'>
              {guide.status === 'Published' && (
              <RouterLink
                to={GuideSampleTranslator[guide.title]}
                target='_blank'
                download
              
              >
                
                <Button onClick={ () => recordView('guideSampleDownloads', guide.title, user._id)} fullWidth invertColor>
                Download sample
                </Button>
              </RouterLink>
            )}

              </Box>
            </Flex>
          </MediumScreenHide>
        </Box>
      </Flex>
      <Flex width={1} flexDirection='column' mt={[2, 3, 3]}>
        {guide.status === 'Published' && (
          <StyledHeadingNew
            size='h3'
            palette='medium'
            as='h3'
            justify
            condensed
            style={{fontSize: '16px', fontWeight: 500}}
          >
            Gain valuable insights from:
          </StyledHeadingNew>
        )}

        <Flex width={1} flexDirection='column' mt='10px'>
          <Flex width={1} flexWrap='wrap'>
            {guide.bookSources &&
              guide.bookSources.map((b) => (
                <BookContainer1 key={b.title}>
                  {b.link ? (
                    <a target='null' href={b.link}>
                      <img src={b.image} width='100%' height='100%' alt={b.title} />
                    </a>
                  ) : (
                    <img src={b.image} width='100%' height='100%' alt={b.title} />
                  )}
                </BookContainer1>
              ))}
          </Flex>

        <Flex width={1} mt={'5px'} flexDirection='column'>
          {/* <Flex>
            <Flex
              width={[1]}
              flexWrap='wrap'
              justifyContent={['flex-start', 'space-around', 'space-around']}
              flex={1}
            >
              {guide.publicationSources.map((b) => (
                <Image
                  key={b}
                  src={b}
                  width={['35px', '50px', '50px']}
                  height={['35px', '50px', '50px']}
                  mr={['3px', '0px', '0px']}
                />
              ))} */}
            {/* </Flex> */}

            {/* <Flex width={1} ml={['0px', '10px', '10px']} style={{ border: '1px solid red'}}>
              <StyledHeadingNew size='h3' palette='medium' as='h3' condensed>
                {guide.studies && (
                  <Flex
                    flexDirection={['column']}
                    alignItems='center'
                    style={{
                      lineHeight: '1.2',
                      minWidth: '150px',
                    }}
                  >
                    <span style={{fontSize: '35px'}}>
                      <b>{guide.studies}</b>
                    </span>{' '}
                    top academic studies
                  </Flex>
                )}
              </StyledHeadingNew>
            </Flex> */}


          {/* </Flex> */}


          <Flex width={1} mt={[2, 3, 3]}>
            <StyledHeadingNew size='h3' palette='medium' as='h3' condensed style={{ textAlign: 'justify'}}>
              {guide.thoughtLeaders ? <span><b>Plus</b> key takeaways from numerous articles and studies, as well as advice from leading thinkers including: {guide.thoughtLeaders}.</span>
              : 
              <span><b>Plus</b> key takeaways from numerous articles and studies.</span>
            }
            </StyledHeadingNew>
          </Flex>
        </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Guide
