import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import Select from '../FormElements/Select'
import TextBox from '../FormElements/TextBox'
import HeadingNew from '../FormElements/HeadingNew'

const Categoriser = ({addCategory, topics, articleTopics, captureInput}) => {
  return (
    <Flex flexWrap='wrap' flexDirection='row' width={1} pt={3}>
      <Box width={1}>
        <HeadingNew size='h3' palette='medium' as='h2' spacing='0 0 8px'>
          Tags (optional)
        </HeadingNew>
      </Box>
      <Box flex={['auto', 'auto', '1 1 auto']} width={[1, 1, 1 / 2]}>
        <Select
          options={topics}
          onChange={addCategory}
          placeholder='Select'
          label='Topics'
          name='topics'
        />
      </Box>
      <Flex
        width={[1, 1, 1 / 2]}
        flexWrap='wrap'
        alignItems='flexStart'
        pl={[0, 0, 2]}
        mt={[2, 2, 0]}
      >
        <TextBox
          label='Tags'
          onChange={(e) => captureInput(e, 'topics')}
          value={articleTopics}
          inputProps={{
            type: 'text',
            id: 'title',
            autoComplete: 'title',
            // onBlur: () => handleFiedlOnBlur(emailValidator)
          }}
          // error={this.state.error.title}
        />
      </Flex>
    </Flex>
  )
}

Categoriser.propTypes = {
  addCategory: PropTypes.func.isRequired,
  captureInput: PropTypes.func.isRequired,
  topics: PropTypes.array.isRequired,
  articleTopics: PropTypes.string.isRequired,
}

export default Categoriser
