import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {withRouter, Link} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import {Flex, Box} from 'rebass'
import {
  updateQuery,
  newSearch,
  viewEmailForm,
  emailArticle,
  captureEmail,
  resendArticle,
  viewComments,
  hideComments,
  captureComment,
  submitComment,
  recordView,
  flagArticle,
  viewLikes,
} from '../../actions/searchActions'
import {articleEngagement} from '../../actions/articleActions'
import {tracker} from '../../actions/trackerActions'
import EmailCapture from '../EmailCapture'
import Newsletter from '../Newsletter'
import Article from '../../components/Article/Article'
import Loader from '../../components/FormElements/Loader'
import Modal from '../../components/FormElements/Modal'
import HeadingNew from '../../components/FormElements/HeadingNew'
import FeedAdvertNew from '../../staticPages/FeedAdvertNew'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #de7033;
`

const StyledFilter = styled.div`
  color: #de7033;
  cursor: pointer;
`

const Separator = styled.span`
  color: #de7033;
  margin-left: 5px;
  margin-right: 5px;
`

const ConditionalWrapper = ({condition, wrapper, children}) =>
  condition ? wrapper(children) : children

const SearchResultsContainer = ({
  user,
  search,
  theme,
  type,
  topic,
  sourceType,
  articles,
  comments,
  loading,
  themePage,
  viewEmailForm1,
  emailTarget,
  articleEmailed,
  tracker,
  articleEmailError,
  comment,
  likes,
  history,
  home,
  landing,
  noResults,
  sameVisit,
  articleEngagement,
  updateQuery,
  newSearch,
  viewEmailForm,
  emailArticle,
  captureEmail,
  resendArticle,
  viewComments,
  hideComments,
  captureComment,
  submitComment,
  recordView,
  flagArticle,
  viewLikes,
}) => {
  const [view, toggleView] = useState(
    user.feedPreferences && user.feedPreferences[0]
      ? user.feedPreferences[0]
      : 'full'
  )


  useEffect(() => {
    const articleID = window.location.search.replace('?', '')
    if (articleID) toggleExpandedArticle(articleID)
    if (!home) window.scrollTo(0, 0)
    const l = window.location.pathname
    if (l === '/' || l === '/browse' || l.includes('/feed') || home) {
      newSearch(
        '-',
        '*',
        '*',
        '*',
        '*',
        null,
        user._id,
        l === '/browse' || home || !user._id ? 'br' : 's',
        articleID
      )
    }
    if (!user._id && !articleID && !sameVisit) {
      setTimeout(() => {
        hideModal(true)
        tracker()
      }, 5000)
    }

    const searchConfig = {
      '/browse/research': {type: 'Report / Study'},
      '/browse/communication': {topic: 'Communication'},
      '/browse/productivity': {topic: 'Productivity'},
      '/browse/decision-making': {topic: 'Decision-making'},
      '/browse/wellbeing': {theme: 'Wellbeing'},
      '/browse/creativity': {topic: 'Creativity'},
      '/browse/business': {theme: 'Business'},
      '/browse/career': {topic: 'Career'},
      '/browse/learning': {topic: 'Learning'},
      '/browse/reasoning': {topic: 'Reasoning'},
      '/browse/habits': {topic: 'Habits'},
    }

    if (
      [
        '/browse/research',
        '/browse/communication',
        '/browse/productivity',
        '/browse/decision-making',
        '/browse/wellbeing',
        '/browse/creativity',
        '/browse/business',
        '/browse/career',
        '/browse/learning',
        '/browse/reasoning',
        '/browse/habits',
      ].includes(l)
    ) {
      newSearch(
        '-',
        searchConfig[l].type || '*',
        searchConfig[l].topic || '*',
        searchConfig[l].theme || '*',
        '*',
        null,
        user._id,
        't'
      )
    }
  }, [])

  const viewMore = () => {
    if (
      ![
        '/browse',
        '/browse/research',
        '/browse/communication',
        '/browse/productivity',
        '/browse/decision-making',
        '/browse/wellbeing',
        '/browse/creativity',
        '/browse/business',
        '/browse/career',
        '/browse/learning',
        '/browse/reasoning',
        '/browse/habits',
      ].includes(window.location.pathname)
    ) {
      setTimeout(() => {
        newSearch(
          search,
          type,
          topic,
          theme,
          sourceType,
          articles.length,
          user._id,
          user._id ? 's' : 'br'
        )
      }, 500)
    }
  }

  // const themeSearch = (theme) => {
  //   updateQuery('theme', theme)
  //   newSearch(search, type, topic, theme, sourceType, null, user._id, 's')
  // }

  const viewArticle = (id, title) => {
    history.push(`/article/${id}/${title}`)
  }

  const viewProfile = (id, firstName, lastName) => {
    history.push(`/profile/${id}/${firstName}-${lastName}`)
  }

  const [expandedArticle, toggleExpandedArticle] = useState()

  const [showModal, hideModal] = useState(false)

  const viewFullArticle = (id) => {
    toggleExpandedArticle(id)
    const anchor = document.getElementById(id)
    if (anchor) {
      setTimeout(
        () => window.scrollTo({top: anchor.offsetTop - 85, behavior: 'smooth'}),
        10
      )
    }
    recordView('articleView', id, user._id)
  }

  const applyFilter = (filter) => {
    updateQuery('type', filter)
    newSearch('-', filter, '*', '*', '*', null, user._id, 's')
  }

  const closeModal = () => {
    hideModal(false)
  }

  const Browse = window.location.pathname.includes('/browse')

  return (
    <Flex
      width={1}
      justifyContent='flex-start'
      flexDirection='column'
      alignItems='center'
      style={{
        backgroundColor: landing ? '#2e4369' : '#fff',
        paddingTop: landing ? '0px' : '25px',
      }}
    >
      {!landing && !Browse && (
        <Flex
          alignItems='center'
          justifyContent='flex-end'
          width={1}
          pb={1}
          px={3}
          mb={3}
          mt={2}
          style={{maxWidth: '750px'}}
        >
          <Flex />
          <StyledFilter onClick={() => applyFilter('*')}>All</StyledFilter>
          <Separator>|</Separator>
          <StyledFilter onClick={() => applyFilter('Book')}>Books</StyledFilter>
          <Separator>|</Separator>
          <StyledFilter onClick={() => applyFilter('Report / Study')}>
            Research
          </StyledFilter>
          {!user._id && !Browse && (
            <Flex>
              <Separator>|</Separator>
              <StyledLink to='/newsletter'>Newsletter</StyledLink>
              {/* <Separator>|</Separator>
              <StyledLink to='/signin/submit'>Submit</StyledLink> */}
            </Flex>
          )}
        </Flex>
      )}

      {loading && (
        <Flex
          style={{minHeight: '70vh'}}
          alignItems='center'
          justifyContent='center'
        >
          <Loader />
        </Flex>
      )}

      {articles && articles.length > 0 && !loading && (
        <ConditionalWrapper
          condition={!Browse && !home}
          wrapper={(children) => {
            return (
              <InfiniteScroll
                dataLength={articles.length}
                next={viewMore}
                hasMore={!noResults}
                scrollThreshold={1}
                // refreshFunction={this.viewMore}
                // pullDownToRefresh
                loader={<Loader />}
                endMessage={
                  user._id ? (
                    <Flex width={1} justifyContent='center' py={3}>
                      <Box>
                        Unfortunately no more articles meet your search criteria.
                      </Box>
                    </Flex>
                  ) : (
                    <Flex
                      width={1}
                      alignItems='center'
                      flexDirection='column'
                      my={['25px', '50px']}
                    >
                      <Box mr={2}>
                        <HeadingNew size='h3' palette='medium' as='h3' centerText>
                          <StyledLink to='/subscription'>
                            <u>Sign-up</u>
                          </StyledLink>{' '}
                          to read more or subscribe to our regular digest:
                        </HeadingNew>
                      </Box>
                      <Box width={Browse ? [1, 2 / 3] : 1}>
                        <EmailCapture category='newsletter' themePage={themePage} />
                      </Box>
                    </Flex>
                  )
                }
              >
                {children}
              </InfiniteScroll>
            )
          }}
        >
          <div style={{maxWidth: '750px'}}>
            {articles &&
              articles.length > 0 &&
              view !== 'categorised' &&
              articles
                .filter(
                  (a, idx) =>
                    !(idx > 0 && a._id === window.location.search.replace('?', ''))
                )
                .map((a, idx) => {
                  return (
                    <>
                      <div id={a._id} />
                      <Article
                        key={idx}
                        data={a}
                        user={user}
                        articleEngagement={articleEngagement}
                        viewEmailForm={viewEmailForm}
                        emailArticle={emailArticle}
                        email={viewEmailForm1 === a._id}
                        expandedArticle={expandedArticle}
                        expandArticle={viewFullArticle}
                        captureEmail={captureEmail}
                        emailTarget={emailTarget}
                        articleEmailed={articleEmailed}
                        articleEmailError={articleEmailError}
                        resendArticle={resendArticle}
                        showComments={comments.articleID === a._id}
                        viewComments={viewComments}
                        hideComments={hideComments}
                        captureComment={captureComment}
                        submitComment={submitComment}
                        comment={comment}
                        comments={comments}
                        flagArticle={flagArticle}
                        results
                        viewLikes={viewLikes}
                        likes={likes}
                        view={view}
                        home={home}
                        landing={landing}
                        browse={Browse}
                        viewArticle={viewArticle}
                        viewProfile={viewProfile}
                      />
                      {idx === 9 && !landing && <FeedAdvertNew />}
                    </>
                  )
                })}
          </div>
        </ConditionalWrapper>
      )}
      {!loading && (!articles || articles.length === 0) && (
        <Box style={{color: '#2e4369', minHeight: '60vh'}} my='50px'>
          We're sorry - none of our records match that query.
        </Box>
      )}
      {showModal && (
        <Modal closeModal={closeModal}>
          <Newsletter popUp />
        </Modal>
      )}
      {Browse && (
        <Flex
          width={1}
          alignItems='center'
          flexDirection='column'
          my={['25px', '50px']}
        >
          <Box mr={2}>
            <HeadingNew size='h3' palette='medium' as='h3' centerText>
              <StyledLink to='/subscription'>
                <u>Sign-up</u>
              </StyledLink>{' '}
              to read more or subscribe to our regular digest:
            </HeadingNew>
          </Box>
          <Box width={Browse ? [1, 2 / 3] : 1}>
            <EmailCapture category='newsletter' themePage={themePage} />
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

SearchResultsContainer.propTypes = {
  articles: PropTypes.array.isRequired,
  query: PropTypes.object,
  user: PropTypes.object,
  comments: PropTypes.object.isRequired,
  likes: PropTypes.object.isRequired,
  viewEmailForm1: PropTypes.string,
  emailTarget: PropTypes.string,
  articleEmailed: PropTypes.bool.isRequired,
  articleEmailError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  comment: PropTypes.string,
  search: PropTypes.string,
  sourceType: PropTypes.string,
  type: PropTypes.string,
  topic: PropTypes.string,
  theme: PropTypes.string,
  home: PropTypes.bool,
  noResults: PropTypes.bool,
  history: PropTypes.object,
  tracker: PropTypes.func.isRequired,
  articleEngagement: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  newSearch: PropTypes.func.isRequired,
  viewEmailForm: PropTypes.func.isRequired,
  emailArticle: PropTypes.func.isRequired,
  captureEmail: PropTypes.func.isRequired,
  resendArticle: PropTypes.func.isRequired,
  viewComments: PropTypes.func.isRequired,
  hideComments: PropTypes.func.isRequired,
  captureComment: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
  viewLikes: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {results} = state.search
  return {
    total: results.total,
    loading: results.loading,
    error: results.error,
    time: results.time,
    preview: state.search.preview,
    articles: results.articles,
    emailTarget: state.search.emailTarget,
    user: state.user,
    viewEmailForm1: state.search.viewEmailForm,
    comments: state.search.comments,
    comment: state.search.comment,
    likes: state.search.likes,
    articleEmailed: state.search.articleEmailed,
    articleEmailError: state.search.articleEmailError,
    sourceType: state.search.sourceType,
    search: state.search.search,
    theme: state.search.theme,
    type: state.search.type,
    topic: state.search.topic,
    noResults: results.noResults,
    sameVisit: state.tracker.sameVisit,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    tracker,
    articleEngagement,
    updateQuery,
    newSearch,
    viewEmailForm,
    emailArticle,
    captureEmail,
    resendArticle,
    viewComments,
    hideComments,
    captureComment,
    submitComment,
    recordView,
    flagArticle,
    viewLikes,
  })(SearchResultsContainer)
)
