import React, {useEffect} from 'react'
import {Flex} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import BlogAdvert from './blogAdvert'
import SideAdvert from './sideAdvert'
import {
  SubHeading,
  SubSubHeading,
  Quote,
  QuoteHeading,
  BigQuote,
  SidePanel,
  NewParagraph,
  StyledLink,
  StyledLi,
  StyledSpan,
  Source,
} from './styles'

const Productivity = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      width={1}
      flexDirection='column'
      style={{maxWidth: '700px'}}
      px='20px'
      pb='25px'
    >
      <HeadingNew size='h1' palette='medium' as='h1'>
        How to tame email
      </HeadingNew>
      <HeadingNew size='h3' palette='medium' as='h3' style={{textAlign: 'justify'}}>
        <Quote>
          <QuoteHeading>
            ‘The email of the species is deadlier than the mail.’
          </QuoteHeading>
          <br />- Stephen Fry, comedian and writer
        </Quote>
        <BlogAdvert tag='productivity' />
        Many of us spend a significant portion of our time dealing with email. One
        study<sup>1</sup> found that the average knowledge worker spends around 28%
        of their day managing their inbox, while respondents to a survey<sup>2</sup>{' '}
        conducted in 2019 reported spending more than five hours each day checking
        work and personal accounts. We therefore stand to realise significant
        productivity gains though improving our approach to composing, screening and
        processing email.
        <SubHeading>SEND FEWER EMAILS</SubHeading>
        One of the best ways to reduce the burden of email is to stop using it for
        purposes that it is not particularly suited to.
        <NewParagraph />
        Given its versatility and ease of use, email often becomes the default option
        for addressing a query,
        <SidePanel>
          <SubHeading sp>THE HIDDEN COSTS OF EMAIL</SubHeading>
          The environmental cost of email represents yet another compelling reason to
          use it less. One recent report<sup>3</sup> found that the UK’s carbon
          output could be cut by 16,433 tonnes a year if everyone sent one fewer
          email each day. This is equivalent to the carbon emissions that would be
          produced by 81,152 people flying from London to Madrid. The report
          estimated that Brits send more than 64 million unnecessary emails every
          day, including 'unactionable one- or two-word pleasantries' such as 'thank
          you', 'have a good weekend', 'you too', 'LOL' and 'received'.
        </SidePanel>
        solving a problem or providing an update. As a result, we potentially
        overlook more effective alternatives. A short call, for instance, is
        generally preferable to sending multiple back-and-forth messages (and also
        better for building relationships<sup>4</sup>), while a face-to-face
        conversation often takes less time than crafting an email that subtly
        addresses a delicate or particularly complex topic<sup>a</sup>. Recorded
        voice memos or photographs are generally quicker to send and more effective
        than a detailed description. Relative to such alternatives, a message
        delivered in written form is also more likely to be misinterpreted.
        <NewParagraph />
        In ‘Unsubscribe: How to Kill Email Anxiety, Avoid Distractions, and Get Real
        Work Done’<sup>5</sup> Jocelyn Glei suggests that we can reduce the time we
        spend processing email by proactively closing conversations as soon as
        possible. For instance, instead of emailing someone to ask if they would like
        to meet for lunch, waiting for a reply, then sending multiple messages back
        and forth to arrange a date, time and venue, we could instead suggest three
        concrete options in the initial email and (hopefully) finalise arrangements
        with just two interactions. Similarly, in ‘The 4-Hour Work Week’<sup>6</sup>{' '}
        Tim Ferriss recommends proactively offering ‘if / then’ solutions (e.g., ‘if
        you don’t like Mexican food, let’s meet at the pizza restaurant’) to avoid
        the need for multiple messages. When scheduling involves multiple people or
        appointments, it is generally far more efficient to use a dedicated
        scheduling tool (such as Doodle polls).
        <SubHeading>COMPOSE BETTER EMAILS</SubHeading>
        Even when an email is the right tool for the task at hand, we often send
        ineffective messages that fail in their objective and spawn more emails as we
        are forced to follow-up or clarify<sup>b</sup>. Lengthy emails, for instance,
        are unlikely to be read fully and, even if they are, take longer to process
        and risk obscuring your request. As a result, they are more likely to elicit
        an unsatisfactory response or be forgotten or lost.
        <NewParagraph />
        Concise messages<sup>c</sup> that quickly highlight important takeaways or
        requests (e.g., using bullet points, lists or underlined text) are generally
        more effective, easier to process and take less time to compose. However, we
        should be careful that our attempts to reduce the time we spend managing
        email and efforts to write concise messages do not result in a loss of
        clarity. Linguist Naomi Baron argues<sup>7</sup> that emails are commonly
        misunderstood because:
        <StyledLi>
          <StyledSpan>
            Reading from a screen encourages recipients to quickly skim text and look
            for key takeaways.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            We have to process such a high volume of messages that most are written
            in haste and, as a result, end-up being sloppy or confusing.{' '}
          </StyledSpan>
        </StyledLi>
        So, while your emails do not need to be literary masterpieces, properly
        composing and proofreading the messages you send<sup>d</sup> will reduce the
        likelihood of you having to follow-up and clarify, ultimately saving you time
        and energy.
        <NewParagraph />
        Similarly, in ‘The HBR Guide to Better Business Writing’<sup>8</sup> Bryan A.
        Garner notes that, while emails should get straight to the point, it may be
        helpful to start them with a brief greeting or compliment to avoid coming
        across as cold or indifferent. You may also need to provide a short recap of
        the relevant details to help the recipient understand your request and
        respond appropriately.
        <NewParagraph />
        Ensuring that the subject line of the emails you send is short, specific and
        compelling will improve open rates and response times. You can also help
        recipients process and respond to your emails by highlighting any action
        required or important deadlines in the subject line<sup>e</sup> (using a
        format such as: ‘[Response needed by 5pm today] Finance report attached for
        comments’) and attaching or embedding hyperlinks to any resources that you
        refer to (meaning that they can be accessed with a single click).
        <SidePanel>
          <SubHeading sp>THE MAGIC OF TEXT EXPANDERS</SubHeading>
          The process of composing emails can be streamlined with the help of a text
          expander, which is a simple (often free) software tool that enables you to
          insert a snippet of text by typing a unique abbreviation that you have
          assigned to it. For example, instead of repeatedly retyping the elevator
          pitch for your company’s latest product across hundreds of emails, you can
          link the description to the abbreviation ‘prodDesc’. When composing an
          email, simply typing the eight characters of ‘prodDesc’ will insert the
          assigned text, saving you both time and effort.
        </SidePanel>
        <NewParagraph />
        You should also think carefully about who you copy-in when you compose an
        email and when to use ‘reply all’. Group messages can easily spawn tens or
        even hundreds of (often irrelevant or inane) follow-ups, while it is often
        unnecessary to keep everyone ‘in the loop’. When you are sending an email to
        multiple people who do not need to communicate with one another, you should
        therefore consider including recipients using BCC rather than CC. Doing so
        prevents conversations from expanding uncontrollably ('reply all' is no
        longer an option) and allows the email addresses of recipients to be kept
        private. To avoid any suspicion that may arise from the use of BCC (many
        people view it as tool that facilitates covert monitoring), you can specify
        who is receiving the message in the body of the email<sup>9</sup>.
        <NewParagraph />
        When sending emails to multiple people, you can also reduce the number of
        meaningless follow-ups you need to review by making ‘no response’ the default
        action<sup>f</sup> (e.g., by including ‘please only respond if…’ or ‘no need
        to reply’ in our message) or specifying what is required of individuals
        <sup>g</sup>. Writer and entrepreneur Brett McKay recommends<sup>10</sup>{' '}
        starting the subject line with ‘FYI’ (‘for your information’) and ending it
        with ‘NRN’ (‘no response needed’) to quickly convey this. Prefixes such as
        ‘Request’ or ‘Important’ can also help to catalyse a response.
        <NewParagraph />
        If replying to an email that includes multiple questions or requests, it is
        often easier to type responses within the original message (this also reduces
        the risk of failing to address any of the points raised). Highlighting that
        you have done so and / or using a different text colour should ensure that
        your answers are not overlooked.
        <NewParagraph />
        Yet another mental burden associated with email stems from the need to keep
        track of pending responses. Business coach Jason Womack suggests<sup>
          11
        </sup>{' '}
        that the best way to do this is to ‘BCC’ yourself into emails that require a
        response, then transfer the message from your inbox to a dedicated
        ‘follow-up’ folder that you regularly review.
        <SideAdvert />
        <SubHeading>SCREEN EFFECTIVELY</SubHeading>
        It is often possible to realise several ‘quick wins’ that reduce the number
        of emails you receive. These include unsubscribing from unwanted newsletters,
        opting out of social media or app notifications (e.g., emails that inform you
        when someone has liked, commented or updated something) and reducing the
        frequency of updates from sources that you are interested in (e.g.,
        subscribing to the ‘weekly highlights’ email rather than the ‘daily digest’).
        Some services (e.g., Throttle) make it possible to condense multiple
        newsletters into a single daily email or control when you receive them.
        <NewParagraph />
        Unwanted emails can be discouraged by creating more friction for prospective
        senders and diverting queries where possible. For instance, websites can be
        designed to take enquirers through a short survey and use the information
        gathered to redirect them to another person or resource (such as a list of
        frequently asked questions) that can provide an answer to their query. In
        addition, providing a form rather than an email address on a website allows
        you to define the structure and maximum length of messages, thus making them
        easier to process.
        <NewParagraph />
        <BigQuote>
          ‘An inbox is the to do list that anyone can add to.’
          <br />
          <span style={{fontSize: '14px', lineHeight: '24px'}}>
            - Daniel Egan, behavioural finance expert<sup>12</sup>
          </span>
        </BigQuote>
        A more extreme approach involves imposing a ‘sender-pays’ model in which
        senders must agree to pay a (relatively small) fee or donation to send an
        email to a contact that they do not personally know. This approach is
        designed to reduce the clutter that generic requests create, thus freeing
        time to focus on more important tasks.
        <NewParagraph />
        To minimise the time consumed by group emails, some teams nominate one person
        to screen and synthesise conversations and newsletters<sup>13</sup>.
        <SubHeading>BATCH PROCESS</SubHeading>
        Email represents a particularly disruptive interruption because it frequently
        raises issues or creates follow-ups that we cannot immediately resolve. These
        linger in our minds and make it harder to focus on other tasks. Moreover,
        breaking away from what you are doing to pay a visit to your inbox often acts
        as a gateway to other forms of digital distraction, such as checking news
        sites, social media platforms or messaging services.
        <NewParagraph />
        As noted above, planning your schedule so that instead of constantly checking
        and responding to emails you batch-process them during one or two dedicated
        periods each day (e.g., once before lunchtime and once in late afternoon)
        allows you to maintain lengthy periods of uninterrupted focus. Checking
        emails only when you have specifically allocated time to doing so also makes
        it easier to process each message in one go, which is generally more
        efficient<sup>h</sup>. Perhaps unsurprisingly, research<sup>14</sup> suggests
        that scheduled email checking reduces stress and improves wellbeing.
        <SidePanel>
          <SubHeading sp>EMAIL HABITS</SubHeading>
          Unfortunately, many of us develop an email habit, which makes it difficult
          to adhere to any self-imposed restrictions. Dealing with email makes us
          feel like we are being productive, while each message provides a reward in
          the form of novelty or mental stimulation that, over time, we start to
          crave in certain situations, particularly when at work<sup>i</sup>. The
          variable nature of the reward (each message is different) makes email even
          more enticing. To counter this:
          <StyledLi sp>
            <StyledSpan>
              Turn-off notifications and disable (or reduce the frequency of)
              automatic inbox refreshes.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Avoid leaving email programmes running in the background when working
              on other things.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              Make it harder to check email outside of the times you allocate to
              doing so, for instance by blocking accounts or maintaining separate
              devices for work and personal use. Some companies even automatically
              delay or delete emails sent to staff during weekends or holidays.
            </StyledSpan>
          </StyledLi>
        </SidePanel>
        <NewParagraph />
        Many email services provide a range of functions and tools that facilitate
        batch processing, such as the ability to navigate directly to the ‘compose’
        screen to avoid being distracted by your inbox (you can achieve a similar
        result by composing emails in a word processor). Some services also allow
        outgoing emails to be scheduled in advance, meaning that you can compose them
        at a time that best suits you but send them when they are most likely to be
        read or elicit a response.
        <NewParagraph />
        In ‘Never Check E-mail in the Morning’<sup>15</sup> Julie Morgenstern
        recommends avoiding email for at least the first hour of the working day.
        Otherwise, she warns, we risk getting caught up with responding to the
        priorities of other people and losing what is often the most productive part
        of our day. However, given that many people will assume that we will be
        constantly checking our inboxes, they may send an email to communicate
        last-minute or urgent updates. Reflecting this, author Laura Vanderkam
        recommends<sup>16</sup> conducting a quick inbox scan at the beginning of the
        day (ideally on your phone during your commute) before turning off
        notifications and starting to work.
        <NewParagraph />
        Most senders are unlikely to be annoyed by a small delay in receiving a
        response<sup>j</sup>. However, if you constantly respond to emails within
        minutes of receiving them your contacts will start to expect quick replies
        and may become frustrated when you fail to deliver. You can manage
        expectations by setting-up an automated response that advises senders not to
        expect a swift reply but provides a phone number through which you can be
        contacted immediately if the matter is urgent. It may also be necessary to
        set-up an alert that informs you when you receive an email from a priority
        contact.
        <SubHeading>STREAMLINE PROCESSING</SubHeading>
        Arguably the most frustrating consequence or our email dependency is the time
        that we are forced to spend dealing with unwanted or irrelevant messages. In
        fact, one study<sup>17</sup> estimated that around 50% of the time we devote
        to managing email is spent reading and responding to messages that we should
        not have received in the first place.
        <NewParagraph />
        In such cases, we should not feel obliged to respond and can reduce any guilt
        we feel about ignoring an email (as well as reduce the risk of causing
        offence) by actively managing the expectations of senders. For example, we
        can use email signatures or automated responses to:
        <StyledLi>
          <StyledSpan>
            {' '}
            List answers to a number of frequently asked questions and instruct the
            sender to call or follow-up only if their query is not covered.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            {' '}
            Thank the sender for their message but inform them that they are unlikely
            to receive a response due to the high volume of emails that we receive.{' '}
          </StyledSpan>
        </StyledLi>
        Author Matt Gemmell suggests<sup>18</sup> that, if you do not feel compelled
        to respond to an email, you should simply ignore it until the sender
        follows-up. If they fail to do so you can assume that the email was not
        important and that responding would have been a waste of your time.
        <NewParagraph />
        Alternatively, we can reduce the time and effort required to respond by
        reusing saved template responses (altered slightly if necessary) to reply to
        common requests. Saving these as different email signatures makes replying
        even easier.
        <SubSubHeading>YESTERBOX</SubSubHeading>
        Managing email is a never-ending process. Even if you manage to get to 'inbox
        zero', it will almost certainly be a short-lived achievement, in part because
        many of the emails you send generate more in response. To deal with the daily
        overwhelm, Zappos founder Tony Hsieh developed an approach called 'Yesterbox'
        <sup>19</sup>
        , which involves replying only to (non-urgent) emails that you received
        yesterday.
        <NewParagraph />
        To implement this approach, Hsieh suggests scheduling time each day to go
        through yesterday's inbox (allocating more time if you fall behind and when
        you return from a holiday). He suggests processing yesterday’s messages in
        blocks of 10 before checking today's emails, which you can either delete,
        file or forward. If a response is needed, unless it cannot wait 48 hours, you
        should not think about it until the next day (the one exception is if the
        email is part of an earlier email chain).
        <NewParagraph />
        While this approach may take time to get used to, it offers several benefits.
        For one, it means that each day you need to process a limited number of
        messages, which transforms email from a never-ending task to one that can be
        completed. Doing so provides a great sense of achievement and relief.
        Moreover, it discourages you from procrastinating on responding to difficult
        or important emails. While responses are not immediate, they are consistent
        in that they mostly happen within 48 hours.
        <NewParagraph />
        If an email requires more than 10 minutes to respond to or the response
        requires some research, Hsieh recommends filing it in a dedicated folder and
        scheduling a time to process it (including the folder name and email subject
        line in the calendar entry). This ensures that you will respond but removes
        the email from your inbox, reducing the anxiety it causes. If you send an
        email that requires a response, Hsieh suggests that immediately after sending
        you schedule an appointment on your calendar to check that you have received
        what you need.
        <SidePanel>
          <SubHeading sp>GMAIL SEARCH OPERATORS</SubHeading> Use the following search
          operators within Gmail to more easily find what you are looking for:
          <StyledLi sp>
            <StyledSpan>
              from: (e.g., from: Dave Smith) - specifies the sender.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>to: - specifies the recipient.</StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              subject: (e.g., subject: drinks) - searches message subject lines for
              the specified phrase.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              has: attachment - searches for emails with an attachment.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              filename: (e.g., filename: budget) - searches for an attachment with
              the specified name.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              “” (e.g., “wedding arrangements”) - searches for an exact match with
              the specified phrase.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              after: yyyy/mm/dd (e.g., after: 2020/04/20) – searches for messages
              received after the specified date.
            </StyledSpan>
          </StyledLi>
          <StyledLi sp>
            <StyledSpan>
              before: yyyy/mm/dd - searches for messages received before the
              specified date.
            </StyledSpan>
          </StyledLi>
        </SidePanel>
        <SubSubHeading>THE 3210 APPROACH</SubSubHeading>
        In ‘15 Secrets Successful People Know About Time Management’<sup>
          20
        </sup>{' '}
        Kevin Kruse recommends processing email using the ‘3210 approach’, which
        involves checking email three times a day, each time allocating just 21
        minutes to get to inbox zero. This approach is intended to be ambitious in
        order to force you to prioritise, focus, keep replies short and resist
        clicking links. For each email, Kruse recommends applying one of David
        Allen’s ‘4Ds’ (discussed above): do (if it will take less than two minutes),
        delete, delegate or defer.
        <SubSubHeading>INBOX ZERO</SubSubHeading>
        In ‘Lifehacker’<sup>21</sup>, Adam Pash and Gina Trapani argue that you
        should strive to regularly get to ‘inbox zero’. As they note, the inbox is
        not the appropriate place to store most messages (e.g., invites should be
        moved to a calendar and project files saved to the relevant server folder),
        while being greeted by a long list of messages each time you check your
        emailS can be overwhelming and make it difficult to prioritise.
        <NewParagraph />
        They therefore suggest that you establish a clear demarcation between
        messages that are incoming and those that have been resolved or are in
        process. The approach they recommend involves:
        <StyledLi>
          <StyledSpan>
            {' '}
            Creating three folders: archive, follow-up and hold.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            {' '}
            Storing anything that you may need in the future within the archive
            folder.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            {' '}
            Moving messages that require some action into the follow-up folder and
            adding the action to your to-do list or task management system.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            {' '}
            Placing anything that you will need to access in the near future into the
            hold folder, as well as anything for which you are waiting for a
            response.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            {' '}
            Regularly reviewing the follow-up and hold folders and removing anything
            that is no longer relevant. Marking all messages within these folders as
            ‘unread’ makes it easy to keep track of the number of outstanding items.{' '}
          </StyledSpan>
        </StyledLi>
        Pash and Trapani also recommend that you:
        <StyledLi>
          <StyledSpan>
            {' '}
            Make a decision on what to do with each email within a day of receiving
            it.{' '}
          </StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>Never leave a read email in your inbox.</StyledSpan>
        </StyledLi>
        <StyledLi>
          <StyledSpan>
            Avoid spending time to subdivide the archive folder and instead rely on
            inbox searches to find messages.
          </StyledSpan>
        </StyledLi>
        <SubSubHeading>MANAGING HOLIDAYS</SubSubHeading>
        Email can become particularly problematic during holidays, when many of us
        face a trade-off between taking time each day to monitor our messages or
        returning to an overflowing inbox. One way to manage the situation is to use
        an out-of-office message to set expectations and transfer some of the
        responsibility back to senders. For instance, this message could convey that
        you will attempt to respond within a few days of returning but highlight the
        risk that you may overlook the email and ask the sender to follow-up if you
        have not responded by a certain date<sup>22</sup>. You may also find that
        turning-on your out-of-office response a day before going on holiday makes it
        easier to disengage from ongoing tasks and leave on time<sup>23</sup>.
        <SubHeading>FILTER INTELLIGENTLY</SubHeading>
        You can also reduce the amount of processing you need to do by automatically
        filtering, labelling and segregating emails based on topic, sender and
        frequency of required engagement. Some email services (such as Gmail) make
        this process easier by allowing email addresses to be ‘tagged’ by adding a
        suffix (of the format ‘+tag’) to the username. For example, emails sent to
        ‘user+receipts@gmail.com’ will be delivered to the ‘user@gmail.com’ account,
        but the tag allows them to be automatically screened and filtered into
        specific inbox folders. This also makes it easier to search for specific
        emails and create processes to automatically delete, block<sup>k</sup> or
        forward messages from certain addresses (unlike filtering by the sender’s
        email address, these rules will apply even if the sender uses multiple
        accounts to contact you).
        <SidePanel>
          <SubHeading sp>A WORLD WITHOUT EMAIL</SubHeading>
          In ‘A World Without Email’<sup>24</sup> Cal Newport notes that while email
          can be useful for tasks such as broadcasting information and sending files,
          it is ill suited to anything that requires back-and-forth interaction.
          <NewParagraph />
          Unfortunately, however, many organisations operate with a ‘hyperactive hive
          mind’ model in which workers constantly engage in unstructured and
          unscheduled back-and-forth email interactions with colleagues and clients.
          While back-and-forth collaboration is entirely natural and essential for
          many tasks, doing it via email has several downsides. For instance,
          relative to a face-to-face conversation it takes much longer, involves
          numerous interruptions and leads to more misunderstanding because we are
          less effective at communicating using text alone. Moreover, the cognitive
          strain of managing multiple ongoing conversations (including writing,
          reading and checking for messages) and the constant switching between tasks
          that this entails is hugely disruptive to our productivity and creativity.
          The situation is made worse by the fact that we are wired to maintain
          social networks, so feel compelled to respond quickly to the messages that
          we receive.
          <NewParagraph />
          Newport argues that, because autonomy is at the heart of knowledge work,
          very few organisations seek to impose systems or processes that could
          discourage the inappropriate use of email. While likely to be met with
          resistance, initiatives such as adopting a shared task management system in
          which information is stored in a central repository (as opposed to within
          multiple inboxes) or establishing protocols around frequently repeated
          processes could significantly reduce the need for back-and-forth emailing.
        </SidePanel>
        <NewParagraph />
        For example, you could maintain three email folders (one that you check
        weekly, one monthly and one each quarter) and set-up automatic filters that
        place emails from certain senders into each. This reduces the amount of
        processing required on a day-to-day basis while ensuring that time-sensitive
        tasks are dealt with when appropriate. Alternatively, you could divert emails
        from key contacts or that are sent only to you (i.e., not group emails) into
        a ‘high priority’ bucket or filter all emails concerning a specific project
        into a dedicated folder, which makes it easier to deal with them only when
        you are working on that project.
        <NewParagraph />
        In the ‘Art of Life Admin’<sup>25</sup> Elizabeth Emens suggests going a step
        further to create a secondary email account for purchases that you check only
        when you need to access a receipt or ticket (thus remaining blissfully
        unaware of all the marketing emails and spam that you receive as a result of
        your purchases). Interestingly, investor Marc Andreessen recommends
        <sup>26</sup> a similar approach for phone calls. He suggests that you should
        have two phones
        <sup>l</sup> – one for family, friends, your boss and selected co-workers,
        and one for everyone else – and always answer the first but never the second.
        Anyone calling the second phone can leave a voicemail, and these can be
        screened twice per day and batch processed. Alternatively, as Tim Ferriss
        suggests in ‘The 4-Hour Work Week’, you can provide your priority number in
        the non-priority voicemail message, so that the caller can reach you quickly
        if their query is urgent.
        <SubHeading>SYSTEMIC CHANGE</SubHeading>
        To be able to fully implement many of these approaches, you are likely to
        need to at least inform and, in some cases, seek permission from your
        contacts. For instance, you may need to establish expectations of brevity in
        order to avoid causing offense, or secure sign-off from your boss to be able
        to stick to a batched processing schedule. In fact, while you will
        undoubtedly benefit from embracing these principles as an individual, without
        a more systemic change to the habits of others in your organisation or
        network, email is likely to continue to represent a significant time drain.
        Moreover, any benefits that accrue from improving your approach to email can
        be largely negated if you supplement it with instant messaging services.
      </HeadingNew>

      <Flex width={1} flexDirection='column' mt='10px'>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          style={{textAlign: 'justify'}}
        >
          <SubHeading>Footnotes</SubHeading>

          <Source>
            a. Ensure that your emails include a signature that clearly conveys
            relevant information such as alternative contact details and your working
            hours.
          </Source>
          <Source>
            b. Remember also that emails are easily forwarded and, within companies,
            often monitored. Never write anything that you would be embarrassed or
            ashamed for others to see and avoid sending a message while highly
            emotional (or drunk).
          </Source>
          <Source>
            c. Some people suggest sticking to a maximum of five sentences or one
            screen of text.
          </Source>
          <Source>
            d. See the{' '}
            <StyledLink to='/guides/Effective-Communication'>
              Sivv Guide to Effective Communication
            </StyledLink>{' '}
            on writing.
          </Source>
          <Source>
            e. In some cases, the entire message may fit in the subject line.
            Consider adding ‘EOM’ (for ‘end of message’) at the end of the subject
            line to confirm that the body of the email has been intentionally left
            empty.
          </Source>
          <Source>
            f. If appropriate, you can also make agreement to whatever you are asking
            for the default response (e.g., ‘if I don’t hear back from you by 6pm I
            will proceed’), which removes the burden of having to track or chase-up
            replies.
          </Source>
          <Source>
            g. Requests made to groups rather than individuals tend to be ignored.
          </Source>
          <Source>
            h. In ‘Unsubscribe’ Jocelyn Glei suggests that if you receive an
            important email that requires a significant amount of work before you
            respond, instead of disrupting your planned schedule to deal with it you
            should send a quick reply to confirm receipt and set expectations about
            when you will follow-up.
          </Source>
          <Source>
            i. See the{' '}
            <StyledLink to='/guides/Managing-Habits'>
              Sivv Guide to Managing Habits
            </StyledLink>{' '}
            for more on this.
          </Source>
          <Source>
            j. An excessive delay can, however, make you look unprofessional or
            disorganised and lead to reciprocal behaviour.
          </Source>
          <Source>
            k. Using a unique email for each service you sign-up to makes it easy to
            identify the source of any spam email and quickly put an end to it.
            Alternatively, you could use one of the many available ‘disposable’ email
            services, which make it possible to quickly create a unique address for
            single or time-limited use.
          </Source>
          <Source>
            l. We can follow this approach with a single phone if we assign different
            ring tones to priority and non-priority contacts or update our phone
            settings to divert non-priority calls to voicemail.
          </Source>
        </HeadingNew>
      </Flex>
      <Flex width={1} flexDirection='column' mt='10px'>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          style={{textAlign: 'justify'}}
        >
          <SubHeading>Sources</SubHeading>
          <Source>
            1. Chui, M et al (2010), The social economy: Unlocking value and
            productivity through social technologies, mcKinsey.com
          </Source>
          <Source>
            2. Abramovich, G (2019), If You Think Email Is Dead, Think Again,
            cmo.adobe.com
          </Source>
          <Source>
            3. ‘Think Before You Thank’: If every Brit sent one less thank you email
            a day, we would save 16,433 tonnes of carbon a year - the same as 81,152
            flights to Madrid (2019), ovoenergy.com
          </Source>
          <Source>
            4. Kumar, A and Epley, N, (2020), It’s surprisingly nice to hear you:
            Misunderstanding the impact of communication media can lead to suboptimal
            choices of how to connect with others, Journal of Experimental
            Psychology: General
          </Source>
          <Source>
            5. Glei, J (2016), Unsubscribe: How to Kill Email Anxiety, Avoid
            Distractions, and Get Real Work Done, Piatkus
          </Source>
          <Source>6. Ferriss, T (2011), The 4-Hour Work Week, Vermilion</Source>
          <Source>
            7. Baron, N (2018), DR. NAOMI BARON: MAXIMIZING AND USING DIGITAL
            COMMUNICATION SKILLS IN LEADERSHIP, EPISODE #2, ERICADHAWAN.COM
          </Source>
          <Source>
            8. Garner, BA (2013), The HBR Guide to Better Business Writing, Harvard
            Business Review Press
          </Source>
          <Source>
            9. Landry, A and Lewiss, RE (2021), What a Compassionate Email Culture
            Looks Like, hbr.org
          </Source>
          <Source>
            10. McKay, B and McKay, K (2013), Slay the Email Monster! How to Manage
            Inbox Overload and Actually Get Stuff Done, artofmanliness.com
          </Source>
          <Source>
            11. Womack, J (2013), How to Transform Your Email into a Productivity
            Tool, entrepreneur.com
          </Source>
          <Source>
            12. Egan, D (2018), A novel way to prevent email overload, bbc.com
          </Source>
          <Source>
            13. Romney, MS et al (2020), Tame Your Inbox Like an ER Doctor, hbr.org
          </Source>
          <Source>
            14. Kushlev, K and Dunn, EW (2015), Checking email less frequently
            reduces stress, Computers in Human Behavior Volume 43, February 2015,
            Pages 220-228
          </Source>
          <Source>
            15. Morgenstern, J (2005), Never Check E-mail in the Morning, Fireside
            Books
          </Source>
          <Source>
            16. Vanderkam, L (2016), How to Never Check Email in the Morning While
            Still Checking Email in the Morning, lauravanderkam.com
          </Source>
          <Source>
            17. Mankins, M (2017), Why the French Email Law Won’t Restore Work-Life
            Balance, hbr.org
          </Source>
          <Source>
            18. Gemmell, M (2012), Managing Email Realistically, mattgemmell.com
          </Source>
          <Source>19. Hsieh, T, Yesterbox, yesterbox.com</Source>
          <Source>
            20. Kruse, K (2015), 15 Secrets Successful People Know About Time
            Management, The Kruse Group
          </Source>

          <Source>
            21. Pash, A and Trapani, G (2011), Lifehacker: The Guide to Working
            Smarter, Faster, and Better, Wiley
          </Source>
          <Source>
            22. Samuel, A (2015), Ease the Pain of Returning to Work After Time Off,
            hbr.org
          </Source>
          <Source>
            23. Saunders, EG (2015), Going on Vacation Doesn’t Have to Stress You Out
            at Work, hbr.org
          </Source>
          <Source>
            24. Newport, C (2021), A World Without Email, Portfolio Penguin
          </Source>
          <Source>25. Emens, E (2019), The Art of Life Admin, Viking</Source>
          <Source>
            26. Andreessen, M (2007), Pmarca Guide to Personal Productivity,
            pmarchive.com{' '}
          </Source>
        </HeadingNew>
      </Flex>
    </Flex>
  )
}

export default Productivity
