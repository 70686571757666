import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Box, Flex, Image} from 'rebass'
import Button from '../../components/FormElements/Button'
import {emailUnsubscribe} from '../../actions/user'
import HeadingNew from '../../components/FormElements/HeadingNew'
import loginIllustration from '../../images/illustration/knowledge.png'

const Header = styled.div`
  margin-bottom: 16px;
`
const VerticalCenter = styled.div`
  min-height: calc(100vh - 220px);
  display: flex;
  justify-content: center;
`

const EmailUnsubscribe = ({
  match,
  unsubscribeConfirmed,
  unsubscribeError,
  emailUnsubscribe,
  history,
}) => {
  const handleClickBackHome = () => {
    history.push('/')
  }

  return (
    <>
      <VerticalCenter>
        <Flex
          mt={3}
          mx={-2}
          p={3}
          flexDirection={['column', 'row']}
          justifyContent='center'
        >
          <Flex
            flexDirection='column'
            ml={[0, 0, 100]}
            width={[1, 1 / 2, 5 / 12]}
            justifyContent='center'
            displayFlex='flex'
            as='div'
          >
            <Box p={2}>
              <Header as='header'>
                <HeadingNew size='h1' palette='medium' as='h2'>
                  {!unsubscribeConfirmed
                    ? `Are you sure that you want to unsubscribe from ${
                        match.params.type === 'digest'
                          ? 'the Sivv digest email'
                          : 'this email alert'
                      }?`
                    : 'Unsubscribe confirmed - sorry to see you go...'}
                </HeadingNew>
                {unsubscribeError && (
                  <HeadingNew size='h3' palette='medium' as='h3'>
                    <span style={{color: 'red'}}>
                      Something went wrong - please email us at info@sivv.io
                    </span>
                  </HeadingNew>
                )}
              </Header>
            </Box>
            <Box px={2} width={[1, 1 / 2, 1 / 2]} pb='24px'>
              {!unsubscribeConfirmed && !unsubscribeError && (
                <Button
                  onClick={() =>
                    emailUnsubscribe(
                      match.params.id,
                      match.params.email,
                      match.params.type
                    )
                  }
                  palette='secondary'
                  fullWidth
                >
                  Confirm
                </Button>
              )}
              {(unsubscribeConfirmed || unsubscribeError) && (
                <Button onClick={handleClickBackHome} palette='secondary' fullWidth>
                  Back to Home
                </Button>
              )}
            </Box>
          </Flex>
          <Flex
            flexDirection='column'
            justifyContent='center'
            p={2}
            px={[80, 2, 2]}
            width={[1, 1 / 2, 7 / 12]}
            ml={[0, 0, 80]}
            mt={[50, 50, 80]}
            textAlign='center'
          >
            <Box>
              <Image
                width={1}
                src={loginIllustration}
                alt='Discover useful insights, knowledge nad advice'
                title='Sivv helps you learn'
              />
            </Box>
          </Flex>
        </Flex>
      </VerticalCenter>
    </>
  )
}

EmailUnsubscribe.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  emailUnsubscribe: PropTypes.func.isRequired,
  unsubscribeConfirmed: PropTypes.bool.isRequired,
  unsubscribeError: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const {unsubscribeConfirmed} = state.user
  const {unsubscribeError} = state.user
  return {unsubscribeConfirmed, unsubscribeError}
}

export default connect(mapStateToProps, {emailUnsubscribe})(EmailUnsubscribe)
