import React, {useEffect} from 'react'
import {Flex, Box, Image} from 'rebass'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Button from '../components/FormElements/Button'
import HeadingNew from '../components/FormElements/HeadingNew'
import Icons from '../images/icons/icons'
// import BookList from '../images/bookImages/bookList'
import Brain from './Brain1.png'
import Mosaic from './Mosaic.jpg'

const TextHolder = styled.div`
  @media screen and (max-width: 52em) {
    display: none;
  }
`

const TextHolder1 = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : '54px')};
  @media screen and (min-width: 52em) {
    display: none;
  }
`


const BookImageHolder = styled.div`
@media screen and (max-width: 320px) {
  display: none;
}
  width: 54px;
  @media screen and (min-width: 52em) {
    display: none;
  }
`


const BookContainer = styled.div`
  width: 10%;
  background-color: #005969;
  @media screen and (min-width: 52em) {
    width: 5%;
    background-color: #2e4369;
  }
`

const benefits = [
  "Quickly access the best advice, tips and wisdom from the world's leading thinkers.",
  <div>
    Overcome the
    <Link
      style={{textDecoration: 'none', color: '#de7033'}}
      to='./information-inefficiencies'
    >
      {' '}
      inefficiencies{' '}
    </Link>
    of information consumption to save time, effort and money.
  </div>,
  'Accelerate progress towards your professional and personal goals.',
]

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const scrollToGuides = (id) => {
    const anchor = document.getElementById(id)
    if (anchor) {
      setTimeout(
        () => window.scrollTo({top: anchor.offsetTop - 85, behavior: 'smooth'}),
        10
      )
    }
  }

  const GeneralImage = (
    <Flex
      width={[1, 1, '190px']}
      flexWrap='Wrap'
      justifyContent={['space-around', 'space-around', 'flex-end']}
      style={{minWidth: '190px'}}
      mt={['20px', '20px', '0px']}
    >
      {['ProductivityGuide', 'HabitsGuide', 'CommunicationGuide', 'CareerGuide'].map(
        (g) => (
          <Box
            height={['75px', '75px', '120px']}
            width={['54px', '54px', '86px']}
            mb='3px'
            ml={['0px', '3px']}
            key={g}
          >
            <LazyLoadImage width='100%' height='100%' effect='blur' src={Icons[g]} />
          </Box>
        )
      )}

      <BookImageHolder>
        <Box height={['75px', '75px', '120px']} width={['54px', '54px', '86px']}>
          <LazyLoadImage
            width='100%'
            height='100%'
            effect='blur'
            src={Icons.DecisionGuide}
          />
        </Box>
      </BookImageHolder>
    </Flex>
  )

  const SingleImage = (icon, message) => (
    <Flex
      width={[1, 1, '190px']}
      justifyContent={['space-around', 'space-around', 'flex-end']}
      style={{minWidth: '190px'}}
      mt={['10px', '20px', '0px']}
      alignItems='center'
    >
      <TextHolder1 style={{width: '60%'}}>
        <Box width={1}>
          <HeadingNew
            size='h2'
            as='h2'
            style={{lineHeight: '1.3', marginBottom: '10px'}}
            palette='white'
          >
            {message}
          </HeadingNew>
        </Box>
      </TextHolder1>
      <Image
        src={Icons[icon]}
        height={['150px', '200px']}
        mb='3px'
        loading='eager'
      />
    </Flex>
  )

  // {BookList.map((g) => (
  //   <BookContainer key={g.title}>
  //     <LazyLoadImage
  //       width='100%'
  //       height='100%'
  //       effect='blur'
  //       alt={g.title}
  //       src={g.image}
  //     />
  //   </BookContainer>
  // ))}

  let Headline = 'Get Smarter, Faster'
  let LandingMessage =
    'The best ideas from the best nonfiction, summarised & curated within concise topic guides'
  let LandingButtonLabel = 'View Guides'
  let LandingImage = GeneralImage

  const SpecifierTranlator = {
    'Being-Productive': {
      title: 'Being Productive',
      headline: 'Get More Done',
      message:
        "The world's best productivity hacks, summarised & curated within a single ebook",
      icon: 'ProductivityGuide',
    },
    'Effective-Communication': {
      title: 'Effective Communication',
      headline: 'Convey Your Message',
      message:
        "The world's best communication techniques, summarised & curated within a single ebook",
      icon: 'CommunicationGuide',
    },
    'Managing-Habits': {
      title: 'Managing Habits',
      headline: 'Master Your Habits',
      message:
        "The world's best methods for managing habits, summarised & curated within a single ebook",
      icon: 'HabitsGuide',
    },
    'Career-Development': {
      title: 'Career Development',
      headline: 'Fulfil Your Potential',
      message:
        "The world's best strategies for career success, summarised & curated within a single ebook",
      icon: 'CareerGuide',
    },
  }

  const specifier = window.location.pathname.split('uides/')[1]
  if (SpecifierTranlator[specifier]) {
    Headline = SpecifierTranlator[specifier].headline
    LandingMessage = SpecifierTranlator[specifier].message
    LandingButtonLabel = 'View Guide'
    LandingImage = SingleImage(
      SpecifierTranlator[specifier].icon,
      SpecifierTranlator[specifier].message
    )
  }

  return (
    <>
      <Flex>
        <Flex width={1} justifyContent='space-around' alignItems='center'>
          <Flex
            flexWrap='wrap'
            width={1}
            style={{
              maxHeight: '100vh',
              height: '100vh',
              overflow: 'hidden',
              background: '#005969',
              backgroundImage: `url(${Mosaic})`,
              backgroundSize: 'cover'
            }}
           />
        </Flex>
        <Flex
          ml={['2.5%', '50px']}
          px={['15px', '30px', '50px']}
          py={['30px', '30px', '50px']}
          width={[9.5 / 10, 6 / 10]}
          flexDirection={['column', 'column', 'row']}
          alignItems='center'
          style={{
            position: 'absolute',
            background: '#2e4369',
            top: '25%',
            color: '#fff',
            backgroundColor: 'rgba(94,125,140,0.98)',
            borderRadius: '5px',
            maxWidth: '750px',
          }}
        >
          <Flex width={1} flexDirection='column' style={{minWidth: '250px'}}>
            <HeadingNew
              size='h1'
              as='h1'
              style={{lineHeight: '1.3', marginBottom: '5px'}}
              palette='white'
            >
              <b>{Headline}</b>
            </HeadingNew>
            <HeadingNew
              size='h2'
              as='h2'
              style={{lineHeight: '1.3'}}
              palette='white'
            >
              {specifier ? (
                <TextHolder>{LandingMessage}</TextHolder>
              ) : (
                <>{LandingMessage}</>
              )}
            </HeadingNew>
            <TextHolder>
              <Box mt='20px' width={1 / 2}>
                <Button onClick={() => scrollToGuides('landingAnchor')} fullWidth>
                  {LandingButtonLabel}
                </Button>
              </Box>
            </TextHolder>
          </Flex>
          {LandingImage}
          <TextHolder1 fullWidth>
            <Box mt='20px'>
              <Button fullWidth onClick={() => scrollToGuides('landingAnchor')}>
                {LandingButtonLabel}
              </Button>
            </Box>
          </TextHolder1>
        </Flex>
      </Flex>

      <Flex
        px={['25px', '75px']}
        py={['50px', '75px']}
        flexDirection='column'
        style={{maxWidth: '1400px'}}
        mx='auto'
      >
        <Flex
          width={1}
          flexDirection={['column', 'column', 'row']}
          alignItems='center'
        >
          <Flex width={[1, 1, 1 / 2]} flexDirection='column'>
            <HeadingNew size='h3' palette='medium' as='h3' justify condensed>
              Sivv guides summarise and curate the key takeaways from the world’s
              best books, articles and research to help you:
            </HeadingNew>
            {benefits.map((b) => (
              <Flex mt={['40px', '50px']} pl={['10px', '30px']} key={b}>
                <Fade>
                  <HeadingNew
                    size='h3'
                    as='h3'
                    style={{lineHeight: '1.3'}}
                    palette='medium'
                  >
                    <Image
                      src={Icons.SubscriptionArrow}
                      width='15px'
                      style={{float: 'left'}}
                      mr='5px'
                      mt={['4px']}
                    />
                    {b}
                  </HeadingNew>
                </Fade>
              </Flex>
            ))}
            <Box mt={['40px', '50px']}>
              <HeadingNew size='h3' palette='medium' as='h3' justify condensed>
                Guides are available to buy and download individually, as a
                <span
                  style={{color: '#de7033', cursor: 'pointer'}}
                  onClick={() => scrollToGuides('bundle')}
                >
                  {' '}
                  bundle{' '}
                </span>
                or as part of a
                <Link
                  style={{textDecoration: 'none', color: '#de7033'}}
                  to='./subscription'
                >
                  {' '}
                  Sivv subscription
                </Link>
                . All options include <b>unlimited updates</b>.
              </HeadingNew>
            </Box>
          </Flex>
          <Flex
            width={[1, 1 / 2]}
            ml={[0, 3, 3]}
            mt={['40px', '40px', 0]}
            justifyContent='center'
          >
            <img
              src={Brain}
              width='100%'
              style={{maxWidth: '500px'}}
              height='100%'
              alt='Sivv_Brain'
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        px={['25px', '75px']}
        py={['50px', '75px']}
        flexDirection='column'
        style={{background: '#82a6b2'}}
      >
        <HeadingNew size='h1' palette='white' as='h1' justify condensed centerText>
          Access thousands of actionable ideas in a fraction of the time it would
          otherwise take
        </HeadingNew>
      </Flex>
    </>
  )
}

export default LandingPage
