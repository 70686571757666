import { css } from 'styled-components';

export const primary = 'OpenSans, sans-serif';
export const secondary = 'Quicksand, sans-serif';

export const regular = '400';
export const bold = '600';

export const regularTitle = '400';
export const boldTitle = '500';

export const fontSmoothing = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const regularCopy = css`
  ${fontSmoothing};
  font-family: ${primary};
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
`;

export const h0 = css`
  ${fontSmoothing};
  font-family: ${secondary};
  font-style: normal;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
  @media screen and (min-width: 520px) {
    font-size: 50px;
    line-height: 44px;
  }
  @media screen and (min-width: 833px) {
    font-size: 85px;
    line-height: 85px;
  }
`;

export const h1 = css`
  ${fontSmoothing};
  font-family: ${secondary};
  font-style: normal;
  font-size: 26px;
  line-height: 38px;
  margin: 0;
  @media screen and (min-width:640px) {
    font-size: 32px;
  }
`;

export const h2 = css`
  ${fontSmoothing};
  font-family: ${secondary};
  font-style: normal;
  font-size: 20px;
  line-height: 36px;
  margin: 0;
  @media screen and (min-width:640px) {
    font-size: 26px;
  }
`;

export const h3 = css`
  ${fontSmoothing};
  font-family: ${secondary};
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
`;

// export const link = css`
//   ${fontSmoothing};
//   color: ${irisBlue};
//   text-decoration: underline;
//   cursor: pointer;

//   &:hover {
//     color: ${esBrightBlue};
//   }
// `;
