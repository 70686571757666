import React from 'react'
import {Box} from 'rebass'
import Expandable from '../../components/FormElements/Expandable'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Container from '../../components/FormElements/Container'
import FormPersonalInfo from '../Form/PersonalInfo'
import ChangePasswordForm from '../Form/ChangePassword'
import ChangeEmailForm from '../Form/ChangeEmail'
import DeleteAccountForm from '../Form/DeleteAccount'

const MyDetails = () => {
  return (
    <Container background='#d4e0e5'>
      <Box width={1} mb={3}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          My Details
        </HeadingNew>
      </Box>
      <Expandable title='Personal Info' isOpen>
        <FormPersonalInfo />
      </Expandable>

      <Expandable title='Change Email'>
        <ChangeEmailForm />
      </Expandable>

      <Expandable title='Change Password'>
        <ChangePasswordForm />
      </Expandable>

      <Expandable title='Delete Account'>
        <DeleteAccountForm />
      </Expandable>
    </Container>
  )
}

export default MyDetails
