import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Flex} from 'rebass'
import {cancelSubscription} from '../../actions/paymentActions'
import SubscriptionDetails from './SubscriptionDetails'
import Premium from '../../staticPages/Premium'
import PaymentContainer from '../PaymentContainer'
import Modal from '../../components/FormElements/Modal'

const CheckoutContainer = ({
  success,
  userID,
  subscription,
  cancelSubscription,
  home
}) => {
  const [showPaymentForm, togglePaymentForm] = useState(false)
  const [subscriptionType, toggleSubscriptionType] = useState(null)

  const upgradeSubscription = (subscriptionType) => {
    toggleSubscriptionType(subscriptionType)
    togglePaymentForm(!showPaymentForm)
  }

  const cancelThisSubscription = () => {
    cancelSubscription(userID)
  }

  if (subscription.type !== 'basic' && !home) {
    return (
      <SubscriptionDetails
        success={success}
        subscription={subscription}
        cancelSubscription={cancelThisSubscription}
      />
    )
  }

  return (
    <Flex flexDirection='column' alignItems='center' width={1}>
      <Premium upgrade={userID} upgradeSubscription={upgradeSubscription} />
      {showPaymentForm && (
        <Modal closeModal={() => togglePaymentForm(false)}>
          <PaymentContainer
            product={subscriptionType}
            home={home}
            closeModal={togglePaymentForm}
          />
        </Modal>
      )}
    </Flex>
  )
}

CheckoutContainer.propTypes = {
  cancelSubscription: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  userID: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const p = state.payment
  const u = state.user
  return {
    processing: p.subscription.processing,
    error: p.subscription.error,
    success: p.subscription.success,
    userID: u._id,
    subscription: u.subscription,
  }
}

export default connect(mapStateToProps, {
  cancelSubscription,
})(CheckoutContainer)
