import React, {useEffect}  from 'react'
import {Box, Flex, Image} from 'rebass'
import {Link} from 'react-router-dom'
import EmailCapture from '../EmailCapture'
// -- Components
import HeadingNew from '../../components/FormElements/HeadingNew'
import Publications from '../../staticPages/Publications2.gif'

const Newsletter = ({popUp}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      width={1}
      pt={popUp ? '0px' : '50px'}
      pb={popUp ? '25px' : '50px'}
      px={popUp ? '5px' : ['15px', '75px']}
      justifyContent='center'
      alignItems='center'
      flexDirection={['column']}
      mb={['15px', 0]}
      style={{minHeight: popUp ? null : '80vh', background: '#e8eef4'}}
    >
      <Box width={1} style={{maxWidth: '900px'}}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          Subscribe to the Sivv digest
        </HeadingNew>
      </Box>
      <Box width={1} style={{maxWidth: '900px'}} mt={['10px', '25px']}>
        <HeadingNew size='h3' palette='medium' as='h3'>
          Sivv summarises actionable insights from leading books, articles and
          research to help you get smarter, faster.
        </HeadingNew>
      </Box>
      <Flex
        width={[1, 1, 3 / 4]}
        justifyContent='space-between'
        my={['25px', '50px']}
        style={{maxWidth: '900px'}}
      >
        <Image
          src={Publications}
          width='100%'
          height='100%'
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
        />
      </Flex>
      <Flex flexDirection={['column', 'column', 'row']} style={{maxWidth: '700px'}}>
        <EmailCapture
          category='newsletter'
          landing
          callToAction=<span>
            Subscribe to our digest (
            <Link to='./sample-newsletter'>view sample</Link>) to receive a selection
            of the best things that we publish each month:
          </span>
        />
      </Flex>
    </Flex>
  )
}

export default Newsletter
