import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {Flex, Image} from 'rebass'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Button from '../../components/FormElements/Button'
import PublicationsSquare from '../../staticPages/Publications2.jpg'

const StyledLink = styled(Link)`
  width: 100%;
`

const SideAdvert = ({tag}) => {
  return (
    <Flex
      width={1}
      pt='15px'
      pb='25px'
      my='20px'
      flexDirection='column'
      style={{borderBottom: '1px solid #FF6600', borderTop: '1px solid #FF6600'}}
    >
      <HeadingNew size='h2' as='h2'>
        Get smarter, faster
      </HeadingNew>
      <HeadingNew size='h3' as='h3'>
        Use the <StyledLink to='/feed'>Sivv Knowledge Feed</StyledLink> to acccess
        summaries of cutting-edge research, interesting blog posts and articles from
        leading publications.
      </HeadingNew>
      <Flex width={1} justifyContent='space-between' mt='10px'>
        <Image
          src={PublicationsSquare}
          width='100%'
          height='100%'
          mb={2}
          mt={2}
          ml={0}
          style={{
            float: 'left',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
        />
      </Flex>
      <Flex width={1} justifyContent='center' mt={['10px', '15px']}>
        <StyledLink to='/feed'>
          <Button onClick={() => console.log('')} fullWidth>
            Browse now
          </Button>
        </StyledLink>
      </Flex>
    </Flex>
  )
}

export default SideAdvert
