import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import styled from 'styled-components'
import {Box, Flex} from 'rebass'
import EmailCapture from '../EmailCapture'
// import HomeLink from '../../components/FormElements/HomeLink'
import {getLatestArticles} from '../../actions/searchActions'
// -- Components
import SearchResultsContainer from '../SearchResultsContainer'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Text from '../../components/FormElements/Text'
// import ArticleTiles from '../../components/ArticleTiles'
import Icons from '../../images/icons/icons'


const LSSHolder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: ${(props) => (props.landing ? '205px' : '300px')};
  max-height: 300px;
  overflow-y: auto;
  scrollbar-color: ${ props => props.landing ? '#37517e #2e4369' : '#eff5f8 #fff'};

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${ props => props.landing ? '#2e4369' : '#fff'};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${ props => props.landing ? '#37517e' : '#eff5f8'};
    border-radius: 25px;
  }
  & -ms-overflow-style: none;
  @media screen and (max-width: 52em) {
    max-height: 275px;
    width: 100%;
  }

  @media screen and (max-width: 40em) {
    max-height: ${(props) => (props.landing ? '155px' : '275px')};
    width: 100%;
  }
`

const LatestArticles = ({
  latestArticles,
  getLatestArticles,
  related,
  theme,
  token,
  articleID,
  history,
  match,
  landing
}) => {
  useEffect(() => {
    getLatestArticles(theme, articleID, history.location.search)
  }, [articleID, getLatestArticles, theme])

  const images = {
    'Advice / Tip': Icons.tip,
    'Framework / Model': Icons.model,
    'Idea / Concept': Icons.idea,
    Innovation: Icons.innovation,
    Quote: Icons.quote,
    'Report / Study': Icons.report,
    Resource: Icons.resource,
    'Statistic / Fact': Icons.statistic,
    Theory: Icons.theory,
    Trend: Icons.trend,
  }

  const generateList = (start, finish) => {
    return latestArticles.slice(start, finish).map((a, idx) => (
      <div
        key={idx}
        style={{
          display: 'flex',
          marginTop: '10px',
          maxHeight: '40px',
          alignItems: 'center',
        }}
      >
        <img
          src={a.sourceLogo}
          alt='source'
          width='20px'
          style={{marginRight: '10px'}}
        />
        <Text>
          <a
            href={`https://www.sivv.io/article/${a._id}/${a.title.replace(
              / /g,
              '-'
            ).replace(/\//g, '')}`}
            target='null'
          >
            {a.title}
          </a>
        </Text>
      </div>
    ))
  }

  if (related) {
    return (
      <Flex
        width={1}
        pt={['0px', '15px']}
        pl={['15px', '50px']}
        pr={['15px', '50px']}
        pb={['15px', '15px', '50px']}
        flexWrap='wrap'
        style={{maxWidth: '900px'}}
      >
        <Box width={1} mb={[0, '15px']} pl={2}>
          <HeadingNew size='h3' palette='medium' as='h3'>
            Other posts you may find interesting
          </HeadingNew>
        </Box>
        <Flex
          width={[1, 1 / 2]}
          pl={2}
          flexDirection='column'
          justifyContent='space-around'
        >
          {generateList(0, 5)}
        </Flex>
        <Flex
          width={[1, 1 / 2]}
          pl={2}
          flexDirection='column'
          justifyContent='space-around'
        >
          {!token && <EmailCapture category='newsletter' related />}
          {token && generateList(5, 10)}
        </Flex>
      </Flex>
    )
  }

  //  <ArticleTiles articles={latestArticles} home />

  if (landing) {
    return (
      <LSSHolder landing>
        <SearchResultsContainer home landing />
      </LSSHolder>
    )
  }

  return (
    <Flex
      width={1}
      py={related ? ['15px'] : ['15px', '50px']}
      px={related ? ['15px'] : ['15px', '75px']}
      flexWrap='wrap'
      flexDirection={['column', 'row']}
      mb={['15px', 0]}
    >
      <Box width={1} mb={['25px', '25px']}>
        <HeadingNew size='h1' palette='medium' as='h1'>
          Latest Posts
        </HeadingNew>
      </Box>
      <Flex flexDirection={['column', 'column', 'row']}>
        <Flex width={[1, 1, 1 / 2]}>
          <LSSHolder>
            <SearchResultsContainer home />
          </LSSHolder>
        </Flex>
        <Box width={[1, 1, 1/2]} pl={[0, 0, 3]}>
          <EmailCapture category='newsletter' landing/>
        </Box>
      </Flex>
    </Flex>
  )
}

LatestArticles.propTypes = {
  latestArticles: PropTypes.array.isRequired,
  getLatestArticles: PropTypes.func.isRequired,
  related: PropTypes.bool,
  theme: PropTypes.string,
  token: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    latestArticles: state.search.latestArticles,
  }
}

export default withRouter(
  connect(mapStateToProps, {getLatestArticles})(LatestArticles)
)

// <Box width={1} mb='15px'>
// <Text palette='light'>
// Have you written or read something interesting that we should be covering?
// Email us at{' '}
// <a style={{color: '#FF6600'}} href='mailto:info@sivv.io'>
// info@sivv.io
// </a>{' '}
// to let us know.
// </Text>
// </Box>

// <Flex
//   width={1}
//   alignItems='center'
//   justifyContent='center'
//   mt={3}
//   flexDirection={['column', 'row']}
// >
//   <HeadingNew size='h3' palette='medium' as='h3'>
//     <div style={{marginRight: '10px'}}>
//       {' '}
//       Or, create a topic alert:
//     </div>
//   </HeadingNew>
//   <HomeLink label='CREATE ALERT' link='/alerts' />
// </Flex>
