import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import iconClose from '../../../images/icons/close.svg'

const WrapperModal = styled.div`
  position: fixed;
  top: 0;
  padding: 0;
  z-index: 50000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContainerModal = styled.div`
  position: fixed;
  /* padding: 12px; */
  padding: 0px 20px 0px 20px;
  background-color: #e8eef4;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  z-index: 2;
  width: calc(100% - 32px);
  max-width: ${(props) => (props.article ? null : '500px')};
  max-height: 90vh;
  border-radius: 4px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #eff5f8;
    border-radius: 25px;
  }
`

const CloseModal = styled.button`
  border: none;
  padding: 0;
  /* margin: 0; */
  margin-top: 5px;
  background: none;
  float: right;
  img {
    width: 24px;
    height: 24px;
  }
`
const Modal = ({children, closeModal, article, noClose}) =>

  ReactDOM.createPortal(
    <WrapperModal>
      <ContainerModal article={article}>
        {!noClose && (
          <CloseModal onClick={() => closeModal()}>
            <img src={iconClose} alt='Close modal' />
          </CloseModal>
        )}
        {children}
      </ContainerModal>
    </WrapperModal>,
    document.getElementById('modal-root')
  )

Modal.propTypes = {
  children: PropTypes.node,
  closeModal: PropTypes.func,
  article: PropTypes.bool,
  noClose: PropTypes.bool,
}

export default Modal
