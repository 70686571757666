/*
  Text:
  - can be any element
  - using fonts definitions from 'styles/fonts'
  - using colors definitions from 'styles/colors'

  Params:
  weight: regular | bold (default to regular)
  palette: dark | medium | light (default to dark)

  small: boolean

  Example:
  <Text as='p' weight='medium' palette='dark>Copy</Text>'
*/

import styled, {css} from 'styled-components'
import {regularCopy, regular, bold} from '../../../styles/fonts'
import {
  darkTitle as darkColor,
  lightTitle as lightColor,
  error,
} from '../../../styles/colors'

const availableColour = {
  dark: darkColor,
  light: lightColor,
  white: '#FFFFFF',
  orange: '#ff6600',
}
const availableWeight = {regular, bold}

export default styled.div`
  ${regularCopy}
  font-weight: ${(props) =>
    props.weight ? availableWeight[props.weight] : regular};
  color: ${(props) =>
    props.palette ? availableColour[props.palette] : availableColour.dark};
  text-align: ${(props) => (props.textAlign || 'left')};
  margin-bottom: ${(props) => (props.mb ? '10px' : '0px')};
  /* ----------------------------------------- SMALL */
  ${(props) =>
    props.small &&
    css`
      font-size: 12px;
    `}

  /* ----------------------------------------- SMALL */
  ${(props) =>
    props.errorForm &&
    css`
      padding: 16px;
      border: solid 1px ${error};
      border-radius: 4px;
      color: ${error};
      background: white;
    `}
`
