import keyMirror from '../../../utils/keyMirror'

export default keyMirror(
  {
    RESET_ERROR: null,
    SET_ERROR: null,
    SET_FORM_STATUS: null,
  },
  'FORM_SIGN_IN'
)
