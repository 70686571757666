import React, {Component} from 'react'
import FroalaEditor from 'react-froala-wysiwyg'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/plugins.pkgd.min.css'
import 'froala-editor/css/froala_editor.min.css'
import {Redirect} from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import {Flex, Box} from 'rebass'
import editorConfig from '../../utils/editorConfig'
import Categoriser from '../Categoriser/Categoriser'
import Input from '../FormElements/Input'
import Button from '../FormElements/Button'
import HeadingNew from '../FormElements/HeadingNew'
import Select from '../FormElements/Select'
import FileUpload from '../../containers/FileUpload'
// import TextBox from '../FormElements/TextBox'
// Components
import Modal from '../FormElements/Modal'
import Container from '../FormElements/Container'
// import ComposeSource from './ComposeSource'
import Article from '../Article/Article'
import ComposeAdmin from './ComposeAdmin'

// Editor plugins
require('froala-editor/js/plugins/align.min')
require('froala-editor/js/plugins/lists.min')
require('froala-editor/js/plugins/link.min')
require('froala-editor/js/plugins/fullscreen.min')
require('froala-editor/js/plugins/char_counter.min')

class ComposeInputs extends Component {
  static propTypes = {
    addCategory: PropTypes.func.isRequired,
    addArticleType: PropTypes.func.isRequired,
    captureInput: PropTypes.func.isRequired,
    draft: PropTypes.object.isRequired,
    edit: PropTypes.bool,
    error: PropTypes.bool,
    feedback: PropTypes.object,
    removeCategory: PropTypes.func.isRequired,
    sourceType: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    topics: PropTypes.array.isRequired,
    type: PropTypes.array.isRequired,
    theme: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    userName: PropTypes.string,
    userID: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      message: null,
      update: false,
      draft: {},
      // showApplications: false,
      // showBackground: false,
      // showExamples: false,
      // showOther: false,
      error: {},
      showImageInput: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.feedback.time !== this.props.feedback.time) {
      this.setState({message: nextProps.feedback.message})
      setTimeout(
        () =>
          this.setState({
            message: null,
            published: nextProps.feedback.message === 'Article published',
          }),
        1500
      )
    }
  }

  submit = () => {
    const {draft} = this.props
    // Add to require summary:
    // && draft.summary
    if (draft.title && draft.sourceURL) {
      this.props.submit('published')
    } else {
      this.setState({
        error: {
          title: draft.title ? null : 'Enter title',
          // Add this to require summary:
          // summary: draft.summary ? null : true,
          sourceURL: draft.sourceURL ? null : true,
        },
        message: 'Something is missing',
      })
      setTimeout(() => this.setState({message: null}), 1500)
    }
  }

  generateLogo = async (e) => {
    this.captureInput(e, `sourceURL`)

    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    const regex = new RegExp(expression)

    if (e.target.value.trim().match(regex)) {
      const response = await axios.post('https://www.sivv.io/getLogo', {
        url: e.target.value.trim(),
      })
      if (response.data.logo) {
        this.captureInput(response.data.logo, `sourceLogo`)
      }
    }
  }

  modalCheck = () => {
    const {draft} = this.props
    if (draft.title && draft.sourceURL && draft.summary) {
      return true
    }
    this.setState({
      error: {
        title: draft.title ? null : 'Enter title',
        sourceURL: draft.sourceURL ? null : 'Enter source',
        // theme: draft.theme ? null : 'Select theme',
        // type: draft.type ? null : 'Select type',
        summary: draft.summary ? null : true,
      },
      message: 'Something is missing',
    })
    setTimeout(() => this.setState({message: null}), 1500)
    return false
  }

  captureInput = (e, label) => {
    if (label === 'title' && this.state.error.title)
      this.setState({error: {...this.state.error, title: null}})
    if (label === 'sourceURL' && this.state.error.sourceURL)
      this.setState({error: {...this.state.error, sourceURL: null}})
    if (label === 'summary' && this.state.error.summary)
      this.setState({error: {...this.state.error, summary: null}})
    // if (label === 'digest' && this.state.error.digest)
    //   this.setState({error: {...this.state.error, digest: null}})
    this.props.captureInput(e, label)
  }

  addArticleType = (name, value) => {
    // if (this.state.error.type && name === 'type')
    //   this.setState({error: {...this.state.error, type: null}})
    // if (this.state.error.theme && name === 'theme')
    //   this.setState({error: {...this.state.error, theme: null}})
    this.props.addArticleType(name, value)
  }

  close = () => {
    this.setState({message: null, preview: null})
  }

  showPreview = () => {
    this.modalCheck() && this.setState({preview: true})
  }

  reveal = (label) => {
    this.setState({[label]: !this.state[label]})
  }

  adminCategorise = (label) => {
    this.captureInput(!this.props.draft[label], label)
  }

  render() {
    const Draft = this.props.draft
    const Status = Draft.status
    if (this.state.published) return <Redirect to='/myArticles' />

    return (
      <Container background='#d4e0e5' minHeight>
        <HeadingNew size='h1' palette='medium' as='h1'>
          {this.props.edit ? 'Edit Article' : 'Submit Article'}
        </HeadingNew>
        <Flex flexWrap='wrap' flexDirection='row' width={1}>
          <Box
            pr={[0, 0, 2]}
            pt={3}
            flex={['auto', 'auto', '1 1 auto']}
            width={[1, 1, 2 / 5]}
          >
            <Input
              label='Title'
              onChange={(e) => this.captureInput(e, 'title')}
              value={Draft.title}
              inputProps={{
                type: 'text',
                id: 'title',
                autoComplete: 'title',
              }}
              error={this.state.error.title}
              limit={100}
            />
          </Box>

          <Flex width={[1, 1, 2 / 5]} pt={3} alignItems='center'>
            <Input
              label='Source URL / Title'
              onChange={(e) => this.generateLogo(e)}
              value={Draft.sourceURL}
              image={Draft.sourceLogo}
              inputProps={{
                type: 'text',
                id: 'sourceURL',
                autoComplete: 'source',
              }}
              error={this.state.error.sourceURL}
            />
            <Flex width={2 / 10} alignItems='center' justifyContent='center'>
              {Draft.sourceLogo ? (
                <img
                  src={Draft.sourceLogo}
                  height='35px'
                  onClick={() => this.captureInput('', `sourceLogo`)}
                  alt='source logo'
                />
              ) : (
                <Flex flexDirection='column' alignItems='center'>
                  <span style={{color: '#fff'}}>No logo</span>
                  <span
                    onClick={() => this.setState({showImageInput: true})}
                    style={{color: '#fff', cursor: 'pointer'}}
                  >
                    +
                  </span>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        {this.state.showImageInput && (
          <Flex flexWrap='wrap' mt={3} flexDirection='row' width={[1, 1, 1 / 3]}>
            <Input
              label='Image URL'
              onChange={(e) => this.captureInput(e, 'sourceLogo')}
              value={Draft.sourceLogo}
              inputProps={{
                type: 'text',
                id: 'title',
                autoComplete: 'title',
              }}
              error={this.state.error.sourceLogo}
            />
          </Flex>
        )}

        <Box mt={3} width={1}>
          <HeadingNew size='h3' palette='medium' as='h2' spacing='0 0 8px'>
            Summary
          </HeadingNew>
          <div
            style={{
              border: this.state.error.summary ? '1px solid red' : 'none',
              borderRadius: '10px',
            }}
          >
            <FroalaEditor
              config={{
                ...editorConfig.summary,
                charCounterMax:
                  this.props.user.subscription.type === 'admin' ? 5000 : 1500,
              }}
              onModelChange={(e) => this.captureInput(e, 'summary')}
              model={Draft.summary}
            />
          </div>
        </Box>

        <Categoriser
          addCategory={this.props.addCategory}
          topics={this.props.topics}
          articleTopics={Draft.topics}
          removeCategory={this.props.removeCategory}
          captureInput={this.props.captureInput}
        />

        {this.props.user.subscription.type === 'admin' && (
          <ComposeAdmin
            adminCategorise={this.adminCategorise}
            premium={Draft.premium}
            newsletter={Draft.newsletter}
            captureInput={this.captureInput}
            relatedTitle={Draft.relatedTitle}
            relatedURL={Draft.relatedURL}
          />
        )}

        {this.props.user.subscription.type === 'admin' && (
          <Box
            pr={[0, 0, 2]}
            pt={3}
            flex={['auto', 'auto', '1 1 auto']}
            width={[1 / 2, 1 / 2, 1 / 5]}
          >
            <Select
              options={this.props.type}
              onChange={this.addArticleType}
              placeholder='Select'
              name='type'
              defaultValue={Draft.type}
              label='Post type'
              error={this.state.error.type}
            />
          </Box>
        )}

        {this.props.user.subscription.type === 'admin' && (
          <FileUpload image={Draft.image} articleID={Draft._id} />
        )}

        <Flex flexWrap='wrap' flexDirection='row' width={1}>
          <Box
            pr={[0, 0, 2]}
            pt={4}
            flex={['auto', 'auto', '1 1 auto']}
            width={[1, 1, 1 / 3]}
          >
            <Button
              onClick={() => this.submit()}
              palette='primary'
              fullWidth
              feedback='Email updated'
              // showFeedback={showFeedback}
            >
              {Status === 'published' ? 'Update' : 'Publish'}
            </Button>
          </Box>
          <Box pt={4} flex={['auto', 'auto', '1 1 auto']} width={[1, 1, 1 / 3]}>
            <Button
              onClick={() =>
                this.props.submit(Status === 'published' ? 'unpublish' : 'draft')
              }
              palette='primary'
              fullWidth
              feedback='Email updated'
              // showFeedback={showFeedback}
            >
              {Status === 'published' ? 'Unpublish' : 'Save draft'}
            </Button>
          </Box>
          {this.state.preview && (
            <Modal closeModal={this.close}>
              <Article
                data={Draft}
                userName={this.props.userName}
                user={this.props.user}
                preview
                comments={[]}
              />
            </Modal>
          )}
          {this.state.message && (
            <Modal closeModal={this.close}>
              <Flex justifyContent='center' alignItems='center' p={3}>
                <HeadingNew size='h2' palette='medium' as='h2' spacing='0 0 8px'>
                  {this.state.message}
                </HeadingNew>
              </Flex>
            </Modal>
          )}
        </Flex>
      </Container>
    )
  }
}

export default ComposeInputs
