import React from 'react'
import {Link} from 'react-router-dom'
import {Flex, Box} from 'rebass'
import Fade from 'react-reveal/Fade'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Icons from '../images/icons/icons'
import Processing from './data_processing.svg'

const InfoPanel = (icon, text, description) => {
  return (
    <Flex
      width={1}
      justifyContent='flex-start'
      px={[0, 2]}
      mt={text === 'Discover' ? ['35px', '35px', '0px'] : ['35px', '35px']}
      flexDirection='column'
      px={['15px']}
    >
      <Flex width={1} alignItems='center'>
        <img
          src={Icons.SubscriptionArrow}
          width='22px'
          style={{marginRight: '5px', marginTop: '2px'}}
        />
        <HeadingNew
          size='h2'
          palette='medium'
          as='h2'
          style={{lineHeight: '1.2'}}
          justified
        >
          {text}
        </HeadingNew>
      </Flex>
      <Flex width={1} mt={1}>
        <HeadingNew
          size='h3'
          palette='medium'
          as='h3'
          justify
          style={{lineHeight: '1.5', textAlign: 'justfy', textJustify: 'inter-word'}}
        >
          {description}
        </HeadingNew>
      </Flex>
    </Flex>
  )
}

const HowItWorks = ({home}) => {
  return (
    <Container home={home}>
      <HeadingNew size='h1' palette='medium' as='h1' style={{lineHeight: '1.2'}}>
        How it works
      </HeadingNew>
      <Flex
        width={1}
        flexDirection={['column', 'column', 'row']}
        justifyContent={['space-between', 'space-around']}
        alignItems={['flex-start', 'center']}
      >
        <Flex width={[1, 1 / 2]} flexDirection='column' >
          <Box mt={['25px', '25px', '25pxpx']}>
            <img
              src={Processing}
              width='100%'
              alt='Information processing'
              style={{maxHeight: '350px'}}
            />
          </Box>
        </Flex>
        <Flex width={[1, 1, 1 / 2]} flexDirection='column'>
          <Fade>
            {InfoPanel(
              'knowledge',
              'Discover',
              "Sivv scans the world's best information sources to find timeless and actionable ideas, advice and wisdom."
            )}
          </Fade>
          <Fade>
            {InfoPanel(
              'thought',
              'Summarise',
              'We separate the signal from the noise, distilling key insights into concise, structured summaries.'
            )}
          </Fade>
          <Fade>
            {InfoPanel(
              'time',
              'Curate',
              <div>
                We publish these through our
                <Link style={{textDecoration: 'none', color: '#ff6600'}} to='./feed'>
                  {' '}
                  knowledge feed
                </Link>
                ,
                <Link
                  style={{textDecoration: 'none', color: '#ff6600'}}
                  to='./library'
                >
                  {' '}
                  library{' '}
                </Link>
                and curated topic
                <Link
                  style={{textDecoration: 'none', color: '#ff6600'}}
                  to='./guides'
                >
                  {' '}
                  guides
                </Link>
                .
              </div>
            )}
          </Fade>
          {/* <Fade>
            {InfoPanel(
              'time',
              'Connect',
              <div>
                We are building a community of curious minds.
                <Link style={{textDecoration: 'none', color: '#ff6600'}} to='./'>
                  {' '}
                  Create an account
                </Link>{' '}
                to save, discuss and share new ideas.
                <Link
                  style={{textDecoration: 'none', color: '#ff6600'}}
                  to='./signin/submit'
                >
                  {' '}
                  Submit
                </Link>{' '}
                an interesting link, summary or insight and enjoy premium access free
                for a month.
              </div>
            )}
          </Fade> */}
        </Flex>
      </Flex>
    </Container>
  )
}

export default HowItWorks
