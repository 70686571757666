import {
  PAYMENT_START,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  RESET_PAYMENT_FORM,
} from '../actions/paymentActions'

const defaultState = {
  subscription: {processing: false, error: false, success: false},
  update: {processing: false, error: false, success: false},
  cancellation: {processing: false, error: false, success: false},
}

export default function payment(state = defaultState, action) {
  switch (action.type) {
    case PAYMENT_START:
      return {
        ...state,
        [action.label]: {processing: true, error: false, success: false},
      }

    case PAYMENT_SUCCESS:
      return {
        ...state,
        [action.label]: {processing: false, error: false, success: true},
      }

    case PAYMENT_ERROR:
      return {
        ...state,
        [action.label]: {processing: false, error: true, success: false},
      }

    case RESET_PAYMENT_FORM:
      return defaultState

    default:
      return state
  }
}
