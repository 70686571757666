import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Flex, Box} from 'rebass'
import {
  updatePreferences,
  addPreference,
  updateFeedPreference,
} from '../../../actions/forms/preferences'
import InstallPwa from '../../../components/InstallPwa'
import Checkbox from '../../../components/FormElements/Checkbox'
import HeadingNew from '../../../components/FormElements/HeadingNew'
import Button from '../../../components/FormElements/Button'
import Categories from '../../../reducers/categories'
import Container from '../../../components/FormElements/Container'
import Icons from '../../../images/icons/icons'

const ChangePreferencesForm = ({
  token,
  _id,
  updatePreferences,
  addPreference,
  alertPreferences,
  updateFeedPreference,
  feedPreferences,
  emailPreference,
  error,
  isPreferencesUpdated,
  loggedIn,
  subscription,
}) => {
  const [showFeedback, toggleShowFeedback] = useState(false)
  const [selectedCategories, selectCategory] = useState(['digest'])

  useEffect(() => {
    toggleFeedback(isPreferencesUpdated)
    if (!loggedIn) window.scrollTo(0, 0)
  }, [isPreferencesUpdated, loggedIn])

  const toggleFeedback = (isUpdated) => {
    if (isUpdated) {
      toggleShowFeedback(true)
      setTimeout(function () {
        toggleShowFeedback(false)
      }, 2500)
    }
  }

  const handleClick = (label) => {
    if (subscription && subscription.type !== 'basic') {
      addPreference(label)
    }
  }

  const Palette = 'medium'

  return (
    <Container
      background={loggedIn ? '#d4e0e5' : '#e8eef4'}
      horizontalAlign='center'
    >
      <Flex
        flexWrap='wrap'
        flexDirection='column'
        alignItems={['flex-start']}
        mb={2}
        width={1}
        style={{maxWidth: '900px'}}
      >
        <HeadingNew size='h1' palette={Palette} as='h1'>
          {loggedIn ? 'Preferences' : 'Sivv Alerts'}
        </HeadingNew>

        <Flex style={{marginBottom: '25px'}} width={[1, 1, 9 / 10]} />
        <HeadingNew size='h3' palette={Palette} as='h3'>
          {loggedIn
            ? 'Use the below form to create email alerts and receive relevant Sivv posts directly to your inbox (premium subscribers only).'
            : 'As a premium subscriber, you can create topic-specific email alerts and receive relevant Sivv posts directly to your inbox.'}
        </HeadingNew>
        <Flex style={{marginBottom: '15px'}} width={9 / 10} />
        <HeadingNew size='h2' palette={Palette} as='h2'>
          1 - Themes
        </HeadingNew>
        <HeadingNew size='h3' palette={Palette} as='h3'>
          You can opt to receive all posts that fall under any of the themes that we
          cover:
        </HeadingNew>
        <Flex width={1} flexWrap='wrap' mt={3} mb={3} p={3}>
          {Categories.themes.map((o, idx) => (
            <Flex width={[1 / 2, 1 / 3]} key={o.label} mt='3px'>
              {loggedIn ? (
                <Checkbox
                  name={o.label}
                  label={o.label}
                  inputProps={{
                    id: o.label,
                    onChange: () => handleClick(o.label),
                    checked: alertPreferences.includes(o.label),
                  }}
                />
              ) : (
                <Flex my={2} alignItems='center'>
                  <img
                    src={Icons.SubscriptionArrow}
                    width='15px'
                    style={{marginRight: '5px'}}
                    alt='arrow'
                  />
                  <HeadingNew size='h3' palette={Palette} as='h3'>
                    {o.label}
                  </HeadingNew>
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>
        <HeadingNew size='h2' palette={Palette} as='h2'>
          2 - Topics
        </HeadingNew>
        <HeadingNew size='h3' palette={Palette} as='h3'>
          Or, alternatively, narrow the scope by selecting from the following topics:
        </HeadingNew>
        <Flex width={1} flexWrap='wrap' mt={3} mb={3} p={3}>
          {Categories.topics.map((o, idx) => (
            <Flex width={[1 / 2, 1 / 4]} mt='3px' key={o.label}>
              {loggedIn ? (
                <Checkbox
                  name={o.label}
                  label={o.label}
                  key={idx}
                  inputProps={{
                    id: o.label,
                    onChange: () => handleClick(o.label),
                    checked: alertPreferences.includes(o.label),
                  }}
                />
              ) : (
                <Flex my={2} alignItems='center'>
                  <img
                    src={Icons.SubscriptionArrow}
                    width='15px'
                    style={{marginRight: '5px'}}
                    alt='arrow'
                  />
                  <HeadingNew size='h3' palette={Palette} as='h3'>
                    {o.label}
                  </HeadingNew>
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>

        {loggedIn && (
          <Box mt='5px' mb='15px' width={[1 / 2, 1 / 2, 1 / 4]}>
            <Button
              onClick={() =>
                updatePreferences(_id, alertPreferences, feedPreferences)
              }
              palette='primary'
              fullWidth
              feedback='Preferences updated'
              showFeedback={showFeedback}
            >
              Update Preferences
            </Button>
          </Box>
        )}
        <InstallPwa />
      </Flex>
    </Container>
  )
}

ChangePreferencesForm.propTypes = {
  token: PropTypes.string,
  _id: PropTypes.string.isRequired,
  topics: PropTypes.array.isRequired,
  updatePreferences: PropTypes.func.isRequired,
  addPreference: PropTypes.func.isRequired,
  alertPreferences: PropTypes.array.isRequired,
  emailPreference: PropTypes.string.isRequired,
  error: PropTypes.object,
  isPreferencesUpdated: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const {
    token,
    _id,
    alertPreferences,
    feedPreferences,
    emailPreference,
    subscription,
  } = state.user
  const {topics} = state.article
  const {error, isPreferencesUpdated} = state.forms.preferences
  return {
    token,
    _id,
    topics,
    alertPreferences,
    feedPreferences,
    emailPreference,
    error,
    isPreferencesUpdated,
    subscription,
  }
}

export default connect(mapStateToProps, {
  updatePreferences,
  addPreference,
  updateFeedPreference,
})(ChangePreferencesForm)

// <Box mt={3}>
//   <HeadingNew size='h2' palette='orange' as='h2'>
//     Feed
//   </HeadingNew>
// </Box>
//
//   <Flex width={1} mb={3} alignItems='center'>
//     <Flex width={[1, 1 / 3]}>
//       <HeadingNew size='h3' palette={Palette} as='h3'>
//         Default feed display:
//       </HeadingNew>
//     </Flex>
//     <Flex width={[1, 1 / 3]} justifyContent='space-around'>
//       <Radio
//         label='Condensed'
//         name='Condensed'
//         inputProps={{
//           checked: feedPreferences.includes('condensed'),
//           onChange: () => updateFeedPreference('condensed'),
//         }}
//         light
//       />
//       <Radio
//         label='Full'
//         name='Full'
//         inputProps={{
//           checked: !feedPreferences.includes('condensed'),
//           onChange: () => updateFeedPreference('full'),
//         }}
//         light
//       />
//     </Flex>
//   </Flex>

// <Flex
//   style={{borderTop: '1px solid white', marginBottom: '25px'}}
//   width={[1, 1, 9 / 10]}
// />
// <HeadingNew size='h2' palette='orange' as='h2'>
//   Weekly digest
// </HeadingNew>
// <HeadingNew size='h3' palette={Palette} as='h3'>
//   Receive a weekly selection of our most popular posts alongside updates on
//   new Sivv features and initiatives:
// </HeadingNew>
// <Flex width={1} flexWrap='wrap' mt={3} mb={3} p={3}>
//   <Checkbox
//     name='Digest'
//     label='Subscribe to digest'
//     inputProps={{
//       id: 'digest',
//       name: 'digest',
//       onChange: () => handleClick('digest'),
//       checked: loggedIn
//         ? alertPreferences.includes('digest')
//         : selectedCategories.includes('digest'),
//     }}
//   />
// </Flex>
// {!loggedIn && (
//   <EmailCapture category='alert' alertCategories={selectedCategories} />
// )}
