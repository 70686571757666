import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const SubHeading = styled.div`
  font-weight: 600;
  margin-top: ${(props) => (props.sp ? '0px' : '15px')};
`

export const SubSubHeading = styled.div`
  margin-top: 10px;
`

export const Quote = styled.div`
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
`

export const QuoteHeading = styled.span`
  font-weight: 450;
  font-style: italic;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 30px;
`

export const BigQuote = styled.div`
  width: 33%;
  padding: 10px 10px 10px 0px;
  text-align: center;
  color: #18b8c7;
  float: left;
  font-size: 20px;
  line-height: 30px;
  font-style: italic;
  @media screen and (max-width: 800px) {
    width: 50%;
  }
`

export const SidePanel = styled.div`
  width: 50%;
  margin: 5px 10px auto -50px;
  text-align: justify;
  padding: 10px;
  border: 1px solid #18b8c7;
  float: left;
  font-size: 14px;
  line-height: 24px;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }
`

export const NewParagraph = styled.div`
  margin: 10px 0px 5px 0px;
`

export const NumberedPoint = styled.div`
  margin: 10px 15px 0px 15px;
`

export const StyledLi = styled.li`
  color: #18b8c7;
  padding-left: ${(props) => (props.sp ? '10px' : '20px')};
  padding-right: ${(props) => (props.sp ? '10px' : '20px')};
  margin-top: ${(props) => (props.sp ? '5px' : '10px')};
  margin-bottom: ${(props) => (props.sp ? '5px' : '10px')};
`

export const StyledSpan = styled.span`
  color: #0a1f44;
  margin-left: -5px;
`

export const StyledLink = styled(Link)`
  color: #ff6600;
`

export const Source = styled.div`
  margin: 10px 0px 0px 0px;
`
