import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Box, Flex} from 'rebass'
// actions
import {updatePassword} from '../../../actions/forms/password'
// utils
import {
  passwordValidator,
  newPasswordValidator,
  newPasswordRepeatValidator,
} from '../../../utils/validators'
// Components
import Input from '../../../components/FormElements/Input'
import Button from '../../../components/FormElements/Button'
import Text from '../../../components/FormElements/Text'
import {Separator} from '../../../components/FormElements/utils'
import {LinkRouter} from '../../../components/FormElements/Link'

const ChangePasswordForm = ({
  token,
  _id,
  updatePassword,
  error,
  isPasswordUpdated,
}) => {
  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
    newPasswordRepeat: '',
  })
  const [formError, setFormError] = useState({})
  const [showFeedback, toggleShowFeedback] = useState(false)

  useEffect(() => {
    toggleFeedback(isPasswordUpdated)
  }, [isPasswordUpdated])

  const toggleFeedback = (isUpdated) => {
    if (isUpdated) {
      toggleShowFeedback(true)
      setTimeout(function () {
        toggleShowFeedback(false)
        setFormData({
          password: '',
          newPassword: '',
          newPasswordRepeat: '',
        })
      }, 4500)
    }
  }

  const handleInputChange = (e) => {
    e.preventDefault()
    setFormData({...formData, [e.target.id]: e.target.value})
  }

  const handleFiedlOnBlur = (validation) => {
    const fieldError = validation(formData)
    const existingErrors = {...formError}
    const errors = Object.assign(existingErrors, fieldError)
    setFormError(errors)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    // ------------------
    const passwordError = passwordValidator(formData)
    const newPasswordError = newPasswordValidator(formData)
    const newPasswordRepeatError = newPasswordRepeatValidator(formData)

    const existingErrors = {...formError}
    const errors = Object.assign(
      existingErrors,
      passwordError,
      newPasswordError,
      newPasswordRepeatError
    )
    setFormError(errors)
    const isFormReady = Object.values(errors).every((value) => value === null)
    isFormReady &&
      updatePassword({
        _id,
        password: formData.password,
        newPassword: formData.newPassword,
      })
  }

  return (
    <section>
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <Text as='p' palette='light'>
          Please complete the following input fields to change your password
        </Text>
        {/* ==================== ERROR */}
        {error && error.form && (
          <Flex
            flexWrap='wrap'
            flexDirection='row'
            alignItems={['normal', 'flex-end']}
            mx={-2}
            mb={2}
          >
            <Box p={2} pb={0} width={[1, 1, 1 / 3]}>
              <Text errorForm>{error.form}</Text>
            </Box>
          </Flex>
        )}

        {/* ==================== FIELDS */}
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          mb={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Password *'
              onChange={(e) => handleInputChange(e)}
              value={formData.password}
              inputProps={{
                type: 'password',
                id: 'password',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(passwordValidator),
              }}
              error={formError && formError.password}
            />
          </Box>
        </Flex>
        <Text as='p'>
          <LinkRouter to='/forgottenPassword'>Forgot your password?</LinkRouter>
        </Text>
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          my={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='New password *'
              onChange={(e) => handleInputChange(e)}
              value={formData.newPassword}
              inputProps={{
                type: 'password',
                id: 'newPassword',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(newPasswordValidator),
              }}
              error={formError && formError.newPassword}
            />
          </Box>
        </Flex>
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
          my={2}
        >
          <Box p={2} width={[1 / 2, 1 / 2, 1 / 3]}>
            <Input
              label='Retype new password *'
              onChange={(e) => handleInputChange(e)}
              value={formData.newPasswordRepeat}
              inputProps={{
                type: 'password',
                id: 'newPasswordRepeat',
                autoComplete: 'new-password',
                onBlur: () => handleFiedlOnBlur(newPasswordRepeatValidator),
              }}
              error={formError && formError.newPasswordRepeat}
            />
          </Box>
        </Flex>
        <Text as='p' weight='bold'>
          Password strength:
        </Text>
        <Text as='p'>
          Your password has to include lower and uppercase characters at least one
          number and be at least 8 characters long.
        </Text>
        <Separator />
        <Flex
          flexWrap='wrap'
          flexDirection='row'
          alignItems={['normal', 'flex-end']}
          mx={-2}
        >
          <Box px={2} pb='24px' width={[1 / 2, 1 / 2, 1 / 4]}>
            <Button
              onClick={(e) => handleSubmitForm(e)}
              palette='primary'
              fullWidth
              feedback='Password updated'
              showFeedback={showFeedback}
            >
              Change Password
            </Button>
          </Box>
        </Flex>
      </form>
    </section>
  )
}

ChangePasswordForm.propTypes = {
  token: PropTypes.string,
  _id: PropTypes.string.isRequired,
  error: PropTypes.object,
  isPasswordUpdated: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {token, _id} = state.user
  const {error, isPasswordUpdated} = state.forms.password
  return {token, _id, error, isPasswordUpdated}
}

export default connect(mapStateToProps, {updatePassword})(ChangePasswordForm)
