import React from 'react'
import {Flex, Box} from 'rebass'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '../components/FormElements/Button'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import HowItWorks from './HowItWorks'
import Benefits from './Benefits'
import LandingLibrary from './LandingLibrary'
import LandingGuidesNew from './LandingGuides'
import LandingFeed from './LandingFeed'
import Brain from './Brain.png'

const TextHolder = styled.div`
  width: 100%;
  @media screen and (max-width: 52em) {
    display: none;
  }
`

const TextHolder1 = styled.div`
  width: 100%;
  @media screen and (min-width: 52em) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  width: 100%;
`

const StyledFlex = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 1600px;
  align-self: center;
  align-items: center;
  @media screen and (min-width: 40em) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media screen and (min-width: 52em) {
    flex-direction: row;
  }
  @media screen and (min-width: 850px) {
    min-width: 835px;
  }
`

const LandingPage = () => {
  return (
    <>
      <Container home minHeight background='#005969'>
        <StyledFlex>
          <Flex width={[1, 1, 1 / 2]} flexDirection='column' justifyContent='center'>
            <Box mb={['10px', '10px', '0px']}>
              <HeadingNew
                size='h0'
                as='h1'
                style={{
                  color: '#FFFFFF',
                }}
              >
                <TextHolder>
                  Get smarter, <br /> faster
                </TextHolder>
                <TextHolder1>Get smarter, faster</TextHolder1>
              </HeadingNew>
            </Box>
            <Box
              width={1}
              mr={['0px', '20px']}
              mb={['30px', '30px', '0px']}
              mt={['0px', '0px', '20px']}
            >
              <HeadingNew
                size='h2'
                palette='white'
                as='h2'
                style={{lineHeight: '1.3', maxWidth: '600px'}}
              >
                Sivv makes it easy to access actionable insights from the world's best books, articles & research
              </HeadingNew>
            </Box>
            <TextHolder>
              <Flex mt={['15px', '25px', '30px']} width={[2 / 3, 1 / 2, 1 / 2]}>
                <StyledLink to='./feed'>
                  <Button onClick={() => console.log('')} fullWidth>
                    Start learning
                  </Button>
                </StyledLink>
              </Flex>
            </TextHolder>
          </Flex>
          <Flex
            width={[1, 1, 1 / 2]}
            justifyContent={['center', 'center', 'flex-end']}
            ml={[0, 2, 2]}
          >
            <img src={Brain} alt='Sivv_Brain' width='100%' height='100%' />
          </Flex>
          <TextHolder1>
            <Flex
              mt='40px'
              width={[2 / 3, 1 / 2, 1 / 2]}
              justifyContent='center'
              mx='auto'
            >
              <StyledLink to='./feed'>
                <Button onClick={() => console.log('')} fullWidth>Start learning</Button>
              </StyledLink>
            </Flex>
          </TextHolder1>
        </StyledFlex>
      </Container>
      <HowItWorks home />
      <Benefits />
      <LandingGuidesNew />
      <LandingLibrary />
      <LandingFeed />
    </>
  )
}

export default LandingPage
