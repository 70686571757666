import React from 'react'
import {Box, Flex, Image} from 'rebass'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import Icons from '../images/icons/icons'
import HeadingNew from '../components/FormElements/HeadingNew'
import Container from '../components/FormElements/Container'
import Button from '../components/FormElements/Button'

const BundleImageHolder = styled.div`
  width: 75px;
  @media screen and (min-width: 52em) {
    width: 15%;
  }
`
const TextHolder = styled.div`
  width: 100%;
  @media screen and (max-width: 52em) {
    display: none;
  }
`

const TextHolder1 = styled.div`
  width: 100%;
  @media screen and (min-width: 52em) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  width: 100%;
`
// The best ideas on key topics, all in one place

const LandingGuidesNew = ({background, maxWidth}) => {
  return (
    <Container home background={background || '#fff'} horizontalAlign='center'>
      <Flex width={1} flexDirection='column' style={{maxWidth: maxWidth || null}}>
        <HeadingNew size='h1' as='h1'>
          Sivv Guides
        </HeadingNew>
        <HeadingNew size='h3' as='h3'>
          Sivv guides summarise and curate the key takeaways from the world’s best
          books, articles and research
        </HeadingNew>
        <TextHolder>
          <Flex
            width={1}
            justifyContent='space-between'
            mt={['25px', '40px']}
            mb={['25px', '20px']}
            alignItems='center'
          >
            {[
              'ProductivityGuide',
              'CommunicationGuide',
              'HabitsGuide',
              'CareerGuide',
              'DecisionGuide',
            ].map((a) => (
              <BundleImageHolder key={a}>
                <Image
                  src={Icons[a]}
                  width='100%'
                  mb={[0, 2]}
                  mt={2}
                  ml={0}
                  style={{
                    float: 'left',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                />
              </BundleImageHolder>
            ))}
          </Flex>
        </TextHolder>

        <TextHolder1>
          <Flex
            width={1}
            justifyContent='space-around'
            mt={['25px', '40px']}
            mb={['25px', '20px']}
            alignItems='center'
          >
            {['ProductivityGuide', 'CommunicationGuide', 'CareerGuide'].map((a) => (
              <Image
                key={a}
                src={Icons[a]}
                width='90px'
                mb={[0, 2]}
                mt={2}
                ml={0}
                style={{
                  float: 'left',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              />
            ))}
          </Flex>
        </TextHolder1>

        <Flex width={1} justifyContent='center' mt={['10px', '25px']}>
          <Box width={[1 / 2, 1 / 3]}>
            <StyledLink to='/guides'>
              <Button onClick={() => console.log('')} fullWidth>
                Find out more
              </Button>
            </StyledLink>
          </Box>
        </Flex>
      </Flex>
    </Container>
  )
}

export default LandingGuidesNew
