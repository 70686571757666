import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Flex, Box} from 'rebass'
import {uploadLogo, clearImage} from '../../actions/articleActions'
import HeadingNew from '../../components/FormElements/HeadingNew'
import Loader from '../../components/FormElements/Loader'

const Files = styled.div`
  position: relative;

  input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
  }

  input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    border: 1px solid #92b0b3;
  }

  :after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: '';
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  :before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: ' or drag it here. ';
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
  }
`

const FileUpload = ({
  uploadLogo,
  image,
  userID,
  uploadError,
  uploading,
  clearImage,
  profile,
  articleID,
}) => {
  const [message, updateMessage] = useState(null)

  const selectFile = (e) => {
    const {files} = e.target
    const fileToUpload = files[0]
    if (!checkFileType(fileToUpload)) {
      updateMessage('The file selected must be png, jpeg or gif format.')
      return
    }
    if (!checkFileSize(fileToUpload)) {
      updateMessage('The file selected is too large.')
      return
    }
    updateMessage(null)
    const data = new FormData()
    data.append('file', fileToUpload)
    if (checkFileType(fileToUpload) && checkFileSize(fileToUpload) ) {
      const name = getName()
      data.append('imageName', name)
      uploadLogo(data, profile)
    }
  }

  const checkFileType = (file) => {
    const types = ['image/png', 'image/jpeg', 'image/gif']
    if (!types.includes(file.type)) return false
    return true
  }

  const getName = () => {
    let name
    if (image) name = image.split('/sivv-images/')[1]
    if (!image && !profile) {
      const timeStamp = Date.now()
      name = `${userID}_${timeStamp}`
    }
    if (profile) name = userID
    if (articleID) name = articleID
    return name
  }

  const checkFileSize = (file) => {
    const size = 15000000
    if (file.size > size) return false
    return true
  }

  return (
    <Flex
      mt={2}
      mb={5}
      width={1}
      flexDirection={['column', 'row']}
      alignItems='center'
    >
      {!image && (
        <Box width={[1, 1 / 2]}>
          <HeadingNew size='h3' palette='darkColor' as='h2' spacing='0 0 8px'>
            {profile ? 'Select a profile picture' : 'Upload an image'}
          </HeadingNew>
          <form
            method='POST'
            encType='multipart/form-data'
            onChange={(e) => selectFile(e)}
          >
            <Files>
              <input type='file' name='file' />
            </Files>
          </form>
        </Box>
      )}
      <Box width={[1, 1 / 2]}>
        {image && (
          <img style={{maxWidth: '100%', height: 'auto'}} src={image} alt='Upload' />
        )}
        {image && (
          <div onClick={() => clearImage()} style={{cursor: 'pointer'}}>
            Select another image
          </div>
        )}
        <Box p={3} style={{color: 'red'}}>
          {message}
        </Box>
        {uploadError && 'Something went wrong...'}
        {uploading && <Loader />}
      </Box>
    </Flex>
  )
}

FileUpload.propTypes = {
  uploadLogo: PropTypes.func.isRequired,
  image: PropTypes.string,
  userID: PropTypes.string.isRequired,
  uploadError: PropTypes.bool.isRequired,
  uploading: PropTypes.bool.isRequired,
  clearImage: PropTypes.func.isRequired,
  profile: PropTypes.bool,
  articleID: PropTypes.string,
}

const mapStateToProps = (state) => {
  const {article} = state
  return {
    uploading: article.uploading,
    uploadError: article.uploadError,
    userID: state.user._id,
  }
}

export default connect(mapStateToProps, {uploadLogo, clearImage})(FileUpload)
