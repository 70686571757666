import React from 'react'
import {Link} from 'react-router-dom'
import {Flex, Box, Image} from 'rebass'
import styled from 'styled-components'
import HeadingNew from '../components/FormElements/HeadingNew'
import Button from '../components/FormElements/Button'
import Text from '../components/FormElements/Text'
import Icons from '../images/icons/icons'

const SmallScreenHide = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 40em) {
    display: none;
  }
`

const BigScreenHide = styled.div`
  width: 30%;
  float: left;
  margin-right: 10px;
  margin-right: 10px;
  @media screen and (min-width: 40em) {
    display: none;
  }
`

const SmallScreenHideText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 40em) {
    display: none;
  }
`

const BigScreenHideText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: 40em) {
    display: none;
  }
`

const GuideAdvert = ({filterApplied, theme}) => {
  const Guides = {
    habits: {
      title: 'Managing Habits',
      description: 'Our guide to habits',
      points: [],
      link: 'Managing-Habits',
      image: 'HabitsGuide',
    },
    productivity: {
      title: 'Get more done',
      description:
        "The Sivv Guide to Being Productive distills the world's best thinking on how to make the most effective use of your time, energy and attention. The tips, strategies and research covered will help you:",
      points: [
        'Define your priorities',
        'Filter opportunities',
        'Decline requests',
        'Delegate effectively',
        'Efficiently manage tasks',
        'Optimise your schedule',
        'Overcome procrastination',
        'Stop multistasking',
        'Develop & maintain focus',
        'Avoid digital distractions',
        'Tame your inbox',
        'Have better meetings',
      ],
      link: 'Being-Productive',
      image: 'ProductivityGuide',
    },
    communication: {
      title: 'Communicate more effectively',
      description:
        'The Sivv Guide to Effective Communication sets-out a wide range of advice, techniques and frameworks that can be used to communicate more effectively. These will help you:',
      points: [
        'Craft effective messages',
        'Remain clear and concise',
        'Give better presentations',
        'Tackle difficult questions',
        'Listen actively',
        'Manage non-verbal cues',
        'Ask better questions',
        'Provide effective feedback',
        'Excel in video calls',
        'Structure writing logically',
        "Overcome writer's block",
        'Edit effectively',
      ],
      link: 'Effective-Communication',
      image: 'CommunicationGuide',
    },
    career: {
      title: 'Boost your career',
      description:
        "The Sivv Guide to Career Development covers the world's best thinking on how to determine which careers you are suited to, secure a job that aligns with these and then thrive within it. The tips, strategies and research covered will help you:",
      points: [
        'Decide what to do',
        'Find new opportunities',
        'Build your network',
        'Craft an effective CV',
        'Deal with interviews',
        'Find a mentor',
        'Ask for feedback',
        'Develop career resilience',
        'Avoid burnout',
        'Negotiate a pay rise',
        'Make a career change',
        'Strategically pursue success',
      ],
      link: 'Career-Development',
      image: 'CareerGuide',
    },
    // creativity: {
    //   title: 'Creativity',
    //   description: 'Our guide to creativity',
    //   points: [],
    //   link: 'EnhancingCreativity',
    // },
    // 'decision-making': {
    //   title: 'Making Better Decisions',
    //   description: 'Our guide to decision-making',
    //   points: [],
    //   link: 'Decision-Making',
    // },
    // career: {
    //   title: 'Career Development',
    //   description: 'Our guide to career development',
    //   points: [],
    //   link: 'CareerDevelopment',
    // },
    //
    // management: {
    //   title: 'Mastering Management',
    //   description: 'Our guide to management',
    //   points: [],
    // },
  }

  // 'Career Development': Icons.professionalDevelopment,
  // 'Making Better Decisions': Icons['Decision Making'],
  // 'Enhancing Creativity': Icons.Creativity,
  // 'Mastering Management': Icons.Management,

  let title = 'Sivv Guides'
  let description = 'The best ideas on key topics, all in one place'
  let guideLink = '/guides'
  let guideImage = Icons.ProductivityGuide
  let points = []
  if (filterApplied && Guides[filterApplied]) {
    title = Guides[filterApplied].title
    description = Guides[filterApplied].description
    points = Guides[filterApplied].points
    guideLink = `/guides/${Guides[filterApplied].link}`
    guideImage = Icons[Guides[filterApplied].image]
  }

  const textColor = theme ? 'medium' : 'white'

  return (
    <Flex
      width={1}
      flexDirection='row'
      justifyContent='space-around'
      style={{background: theme ? '#fff' : '#2e4369'}}
      py={theme ? 0 : '50px'}
    >
      <Flex flexDirection='column' style={{maxWidth: '1000px'}} width={1} p={3}>
        <HeadingNew size='h1' palette={textColor} as='h1'>
          {title}
        </HeadingNew>
        <HeadingNew size='h3' palette={textColor} as='h3' justify>
          <BigScreenHide>
            <Image
              src={guideImage}
              width='150px'
              m={[0, 3]}
              mt={[2, 2, 3]}
              style={{
                height: '100%',
                maxHeight: '200px',
                border: '1px solid #37517e',
              }}
            />
          </BigScreenHide>
          {description}
        </HeadingNew>
        <Flex justifyContent='space-around' mt='15px' flexWrap='wrap'>
          <SmallScreenHide>
            <Image
              src={guideImage}
              width='150px'
              m={[0, 3]}
              mt={[2, 2, 3]}
              style={{
                height: '100%',
                maxHeight: '200px',
                border: '1px solid #37517e',
              }}
            />
          </SmallScreenHide>
          <Flex
            flexWrap='wrap'
            mt={[2, 1, 3]}
            width={[1, 2 / 3]}
            justifyContent='center'
            alignItems='center'
          >
            {points.length > 0 &&
              points.map((p) => (
                <Flex width={[1 / 2]} mb={1} style={{minWidth: '180px'}}>
                  <SmallScreenHideText>
                    <HeadingNew size='h3' palette={textColor} as='h3' justify>
                      <img
                        src={Icons.SubscriptionArrow}
                        width='15px'
                        style={{marginRight: '5px'}}
                        alt='arrow'
                      />{' '}
                      {p}
                    </HeadingNew>
                  </SmallScreenHideText>
                  <BigScreenHideText>
                    <Text palette={textColor}>
                      <img
                        src={Icons.SubscriptionArrow}
                        width='15px'
                        style={{marginRight: '5px'}}
                        alt='arrow'
                      />{' '}
                      {p}
                    </Text>
                  </BigScreenHideText>
                </Flex>
              ))}
          </Flex>
          <Flex width={1} justifyContent='center' mt='25px'>
            <Box width={[1, 1, 1 / 2]}>
              <Link to={guideLink}>
                <Button fullWidth>FIND OUT MORE</Button>
              </Link>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default GuideAdvert
